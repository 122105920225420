import API from "./api";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('sharepoint').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (settings) => {
        return new Promise((resolve, reject) => {
            API.post('sharepoint/sync/settings' , null, {item:settings}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    sync: () => {
        return new Promise((resolve, reject) => {
            API.get('sharepoint/sync').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    syncDelete:()=>{
        return new Promise((resolve, reject) => {
            API.deleteItem('sharepoint/logs/sync/delete').then(
                result => {
                    resolve(result);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    backupDelete:()=>{
        return new Promise((resolve, reject) => {
            API.deleteItem('sharepoint/logs/backup/delete').then(
                result => {
                    resolve(result);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getSettings: () => {
        return new Promise((resolve, reject) => {
            API.get('sharepoint/sync/settings').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getlogs: (source,type) => {  //source = sync or backup , type = copied, failed, excluded, delete
        return new Promise((resolve, reject) => {
            API.get('sharepoint/logs/'+source+'/'+type).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;