import React, { useState  } from 'react'

import {Dialog,DialogContent, DialogActions, DialogTitle,Button,TextField,Typography} from '../../../ui/core';

import FileService from '../../../../services-admin/files';
export default function NewLevel(props) { 
    const [newName, setNewName] = useState(''); 
    const save = () => {
        FileService.newLevel(props.version,props.access,props.level || '/',newName)
        .then((result)=>{
            props.onRefresh();
            props.onClose();  
        },(err)=>{})
    } 
    return (
        <Dialog  onClose={props.onClose}>
             <DialogTitle ><Typography component="h2">Nieuw niveau</Typography></DialogTitle>
            <DialogContent >
                {props.level && <div>Dit niveau wordt geplaats onder het huidig geselecteerd niveau : {props.level}<br/><br/></div>}
                <TextField label="Naam voor het niveau" id="newlevel" fullWidth value={newName} onChange={(ev) => setNewName(ev.target.value)}></TextField>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={save} disabled={newName.length === 0}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
