import { useState, useEffect } from "react";
import { PushNotifications } from "../plugins/capacitor";
import Settings from "../AppSettings";
export default function useIncommingMessage() {
    const [message, setMessage] = useState()

    const handleNotification = (notification) => {
        if (notification.data){
            var newMessage = {};
           if (notification !== undefined && notification.title !== undefined) {
                newMessage.title = notification.title;
            } else if (notification.aps !== undefined) {
                if (notification.aps.alert !== undefined && notification.aps.alert.title !== undefined) {
                    newMessage.title = notification.aps.alert.title;
                } else {

                }
            }
            if (newMessage.title === undefined  ) {
                newMessage.title = notification.message || notification.data.message;
            }            
            if(notification.data){
                newMessage.view = notification.data.view;
            }
            if(newMessage.title){
                setMessage(newMessage);
            }
          
        }
    }

    useEffect(() => {
        if(PushNotifications && Settings.getPlatform()!=='web'){
           
            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification) => {
                    console.log('Push received: ' + JSON.stringify(notification));
                    handleNotification(notification);
                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification) => {
                    console.log('Push action performed: ' + JSON.stringify(notification));
                    if (notification.notification) {
                        handleNotification(notification.notification);
                    } else {
                        handleNotification(notification);
                    }
                }
            );
        }
    }, [])

    const reset = () => {
        console.log('resetting message')
        setMessage(null);
    }
    return [message, reset];

}

