/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect ,useContext} from 'react'

import GeneralService from '../../services/general';
import AnnouncementService from '../../services/announcements';
import File from '../../components/index/file';
import {Context} from '../../AppContext';

import {Typography} from '../ui/core';
export default function Announcement(props) {
    const context = useContext(Context);
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);

    useEffect(() => {
        AnnouncementService.markRead(props.announcement._id)
        .then((result)=>{
            context.checkAnnouncements();
        },(err)=>{ context.checkAnnouncements();})
    }, []);

    return (
        <React.Fragment>
            <div className="padding">
              
                <Typography component="h1">{props.announcement.title}</Typography>
                <div className="label">{GeneralService.formatDate(props.announcement.date,true)}</div>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(GeneralService.processTags(props.announcement.description)) }}></div>

                {props.announcement.files && props.announcement.files.map((file) => {
                let tmpFile = {};
                tmpFile.Name = file.filename.split('/').pop();
                tmpFile.DPath = file.dpath;
                tmpFile.Extention = file.filename.split('.').pop();
                return <File key={file.filename} file={tmpFile} />
            })}
              
            </div>
          
        </React.Fragment>

    )
}
