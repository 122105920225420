import React, { useContext, useState, useEffect } from 'react'
import Login from './login';
import UserService from '../../services/user';
import ThemesService from "../../services/themes";
import Apps from './apps';
import { Context } from '../../AppContext';
import { Button, CheckBox, Typography, Paper } from '../ui/core';
import { Warning } from '../ui/coreIcons';
import Settings from '../../AppSettings';
export default function Account(props) {
    const context = useContext(Context);
    const [update, setUpdate] = useState('');
    const [theme, setTheme] = useState({})
    const [themes, setThemes] = useState([]);

    const logout = () => {
        context.logout(context.history);
    }

    useEffect(() => {
        setTheme(context.theme);
    }, [context.theme])

    useEffect(() => {
        ThemesService.get()
            .then(result => {
                setThemes(result);
            }, err => {

            })
    }, [])
    const resetTheme = () => {
        context.setActivateThemeSelect(true);
    }

    useEffect(() => {
        if (props.location.pathname === '/login' && context.info.LoggedIn === true) {
            console.log('Redirect to index')
            props.history.push('/index');
        }
    }, [context.info])

    return (
        <div>
            {!context.info.LoggedIn && <Login {...props} />}
            {context.info.LoggedIn && <div className="padding">
                <Typography component="h2">Account</Typography>
                {context.info.StatusText && <><Paper className="padding"><br /><Warning color="#aa0009" /> &nbsp; &nbsp;<i>{context.info.StatusText}</i><br /><br /></Paper><br /><br /></>}
                Ingelogd {(context.info.User && context.info.User.Name) ? ' als ' + context.info.User.Name + ', ' : ''} {(context.info.User && context.info.User.Email) ? context.info.User.Email : ''} <Button variant="text" color="primary" onClick={() => { logout() }}>Uitloggen</Button>
                <br /><br />

                {Settings.getPlatform() === 'electron' &&
                    <CheckBox id="autoopenkeyboard" checked={window.localStorage.getItem('autoopenkeybord') !== null || false} onClick={(ev) => { if (ev.target.checked) { window.localStorage.setItem('autoopenkeybord', 'true') } else { window.localStorage.removeItem('autoopenkeybord') }; setUpdate(new Date()) }} label={'Gebruik virtueel toetsenbord'} />

                }

                {theme && themes.length > 0 && context.info && context.info.User && context.info.User.generalAccount !== true && <>  <Typography component="h2">Thema</Typography>
                    {theme.name}<Button variant="text" color="primary" onClick={() => { resetTheme() }}>VERANDER</Button>
                </>}

                <br /><br />

                {Settings.getPlatform() === 'web' && context.info.User && ((context.info && context.info.Settings && context.info.Settings.AndroidSingleFile) || (context.info && context.info.Settings && context.info.Settings.IOSRedeemcodes)) && <>
                    <Typography component="h2">Installaties</Typography><br />
                    Voor de volgende platformen is er een installatie beschikbaar:<br /><br />
                    <Apps {...props} /></>}
                <br /><br />
            </div>
            }

        </div>
    )
}
