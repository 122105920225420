import React from 'react'
import { TextArea } from '../../ui/core'

export default function Help({db,onChange}) {

    const handleChange = (ev)=>{
        let tmpDb = Object.assign({},db);
        tmpDb[ev.target.id] = ev.target.value;
        onChange(tmpDb);
    }
    
    return (
        <div className="padding">
            <TextArea label="Helptekst symptomen" id="HelptextSymptomen" value={db.HelptextSymptomen} onChange={handleChange}></TextArea>
            <TextArea label="Helptekst ziekten" id="HelptextZiekten" value={db.HelptextZiekten} onChange={handleChange}></TextArea> 
        </div>
    )
}
