import React,{useEffect,useState} from 'react'
import FileService from '../../../services-admin/files';

import {TextArea} from '../../ui/textArea';

export default function Keywords(props) {
    const [keywords,setKeywords] = useState('') ;

    useEffect(()=>{
        FileService.getKeywords(props.version,props.access,props.level)
        .then(result=>{
            let items = result.split('\n');
            items = items.filter(i=>{return (i.indexOf('.htmlbuilder')===-1&&i.indexOf('adresboek.json')===-1)})
            setKeywords(items.join('\n'))
           
        },(err)=>{

        })
    },[props.version,props.access,props.level]);

    const saveKeywords = (keywords)=>{
        FileService.saveKeywords(props.version,props.access,props.level,keywords)
        .then(result=>{
         
        },(err)=>{

        })
    }
    return (
        <div className="padding">
            <br/>
            <div className="label">Plaats achter de bestandsnaam de zoekwoorden</div>
            <TextArea id="keywords" value={keywords} fullWidth onChange={(ev)=>{setKeywords(ev.target.value)}} onBlur={(ev)=>{saveKeywords(ev.target.value)}}></TextArea>
        </div>
    )
}
