import React from 'react'

import { Paper, TextField, Switch, Divider, IconButton } from '../../ui/core';
import {KeyBoardArrowUp,KeyBoardArrowDown,Delete} from '../../ui/coreIcons';
export default function MultiLine({ item, onChange, onDelete, onChangeSort }) {
    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <TextField fullWidth placeholder="Vraag" value={item.question || ''} onChange={(ev) => { changeItem(ev.target.value, 'question') }} ></TextField>
            <TextField fullWidth multiline placeholder="Uitgebreid antwoord" disabled ></TextField>
            <br /><br /><Divider />
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'up') }}><KeyBoardArrowUp /></IconButton>
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'down') }}><KeyBoardArrowDown /></IconButton>
            <div style={{ float: 'right' }}>
                <div style={{ display: 'flex' }}>
                    <div>{onDelete && <IconButton onClick={() => { if (window.confirm("Weet u zeker dat u dit element wilt verwijderen?")) { onDelete() } }}><Delete /></IconButton>}</div>
                    <div style={{ padding: '6px' }}>   <Switch checked={item.required || false} value="required" onChange={() => { changeItem(!item.required, 'required') }} label="Verplicht" /></div>
                </div>
            </div>
            <br style={{ clear: 'both' }} />

        </Paper>
    )
}
