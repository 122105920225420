import API from "./apiv2";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('themes').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    setTheme: (theme) => {
        return new Promise((resolve, reject) => {
            API.post('user/currentuser/themes' , null, {item:theme}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;