
import React,{useEffect,useState} from 'react' 
import { DialogTitle ,Typography,Dialog,DialogContent, DialogActions, Button,List, ListItem, ListItemText, SearchBar} from '../../ui/core' 
import FileServiceAdmin from "../../../services-admin/files";
export default function Loadtemplate({onClose,onSelect}) {
    const [files,setFiles] = useState([]);
    const [filtered,setFiltered] = useState([]);
    useEffect(() => {
        FileServiceAdmin.getTemplateFiles().then(
            result=>{
                setFiles(result);
                setFiltered(result);
            }).catch(err=>{console.log(err)})
        return () => {
             
        }
    }, [ ])

    const filter = (word)=>{
        let tmpList = Object.assign([],files);
        let splitSearch = word.split(' ');
        for(let i=0;i<splitSearch.length;i++){
            tmpList = tmpList.filter(l=>{return JSON.stringify(l).toLowerCase().indexOf(splitSearch[i].toLowerCase())>=0});
        }
        setFiltered(tmpList);
    }
    
    return (
        <Dialog fullScreen = {true}>
            <DialogTitle><Typography component="h1">Importeren uit ander document</Typography></DialogTitle>
            <DialogContent style={{padding:'0px'}}>
                <SearchBar onKeyUp={filter} onReset={()=>{filter('')}}></SearchBar>
            <List>{
            filtered.map(f=>{
                return  <ListItem key={f.dpath} onClick={()=>{onSelect(f.dpath)}}><ListItemText primary={f.filename.split('/').pop().replace('.html','').replace('.htmlbuilder','')} secondary={f.path}></ListItemText></ListItem>
            })
            }
            </List>
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={onClose}>Annuleren</Button> 
            </DialogActions>
        </Dialog>
    )
}
