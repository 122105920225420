import React, { useState, useContext, useEffect } from 'react'

import { Dialog, DialogTitle,DialogContent, Typography, Paper } from '../ui/core';
import { Context } from "../../AppContext";
import ThemesService from "../../services/themes";
export default function SelectTheme(props) {
    const context = useContext(Context);
    const [themes, setThemes] = useState([]);
    const [show, setShow] = useState(false);

    const checkTheme = (themes, info) => {
        if (info && info.User && !info.User.Theme && themes.length > 0) {
            if(themes.length===1){
                selectTheme(themes[0]);
            }else{
                setShow(true);
            }
        }
    }

    const getThemes = () =>{
        ThemesService.get()
        .then(result => {
            setThemes(result);
            checkTheme(result, context.info)
        }, err => {
         
        })
    }

    useEffect(() => {
        getThemes();
    }, [])

    useEffect(() => { 
        checkTheme(themes, context.info);
    }, [context.info,context.user, themes])

    useEffect(() => {
        if (context.activateThemeSelect === true) {
            setShow(true);
        }
    }, [context.activateThemeSelect])

    const selectTheme = (theme) => {
        context.setTheme(theme);      
        ThemesService.setTheme(theme.id)
            .then(result => {
                setShow(false);
             //   context.checkInfo(true);
                context.setActivateThemeSelect(false);
            }, err => {
                setShow(false)
                context.setActivateThemeSelect(false);
            })
    }

    return (<>{
        show && <Dialog fullScreen onClose={props.onClose} className="padding-ios">
             <DialogTitle style={{ textAlign: 'center' , backgroundColor: '#f7f7f7' }}>
             <Typography component="h1" >Kies uw thema</Typography>
                </DialogTitle>
            <DialogContent style={{ padding: '0px', backgroundColor: '#f7f7f7' }}  >
                <div style={{ width: '100%', textAlign: 'center' }}>
                 

                    {themes.map(theme => {
                        return <Paper key={theme.id} onClick={() => selectTheme(theme)} style={{ margin: '10px auto', marginLeft:'20px', cursor:'pointer',float:'left',width: '300px', backgroundColor: 'white' }}>
                            <div style={{ padding: '10px', width: '100%', boxSizing: 'border-box', color: theme.primarycontrast, backgroundColor: theme.primary }}>{theme.name}</div>
                            {theme.logo && <img src={theme.logo} style={{ width: '100%' }} />}
                            {theme.striping && <img src={theme.striping} style={{ width: '100%' }} />}
                        </Paper>
                    })}

                </div>
            </DialogContent>
        </Dialog>
    }</>

    )
}
