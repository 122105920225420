import API from "./api";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('announcements').then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post('announcements' , null, {item:item}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteItem: (id) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('announcements/'+id ).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;