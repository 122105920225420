import React, { useState } from 'react'
import VeditorSimple from "../../../veditor-simple/veditor";
import Imageselection from '../edit/imageselection';
import { v4 as uuidv4 } from 'uuid';
import { Button, IconButton, Select, TextArea } from '../../../ui/core';
import {Delete} from '../../../ui/coreIcons';
export default function Paragraph({ block, onChange, uploadFile, files }) {

    const handleChange = (attribute,value) => {
        let tmpBlock = Object.assign({}, block);
        // tmpBlock.paragraph = contentRef.current.innerHTML;
        tmpBlock[attribute] = value;
        onChange(tmpBlock);
    };


    const handleChangeDescription = (image, description) => {
        let tmpBlock = Object.assign({}, block);
        let find = tmpBlock.images.find(i => { return i._id === image._id });
        if (find) {
            find.description = description;
        }
        // tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock);
    };

    const handleChangeWidth = (image, value) => {
        let tmpBlock = Object.assign({}, block);
        let find = tmpBlock.images.find(i => { return i._id === image._id });
        if (find) {
            find.width = value;
        }
        // tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock);
    };


    const handleSelectImage = url => {
        try {
            let tmpBlock = Object.assign({}, block);
            if (!tmpBlock.images) { tmpBlock.images = [] }
            tmpBlock.images.push({ _id: uuidv4(), base64: url,width:'200px' })

            onChange(tmpBlock);
        } catch (ex) {
            console.log(ex)
        }
    }

    const handleDeleteImage = (image) => {
       if(window.confirm("Weet u zeker dat u deze afbeelding wilt verwijderen?")){
        let tmpBlock = Object.assign({}, block);
        
        tmpBlock.images = tmpBlock.images.filter(i=>{return i._id!==image._id});
      
       // tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock); 
       }
       
    }; 

    

    return (
        <>
        <div style={{display:'flex',flexWrap:'wrap',width:'100%'}}>
            {block.images && block.images.map(image => {
                return <div key={image._id} style={{ width: image.width||'200px' }}>
                    <table><tbody>
                        <tr><td colSpan="2">  <img src={image.base64} style={{ width:'100%', margin: '8px' }} /></td></tr>
                        <tr>
                            <td><TextArea id={'descr_' + image._id} value={image.description || 'Beschrijving bij afbeelding'} onChange={(ev) => { handleChangeDescription(image, ev.target.value) }}></TextArea></td>
                            <td> <IconButton variant="outlined" onClick={(ev) => { handleDeleteImage(image) }}><Delete/></IconButton></td>
                        </tr>
                        <tr>
                             <td  > 
                             <Select  label="Breedte" value={image.width || '200px'} onChange={(ev)=>{handleChangeWidth(image,ev.target.value)}}>
                                 <option value={'100px'}>100px</option>
                                 <option value={'200px'}>200px</option>
                                 <option value={'300px'}>300px</option>
                                 <option value={'400px'}>400px</option>
                                 <option value={'25%'}>25%</option>
                                 <option value={'33%'}>33%</option>
                                 <option value={'50%'}>50%</option>
                                 <option value={'100%'}>100%</option>
                             </Select>
                             </td>
                             <td></td>
                        </tr>
                        </tbody></table>
                </div>
            })}
            </div>
            <Imageselection uploadFile={uploadFile} files={files} onSelect={handleSelectImage}></Imageselection>
            <br style={{ clear: 'both' }} />
        </>
    )
}
