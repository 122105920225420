import React, { useState, useEffect, useContext } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, TextField, Select, CheckBox } from '../../ui/core';
import readXlsxFile from 'read-excel-file'
import { v1 as uuidv1 } from 'uuid';
import { Context } from '../../../AppContext';
export default function Edit({ onClose, onSave }) {
    const context = useContext(Context)
    const [contacts, setContacts] = useState([]);
    const [importRows, setImportRows] = useState([])
    const [selectedTab, setSelectedTab] = useState(1);
    const [columnSettings, setColumnsettings] = useState([]);
    const [file, setFile] = useState();
    const [useEmailForSync, setUseEmailForSync] = useState(true)
    const [clean, setClean] = useState(false)
    const [skipFirst, setSkipFirst] = useState(true)

    useEffect(() => {
        const input = document.getElementById('inputexcelfilecontacts');
        input.addEventListener('change', importExcel)
        return () => {
            input.removeEventListener('change', importExcel);
        }
    }, [])

    const importExcel = (ev) => {
        if (ev.target && ev.target.files && ev.target.files.length > 0 && ev.target.files[0].name.toLowerCase().indexOf('.xlsx') > 0) {
            setFile(ev.target.files[0])
            ev.target.value = '';
        }
    }
    useEffect(() => {
        if (file) {
            readXlsxFile(file, { sheet: selectedTab }).then((rows) => {
                setImportRows(rows);
                if (rows.length > 0) {
                    let tmpColumns = [];
                    for (let r = 0; r < rows[0].length; r++) {

                        tmpColumns[rows[0][r]] = r + 1;

                    }
                    setColumnsettings(tmpColumns);
                }

            }).catch((err) => {
                context.setMessage('Inlezen mislukt')
            })
        }
    }, [file, selectedTab])

    const convertRows = () => {
        let tmpContacts = [];
        for (let r = 0; r < importRows.length; r++) {

            tmpContacts.push({
                ID: uuidv1(),
                Achternaam: importRows[r][columnSettings['Achternaam'] - 1] || '',
                Voornaam: importRows[r][columnSettings['Voornaam'] - 1] || '',
                Tussenvoegsel: importRows[r][columnSettings['Tussenvoegsel'] - 1] || '',
                Voorletters: importRows[r][columnSettings['Voorletters'] - 1] || '',
                Organisatie: importRows[r][columnSettings['Organisatie'] - 1] || '',
                Gemeente: importRows[r][columnSettings['Gemeente'] - 1] || '',
                Deskundigheid: importRows[r][columnSettings['Deskundigheid'] - 1] || '',
                Opmerking: importRows[r][columnSettings['Opmerking'] - 1] || '',
                Functie: importRows[r][columnSettings['Functie'] - 1] || '',
                PiketFunctie: importRows[r][columnSettings['PiketFunctie'] - 1] || '',
                Adres: importRows[r][columnSettings['Adres'] - 1] || '',
                Postcode: importRows[r][columnSettings['Postcode'] - 1] || '',
                Woonplaats: importRows[r][columnSettings['Woonplaats'] - 1] || '',
                Email: importRows[r][columnSettings['Email'] - 1] || '',
                Telefoonnummer: importRows[r][columnSettings['Telefoonnummer'] - 1] || '',
                Mobiel: importRows[r][columnSettings['Mobiel'] - 1] || '',
                Piketnummer: importRows[r][columnSettings['Piketnummer'] - 1] || '',
                Team: importRows[r][columnSettings['Team'] - 1] || '',

                Sectie: importRows[r][columnSettings['Sectie'] - 1] || '',
                FunctieinCoPI: importRows[r][columnSettings['FunctieinCoPI'] - 1] || '',
                Proces: importRows[r][columnSettings['Proces'] - 1] || '',
                Alternatiefbereikbaarop: importRows[r][columnSettings['Alternatiefbereikbaarop'] - 1] || '',
                FunctieinROT: importRows[r][columnSettings['FunctieinROT'] - 1] || '',
                FunctieinBT: importRows[r][columnSettings['FunctieinBT'] - 1] || '',
                Rolbinnenteam: importRows[r][columnSettings['Rolbinnenteam'] - 1] || '',
                FunctieMeldkamer: importRows[r][columnSettings['FunctieMeldkamer'] - 1] || '',
                FunctieActiecentrum: importRows[r][columnSettings['FunctieActiecentrum'] - 1] || '',
                Functieoverig: importRows[r][columnSettings['Functieoverig'] - 1] || ''
            })
        }

        setContacts(tmpContacts);
    }

    useEffect(() => {
        convertRows();
    }, [columnSettings, importRows])

    const handleChangeColumn = (ev) => {
        const tmpObj = Object.assign([], columnSettings);
        tmpObj[ev.target.id] = ev.target.value;
        setColumnsettings(tmpObj);
    }

    return (
        <Dialog fullScreen={true}>
            <DialogTitle><Typography component="h1">Importeren contact</Typography></DialogTitle>
            <DialogContent>
                <Typography component="h3">1. Selecteer een Excel (xlsx) document.</Typography><br />
                <Button variant="contained" color="primary" onClick={() => { document.getElementById('inputexcelfilecontacts').click() }}>Selecteer Excel document</Button>
                <input type="file" id="inputexcelfilecontacts" style={{ display: 'none' }} />
                <br /><br />
                <Typography component="h3"> 2. Selecteer het tabblad uit de Excel die geimporteerd moet worden. </Typography>
                <Select value={selectedTab || 1} onChange={(ev) => { setSelectedTab(parseInt(ev.target.value)) }}>
                    <option value={1}>Eerste tabblad</option>
                    <option value={2}>Tweede tabblad</option>
                    <option value={3}>Derde tabblad</option>
                    <option value={4}>Vierde tabblad</option>
                    <option value={5}>Vijfde tabblad</option>
                    <option value={6}>Zesde tabblad</option>
                    <option value={7}>Zevende tabblad</option>
                    <option value={8}>Achtste tabblad</option>
                    <option value={9}>Negende tabblad</option>
                    <option value={10}>Tiende tabblad</option>
                </Select>
                <br /><br />
                <Typography component="h3">3. Geef per veld aan in welke kolom (nummer) de gegevens zich bevinden.</Typography>
                <br />
                <CheckBox id="clean" checked={clean} onChange={(ev) => { setClean(ev.target.checked);setUseEmailForSync(false); }} label="Verwijder de huidige lijst, en vervang door deze import." />
                {!clean && <CheckBox id="useforsync" checked={useEmailForSync} onChange={(ev) => { setUseEmailForSync(ev.target.checked) }} label="Gebruik het veld Email om hiermee bestaande gegevens bij te werken en niet nieuw toe te voegen." />}
                <CheckBox id="skip" checked={skipFirst} onChange={(ev) => { setSkipFirst(ev.target.checked);}} label="De eerste rij niet importeren." />
                <div style={{ overflowX: 'scroll' }}>
                    <table><tbody>
                        <tr><td>Achternaam</td><td>Voornaam</td><td>Tussenvoegsel</td><td>Voorletters</td><td>Organisatie</td><td>Gemeente</td><td>Deskundigheid</td><td>Opmerking</td><td>Functie</td><td>Adres</td><td>Postcode</td><td>Woonplaats</td><td>Email</td><td>Telefoonnummer</td><td>Mobiel</td><td>Piketnummer</td><td>Alternatiefbereikbaarop</td><td>Team</td><td>Sectie</td><td>Rolbinnenteam</td><td>Proces</td><td>PiketFunctie</td><td>FunctieinCoPI</td><td>FunctieinROT</td><td>FunctieinBT</td><td>FunctieMeldkamer</td><td>FunctieActiecentrum</td><td>Functieoverig</td></tr>
                        <tr>
                            <td><TextField id="Achternaam" type="number" value={columnSettings['Achternaam'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Voornaam" type="number" value={columnSettings['Voornaam'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Tussenvoegsel" type="number" value={columnSettings['Tussenvoegsel'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Voorletters" type="number" value={columnSettings['Voorletters'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Organisatie" type="number" value={columnSettings['Organisatie'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Gemeente" type="number" value={columnSettings['Gemeente'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Deskundigheid" type="number" value={columnSettings['Deskundigheid'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Opmerking" type="number" value={columnSettings['Opmerking'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Functie" type="number" value={columnSettings['Functie'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Adres" type="number" value={columnSettings['Adres'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Postcode" type="number" value={columnSettings['Postcode'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Woonplaats" type="number" value={columnSettings['Woonplaats'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Email" type="number" value={columnSettings['Email'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Telefoonnummer" type="number" value={columnSettings['Telefoonnummer'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Mobiel" type="number" value={columnSettings['Mobiel'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Piketnummer" type="number" value={columnSettings['Piketnummer'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Alternatiefbereikbaarop" type="number" value={columnSettings['Alternatiefbereikbaarop'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Team" type="number" value={columnSettings['Team'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Sectie" type="number" value={columnSettings['Sectie'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Rolbinnenteam" type="number" value={columnSettings['Rolbinnenteam'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="Proces" type="number" value={columnSettings['Proces'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="PiketFunctie" type="number" value={columnSettings['PiketFunctie'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="FunctieinCoPI" type="number" value={columnSettings['FunctieinCoPI'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="FunctieinROT" type="number" value={columnSettings['FunctieinROT'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="FunctieinBT" type="number" value={columnSettings['FunctieinBT'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="FunctieMeldkamer" type="number" value={columnSettings['FunctieMeldkamer'] || 0} onChange={handleChangeColumn} /></td>
                            <td><TextField id="FunctieActiecentrum" type="number" value={columnSettings['FunctieActiecentrum'] || 0} onChange={handleChangeColumn} /></td> 
                            <td><TextField id="Functieoverig" type="number" value={columnSettings['Functieoverig'] || 0} onChange={handleChangeColumn} /></td> 
                        </tr>
                        {contacts.map(contact => {
                            return <tr key={contact.ID}>
                                <td>{contact.Achternaam.toString() || ''}</td>
                                <td>{contact.Voornaam.toString() || ''}</td>
                                <td>{contact.Tussenvoegsel.toString() || ''}</td>
                                <td>{contact.Voorletters.toString() || ''}</td>
                                <td>{contact.Organisatie.toString() || ''}</td>
                                <td>{contact.Gemeente.toString() || ''}</td>
                                <td>{contact.Deskundigheid.toString() || ''}</td>
                                <td>{contact.Opmerking.toString() || ''}</td>
                                <td>{contact.Functie.toString() || ''}</td>
                                <td>{contact.Adres.toString() || ''}</td>
                                <td>{contact.Postcode.toString() || ''}</td>
                                <td>{contact.Woonplaats.toString() || ''}</td>
                                <td>{contact.Email.toString() || ''}</td>
                                <td>{contact.Telefoonnummer.toString() || ''}</td>
                                <td>{contact.Mobiel.toString() || ''}</td>
                                <td>{contact.Piketnummer.toString() || ''}</td>
                                <td>{contact.Alternatiefbereikbaarop.toString() || ''}</td>
                                <td>{contact.Team.toString() || ''}</td>
                                <td>{contact.Sectie.toString() || ''}</td>
                                <td>{contact.Rolbinnenteam.toString() || ''}</td>
                                <td>{contact.Proces.toString() || ''}</td>
                                <td>{contact.PiketFunctie.toString() || ''}</td>
                                <td>{contact.FunctieinCoPI.toString() || ''}</td>
                                <td>{contact.FunctieinROT.toString() || ''}</td>
                                <td>{contact.FunctieinBT.toString() || ''}</td>
                                <td>{contact.FunctieMeldkamer.toString() || ''}</td>
                                <td>{contact.FunctieActiecentrum.toString() || ''}</td> 
                                <td>{contact.Functieoverig.toString() || ''}</td> 
                            </tr>
                        })}
                    </tbody></table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>Annuleren</Button>
                <Button variant="contained" onClick={() => onSave(contacts, useEmailForSync,clean,skipFirst)} disabled={(useEmailForSync && (columnSettings['Email'] === undefined || columnSettings['Email'] <= 0))}>Importeren</Button>
            </DialogActions>
        </Dialog>
    )
}