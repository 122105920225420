import React, { useState } from 'react'
import Html from './new/html';
import FilesService from "../../../services-admin/files";
import UploadFile from './new/file';
import Form from './new/form';
import Level from './new/level';
import Access from './new/access';
import Icon from './new/icon';  
import { Menu, MenuItem, Divider, Fab } from '../../ui/core';
import {Security, SubdirectoryArrowRight as LevelIcon, FileCopy as FileIcon, ContactPhone as AddressBookIcon, AssignmentTurnedIn as FormIcon, FormatAlignLeft as HtmlIcon, Add as AddIcon ,Image as ImageIcon} from '../../ui/coreIcons';

export default function FilesMenuNew(props) {
    const [showHtml, setShowHtml] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showLevel, setShowLevel] = useState(false);
    const [showAccess,setShowAccess] = useState(false);
    const [showIcon,setShowIcon] = useState(false);

    const addAddressBook = () => {
        console.log('save, adres',btoa('[]'));
        FilesService.saveBase64(props.version, props.access, props.level, 'adresboek.json', btoa('[]'))
            .then(result => {
                props.onRefresh();

            }, (err) => {
                props.onRefresh();
            })
    }

    const handleIconSave = (icon)=>{
        FilesService.saveBase64(props.version, props.access, props.level, 'logo.png', icon)
        .then(result => {
            props.onRefresh();

        }, (err) => {
            props.onRefresh();
        })
    }
//          <MenuItem onClick={() => { setShowFile(true) }}><FileIcon />Document</MenuItem>
    return (
        <>
            {showFile && <UploadFile onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} onClose={() => { setShowFile(false) }} />}
            {showHtml && <Html onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} pages={props.pages} files={props.files} onClose={() => { setShowHtml(false) }} />}
            {showForm && <Form onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} files={props.files} onClose={() => { setShowForm(false) }} />}
            {showLevel && <Level onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} files={props.files} onClose={() => { setShowLevel(false) }} />}
            {showAccess && <Access onRefresh={props.onRefresh} version={props.version}  onClose={() => { setShowAccess(false) }} />}
            {showIcon && <Icon onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} onClose={() => { setShowIcon(false) }} onSave={handleIconSave} />}

            <Menu   id="file-menu-new"      control={<Fab color="primary" style={{ position: 'fixed', right: '15px', bottom: '35px', zIndex: '9' }}><AddIcon color="white" /></Fab>}>
                <MenuItem onClick={() => { setShowAccess(true) }}><Security />Toegang</MenuItem>
                {props.access && <MenuItem onClick={() => { setShowLevel(true) }}><LevelIcon />Niveau</MenuItem>}
                {props.access && props.level && <>
                    <Divider />
          
                    <MenuItem onClick={() => { setShowHtml(true) }}><HtmlIcon />Tekstblok</MenuItem>
                    <MenuItem onClick={() => { setShowForm(true) }}><FormIcon />Formulier</MenuItem>
                    {(Object.assign([], props.files).filter(file => { return file.filename === 'adresboek.json' }).length === 0) && <MenuItem onClick={() => { addAddressBook() }}><AddressBookIcon />Adresboek</MenuItem>}
                    <MenuItem onClick={() => { setShowIcon(true) }}><ImageIcon />Icoon (Beta)</MenuItem>
                </>}
            </Menu>
        </>
    )
}
