import React,{useState,useEffect} from 'react'
import BookBuilderPopup from '../../addressbook/builderpopup';
import AdminFilesService from "../../../../services-admin/files";
import FileService from "../../../../services/file";

export default function Addressbook(props) {
    const [book, setBook] = useState();

    const handleSave = (book) => {
      
        AdminFilesService.saveBase64(props.version, props.access, props.level, props.file.filename, AdminFilesService.encodeUnicode(JSON.stringify(book)))
            .then((result) => {
                props.onRefresh();
             //   props.onClose();
            }, (err) => {
                props.onRefresh();
             //   props.onClose();
            })
    }

    useEffect(() => {
        try {
            FileService.getContentOnline(props.file.DPath)
            .then(result=>{
                setBook(result);
            })
            .catch(err=>{
                console.log(err)
            })
        } catch (ex){console.log(ex) }
    }, [props.file.content])


    return (
        <React.Fragment>
            {book && <BookBuilderPopup items={book}  onSave={handleSave} onClose={props.onClose} />}
        </React.Fragment>
    )
}
 