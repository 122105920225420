
import StorageService from './storage';
import APIServiceV2 from './apiv2';
import AppSettings from '../AppSettings';

const checkForceDownloads = (levels) => {
    let HPaths = localStorage.getItem('offline_hpaths') || '';
    let newHPaths = HPaths;
    for (let i = 0; i < levels.length; i++) {
        if ((levels[i].DirInfo && levels[i].DirInfo.levelForceDownload) || localStorage.getItem('forcedownload')) {
            if (HPaths.indexOf(levels[i].HPath) === -1) {
                newHPaths += levels[i].HPath + ',';
            }
        }
    }
    if (newHPaths !== HPaths) {
        localStorage.setItem('offline_hpaths', newHPaths);
    }
}

const checkSelectedHPaths = (downloadlist) => {
    if (localStorage.getItem('forcedownload')) {
        return downloadlist
    } else {
        let offline_hpaths = localStorage.getItem('offline_hpaths') || '';
        downloadlist = downloadlist.filter((item) => { return (item.Extention === 'json' || item.Extention === 'note' || item.Extention === 'html' || item.Extention === 'form' || offline_hpaths.indexOf(item.HPath) >= 0) })
        return downloadlist
    }
}
const UpdateService = {
    checkUseEncryption: (levels, item) => {
        for (let i = 0; i < levels.length; i++) {
            if (levels[i].DirInfo && levels[i].DirInfo.noEncryptionFiles) {
                if (levels[i].DirInfo.noEncryptionFiles.indexOf(item.Name) >= 0) {
                    return false;
                }
            }
        }
        if (AppSettings.getPlatform() === 'electron') {
            return false;
        }
        return true;
    },
    getDownloadList: (index, levels) => {
        return new Promise((resolve, reject) => {
            if (index) {
                let downloadlist = index.map((item) => {
                    let downloadItem = item;
                    downloadItem.encrypt = item.encrypt;// UpdateService.checkUseEncryption(levels, item.Name); 
                    downloadItem.target = StorageService.getStorageFileName(item)
                    return downloadItem;
                });
                checkForceDownloads(levels);
                downloadlist = checkSelectedHPaths(downloadlist);
                StorageService.listDirIndex()
                    .then((resultStorageList) => {
                    
                        //downloadlist = downloadlist.filter((item) => { return resultStorageList.files.indexOf(item.target) === -1 });
                        for (let i = downloadlist.length -1; i >= 0; i--) {
                            if (resultStorageList.files.find(file => {return file.name == downloadlist[i].target })) {
                             
                                downloadlist.splice(i, 1);
                            }
                        }
                  
                        resolve(downloadlist);
                    }, (err) => {
                        console.log('NO LIST')
                        console.log(err)
                        reject(err)
                    })
            } else {
                reject();
            }
        });
    },
    cleanDirectory: (index, levels) => {
        return new Promise((resolve, reject) => {
            StorageService.listDirIndex()
                .then((resultStorageList) => {
                    let searchIndex = Object.assign([], index);
                    checkForceDownloads(levels);
                    searchIndex = checkSelectedHPaths(searchIndex);
                    for (let i = 0; i < resultStorageList.files.length; i++) {
                        let found = searchIndex.find((item) => { return item.target === resultStorageList.files[i].name });
                        if (found === undefined) {
                            StorageService.removeDocument(resultStorageList.files[i].name)
                                .then((result) => { }, (err) => { console.log(err) });
                        }
                    }
                    resolve();
                }, (err) => {
                    console.log(err);
                    reject(err)
                })
        });
    },
    getLastModified: () => {
        return new Promise((resolve, reject) => {
            APIServiceV2.post('lastmodified', undefined, {})
                .then((result) => {
                    resolve(result);
                }, (err) => { reject(err) })
        })
    },
    getDownloadToken: (DPath) => {
        return new Promise((resolve, reject) => {
            APIServiceV2.get('downloadtoken/?' + DPath)
                .then((result) => {
                    resolve(result);
                }, (err) => { reject(err) })
        })
    }

}
export default UpdateService;