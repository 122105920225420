import React,{useState} from 'react'
import { Tabs } from '../../ui/tabs'

import Sync from './sync';
import Backup from './backup';

export default function Index() {
    const [selectedTarget,setSelectedTarget] = useState(0);
    
    return (
        <>
            <Tabs value={selectedTarget} onChange={setSelectedTarget}>
                    <div className="ui-tab">SHAREPOINT (BRONDOCUMENTEN) {'>'} HANDBOEK APP SERVER</div>
                    <div className="ui-tab">HANDBOEK APP SERVER {'>'} SHAREPOINT (BACKUP)</div>
            </Tabs>
            {selectedTarget===0 && <Sync/>}
            {selectedTarget===1 && <Backup/>}
        </>
    )
}
