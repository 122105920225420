import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '../ui/core'
import { Close } from '../ui/coreIcons'

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function Modalimage({ open, image, title, onClose }) {
    return (
        <Dialog fullScreen fullWidth open={open} onClose={onClose}  >

            <DialogContent style={{ width: '100%',height:'100%' }} >
                <TransformWrapper>
                    <TransformComponent>
                        <img src={image} style={{ width: '100%' }} />
                    </TransformComponent>
                </TransformWrapper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
