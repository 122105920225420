/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import FileService from '../../services/file';
import StorageService from '../../services/storage';
import XLSX from '../../services-admin/xlsx';
import AddressbookContact from './addressbookcontact';
import { Context } from '../../AppContext';
import { Button } from '../ui/button';
import { Menu } from '../ui/menu';
import { MenuItem } from '../ui/menuItem';
import { IconButton } from '../ui/iconButton';
import { MoreVert } from '../ui/coreIcons';
import { Select } from '../ui/select';
export default function Addressbook(props) {
    const context = useContext(Context);
    const [filterOrganisation, setFilterOrganisation] = useState('');
    const [filterFunction, setFilterFunction] = useState('');
    const [filterPlace, setFilterPlace] = useState('');
    const [contacts, setContacts] = useState([]);
    const [filterContacts, setFilterContacts] = useState([]);

    const [organisationList,setOrganisationList] = useState([]);
    const [functionList,setFunctionList] = useState([]);
    const [placeList,setPlaceList] = useState([]);

    
    //props.dirInfo
    useEffect(() => {
        if (props.id) {
            //   readStorage(); 
            FileService.getContent(props.file.DPath, StorageService.getStorageFileName(props.file), true)
                .then(content => {
                    let contacts = (typeof content === 'string') ? JSON.parse(content) : content;
                    setContacts(contacts);
                    setFilterContacts(contacts);


                    setOrganisationList([...new Set(contacts.filter(c => { return c.Organisatie }).map((c) => { return c.Organisatie.trim() }))].sort())
                    setFunctionList([...new Set(contacts.filter(c => { return c.Functie }).map((c) => { return c.Functie.trim() }))].sort())
                    setPlaceList([...new Set(contacts.filter(c => { return c.Woonplaats }).map((c) => { return c.Woonplaats.trim() }))].sort())

                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [props.id])

    useEffect(() => {
        if (contacts && contacts.length > 0) {
            let tmpContacts = Object.assign([], contacts);
            if (filterOrganisation) {
                tmpContacts = tmpContacts.filter(c => { return c.Organisatie }).filter((contact) => { return contact.Organisatie.trim() === filterOrganisation.trim() });
            }
            if (filterFunction) {
                tmpContacts = tmpContacts.filter(c => { return c.Functie }).filter((contact) => { return contact.Functie.trim() === filterFunction.trim() });
            }
            if (filterPlace) {
                tmpContacts = tmpContacts.filter(c => { return c.Woonplaats }).filter((contact) => { return contact.Woonplaats.trim() === filterPlace.trim() });
            }
            setFilterContacts(tmpContacts);
        }
    }, [filterOrganisation, filterFunction, filterPlace])

    const downloadExcel = () => {
        let tmpAdresboek = Object.assign([], contacts);
        tmpAdresboek = tmpAdresboek.map((contact) => { delete contact.Image; delete contact._checked; delete contact.ID; return contact; })
        XLSX.downloadJSON(tmpAdresboek, "adresboek.xlsx");
    } 


    return (
        <div>
            {props.dirInfo && props.dirInfo.downloadAddressbook === true && <div style={{ float: 'right', paddingRight: '10px', paddingTop: '8px' }}>   <Menu id={"menu_downloadHtml"} control={<IconButton><MoreVert /></IconButton>}>
                <MenuItem onClick={downloadExcel}>  Download adresboek in Excel </MenuItem>
            </Menu></div>}
            {props.dirInfo && props.dirInfo.hideFilterAddressBook !== true && <div className="padding">
                {(organisationList.length>1 || placeList.length>1 || functionList.length>1) && <div className="label">Filter</div>}
                <div style={{ display: 'flex' }}>
                    {organisationList.length > 1 &&
                        <div><Select value={filterOrganisation} onChange={(e) => { setFilterOrganisation(e.target.value) }}>
                            <option value="">-- organisatie --&nbsp;&nbsp;</option>
                            {organisationList.map((item, i) => <option key={item} value={item}>{item}  &nbsp;&nbsp;</option>)}
                        </Select></div>}

                        {placeList.length > 1 &&
                    <div><Select value={filterPlace} onChange={(e) => { setFilterPlace(e.target.value) }}>
                        <option value="">-- plaats --&nbsp;&nbsp;</option>
                        {placeList.map((item, i) => <option key={item} value={item}>{item}  &nbsp;&nbsp;</option>)}

                    </Select></div>}

                        {functionList.length > 1 &&
                    <div><Select value={filterFunction} onChange={(e) => { setFilterFunction(e.target.value) }}>
                        <option value="">-- functie --&nbsp;&nbsp;</option>
                        {functionList.map((item, i) => <option key={item} value={item}>{item}  &nbsp;&nbsp;</option>)}
                    </Select></div>}

                  
                </div>
            </div>}
            {filterContacts.map((contact, i) => {
                return <AddressbookContact key={i} contact={contact} />
            })}
            {contacts.length > 0 && filterContacts.length === 0 && <div className="label padding"><i>-- geen contacten gevonden --</i><br /><br /></div>}
        </div>
    )
}
