/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect,useContext} from 'react'
import {Button, SearchBar} from '../components/ui/core';
import SearchResults from '../components/index/searchresults';
import { ContextUI } from '../uiContext';
import {Context} from '../AppContext';

export default function SearchPage(props) {
    const contextUI = useContext(ContextUI);  
    const context = useContext(Context);
    const [searchWord, setSearchWord] = useState(window.localStorage.getItem('search') || '');

    useEffect(()=>{
        contextUI.setShowBackButton(false);
        contextUI.setTitle('Zoeken')
        contextUI.setSubTitle(''); 
       
        if(props.location.pathname && props.location.pathname.indexOf('/search/')>=0 && props.location.pathname.length>8){
            updateSearchword(decodeURI(props.location.pathname.replace('/search/','')));
         
           // contextUI.setShowBackButton(true);
        }    
    },[]);

    const redirectLogin = () =>{
        props.history.push('/settings?redirect='+props.location.pathname)
    }
    const updateSearchword= (word)=>{
        window.localStorage.setItem('search',word);
        setSearchWord(word);
    }
    return (
        <div>
            <SearchBar onSubmit={updateSearchword}  onKeyUp={updateSearchword} onReset={() => { updateSearchword('')}} />
           {(!context.info || context.info.User === undefined ) && <Button fullWidth style={{boxSizing:'border-box',display:'block'}} onClick={redirectLogin}>Klik hier om in te loggen</Button>  }
            <SearchResults searchWord={searchWord} {...props} />
          <br/><br/>
        </div>
    )
}
