import React, { useEffect, useState } from 'react';
import ServiceAuth from '../../services/auth';
import ServiceGeneral from '../../services/general';
import AppSettings from '../../AppSettings'; 
import { Button, CheckBox, Typography, Paper } from '../ui/core';

export default function Sessions() {
    const [sessions, setSessions] = useState([]);

    const getSessions = () => {
        ServiceAuth.getSessions()
            .then(result => { setSessions(result) })
            .catch(err => { })
    }
    useEffect(() => {
        getSessions();
    }, [])

    const handleDelete = (id) => {
        ServiceAuth.deleteSession(id)
            .then(result => { getSessions() })
            .catch(err => { })
    }

    const getDeviceUUID = (deviceInfo)=>{
        const attribute = "uuid";
        if(deviceInfo){
            if(deviceInfo[attribute]){
                return deviceInfo[attribute];
            }
        }
    }

    const getDeviceInfo = (deviceInfo)=>{
        let info = "";
        if(deviceInfo){
            if(deviceInfo.operatingSystem){
                info +=  deviceInfo.operatingSystem + ' ';
            }
            if(deviceInfo.osVersion){
                info +=  deviceInfo.osVersion + ' ';
            }
        }
        return info;
    }

    return <div className='padding'>

        <Typography component="h2">Actieve sessies</Typography>
        <br /><br />

        <table style={{ width: '100%' }}>
            <tbody>
                <tr> 
                    <th>Platform</th> 
                    <th>Apparaat</th> 
                    <th>Laatst actief</th>
                    <th></th>
                </tr>
                {sessions.map(session => {
                    return <tr key={session._id}>                        
                          <td>{session.device.platform}</td> 
                          <td>{getDeviceInfo(session.device)}</td> 
                        <td>{ServiceGeneral.formatDate(session.lastactive, true)}</td>
                        <td style={{textAlign:'center'}}>{AppSettings.getUUID() !== session.device.uuid ? <Button onClick={() => { handleDelete(session._id) }}>Verwijder sessie</Button>:<i>deze sessie</i>}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>;
}
