 
import React, { useRef, useState } from 'react'

import VeditorSimple from "../../../veditor-simple/veditor";
export default function ParagraphVideo({ block, onChange, files, uploadFile, pages }) {
    const [contentRef] = useState(React.createRef());

    const handleChange = evt => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock);
    };

    const handleChangeVideo = val => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.youtubeCode = val;
        onChange(tmpBlock);
    };

    //{block.youtubeCode && <iframe width="200" height="140" src={"https://www.youtube.com/embed/"+block.youtubeCode} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}


    return (
        <>
        {block.youtubeCode &&  
            <div style={{ float: (block.alignImage === 'left' ? 'left' : 'right'), margin: '8px',width:'320px',height:'180px',backgroundImage:'url(https://img.youtube.com/vi/'+block.youtubeCode+'/mqdefault.jpg)' }}>

                {block.youtubeCode &&  
                        <div style={{boxSizing: 'border-box',padding:'8px',width:'100%',marginTop:'140px',height:'40px',backgroundColor:'rgba(255,255,255,0.8)',textAlign:'center'}}>
                            <a className="link" href={"https://www.youtube.com/watch?v="+block.youtubeCode} style={{textDecoration:'none'}} target="_blank">BEKIJK VIDEO</a>
                        </div>
                }
                <br style={{clear:'both'}} /> 
            </div>
}
            <div>
                <VeditorSimple id={block._id} value={block.paragraph !== undefined ? block.paragraph : 'Plaats hier uw tekst'} contentRef={contentRef} files={[]} pages={pages} onChange={handleChange} showMenu={block.selected === true} />

            </div>
            <br style={{ clear: 'both' }} />
            <br style={{ clear: 'both' }} />
        </>
    )

}
