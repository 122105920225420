import React, { useState, useEffect, useContext } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Tabs, TextField, CheckBox, Typography, Select, Paper, TextArea } from '../../ui/core';

import FileService from "../../../services-admin/files"
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
import Settings from "../../../AppSettings";
import Pushmessages from "./pushmessages";
import { Context } from "../../../AppContext";
export default function Edit(props) {
    const context = useContext(Context);
    const [user, setUser] = useState();
    const [accessList, setAccessList] = useState([])
    const [selectedTab, setSelectedTab] = useState(0);
    const [strongPasswordOK, setStrongPasswordOK] = useState(false)
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')


    useEffect(() => {
        if (user && user.newpassword) {
            if (strongPassword.test(user.newpassword)) {
                setStrongPasswordOK(true);
            } else {
                setStrongPasswordOK(false);
            }
        }


    }, [user])

    useEffect(() => {
        let tmpUser = Object.assign({}, props.user);
        if (!tmpUser.Rechten) tmpUser.Rechten = '';
        setUser(tmpUser);
        FileService.getAccess()
            .then(result => setAccessList(result), err => { })
    }, [])

    const changeItem = (ev) => {
        let tmpUser = Object.assign({}, user);
        if (!tmpUser.Accesslist) { tmpUser.Accesslist = [] }
        if (!tmpUser.FileAccesslist) { tmpUser.FileAccesslist = [] }
        tmpUser[ev.target.id] = ev.target.value;
        setUser(tmpUser);
    }

    const changeItemCheckBox = (ev) => {
        let tmpUser = Object.assign({}, user);
        if (!tmpUser.Accesslist) { tmpUser.Accesslist = [] }
        if (!tmpUser.FileAccesslist) { tmpUser.FileAccesslist = [] }
        tmpUser[ev.target.id] = ev.target.checked;
        setUser(tmpUser);
    }

    const toggleAccess = (access) => {
        let tmpUser = Object.assign({}, user);
        if (tmpUser.Accesslist.indexOf(access) >= 0) {
            tmpUser.Accesslist.splice(tmpUser.Accesslist.indexOf(access), 1);
        } else {
            tmpUser.Accesslist.push(access);
        }
        setUser(tmpUser);
    }

    const toggleFileAccess = (access) => {
        let tmpUser = Object.assign({}, user);
        if (!tmpUser.FileAccesslist) tmpUser.FileAccesslist = [];
        if (tmpUser.FileAccesslist.indexOf(access) >= 0) {
            tmpUser.FileAccesslist.splice(tmpUser.FileAccesslist.indexOf(access), 1);
        } else {
            tmpUser.FileAccesslist.push(access);
        }
        setUser(tmpUser);
    }

    const toggleFileAccessUser = (access) => {
        let tmpUser = Object.assign({}, user);
        if (!tmpUser.FileAccesslistUser) tmpUser.FileAccesslistUser = [];
        if (tmpUser.FileAccesslistUser.indexOf(access) >= 0) {
            tmpUser.FileAccesslistUser.splice(tmpUser.FileAccesslistUser.indexOf(access), 1);
        } else {
            tmpUser.FileAccesslistUser.push(access);
        }
        setUser(tmpUser);
    }

    const toggleRights = (access) => {
        let tmpUser = Object.assign({}, user);
        if (tmpUser.Rechten.indexOf(access) >= 0) {
            tmpUser.Rechten = tmpUser.Rechten.replace(access + ',', '');
        } else {
            tmpUser.Rechten = tmpUser.Rechten + access + ',';
        }
        setUser(tmpUser);
    }

    const generateLoginLink = () => {
        let tmpUser = Object.assign({}, user);
        tmpUser.Autologintoken = uuidv4() + '-' + uuidv1().toUpperCase() + '-' + uuidv1() + '-' + uuidv4().toUpperCase()
        setUser(tmpUser);
    }
    const deleteLoginLink = () => {
        let tmpUser = Object.assign({}, user);
        tmpUser.Autologintoken = undefined;
        setUser(tmpUser);
    }
    // <CheckBox id="cb_contactlijst" label="contactlijst" checked={user.Rechten.indexOf('contactlijst') >= 0} onClick={() => { toggleRights('contactlijst') }} />
    //<CheckBox id="cb_formulieren" label="formulieren" checked={user.Rechten.indexOf('formulieren') >= 0} onClick={() => { toggleRights('formulieren') }} />
    //<CheckBox id="cb_sharepoint" label="sharepoint" checked={user.Rechten.indexOf('sharepoint') >= 0} onClick={() => { toggleRights('sharepoint') }} />
    const approveSubscription = () => {
        let tmpUser = Object.assign(user);
        let requestAccessLeft = [];
        if (!tmpUser.subscriptionResponse) tmpUser.subscriptionResponse = context.info.Settings.subscribeDefaultReaction;

        for (let i = 0; i < tmpUser.RequestAccess.length; i++) {
            if (!hasRightToAccess(tmpUser.RequestAccess[i])) {
                requestAccessLeft.push(tmpUser.RequestAccess[i])
            }
        }
        tmpUser.Aanmelding = (requestAccessLeft.length > 0);
        tmpUser.active = true;
        tmpUser.RequestAccess = requestAccessLeft;
        props.onSave(tmpUser)
    }
    const rejectSubscription = () => {
        if (window.confirm("Weet u zeker dat u deze gebruiker wilt afkeuren en verwijderen uit de gebruikersdatabase?")) {
            let tmpUser = Object.assign(user);
            if (!tmpUser.subscriptionResponse) tmpUser.subscriptionResponse = context.info.Settings.subscribeDefaultReaction;
            tmpUser.Aanmelding = false;
            tmpUser.active = false;
            tmpUser.RequestAccess = [];
            props.onSave(tmpUser).then(() => { props.onDelete(tmpUser) })
        }

    }
    
    const hasRightToAccess = (access) => {
        if (context.info.User.FileAccesslistUser && context.info.User.FileAccesslistUser.length > 0) {
            return context.info.User.FileAccesslistUser.indexOf(access) >= 0;
        }
        return true;
    }

  
//  {user.Aanmelding && accessList.filter(access => { return hasRightToAccess(access) && user.RequestAccess.indexOf(access) >= 0 }).length > 0 && <Paper className="padding" style={{ backgroundColor: '#595959', color: 'white' }}><b>Aanvraag</b><br /> <br />
                  
    if (!user) return null;
    return (
        <Dialog fullScreen onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Wijzig gebruiker {user.Name}</Typography><br />
                <Tabs value={selectedTab} onChange={(value) => { setSelectedTab(value) }}><div  >ALGEMEEN</div><div  >RECHTEN</div><div  >OPTIES</div><div>PUSH REGISTRATIES</div></Tabs></DialogTitle>

            <DialogContent>
                {selectedTab === 0 && <>
                    {user.Aanmelding && accessList.filter(access => { return hasRightToAccess(access) }).length > 0 && <Paper className="padding" style={{ backgroundColor: '#595959', color: 'white' }}><b>Aanvraag</b><br /> <br />
                        {user.Opmerking ? <div style={{ whiteSpace: 'pre' }}>{user.Opmerking || ''}</div> : <div>- geen opmerkingen verstuurd -</div>}
                        <br />

                        Aanvraag voor de toegang :<br /><br />

                        {accessList.filter(access => { return hasRightToAccess(access) && (user.RequestAccess.length===0 || user.RequestAccess.indexOf(access) >= 0 )}).map(access => {
                            return < CheckBox key={access} id={"access" + access} label={access} checked={user.Accesslist.indexOf(access) >= 0} onClick={() => { toggleAccess(access) }} />
                        })}
                        <div className='label' style={{ color: 'white' }}>Vink de toegangen aan die toegewezen mogen worden</div>
                        <br />
                        Reactie naar aanvrager (* verplicht):
                        <TextArea label="" id="subscriptionResponse" fullWidth value={user.subscriptionResponse || context.info.Settings.subscribeDefaultReaction} onChange={changeItem} style={{ color: 'white' }}></TextArea>

                        {user.Aanmelding && <Button variant="text" color="primary" onClick={() => { rejectSubscription() }}>afkeuren en verwijder gebruiker</Button>}
                        {user.Aanmelding && <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={() => { approveSubscription() }}  >afronden</Button>}
                    </Paper>}
                    <TextField label="Naam" id="Name" fullWidth value={user.Name} onChange={changeItem}></TextField>

                    <TextField label="Email / gebruikersnaam" id="Email" fullWidth value={user.Email} onChange={changeItem} required></TextField>

                    <TextField label="Organisatie" id="Organisatie" fullWidth value={user.Organisatie || ''} onChange={changeItem} ></TextField>

                    <TextField label="Functie" id="Functie" fullWidth value={user.Functie} onChange={changeItem}></TextField>

                    {props.themes.length > 0 && <Select id="Theme" label="Thema" value={user.Theme} onChange={changeItem}>
                        <option></option>
                        {props.themes.map(theme => { return <option key={theme.id} value={theme.id}>{theme.name}</option> })}
                    </Select>}
                    <br />
                    <CheckBox id="SSOAccount" label="Inloggen met toegangsgegevens app niet mogelijk (SSO account)" checked={user.SSOAccount} onClick={changeItemCheckBox} />
                    {user.SSOAccount !== true && <TextField label="Instellen nieuw wachtwoord" helper="Minimaal 8 karakters waarvan minimaal 1 hoofdletter, 1 kleine letter, 1 getal en een speciaal karakter" id="newpassword" fullWidth value={user.newpassword} onChange={changeItem}></TextField>}

                    <br /> <br /> <div className="label">Inzage in de volgende toegang(en)</div>
                    {accessList.filter(access => { return hasRightToAccess(access) }).map(access => {
                        return < CheckBox key={access} id={"access" + access} label={access} checked={user.Accesslist.indexOf(access) >= 0} onClick={() => { toggleAccess(access) }} />

                    })}
                </>}
                {selectedTab === 1 && <>
                    <div className="label">Rechten op de beheermodules</div>
                    <CheckBox id="cb_installaties" label="installaties" checked={user.Rechten && user.Rechten.indexOf('installaties') >= 0} onClick={() => { toggleRights('installaties') }} />
                    <CheckBox id="cb_gebruikers" label="gebruikers" checked={user.Rechten && user.Rechten.indexOf('gebruikers') >= 0} onClick={() => { toggleRights('gebruikers') }} />
                    {user.Rechten.indexOf('gebruikers') >= 0 && <>
                        <div className="label">Beperk de rechten op gebruikers met de toegang:</div>
                        {accessList.map(access => {
                            return <CheckBox style={{ paddingLeft: '20px' }} id={"cb_users" + access} key={access} label={access} checked={user.FileAccesslistUser && user.FileAccesslistUser.indexOf(access) >= 0} onClick={() => { toggleFileAccessUser(access) }} />
                        })}
                    </>
                    }
                    <CheckBox id="cb_documenten" label="documenten" checked={user.Rechten && user.Rechten.indexOf('documenten') >= 0} onClick={() => { toggleRights('documenten') }} />

                    {user.Rechten.indexOf('documenten') >= 0 && <>
                        <div className="label">Rechten op toegang:</div>
                        {accessList.map(access => {
                            return <CheckBox style={{ paddingLeft: '20px' }} id={"cb_documenten" + access} key={access} label={access} checked={user.FileAccesslist && user.FileAccesslist.indexOf(access) >= 0} onClick={() => { toggleFileAccess(access) }} />
                        })}
                    </>
                    }

                    <CheckBox id="cb_meldingen" label="meldingen" checked={user.Rechten && user.Rechten.indexOf('meldingen') >= 0} onClick={() => { toggleRights('meldingen') }} />
                    <CheckBox id="cb_feedback" label="feedback" checked={user.Rechten && user.Rechten.indexOf('feedback') >= 0} onClick={() => { toggleRights('feedback') }} />
                    <CheckBox id="cb_instellingenapp" label="instellingen App" checked={user.Rechten && user.Rechten.indexOf('instellingenapp') >= 0} onClick={() => { toggleRights('instellingenapp') }} />

                    <TextField label="Aanvullende rechten" id="Additionalrights" fullWidth value={user.Additionalrights} onChange={changeItem}></TextField>
                </>}

                {selectedTab === 2 && <>
                    <CheckBox checked={user.generalAccount || false} id="generalAccount" onClick={changeItemCheckBox} label={'Dit is een "algemeen account", gebruikt door meerdere personen.'} />
                    <CheckBox checked={user.ignoreMaxAtempts || false} id="ignoreMaxAtempts" onClick={changeItemCheckBox} label={'Negeer tijdelijke blokkering na 5 foutieve inlogpogingen.'} />

                    <br style={{ clear: 'both' }} />

                   {props.onSessionReset &&  <Button variant="outlined" color="primary" onClick={()=>props.onSessionReset(user)} style={{color:"red"}}>Uitloggen op alle apparaten</Button>}

                    <br style={{ clear: 'both' }} />    <br style={{ clear: 'both' }} />
                    <div className="label">Automatisch inlogtoken voor gebruik bij MDM (attribute autologintoken)</div>
                    {user.Autologintoken &&
                        <>
                            {user.Autologintoken}<br />
                            <div className="label">Onderstaand adres kan worden gebruikt in de browser voor het automatisch inloggen:</div>
                            {Settings.getPlatform() === 'web' && 'https://' + window.location.host + '/?autologintoken=' + user.Autologintoken}<br />
                            <div className="label">Automatische inloglink alleen mogelijk indien afkomstig via website (optioneel)</div>
                            <TextField id="Autologinreferrer" fullWidth value={user.Autologinreferrer || ''} onChange={changeItem}></TextField><br /><br />

                        </>}
                    <Button variant="contained" color="primary" onClick={generateLoginLink}>Nieuwe logintoken</Button> {user.Autologintoken && <Button variant="outlined" color="primary" onClick={deleteLoginLink}>Verwijder logintoken</Button>}


                </>
                }
                {selectedTab === 3 && <>
                    <Pushmessages user={user}  />
                </>}
            </DialogContent>
            <DialogActions>
                {!user.Aanmelding && <Button variant="text" onClick={() => props.onDelete(user)}>Verwijder</Button>}
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>

                {!user.Aanmelding && <Button variant="contained" color="primary" onClick={() => { props.onSave(user) }} disabled={user.newpassword && user.newpassword.length > 0 && strongPasswordOK === false}>Opslaan</Button>}
            </DialogActions>
        </Dialog>
    )
}
