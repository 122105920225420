import React, { useState,useContext  } from 'react'

import {Dialog,DialogContent, DialogActions, DialogTitle,Button,TextField,Typography} from '../../../ui/core';

import FileService from '../../../../services-admin/files';
import {Context} from '../../../../AppContext';
export default function NewAccess(props) { 
    const context = useContext(Context);
    const [newName, setNewName] = useState(''); 
    const save = () => {
        FileService.newAccess(props.version,newName)
        .then((result)=>{
            context.checkInfo(true);
            props.onRefresh();
            props.onClose();  
        },(err)=>{})
    } 
    return (
        <Dialog  onClose={props.onClose}>
             <DialogTitle ><Typography component="h2">Nieuwe toegang</Typography></DialogTitle>
            <DialogContent >
                <TextField label="Naam voor de nieuwe toegang" id="newAccess" fullWidth value={newName} onChange={(ev) => setNewName(ev.target.value)}></TextField>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={save} disabled={newName.length === 0}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
