import React from 'react'
import Paragraph from './blocks/paragraph'
import Heading from './blocks/heading';
import ParagraphImage from './blocks/paragraphimage';
import ParagraphVideo from './blocks/paragraphvideo';
import Gallery from './blocks/gallery';
import Table from './blocks/table';
import './block.css'
export default function Block({block,onChange,uploadFile,files,pages}) {
   
    return (
        <>
            {block.type==='paragraph' && <div ><Paragraph block={block} onChange={onChange} pages={pages} /></div>}
            {block.type==='heading' && <div ><Heading block={block} onChange={onChange}/></div>}
            {block.type==='paragraphimage' && <div ><ParagraphImage block={block} onChange={onChange} uploadFile={uploadFile} files={files} pages={pages} /></div>}
            {block.type==='paragraphyoutube' && <div ><ParagraphVideo block={block} onChange={onChange} uploadFile={uploadFile} files={files} pages={pages} /></div>}
            {block.type==='gallery' && <div ><Gallery block={block} onChange={onChange} files={files}/></div>}
            {block.type==='table' && <div ><Table block={block} onChange={onChange}/></div>}
        
        </>
    )
}
