import React, { useState, useContext } from 'react'

import {Dialog,DialogContent, DialogActions, DialogTitle,Button,TextField,Typography} from '../../../ui/core';

import FilesService from "../../../../services-admin/files";
import { v4 as uuidv4 } from 'uuid';
import { Context } from "../../../../AppContext";
export default function NewForm(props) {
    const context = useContext(Context);
    const [newName, setNewName] = useState('');

    const save = () => {
        let fileName = newName;
        fileName = fileName.replace(/\//g, ' ');
        if (newName.split('.').pop() !== '.form') {
            fileName = fileName + '.form'
        }

        const found = props.files ? props.files.find(file => { return file.filename.toLowerCase() === fileName.toLowerCase() }) : null;
        if (found) {
            context.setMessage('Er is al een formulier aanwezig met deze naam.')
        } else {
            FilesService.saveBase64(props.version, props.access, props.level, fileName, btoa(JSON.stringify({ id: uuidv4(), title: newName, author: context.info.user })))
                .then(result => {
                    props.onRefresh();
                    props.onClose();
                }, (err) => {
                    props.onRefresh();
                    props.onClose();
                })
        }
    } 
    return (
        <Dialog  onClose={props.onClose}>
            <DialogTitle ><Typography component="h2">Nieuw formulier</Typography></DialogTitle>
            <DialogContent >
                <TextField label="Naam voor het formulier" id="newname" fullWidth value={newName} onChange={(ev) => setNewName(ev.target.value)}></TextField>

            </DialogContent>
            <DialogActions>
                <Button  variant="outlined"  onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={save} disabled={newName.length === 0}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
