
import { Device as DevicePlugin } from '@capacitor/device';
import { Filesystem as FileSystemPlugin, Directory as DirectoryPlugin, Encoding } from '@capacitor/filesystem';
import { App as AppPlugin } from '@capacitor/app'
import { Network as NetworkPlugin } from '@capacitor/network'; 
import {  Secure as SecurePlugin} from 'capacitor-secure'
import { Browser as BrowserPlugin} from '@capacitor/browser';
import {PushNotifications as PushNotificationsPlugin} from '@capacitor/push-notifications'

export let Capacitor;
//export let Plugins;
export let FilesystemDirectory
export let App;
export let Filesystem;
export let FilesystemEncoding
export let Device; 
export let Network;
export let Secure ;
export let Browser;
export let PushNotifications;

try {
  
       
       //Capacitor 3.0 net plugin system
       Device = DevicePlugin;
       Filesystem = FileSystemPlugin; 
       FilesystemDirectory = DirectoryPlugin;
       FilesystemEncoding = Encoding;
       App = AppPlugin;
       Network = NetworkPlugin;
       Secure = SecurePlugin;
       Browser = BrowserPlugin;
       PushNotifications = PushNotificationsPlugin;
   
}
catch (e) {
    console.log('Error loading plugins...')
    console.log(e)
    // Catch the error IE11 throws about Proxy being undefied;
}