import React, { useState, useContext } from 'react'

import { Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField, Typography } from '../../../ui/core';

import FilesService from "../../../../services-admin/files";
import { Context } from "../../../../AppContext";
export default function NewHtml(props) {
  const context = useContext(Context);
  const [newName, setNewName] = useState('');
  //const [contentRef] = useState(React.createRef());

  const save = () => {
    let fileName = newName;
    fileName = fileName.replace(/\//g, ' ');
    if (newName.split('.').pop() !== 'html') {
      fileName = fileName + '.html'
    }
    const found = props.files ? props.files.find(file => { return file.filename.toLowerCase() === fileName.toLowerCase() }) : null;
    if (found) {
      context.setMessage('Er is al een tekstblok aanwezig met deze naam.')
    } else {
      FilesService.saveBase64(props.version, props.access, props.level, fileName, btoa(' '))
        .then(result => {
          props.onRefresh();
          props.onClose();
        }, (err) => {
          props.onRefresh();
          props.onClose();
        })
    }
  }
  return (
    <Dialog onClose={props.onClose}>
      <DialogTitle ><Typography component="h2">Nieuw tekstblok</Typography></DialogTitle>
      <DialogContent >
        <TextField label="Naam voor het document" id="newname" fullWidth value={newName} onChange={(ev) => setNewName(ev.target.value)}></TextField>

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
        <Button variant="contained" color="primary" onClick={save} disabled={newName.length === 0}>Opslaan</Button>
      </DialogActions>
    </Dialog>
  )
}
