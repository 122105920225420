import React, { useEffect, useState } from 'react'
import FileService from '../../../services-admin/files';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { TextArea, TextField, DatePicker, Button } from '../../ui/core';

export default function Keywords(props) {
    const [revision, setRevision] = useState()

    const checkRevision = (rev) => {
        if (!rev) { rev = {} };
        if (!rev._uuid) {
            rev.uuid = uuidv4();
        }
        if (!rev.term) {
            rev.term = 12;
        }
        if (!rev.changes) {
            rev.changes = [{ uuid: uuidv4(), version: '', date: null, remark: '' }]
        }
        let hasEmpty = false;
        for (let i = 0; i < rev.changes.length; i++) {
            if (rev.changes[i].version.trim().length === 0) {
                hasEmpty = true;
            }
        }
        if (!hasEmpty) {
            rev.changes.push({ uuid: uuidv4(), version: '', date: null, remark: '' })
        }
        return rev;
    }

    useEffect(() => {
        FileService.getFileContent(props.version, props.access, props.level, 'revisie.json')
            .then(result => {
                try {
                    setRevision(checkRevision(result))
                } catch (ex) {
                    //todo set uuid
                    setRevision(checkRevision())
                }
            }, (err) => {
                setRevision(checkRevision())
            })
    }, [props.version, props.access, props.level]);

    const save = (rev) => {
        let base64 = btoa(JSON.stringify(rev));// Buffer.from(JSON.stringify(rev)).toString("base64");
        FileService.saveBase64(props.version, props.access, props.level, 'revisie.json', base64)
            .then(result => {
                props.onRefresh();
            }, (err) => {

            })
    }

    const onChange = (attribute, value) => {
        let tmpRevision = Object.assign({}, revision);
        tmpRevision[attribute] = value;
        setRevision(tmpRevision);
    }

    const onChangeDate = (attribute, value) => {
        let tmpRevision = Object.assign({}, revision);
        tmpRevision[attribute] = new Date(value);
        setRevision(tmpRevision);
        save(tmpRevision);
    }

    const onChangeChange = (index, attribute, value) => {
        let tmpRevision = Object.assign({}, revision);
        tmpRevision.changes[index][attribute] = value;
        setRevision(tmpRevision);
    }

    const addTerm = () => {
        let tmpRevision = Object.assign({}, revision);
        tmpRevision.update = moment(tmpRevision.update).add(tmpRevision.term, 'M');
        setRevision(tmpRevision);
        save(tmpRevision);
    }

    return (
        <div className="padding">

            {revision && <>
                <TextField id="name" label="Verantwoordelijke" value={revision.name || ''} fullWidth onChange={(ev) => { onChange('name', ev.target.value) }} onBlur={(ev) => { save(revision) }}></TextField>
                <TextField id="email" label="E-mailadres" value={revision.email || ''} fullWidth onChange={(ev) => { onChange('email', ev.target.value) }} onBlur={(ev) => { save(revision) }}></TextField>

                <br />
                <TextField id="term" type="number" label="Termijn actualisatie (maanden)" value={revision.term || 12} fullWidth onChange={(ev) => { onChange('term', ev.target.value) }} onBlur={(ev) => { save(revision) }}></TextField>
                <br /><br />
                <DatePicker id="update" label="Datum actualisatie" value={revision.update ? new Date(revision.update) : null} onChange={(val) => { onChangeDate('update', val) }} />
                <Button disabled={moment(revision.update).add(2, 'M').diff(moment(new Date()), 'M', false) > parseInt(revision.term || 0)} style={{ float: 'right' }} onClick={addTerm}>+{revision.term} maanden</Button>
                <br /><br />

                <div className="label"> Wijzigingen:</div>

                <table cellPadding={6} cellSpacing={6} style={{ width: '100%' }}><tbody>
                    <tr><th>Versie</th><th>Datum</th><th>&nbsp;&nbsp;Opmerking</th></tr>
                    {revision.changes && revision.changes.map((change, i) => {
                        return <tr key={change.uuid}>
                            <td style={{ verticalAlign: 'top', width: '50px' }}><TextField id="version" type="text" value={change.version || ''} fullWidth onChange={(ev) => { onChangeChange(i, 'version', ev.target.value) }} onBlur={(ev) => { save(revision) }}></TextField></td>
                            <td style={{ verticalAlign: 'top', width: '140px' }}><DatePicker id="date" value={change.date ? new Date(change.date) : null} onChange={(val) => { onChangeChange(i, 'date', val) }} /></td>
                            <td style={{ paddingTop: '2px', paddingLeft: '8px' }} ><TextArea id="remark" type="text" value={change.remark || ''} fullWidth onChange={(ev) => { onChangeChange(i, 'remark', ev.target.value) }} onBlur={(ev) => { save(revision) }}></TextArea></td>
                        </tr>
                    })}
                </tbody></table>

            </>}

        </div>
    )
}
