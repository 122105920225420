import React from 'react'
import {  TextField } from '../../../ui/core'

export default function VideoSettings({ block, onChange}) {
    const handleChange = (value) =>{
        let tmpBlock = Object.assign({}, block);
        tmpBlock.youtubeCode = value;
        onChange(tmpBlock); 
    } 
    return (
        <div>
            <TextField fullWidth label="ID van Youtube clip" id="youtubeCode" value={block.youtubeCode || ''} onChange={(ev)=>{handleChange(ev.target.value)}}></TextField>
           
        </div>
    )
}
