import API from "./api";
const Service = {
    getPushMessages:(userId)=>{
        return new Promise((resolve,reject)=>{
            API.get(`users/${userId}/pushnotifications`,{id:userId}).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        })
    },
    deletePushMessage:(id)=>{
        return new Promise((resolve,reject)=>{
            API.deleteItem(`pushnotifications/${id}`).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        })
    },
    sendTestMessage:(id)=>{
        return new Promise((resolve,reject)=>{
            API.get(`pushnotifications/${id}/sendtest`).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        })
    }
}

export default Service