import API from "./api";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('appsettings').then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (appsettings) => {
        return new Promise((resolve, reject) => {
            API.post('appsettings' , null, {item:appsettings}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;