import React, { useContext, useEffect, useState } from 'react'
import { HashRouter, Route } from "react-router-dom";
import Index from './pages/index';
import Settings from './pages/settings';
import Info from './pages/info';
import Search from './pages/search';
import Announcements from './pages/announcements';
import Feedback from './pages/feedback';
import PageWrapper from './components/general/pagewrapper'
//import PDF from './pages/pdf';
import AppSettings from './AppSettings';
import Admin from './pages/admin';
import LockScreen from './components/lockscreen/lockscreen';
import ThemeSelection from './components/theme/selecttheme';
import AppSelection from './components/settings/selectapps';
import Account from './components/settings/account';
import Privacy from './pages/privacy-policy';
import { ContextUI } from './uiContext';
import { Context } from './AppContext';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Badge } from './components/ui/core';
import { KeyBoard, Message, Search as SearchIcon, Settings as SettingsIcon, ViewList, Email, Info as InfoIcon, ExitToApp, BuildCircle } from './components/ui/coreIcons';

export default function Router(props) {
        const contextUI = useContext(ContextUI);
        const context = useContext(Context);

        const [refresh, setRefresh] = useState();
        // const [showAdmin, setShowAdmin] = useState(false);
        const [striping, setStriping] = useState();

        const adminDisabled = () => {
                if (context.info.Settings.disableAdminInApp === true && AppSettings.getPlatform() !== 'web') {
                        return true;
                } else {
                        return false;
                }
        }

        const hasFeedback = () => {

                if (!context.info.LoggedIn && context.info.Settings.disablepublicfeedback === true) return false;
                if (context.info.LoggedIn && context.info.Settings.disableprivatefeedback === true) return false;

                return true;
        }

        const getFooterMenu = () => {
                let currentPath = '';
                if (context.history) try { currentPath = context.history.location.pathname; } catch (ex) { console.log(ex); currentPath = '' }

                let menu = [];
                if (context.hasOnlyAdmin() === false) {
                        menu.push(<div className="footerMenuItem" key={'menu-index'} style={{ backgroundColor: (currentPath.indexOf('/index') === 0 || currentPath === '/') ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0)' }} onClick={() => { setRefresh(new Date()); if (context.history) context.history.push("/index") }} ><IconButton><ViewList color={'white'} /><br />Index</IconButton></div>)
                }
                menu.push(<div className="footerMenuItem" key={'menu-settings'} style={{ backgroundColor: (currentPath.indexOf('/settings') === 0) ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0)' }} onClick={() => { setRefresh(new Date()); if (context.history) context.history.push("/settings") }} ><IconButton ><SettingsIcon color={'white'} /><br />Instellingen</IconButton></div>)
                if (hasFeedback()) {
                        menu.push(<div className="footerMenuItem" key={'menu-feedback'} style={{ backgroundColor: (currentPath.indexOf('/feedback') === 0) ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0)' }} onClick={() => { setRefresh(new Date()); if (context.history) context.history.push("/feedback") }} ><IconButton ><Email color={'white'} /><br />Feedback</IconButton></div>)
                }
                menu.push(<div className="footerMenuItem" key={'menu-info'} style={{ backgroundColor: (currentPath.indexOf('/info') === 0) ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0)' }} onClick={() => { setRefresh(new Date()); if (context.history) context.history.push("/info") }} > <IconButton ><InfoIcon color={'white'} /><br />Info</IconButton></div>)

                return menu;
        }

        const getSideMenu = (theme) => {
                let currentPath = '';

                if (context.history) try { currentPath = context.history.location.pathname; } catch (ex) { console.log(ex); currentPath = '' }
                return <div>
                        {theme.logo && <img src={theme.logo} style={{ width: '270px' }}></img>}
                        <List style={{ width: '270px' }}>
                                {context.hasOnlyAdmin() === false &&
                                        <ListItem onClick={() => { setRefresh(new Date()); contextUI.setShowSideBar(false); if (context.history) context.history.push("/index") }}><ListItemIcon><ViewList /></ListItemIcon> <ListItemText primary="Index"></ListItemText></ListItem>
                                }
                                <ListItem onClick={() => { setRefresh(new Date()); contextUI.setShowSideBar(false); if (context.history) context.history.push("/settings") }}><ListItemIcon><SettingsIcon /></ListItemIcon> <ListItemText primary="Instellingen"></ListItemText></ListItem>


                                {hasFeedback() && <ListItem onClick={() => { setRefresh(new Date()); contextUI.setShowSideBar(false); if (context.history) context.history.push("/feedback") }}><ListItemIcon><Email /></ListItemIcon> <ListItemText primary="Feedback"></ListItemText></ListItem>}

                                {(context.info && context.info.User && context.info.User.Rechten && context.info.User.Rechten.replace('applogin,', '').length > 2 && adminDisabled() === false) &&
                                        <ListItem onClick={() => { setRefresh(new Date()); contextUI.setShowSideBar(false); if (context.history) context.history.push("/admin"); }}><ListItemIcon><BuildCircle /></ListItemIcon> <ListItemText primary="Beheer"></ListItemText></ListItem>
                                }
                                <ListItem onClick={() => { setRefresh(new Date()); contextUI.setShowSideBar(false); if (context.history) context.history.push("/info") }}><ListItemIcon><InfoIcon /></ListItemIcon> <ListItemText primary="Info"></ListItemText></ListItem>
                                {(context.info && context.info.User) &&
                                        <ListItem onClick={() => { contextUI.setShowSideBar(false); context.logout(context.history); setRefresh(new Date()); }}><ListItemIcon><ExitToApp /></ListItemIcon> <ListItemText primary="Uitloggen"></ListItemText></ListItem>
                                }
                        </List>
                </div>
        }

        //INITIALIZE UI
        useEffect(() => {

                //GET THEME
                let theme = context.theme;
                //BUILD FRAMEWORK               
                if (window.moveBackButtonOnElectron && AppSettings.getPlatform() === 'electron') {
                        contextUI.setShiftHeaderButton(true)
                }
                if (context.info && context.info.Settings && theme) {
                        if (context.info.Settings.MenuStyle === "0" || context.info.Settings.MenuStyle === undefined) {
                                //Menu bar on bottom
                                contextUI.setShowHeader(true);
                                contextUI.setShowFooter(true);
                                contextUI.setPrimary({ color: theme.primary, contrast: theme.primarycontrast || '#fff' })
                                contextUI.setSecondary({ color: theme.secondary, contrast: theme.secondarycontrast || '#fff' })
                                contextUI.setFooterLine1(context.info.Settings.FooterText1);
                                contextUI.setFooterLine2(context.info.Settings.FooterText2);
                                contextUI.setFooterImage(theme.Icon || context.info.Settings.Icon);
                                contextUI.setFooterMenuItems(getFooterMenu());
                                contextUI.setSideMenu();
                        } else {
                                //Menubar on top
                                contextUI.setShowHeader(true);
                                contextUI.setShowFooter(false);
                                contextUI.setPrimary({ color: theme.primary, contrast: theme.primarycontrast || '#fff' })
                                contextUI.setSecondary({ color: theme.secondary, contrast: theme.secondarycontrast || '#fff' })
                                contextUI.setSideMenu(getSideMenu(theme));
                                if (theme.striping) {
                                        setStriping(<div style={{ position: 'fixed', bottom: '0px', backgroundImage: 'url(' + theme.striping + ')', width: '100%', height: '40px' }}>&nbsp;</div>)
                                }

                        }
                }
                //HEADER ICONS
                let headersIcons = [];
                if (AppSettings.getPlatform() === 'electron') {
                        headersIcons.push(<IconButton key={'onscreenbutton'} onClick={() => { window.sendMessage('showkeyboard'); contextUI.setMessage('Het onscreen keyboard wordt geopend') }}><KeyBoard color="white" /></IconButton>);
                }
                if (context.hasOnlyAdmin() === false) {
                        //ANNOUNCEMENTS
                        let count = context.announcements.filter(item => { return !item.read }).length;

                        headersIcons.push(<Badge count={count} key={'announcementbutton'} onClick={() => { context.history && context.history.push('/announcements') }}><IconButton > <Message color="white" /> </IconButton></Badge>);

                        headersIcons.push(<IconButton key={'searchbutton'} onClick={() => { context.history && context.history.push('/search') }}> <SearchIcon color="white" /> </IconButton>);
                }
                if (context.info && context.info.User && context.info.User.Rechten && context.info.User.Rechten.replace('applogin,', '').length > 2 && adminDisabled() === false) { //&& context.info.Settings.MenuStyle === "0"
                        headersIcons.push(<IconButton key={'settingsbutton'} onClick={() => { context.history.push('/admin') }}> <BuildCircle color="white" /> </IconButton>);
                }
                contextUI.setHeaderActions(headersIcons)
        }, [context.theme, context.announcements, refresh])
//<Route path="/pdf/:id" exact render={props => (<PageWrapper {...props}><PDF  {...props} /></PageWrapper>)} />
        if (!context.theme) { return (<div></div>) }
        return (
                <div style={{ width: "100%" }}>
                        <LockScreen>
                                <ThemeSelection />
                                <AppSelection />
                                <HashRouter path={"/"} Component={Index}>
                                        <Route path="/" exact render={props => (<PageWrapper {...props}><Index  {...props} /></PageWrapper>)} />
                                        <Route path="/index" exact render={props => (<PageWrapper {...props}><Index  {...props} /></PageWrapper>)} />
                                        
                                        <Route path="/feedback" exact render={props => (<PageWrapper {...props}><Feedback  {...props} /></PageWrapper>)} />
                                        <Route path="/index/:id" exact render={props => (<PageWrapper {...props}><Index  {...props} /></PageWrapper>)} />
                                        <Route path="/announcements" exact render={props => (<PageWrapper {...props}><Announcements  {...props} /></PageWrapper>)} />
                                        <Route path="/announcements/:id" exact render={props => (<PageWrapper {...props}><Announcements  {...props} /></PageWrapper>)} />
                                        <Route path="/search/:keywords" exact render={props => (<PageWrapper {...props}><Search  {...props} /></PageWrapper>)} />
                                        <Route path="/search" exact render={props => (<PageWrapper {...props}><Search  {...props} /></PageWrapper>)} />
                                        <Route path="/settings" exact render={props => (<PageWrapper {...props}><Settings  {...props} /></PageWrapper>)} />
                                        <Route path="/login" exact render={props => (<PageWrapper {...props}><Account  {...props} /></PageWrapper>)} />
                                        <Route path="/info" exact render={props => (<PageWrapper {...props}><Info  {...props} /></PageWrapper>)} />
                                        <Route path="/admin" exact render={props => (<PageWrapper {...props}><Admin  {...props} /></PageWrapper>)} />
                                        <Route path="/privacy" exact render={props => (<PageWrapper {...props}><Privacy  {...props} /></PageWrapper>)} />
                                </HashRouter>
                        </LockScreen>
                        {striping && striping}
                </div>
        )
}