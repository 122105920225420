import React, { useEffect, useState } from 'react'
import { Tabs ,Button} from '../../../components/ui/core'
import Service from '../../../services-admin/customdatabase';

import Sym from './sym';
import Dis from './dis';
import Help from './help';

export default function Index() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [db, setDb] = useState({});
    const [dirty,setDirty] = useState(false);

    useEffect(() => {
        Service.get('duikmedischhandboek')
            .then(db => { 
                setDb(db)
            })
            .catch(err => { })
    }, [])

    const handleChange = (db) =>{
        db.LastModified = new Date();
        setDb(db);
        setDirty(true);
    }

    const handleSave = ()=>{
        Service.post('duikmedischhandboek',db)
            .then(result => { 
                setDirty(false);
            })
            .catch(err => { alert('opslaan mislukt') })
    }

    return (
        <div>
             <Button variant="contained" color="primary" onClick={handleSave} disabled={!dirty}>Definitief opslaan</Button>
           
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="ui-tab">SYMPTOMEN</div>
                <div className="ui-tab">ZIEKTEN</div>
                <div className="ui-tab">HELPTEKSTEN</div>
            </Tabs>
            {selectedTab===0 && <Sym db={db} onChange={handleChange}/>}
            {selectedTab===1 && <Dis db={db} onChange={handleChange}/>}
            {selectedTab===2 && <Help db={db} onChange={handleChange}/>}
        </div>
    )
}
