
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import SimpleEditor from '../../veditor-simple/veditor';
import { CheckBox, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField } from '../../ui/core';
export default function Dis({ db, onChange }) {
    const [contentRef] = useState(React.createRef());
    const [editItem, setEditItem] = useState();
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);

    const handleChange = (ID, attribute, value) => {
        let tmpDb = Object.assign({}, db);
        let found = tmpDb.Ziekten.find(dis => { return dis.ID === ID });
        if (found) {
            found[attribute] = value;
        }
        onChange(tmpDb);
    }

    const handleChangeEditItem = (attribute, value) => {
        let tmpEdit = Object.assign({}, editItem);
        tmpEdit[attribute] = value;
        setEditItem(tmpEdit);
    }

    const handleSaveEditItem = (ID, item) => {
        let tmpDb = Object.assign({}, db);
        let found = tmpDb.Ziekten.find(dis => { return dis.ID === ID });
        if (found) {
            found.Ziekte = item.Ziekte;
            found.Behandeling = item.Behandeling;
            found._checked = false;
        } else {
            tmpDb.Ziekten.push(item);
        }
        tmpDb.Ziekten = tmpDb.Ziekten.sort((a, b) => { return (a.Ziekte > b.Ziekte) ? 1 : -1 })
        onChange(tmpDb);
        setEditItem(null)
    }

    const handleDelete = () => {
        if (window.confirm('Weet u zeker dat u de geselecteerde items wilt verwijderen?')) {
            let tmpDb = Object.assign({}, db);
            tmpDb.Ziekten = tmpDb.Ziekten.filter(dis => { return dis._checked !== true })
            onChange(tmpDb);
        }
    }

    const handleEdit = () => {
        let found = db.Ziekten.find(dis => { return dis._checked === true });
        if (found) {
            setEditItem(found);
        }
    }

    const handleCheckSym = (symID, disID, checked) => {
        let tmpDb = Object.assign({}, db);
         for(let i=tmpDb.Symptoomziekten.length-1;i>=0;i--){ 
            if(tmpDb.Symptoomziekten[i].ZiekteID == disID && tmpDb.Symptoomziekten[i].SymptoomID == symID){
                tmpDb.Symptoomziekten.splice(i,1);
              
            }
        }
        if (checked) {
            tmpDb.Symptoomziekten.push({ ID: uuidv4(), ZiekteID: disID, SymptoomID: symID });
        } 
        onChange(tmpDb);
    }

    const handleNew = () => {
        setEditItem({ ID: uuidv4(), Ziekte: 'Nieuwe Ziekte', Behandeling: 'Tekst voor behandeling' })
    }

    if (!db.Ziekten || !db.Symptoomziekten) return null;

    if (editItem) return <Dialog fullScreen={true}>
        <DialogTitle><Typography component="h1">Ziekte</Typography></DialogTitle>
        <DialogContent>
            <TextField id="Ziekte" label="Ziekte" value={editItem.Ziekte || ''} onChange={(ev) => { handleChangeEditItem('Ziekte', ev.target.value) }} />
            <br />  <br />
            <SimpleEditor style={{ borderBottom: '1px solid gray' }} contentRef={contentRef} id="Behandeling" label="Behandeling" value={editItem.Behandeling || ''} onChange={(ev) => { handleChangeEditItem('Behandeling', contentRef.current.innerHTML) }} showMenu={true} />
            <br />
            <div className="label">Symptomen</div>
            <div>
                {db.Symptomen.map(sym => { return <CheckBox key={sym.ID} id={sym.ID} label={sym.Symptoom } onChange={(ev) => { handleCheckSym(sym.ID, editItem.ID, ev.target.checked) }} checked={db.Symptoomziekten.find(sz => { return sz.ZiekteID == editItem.ID && sz.SymptoomID == sym.ID }) !== undefined} /> })}
            </div>
        </DialogContent>
        <DialogActions>
            <Button variant="containted" color="primary" onClick={() => { handleSaveEditItem(editItem.ID, editItem) }}>Sluiten</Button>
        </DialogActions>
    </Dialog>

    return (
        <div className="padding">
            <Button variant="contained" color="primary" onClick={handleNew}>Nieuw Ziekte</Button>
            <Button variant="contained" color="primary" disabled={db.Ziekten.filter(dis => { return dis._checked === true }).length !== 1} onClick={handleEdit}>Wijzigen</Button>
            <Button variant="contained" color="primary" disabled={db.Ziekten.filter(dis => { return dis._checked === true }).length === 0} onClick={handleDelete}>Verwijderen</Button>
            <table><tbody><tr><th></th><th></th><th>Ziekte</th><th>Behandeling</th></tr>
                {db.Ziekten && db.Ziekten.map((dis, i) => {
                    return <tr key={dis.ID}><td className="label">{i}</td><td><CheckBox id={dis.ID} checked={dis._checked} onChange={(ev) => { handleChange(dis.ID, '_checked', ev.target.checked) }} /></td><td>{dis.Ziekte}</td><td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dis.Behandeling) }}></td></tr>
                })}
            </tbody></table>

            <br /><br /><br />
        </div>
    )
}
