import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '../ui/core'

export default function AddressbookPopup(props) {
    return (
        <Dialog
            open={true}
            fullScreen
            onClose={props.onClose}
            className="padding-ios"
        >
            <DialogTitle className="padding">
               <div className="padding-ios"> <Typography component="h1" >{props.contact.Achternaam ? <>{props.contact.Achternaam}, {props.contact.Voornaam} {props.contact.Tussenvoegsel}<br />{props.contact.Organisatie}</> : <>{props.contact.Organisatie}</>}</Typography></div>
            </DialogTitle>
            <DialogContent className="padding">
                <img src={props.contact.Image} style={{float:'right'}}/>
               {props.contact.Voorletters && <div>
                    <div className="label">Voorletters: </div> {props.contact.Voorletters}<br />  <br />
                </div>}

                {props.contact.Gemeente && <div>
                    <div className="label">Gemeente: </div> {props.contact.Gemeente}<br />  <br />
                </div>}

                {props.contact.Deskundigheid && <div>
                    <div className="label">Deskundigheid: </div> {props.contact.Deskundigheid}<br />  <br />
                </div>}
                
                {props.contact.Opmerkingen && <div style={{ whiteSpace: "pre-wrap" }}><i>{props.contact.Opmerkingen}</i><br /><br /></div>}
                {props.contact.Functie && <div>
                    <div className="label">Functie: </div>   {props.contact.Functie}<br /> <br />
                </div>}

                {props.contact.PiketFunctie && <div>
                    <div className="label">Piketfunctie: </div> {props.contact.PiketFunctie}<br />   <br />
                </div>}

                {props.contact.FunctieinCoPI && <div>
                    <div className="label">Functie in CoPI: </div> {props.contact.FunctieinCoPI}<br />  <br />
                </div>}

                {props.contact.FunctieinROT && <div>
                    <div className="label">Functie in ROT: </div> {props.contact.FunctieinROT}<br />  <br />
                </div>}

                {props.contact.FunctieinBT && <div>
                    <div className="label">Functie in BT: </div> {props.contact.FunctieinBT}<br />  <br />
                </div>}

                {props.contact.FunctieMeldkamer && <div>
                    <div className="label">Functie Meldkamer: </div> {props.contact.FunctieMeldkamer}<br />  <br />
                </div>}

                {props.contact.FunctieActiecentrum && <div>
                    <div className="label">Functie in het Actiecentrum: </div> {props.contact.FunctieActiecentrum}<br />  <br />
                </div>}

                {props.contact.Functieoverig && <div>
                    <div className="label">Functie overig: </div> {props.contact.Functieoverig}<br />  <br />
                </div>}

                {(props.contact.Woonplaats || props.contact.Adres) && <div>
                    <div className="label">Adres:</div>
                    {props.contact.Adres}<br />
                    {props.contact.Postcode} {props.contact.Woonplaats}<br />  <br />
                </div>}

                {props.contact.Email && <div>
                    <div className="label">Email: </div> <a href={'mailto:' + props.contact.Email} target="_blank" rel="noopener noreferrer">{props.contact.Email}</a><br />  <br />
                </div>}

                {props.contact.Telefoonnummer && <div>
                    <div className="label">Telefoonnummer: </div> <a href={'tel:' + props.contact.Telefoonnummer} target="_blank" rel="noopener noreferrer">{props.contact.Telefoonnummer}</a><br />  <br />
                </div>}

                {props.contact.Mobiel && <div>
                    <div className="label">Mobiel: </div> <a href={'tel:' + props.contact.Mobiel} target="_blank" rel="noopener noreferrer">{props.contact.Mobiel}</a><br />  <br />
                </div>}

                {props.contact.Alternatiefbereikbaarop && <div>
                    <div className="label">Alternatief bereikbaar op: </div> {props.contact.Alternatiefbereikbaarop}<br />  <br />
                </div>}

                {props.contact.Piketnummer && <div>
                    <div className="label">Piketnummer: </div> <a href={'tel:' + props.contact.Piketnummer} target="_blank" rel="noopener noreferrer">{props.contact.Piketnummer}</a><br />  <br />
                </div>}

                {props.contact.Team && <div>
                    <div className="label">Team: </div> {props.contact.Team}<br />  <br />
                </div>}

                {props.contact.Sectie && <div>
                    <div className="label">Sectie: </div> {props.contact.Sectie}<br />  <br />
                </div>}

                {props.contact.Rolbinnenteam && <div>
                    <div className="label">Rol binnen team: </div> {props.contact.Rolbinnenteam}<br />  <br />
                </div>}

                {props.contact.Proces && <div>
                    <div className="label">Proces: </div> {props.contact.Proces}<br />  <br />
                </div>}
              

             

              

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Sluiten
          </Button>
            </DialogActions>
        </Dialog>

    )
}
