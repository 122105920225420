import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '../../ui/core';
import List from './list';
import Edit from './edit';
import Import from './import';
import GeneralFunctions from '../../../services/general';
import { v1 as uuidv1 } from 'uuid';
import XLSX from  '../../../services-admin/xlsx';
export default function BuilderPopup({ items, onSave, onClose }) {
    const [contacts, setContacts] = useState([])
    const [contact, setContact] = useState();
    const [showImport, setShowImport] = useState(false);

    useEffect(() => {
        setContacts(items);
    }, [items]);

    const addContact = () => {
        setContact({ ID: uuidv1() });
    }

    const saveContact = (changedContact) => {
        let tmpContacts = Object.assign([], contacts);
        tmpContacts = tmpContacts.filter(function (item) { return item.ID != changedContact.ID });
        tmpContacts.push(changedContact);
        tmpContacts = GeneralFunctions.sortJSON(tmpContacts, 'Organisatie', '123')
        tmpContacts = GeneralFunctions.sortJSON(tmpContacts, 'Achternaam', '123')
        setContacts(tmpContacts);
        setContact();
        onSave(tmpContacts);
    }

    const saveImport = (importContacts, syncOnEmail, clean, skipFirst) => {
        let tmpContacts = Object.assign([], contacts);
        if (clean) {
            tmpContacts = [];
        }
        for (let i = 0; i < importContacts.length; i++) {
            if (skipFirst === true && i === 0) {
                i = 1;
            }
            if (clean) {
                let found = contacts.find(function (item) { if (!item.Email || !importContacts[i].Email) return false; return item.Email.toLowerCase().trim() == importContacts[i].Email.toLowerCase().trim() });

                if (found && found.Image) {
                    importContacts[i].Image = found.Image;
                }
                tmpContacts.push(importContacts[i]);
            } else {
                let found = tmpContacts.find(function (item) { if (!item.Email || !importContacts[i].Email) return false; return item.Email.toLowerCase().trim() == importContacts[i].Email.toLowerCase().trim() });
                if (syncOnEmail && found && importContacts[i].Email && importContacts[i].Email.length > 0 && importContacts[i].Email.indexOf('@') > 0) {
                    let backupID = found.ID;
                    let tmpImage;
                    if(found.Image){
                        tmpImage = found.Image;
                    }
                    found = importContacts[i];
                    found.Image = tmpImage;
                    found.ID = backupID;
                } else {
                    tmpContacts.push(importContacts[i]);
                }
            }
        }
        tmpContacts = GeneralFunctions.sortJSON(tmpContacts, 'Organisatie', '123')
        tmpContacts = GeneralFunctions.sortJSON(tmpContacts, 'Achternaam', '123')
        setContacts(tmpContacts);
        onSave(tmpContacts);
        setShowImport(false);
    }

    const deleteContact = (deletedContact) => {
        let tmpContacts = Object.assign([], contacts);
        tmpContacts = tmpContacts.filter(function (item) { return item.ID != deletedContact.ID });
        setContacts(tmpContacts);
        setContact();
        onSave(tmpContacts);
    }

    return (
        <>
            {showImport && <Import onClose={() => { setShowImport(false) }} onSave={saveImport} />}
            {contact && <Edit item={contact} onClose={() => { setContact() }} onSave={saveContact} onDelete={deleteContact} />}
            <Dialog fullScreen={true}>
                <DialogTitle><Typography component="h1">Bewerken adresboek</Typography></DialogTitle>
                <DialogContent style={{ padding: '0px' }}>
                    <List items={contacts} onClick={(item) => { setContact(item) }} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { XLSX.downloadJSON(contacts,'export.xlsx') }} >Exporteren</Button>
                    <Button variant="outlined" onClick={() => { setShowImport(true) }} >Importeren</Button>
                    <Button variant="outlined" onClick={addContact}>Toevoegen contact</Button>
                    <Button variant="outlined" onClick={onClose}>Sluiten</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
