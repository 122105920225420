/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import FileService from '../../services/file';
import Settings from "../../AppSettings";
import StorageService from '../../services/storage';
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';
import ModalImage from '../general/modalimage';
import UpdateService from '../../services/updateservice';
import { Browser } from "../../plugins/capacitor";
import { ExpansionPanel, Typography, LoadingPlaceholder } from '../ui/core';
import './html.css'
const locationAPI = Settings.getLocationAPI();

export default function Html(props) {
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    const [html, setHtml] = useState('');
    const [loading, setLoading] = useState(true);
    const [showImage, setShowImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState()
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const hasToggle = (props.dirInfo && props.dirInfo.toggleTextBlock && props.dirInfo.toggleTextBlock.indexOf(props.file.Name) >= 0);
    const [expanded, setExpanded] = useState([]);
    const containerRef = useRef(null);
    const { current } = containerRef;

    const scrollToView = (id) => {
        var element = document.getElementById(id);
        if (element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset - 65;
            window.scrollTo({ top: y, behavior: 'smooth' })
        }
    }
    const previewFile = (DPath) => {
        UpdateService.getDownloadToken(DPath)
            .then(token => {
                window.open(locationAPI + 'documentpreviewv3/' + token + '/?' + DPath, '_blank');
            })
            .catch(ex => {
                context.setMessage('Er is een fout opgetreden bij het openen van het bestand.')
            })
    }

    const toggleBlock = (id, show) => {
        var elements = document.getElementsByClassName("belongsToHeader_" + id);

        for (let i = 0; i < elements.length; i++) {
            if (show) {
                elements[i].style.display = 'block';
            } else {
                elements[i].style.display = 'none';
            }
        }
    }

    const handleClickLinkDocument = (e) => {
        let DPath = e.pathname.replace('/', '');
        let foundDoc = context.info.Index.find((item) => { return item.DPath === DPath });
        if (foundDoc) {
          
            context.setMessage('Bestand wordt geopend...')
            FileService.viewFile(props.file.DPath, StorageService.getStorageFileName(props.file), props.file.Name)
                .then(result => {
                  
                })
                .catch(err => {
                    context.setMessage('Het bestand kan niet worden geopend.')
                    console.log(err);
                })

           // StorageService.previewDocument(props.file, props.history)
           //     .then((result) => { }, (err) => {
           //         previewFile(DPath)
            //    })
        } else {
            previewFile(DPath)
        }
    }

    const handleClickLinkLevel = (e) => {
        const clickedLink = decodeURI(e.pathname) + '/';
        let foundLevel = context.info.Levels.find((item) => { return '/' + item.Path === clickedLink });
        if (foundLevel) {
            props.history.push('/index/' + foundLevel.HPath)
        } else {
            context.setMessage('De verwijzing van deze link kan niet worden gevonden')
        }
    }

    const handleClickLink = (e) => {
        let clickedLink = e.href;
        if (clickedLink) {
            if (Browser) {
                Browser.open({ url: clickedLink });
            } else {
                window.open(clickedLink, '_blank');
            }
        }
    }

    const handleClickLinkFile = (e) => {
        let clickedFile = decodeURI(e.pathname).replace(/\//g, '');
        console.log(clickedFile)
        if (clickedFile) {
            try {
                let foundDocs = context.info.Index.filter((item) => { if (!item.Name) { return false }; return item.Name.trim().toLowerCase() === clickedFile.trim().toLowerCase() });
                if (foundDocs.length > 0) {
                    context.setMessage('Bestand wordt geopend...')
                    FileService.viewFile(foundDocs[0].DPath, StorageService.getStorageFileName(foundDocs[0]), foundDocs[0].Name)
                        .then(result => {
                          
                        })
                        .catch(err => {
                            context.setMessage('Het bestand kan niet worden geopend.')
                            console.log(err);
                        })
                  //  StorageService.previewDocument(foundDocs[0], props.history)
                    //    .then((result) => { }, (err) => {
                      //      previewFile(foundDocs[0].DPath)
                       // })
                } else {
                    context.setMessage('Kan geen document vinden met de bestandsnaam ' + clickedFile)
                }
            } catch (ex) {
                console.log(ex)
            }

        }
    }

    const handleClickLinkSearch = (e) => {
        const search = encodeURI(e.pathname);
        props.history.push('/search' + search)
    }

    const handleClickImage = (e) => {
        if (e.src.indexOf('data:') === 0) {
            setSelectedImage(e);
            setShowImage(true)
        }
    }

    const catchClick = (ev) => {
        let val = '';
        try {
            val = ev.srcElement.className.split(' ')[0];
        } catch (ex) { }
        switch (val) {
            case 'internalPage':
                handleClickLinkLevel(ev.srcElement)
                ev.preventDefault();
                break;
            case 'internalSearchPage':
                handleClickLinkSearch(ev.srcElement)
                ev.preventDefault();
                break;
            case 'internalFile': //todo
                handleClickLinkFile(ev.srcElement)
                ev.preventDefault();
                break;
            case 'link':
                handleClickLink(ev.srcElement)
                ev.preventDefault();
                break;
            case 'linkTag':
                scrollToView(ev.srcElement.href.split('/').pop())
                ev.preventDefault();
                break;
            case 'toggleBlock':
                if (ev.srcElement.className.indexOf('collapsed') >= 0) {
                    ev.srcElement.classList.remove('collapsed');
                    toggleBlock(ev.srcElement.id, true);
                } else {
                    ev.srcElement.classList.add('collapsed');
                    toggleBlock(ev.srcElement.id, false)
                }

                ev.preventDefault();
                break;
            case 'DPathLink':
                handleClickLinkDocument(ev.srcElement)
                ev.preventDefault();
                break;
            case 'paragraphimage':
                handleClickImage(ev.srcElement)
                ev.preventDefault();
                break;
            case 'htmlbuilder-gallery-card-image':
                handleClickImage(ev.srcElement)
                ev.preventDefault();
                break;
            case 'htmlbuilder-paragraph-card-image':
                handleClickImage(ev.srcElement)
                ev.preventDefault();
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('click', catchClick);
        return () => {
            document.removeEventListener('click', catchClick);
        }
    }, [])

    useEffect(() => {
        if (props.id) {
          //  readStorage();
          FileService.getContent(props.file.DPath,StorageService.getStorageFileName(props.file),true)
          .then(content=>{
              setHtml(setHeadings(content));
              setLoading(false);
          })
          .catch(err=>{
              console.log(err)
          })
        }
    }, [])

    const setHeadings = (text) => {
        let retValue = text.replace(/<h1/g, '<h1 style="color:' + contextUI.primary.color + '" ');
        retValue = retValue.replace(/<h2/g, '<h2 style="color:' + contextUI.primary.color + '" ');
        retValue = retValue.replace(/<h3/g, '<h3 style="color:' + contextUI.primary.color + '" ');
        return retValue;
    }
    
    /*
    useEffect(() => {
        if (context.decryptedFiles && context.decryptedFiles[StorageService.getStorageFileName(props.file) ]) {
            setHtml(setHeadings(FileService.decodeUnicode(context.decryptedFiles[StorageService.getStorageFileName(props.file) ])));
        }
    }, [context.decryptedFiles])


    const readOnline = () => {
        console.log('get online')
        FileService.getContent(props.file.DPath,StorageService.getStorageFileName(props.file),true)
        .then(content=>{
            setHtml(setHeadings(content));
            setLoading(false);
        })
        .catch(err=>{
            console.log(err)
        })
        /*
        console.log('Gettting online')
        FileService.getContentOnline(props.id).then((result) => {
            setHtml(setHeadings(result));
            setLoading(false);
        }, (err) => { console.error(err) })
   
    }

    const readStorage = () => {
        StorageService.readFile(StorageService.documentsDir(),StorageService.getStorageFileName(props.file) )
            .then((result) => {
                try {
                    if (props.file.encrypt) {
                        context.decryptFile(StorageService.getStorageFileName(props.file) , result);
                    } else {
                        setHtml(setHeadings(result));
                    }
                    setLoading(false);
                } catch (ex) {
                    console.log(ex)
                    readOnline();
                }
            }, (err) => {
                // console.log(err)
                readOnline();
            })
    }
    */

    useEffect(() => {
        let exp = [];
        exp['exp_' + props.file.DPath] = window.localStorage.getItem('exp_' + props.file.DPath) ? true : false;
        setExpanded(exp);

    }, [])

    const toggleExpanded = (key) => {
        let exp = Object.assign([], expanded);
        if (Object.keys(exp).indexOf(key) >= 0) {
            if (exp[key] === true) {
                exp[key] = undefined;
            } else {
                exp[key] = true;
            }
        } else {
            exp[key] = true;
        }
        if (exp[key] === true) {
            window.localStorage.setItem(key, '1')
        } else {
            window.localStorage.removeItem(key)
        }

        setExpanded(exp)
    }

    if (loading) return (<LoadingPlaceholder />)
    //const h1Style = { color: (context.info && context.info.Settings && context.info.Settings.Color) || 'black' }

    return (
        <div style={{ clear: 'both' }}>
            {showImage && selectedImage && <ModalImage image={selectedImage && selectedImage.src} title={selectedImage.alt || ''} onClose={() => { setShowImage(false); setSelectedImage() }} open={showImage} />}
            {hasToggle ?
                <ExpansionPanel expanded={expanded['exp_' + props.file.DPath] == true} onClick={() => { toggleExpanded('exp_' + props.file.DPath) }} primary={<Typography component="h2">{props.file.Name.replace('.' + props.file.Name.split('.').pop(), '')}</Typography>}>
                    <div className="padding content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
                </ExpansionPanel>
                //<ExpansionPanel expanded={expanded['exp_' + props.file.DPath] == true} >
                //    <ExpansionPanelSummary expandIcon={<MoreIcon />} onClick={() => { toggleExpanded('exp_' + props.file.DPath) }} >
                //        <h1 style={h1Style} >{props.file.Name.replace('.' + props.file.Name.split('.').pop(), '')}</h1>
                //    </ExpansionPanelSummary>
                //    <ExpansionPanelDetails>
                //        <div style={{ width: '100%' }} className="content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
                //    </ExpansionPanelDetails>
                //</ExpansionPanel>
                :
                <div ref={containerRef} style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} className="padding content"></div>
            }

        </div>
    )
}
