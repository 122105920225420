import API from "./apiv2";

const Service = {
    send:(message)=> {
        return new Promise((resolve, reject) => {
            API.post('feedback',null,message).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;