import { Device } from "./plugins/capacitor";

if (!window.__context) {

    window.__context = {
        cache: [],
        locationAPI: '/api/resolve/v2/',
        locationAPIV2: '/apiv2/',
        locationAdminAPI: '/apiadminv2/',
        locationDocuments: '/documents/',
        isApp: false,
        platform: 'web',
        JWT: '',
        uuid: '',
        device: {},
        database: [],
        menu: [],
    }

    //TEST ENVIROMENT
    if (document.location.href.indexOf('handboektest.vedebo.net:3000') >= 0 || document.location.href.indexOf('localhost:3000') >= 0) {
        window.__context.locationAPI = 'http://localhost:5000/api/resolve/v2/';//'https://handboektest.vedebo.net/api/resolve/v2/';// 
        window.__context.locationAPIV2 = 'http://localhost:3500/apiv2/';
        window.__context.locationAdminAPI = 'http://localhost:3400/apiadminv2/';
        window.__context.locationDocuments = 'http://localhost:3002/documents/';//'https://handboektest.vedebo.net/documents/';
    }

    //OVERRIDE APP SETTINGS
    if (window.defaultAPILocation) {
        window.__context.locationAPI = window.defaultAPILocation + 'api/resolve/v2/';
        window.__context.locationDocuments = window.defaultAPILocation + 'documents/';
        window.__context.locationAPIV2 = window.defaultAPILocation + 'apiv2/';
        window.__context.locationAdminAPI = window.defaultAPILocation + 'apiadminv2/';
    }
}

const settings = {
    getPlatform() {
        // try {
        //     window.__context.platform = window.device.platform.toLowerCase();
        //} catch (ex) { }
        return window.__context.platform;
    },
    getUUID() {
        return window.__context.uuid;
    },
    getDevice() {
        return window.__context.device;
    },
    getContext() {
        return window.__context;
    },
    getLocationAPI() {
        return window.__context.locationAPI;
    },
    getLocationAdminAPI() {
        return window.__context.locationAdminAPI;
    },
    getLocationAPIV2() {
        return window.__context.locationAPIV2;
    },
    getLocationDocumentAPI() {
        return window.__context.locationDocuments;
    },
    getToken() {
        return window.__context.token || {};
    },
    setToken(token) {
        window.__context.token = token;
    },
    getJWT() {
        if (window.localStorage.getItem('t')) {
            window.__context.JWT = window.localStorage.getItem('t');
        }
        return window.__context.JWT;
    },
    setJWT(jwt) {
        window.localStorage.setItem('t', jwt);
        window.__context.JWT = jwt;
    },
    getCollection(collection) {
        return window.__context.database[collection];
    },
    setCollection(collection, value) {
        window.__context.database[collection] = value;
    },
    setCache(item, value) {
        window.__context.cache[item] = value;
    },
    getCache(item) {
        return window.__context.cache[item];
    }
}

//Electron
window.sendMessage = (subject, message) => {
    if (window.ipc) {
        window.ipc.send(subject, message);
    }
}


Device.getInfo().then(
    (result) => {
    
        window.__context.platform = result.platform;
        window.__context.isApp = (result.platform !== 'web');
        result.versionapp = result.appVersion;
        result.version = result.osVersion;
        window.__context.device = result;
        if (result.platform === 'electron') {
            window.ipc = window.require('electron').ipcRenderer;
        }
    },
    (err) => { }
)

Device.getId().then(
    (result) => {
        window.__context.uuid = result.identifier;
    }
)

export default settings;