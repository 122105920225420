import React, { useState, useEffect } from 'react' 

import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Tabs, Fab, List, ListItem, ListItemText, SearchBar, ListItemIcon, TextField, CheckBox, Typography, TextArea } from '../../ui/core';

import FileService from "../../../services-admin/files" 
export default function Edit(props) {
    const [item, setItem] = useState(Object.assign({}, props.item));
   const [accessList,setAccessList ] = useState([])

    useEffect(() => {        
        FileService.getAccess()
            .then(result => {setAccessList(result)}, err => { })
    }, [])

    const changeItem = (ev) => {
        let tmpItem = Object.assign({}, item);     
        tmpItem[ev.target.id] = ev.target.value;
        setItem(tmpItem);
    }

    const toggleAccess = (access) => {
        let tmpItem = Object.assign({}, item);
        if(!tmpItem.access){tmpItem.access = []}
        if (tmpItem.access.indexOf(access) >= 0) {
            tmpItem.access.splice(tmpItem.access.indexOf(access), 1);
        } else {
            tmpItem.access.push(access);
        }
        setItem(tmpItem);
    }
    
    return (
        <Dialog fullScreen onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Melding {item.title}</Typography></DialogTitle>              
            <DialogContent>
                 <TextField label="Titel" id="title" fullWidth value={item.title} onChange={changeItem}></TextField>
                <TextArea label="Beschrijving" id="description" value={item.description||''} onChange={changeItem}/>
                <br /> <br /> <div className="label">Toegang</div>
                    {accessList.map(access => {
                        return < CheckBox key={access} id={"access" + access} label={access} checked={item.access && item.access.indexOf(access) >= 0} onClick={() => { toggleAccess(access) }} />

                    })}
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => props.onDelete(item)}>Verwijder</Button>
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={() => { props.onSave(item) }}>Verstuur</Button>
            </DialogActions>
        </Dialog>
    )
}
