import React from 'react' 
import { Paper,  TextField,  Divider, IconButton } from '../../ui/core';
import {KeyBoardArrowUp,KeyBoardArrowDown,Delete} from '../../ui/coreIcons';

export default function Textblock({item,onChange,onDelete,onChangeSort}) {
    const changeItem = (content,attribute)=>{ 
        let newItem = Object.assign({},item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{marginBottom:'6px'}}>
           <TextField fullWidth  placeholder="Titel" value={item.textTitle || ''} onChange={(ev)=>{changeItem(ev.target.value,'textTitle')}} ></TextField>
            <TextField fullWidth multiline placeholder="(Introductie) tekst" value={item.text || ''} onChange={(ev)=>{changeItem(ev.target.value,'text')}} ></TextField>
            <br/><br/><Divider />
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'up') }}><KeyBoardArrowUp /></IconButton>
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'down') }}><KeyBoardArrowDown /></IconButton>
              <div style={{ float: 'right' }}>
              <div style={{ display: 'flex' }}>
                  <div>{onDelete && <IconButton onClick={() => { if (window.confirm("Weet u zeker dat u dit element wilt verwijderen?")) { onDelete() } }}><Delete /></IconButton>}</div>
                  </div>
            </div>
            <br style={{clear:'both'}}/>
         </Paper>
    )
}
