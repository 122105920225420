import React from 'react'
import './block.css'
import IconParagraph from './icons/paragraph.svg'
import IconParagraphImageLeft from './icons/paragraphimageleft.svg'
import IconGet from './icons/get.svg'
import IconTable from './icons/table.svg'
import IconGallery from './icons/gallery.svg'
import IconHeading from './icons/heading.svg'
// <div onClick={()=>{onSelect('paragraphyoutube')}} className="block-add-box">  <img src={IconParagraphVideo} /><br/>  paragraaf met youtube video </div>
//<div onClick={()=>{onSelect('paragraphimage')}} className="block-add-box">  <img src={IconParagraphImageLeft}  /> <br/> paragraaf met afbeelding </div>
          //   <div onClick={()=>{onSelect('table')}} className="block-add-box">  <img src={IconTable}  /> <br/> tabel </div>
export default function BlockAdd({onSelect}) {
    return (
        <div className="block-menu label">
            <div>Toevoegen nieuw blok:</div>
            <div onClick={()=>{onSelect('heading')}} className="block-add-box">  <img src={IconHeading}  /><br/>  titel </div>
            <div onClick={()=>{onSelect('paragraph')}} className="block-add-box">  <img src={IconParagraph}  /><br/>  tekstblok </div>
            <div onClick={()=>{onSelect('table')}} className="block-add-box">  <img src={IconTable}  /> <br/> tabel </div>
            <div onClick={()=>{onSelect('gallery')}} className="block-add-box">  <img src={IconGallery}  /> <br/> gallerij </div>
            <div onClick={()=>{onSelect('template')}} className="block-add-box">  <img src={IconGet}  /> <br/> importeren </div>
            <br style={{clear:'both'}}/>
        </div>
    )
}
