/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react'
import Index from '../components/index/index';
import UpdateStatus from '../components/index/updatestatus' 
import { App } from "../plugins/capacitor";
import { Context } from '../AppContext';
export default function IndexPage(props) {
    const context = useContext(Context)

    useEffect(() => {
        if (context.hasOnlyAdmin()) {
            console.log('Only admin')
            props.history.push('/settings');
        }
    })

    const goBack = () => {
        if (!window.global) { window.global = {} }
        if (!window.global.delay) {
            window.global.delay = new Date().getTime()
            props.history.goBack();
        } else if ((new Date().getTime() - window.global.delay) > 1000) {
            if (window.location.href.split('/#/').pop() === '') {
                if (App) { 
                    App.exitApp();
                }
            } else {
                window.global.delay = new Date().getTime();
                props.history.goBack();
            }
        }
    }

    useEffect(() => {
        if (App) { 
            App.addListener('backButton', goBack);
        }
    }, [])
    //    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
    if (context.hasOnlyAdmin() === true) return null;
    /*

    */
    return (
        <React.Fragment>
<div>
                <UpdateStatus />
                <Index {...props} />
            </div>
        </React.Fragment>
    )
}
