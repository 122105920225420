import React, { useState, useEffect } from 'react'
import TableCell from './tablecell';
export default function Table({ block, onChange }) {
    const [currentBlock, setCurrentBlock] = useState();
    const [rows, setRows] = useState([])
    useEffect(() => {
        let tmpRows = [];
        let tmpBlock = Object.assign({}, block);
        if (!tmpBlock.cells) {
            tmpBlock.cells = [];
        }
        if (!tmpBlock.maxRows) {
            tmpBlock.maxRows = 3;
        }
        if (!tmpBlock.maxColumns) {
            tmpBlock.maxColumns = 3;
        }
        for (let r = 0; r < tmpBlock.maxRows; r++) {
            tmpRows[r] = [];

            for (let c = 0; c < tmpBlock.maxColumns; c++) {

                if (tmpBlock.cells[r] === undefined) {
                    tmpBlock.cells[r] = [];
                    tmpBlock.cells[r][c] = {};
                }
                if (tmpBlock.cells[r][c] === undefined) {
                    tmpBlock.cells[r][c] = {};
                    tmpBlock.cells[r][c].content = ' ';
                }
                tmpBlock.cells[r][c].c = c;
                tmpBlock.cells[r][c].r = r;
                //    tmpRows[r][c] = <TableCell key={''+r+'-'+c} tableCell={tmpBlock.cells[r][c]}/>;
            }
        }
        setCurrentBlock(tmpBlock);

        //building cells for view


    }, [block])

    const handleChangeCell = (row, column, cell) => {
        let tmpCell = Object.assign({}, currentBlock);
        tmpCell.cells[row][column] = cell;

        onChange(tmpCell);
    };

    if (!currentBlock) return null;

    const getStyle = (cell) => {
        let tmpStyle =  {};
        if(cell.style){tmpStyle = Object.assign({},cell.style)}
        if (!tmpStyle.border && !tmpStyle.borderTop && !tmpStyle.borderBottom && !tmpStyle.borderLeft && !tmpStyle.borderRight) {
            if (cell.selected) {
                tmpStyle.border = '1px dotted black'
            } else {
                tmpStyle.border = '1px dotted silver'
            }
        }
       tmpStyle.padding = '6px';
        return tmpStyle;
    }

    const handleCellSelection = (row, column) => {
        let tmpBlock = Object.assign({}, currentBlock);
        for (let r = 0; r < tmpBlock.maxRows; r++) {
            for (let c = 0; c < tmpBlock.maxColumns; c++) {
                tmpBlock.cells[r][c].selected = false;
            }
        }
        tmpBlock.cells[row][column].selected = true;
        onChange(tmpBlock);
    }

    const renderedTable = ()=>{
        let retVal = [];
        for (let r = 0; r < currentBlock.maxRows; r++) {
            let tmpCells = [];
            for (let c = 0; c < currentBlock.maxColumns; c++) {
                tmpCells.push(<td key={'row' + r + 'cell' + c} onFocus={() => { handleCellSelection(r, c) }} style={getStyle(currentBlock.cells[r][c])}><TableCell tableCell={currentBlock.cells[r][c]} row={r} column={c} onChange={handleChangeCell} ></TableCell></td>);
            }
            retVal.push(<tr key={'row_' + r}>{tmpCells}</tr>)
        }

        return  <table style={{ width: '100%', cellPadding: '0px', cellSpacing: '0px' }} cellSpacing="0" cellPadding="0">
        <tbody>{retVal}</tbody></table>
    }
    
    return (
        <>
            {renderedTable()}
        </>
    )
}
