/* eslint-disable react-hooks/exhaustive-deps */
import React,{useContext,useEffect,useState} from 'react'
import { Context } from "../AppContext";
import { ContextUI } from '../uiContext';
import AppSettings from '../AppSettings'; 
import GeneralService from '../services/general';
import  { Network } from '../plugins/capacitor';

const Privacy = (props) =>{
    const contextUI = useContext(ContextUI);
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    const context = useContext(Context); 
    useEffect(() => {
        contextUI.setTitle('Privacy verklaring'); 
        contextUI.setSubTitle('');
        contextUI.setShowBackButton(false);

     
    }, [])

    return (
        <   >
            {context.info.Settings && <div className="padding" style={{whiteSpace:'pre-wrap'}} dangerouslySetInnerHTML={{ __html:DOMPurify.sanitize(context.info.Settings.PrivacyPolicy) }}></div>}
          
         
        </ >
    )
}
export default Privacy
