import API from "./api";
const Service = {
    get: (db) => {
        return new Promise((resolve, reject) => {
            API.get('customdatabase/'+db).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    post: (db,item) => {
        return new Promise((resolve, reject) => {
            API.post('customdatabase/'+db , null, {database:item}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;