import API from "./api";
import GeneralService from "./general";
const Service = {
    get: ( file) => {
        return new Promise((resolve, reject) => {
            API.get('sharedlinks/file/' + GeneralService.base64Encode(file)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    }, 
    create: (  file) => {
        return new Promise((resolve, reject) => {
            API.post('sharedlinks/file/' +GeneralService.base64Encode(file),[],{}).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    }, 
    deleteSharedLink: (id) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('sharedlinks/' + id).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;