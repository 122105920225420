import React from 'react'

import {Dialog,DialogContent, DialogActions, DialogTitle,Button,Typography} from '../../../ui/core';

import FilesService from "../../../../services-admin/files";

export default function DeleteFile(props) { 

    const deleteItem = () => {
        FilesService.deleteAccess(props.version, props.access)
            .then(result => {
                props.onRefresh();
                props.onClose();
            }, (err) => {
                props.onRefresh();
                props.onClose();
            })
    }
    return (
        <Dialog  onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Verwijderen</Typography></DialogTitle>
            <DialogContent >
                 Weet u zeker dat u de toegang <b>{props.access}</b> wilt verwijderen? <br/><br/>
                 LET OP! Alle onderliggende niveaus en bestanden worden hiermee verwijderd!

            </DialogContent>
            <DialogActions>
                <Button variant="outlined"  onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={deleteItem}  >Verwijder</Button>
            </DialogActions>
        </Dialog>
    )
}
