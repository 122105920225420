/* eslint-disable react-hooks/exhaustive-deps */
import React,{useContext,useEffect} from 'react'
import FeedbackForm from '../components/feedback/feedbackform'; 
import { ContextUI } from '../uiContext';
export default function Feedback(props) {
    const contextUI = useContext(ContextUI); 
    
    useEffect(() => {
        contextUI.setTitle('Feedback');
        contextUI.setSubTitle('');
     //   contextUI.setShowBackButton(false)
    }, [])
    
    return (
        <div className="padding"> 
            <FeedbackForm {...props}/> 
        </div>
    )
}
