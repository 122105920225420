import React from 'react'
import { CheckBox, Paper } from '../../../ui/core'

export default function Page({builderFile,onChange}) {
    const handleChange = (ev)=>{
        let tmpBuilderFile = Object.assign({},builderFile);
        tmpBuilderFile[ev.target.id] = ev.target.value || ev.target.checked;
        onChange(tmpBuilderFile);
    }
    //  <CheckBox id="collapseChapters" checked={builderFile.collapseChapters===true} label="Hoofdstukken in/uitklappen" onChange={handleChange}/> 
    return (
        <div className="padding" >
            <Paper className="padding">
             <b>Pagina</b><br/><br/>
            <CheckBox id="showIndex" checked={builderFile.showIndex===true} label="Toon inhoudsopgave" onChange={handleChange}/> 
            <CheckBox id="publishPage" checked={builderFile.publishPage===true} label="Pagina publiceren" onChange={handleChange}/> 
            </Paper>
          </div>
    )
}
