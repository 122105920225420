import React, { useState} from 'react' 
import Imageselection from '../edit/imageselection';
import { Button,TextArea, IconButton } from '../../../ui/core';
import {Delete} from '../../../ui/coreIcons';
import VeditorSimple from "../../../veditor-simple/veditor";
export default function Paragraph({ block, onChange, files, uploadFile,pages }) {
    const [contentRef] = useState(React.createRef());


    const handleChange = evt => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock); 
    };

    const handleSelectImage = url => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.image = url;
        onChange(tmpBlock);
    }
    const handleDeleteImage = () => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.image = null;
        onChange(tmpBlock);
    }
    const handleChangeImageDescription = text => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.imageDescription = text;
        onChange(tmpBlock);
    }
    

    return (
        <>
            <div style={{ float: block.alignImage === 'left' ? 'left' : 'right' }}>
                {block.image && <>
                    <img src={block.image} style={{ width: '200px',margin:'8px' }} /><br/>
                    <TextArea id={'descr_' + block._id} value={block.imageDescription || 'Beschrijving bij afbeelding'} onChange={(ev) => { handleChangeImageDescription(ev.target.value) }}></TextArea>
                    <br /><IconButton variant="outlined" onClick={handleDeleteImage}><Delete/></IconButton>
                </>}
                {!block.image && <Imageselection uploadFile={uploadFile} files={files} onSelect={handleSelectImage} />}

            </div>
            <div>
            <VeditorSimple id={block._id} value={block.paragraph!==undefined?block.paragraph: 'Plaats hier uw teksten'}  contentRef={contentRef}  files={[]} pages={pages} onChange={handleChange} showMenu={block.selected===true}/>
     
            </div>
            <br style={{ clear: 'both' }} />
        </>
    )

}
