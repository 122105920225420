import React, { useState, useContext, useEffect } from 'react'

import { Tabs, Dialog, DialogContent, Drawer, IconButton, Typography } from '../components/ui/core';
import { Close, Devices, FolderOpen, People, NotificationsActive, Email, AssignmentTurnedIn, Sharepoint, SettingsApplications,LocalHospital ,Website} from '../components/ui/coreIcons';

import { Context } from "../AppContext";
import { ContextUI } from '../uiContext';
import AdminIndex from "../components/admin/index/index";
import UsersIndex from "../components/admin/users/index";
import SettingsAppIndex from "../components/admin/settingsapp/index";
import InstallationsIndex from "../components/admin/installations/index";
import AnnouncementsIndex from "../components/admin/announcements/index";
import FeedbackIndex from '../components/admin/feedback/index';
import DMHIndex from '../components/admin/dmh/index';
import SharepointIndex from '../components/admin/sharepoint/index';
import WebsiteIndex from '../components/admin/websites/index';
import HtmlBuilder from '../components/admin/htmlbuilder';
export default function Settingslevel(props) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [selectedModule, setSelectedModule] = useState('');

    useEffect(() => {
        if (context.info && context.info.User && context.info.Rechten && context.info.User.Rechten.split(',').length > 0) {
            setSelectedModule(context.info.User.Rechten.split(',')[0])
        }
    }, [])

    useEffect(() => {
        contextUI.setTitle('Beheer ');
        contextUI.setSubTitle(selectedModule);
        contextUI.setShowBackButton(false)
    }, [selectedModule]) 

//htmlbuilder for testing todo
//      <HtmlBuilder onRefresh={props.onRefresh} version={'1'} access={'prive'} level={'01. Handboek IJsselland/02. Adresboek'} file={{filename:'test.html'}} files={[]} pages={[]} onClose={() => {  }} />
   

//  {context.info.User.Rechten.indexOf('formulieren') >= 0 && <div id="formulieren"> <IconButton onClick={() => setSelectedModule('formulieren')}><AssignmentTurnedIn /></IconButton> </div>}
          
    return (
        <>
   
            {context.info && context.info.User &&
                <Tabs value={selectedModule} onChange={setSelectedModule}>
                    {context.info.User.Rechten.indexOf('installaties') >= 0 && <div id="installaties" ><IconButton onClick={() => setSelectedModule('installaties')}><Devices /></IconButton> </div>}
                    {context.info.User.Rechten.indexOf('documenten') >= 0 && <div id="documenten"> <IconButton onClick={() => setSelectedModule('documenten')}><FolderOpen /></IconButton> </div>}
                    {context.info.User.Rechten.indexOf('gebruikers') >= 0 && <div id="gebruikers"> <IconButton onClick={() => setSelectedModule('gebruikers')}><People /></IconButton> </div>}
                    {context.info.User.Rechten.indexOf('meldingen') >= 0 && <div id="mededelingen"> <IconButton onClick={() => setSelectedModule('mededelingen')}><NotificationsActive /></IconButton> </div>}
                    {context.info.User.Rechten.indexOf('feedback') >= 0 && <div id="feedback"> <IconButton onClick={() => setSelectedModule('feedback')}><Email /></IconButton> </div>}
                    {context.info.User.Additionalrights && context.info.User.Additionalrights.indexOf('websites')  >= 0 && <div id="websites"> <IconButton onClick={() => setSelectedModule('websites')}><Website /></IconButton> </div>}
                    {context.info.User.Rechten.indexOf('sharepoint') >= 0 && <div id="sharepoint"> <IconButton onClick={() => setSelectedModule('sharepoint')}><Sharepoint /></IconButton> </div>}
                    {context.info.User.Rechten.indexOf('instellingenapp') >= 0 && <div id="instellingenapp"> <IconButton onClick={() => setSelectedModule('instellingenapp')}><SettingsApplications /></IconButton> </div>}
                    {context.info.User.Additionalrights && context.info.User.Additionalrights.indexOf('duikmedischhandboek') >= 0 && <div id="duikmedischhandboek"> <IconButton onClick={() => setSelectedModule('duikmedischhandboek')}><LocalHospital /></IconButton> </div>}
                    {context.info.User.Additionalrights && context.info.User.Additionalrights.indexOf('sharepoint') >= 0 && <div id="sharepoint"> <IconButton onClick={() => setSelectedModule('sharepoint')}><Sharepoint /></IconButton> </div>}
                </Tabs>
            }


            <div >
                {selectedModule === 'installaties' && <InstallationsIndex />}
                {selectedModule === 'documenten' && <AdminIndex />}
                {selectedModule === 'gebruikers' && <UsersIndex />}
                {selectedModule === 'instellingenapp' && <SettingsAppIndex />}
                {selectedModule === 'mededelingen' && <AnnouncementsIndex />}
                {selectedModule === 'feedback' && <FeedbackIndex />}
                {selectedModule === 'duikmedischhandboek' && <DMHIndex />}
                {selectedModule === 'sharepoint' && <SharepointIndex />}
                {selectedModule === 'websites' && <WebsiteIndex/>}
            </div>
        </>
    )
}
