/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { TextField, TextArea, Button } from '../ui/core';
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';
import Select from 'react-select'
import FeedbackService from '../../services/feedbackservice';

export default function Feedbackform(props) {

    const [message, setMessage] = useState({});
    const [options, setOptions] = useState([])
    const [defaultValue, setDefaultValue] = useState(null)
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);

    const checkFocus = () => {
        if (window.localStorage.getItem('autoopenkeybord') !== null) {
            window.sendMessage('showkeyboard');
        }
    }
    useEffect(() => {
        let newMessage = {};
        let email = '';
        if(context.info && context.info.User && context.info.User.Email) email = context.info.User.Email
        if (email.indexOf('@') === -1) email = '';
        if (context.info.User) { 
            newMessage = { sendname: context.info.User.Name, sendemail: email } 
        }

        let tmpOptions = [];
        tmpOptions = tmpOptions.concat(context.info.Levels.filter((item) => { return (item.Path !== '/') }).map((item) => { return { value: item.Path, label: item.Path } }));
        tmpOptions = tmpOptions.concat(context.info.Index.filter((item) => { return (item.Name.indexOf('.json') === -1 && item.Name.indexOf('logo.png') === -1 && item.Name.indexOf('.note') === -1) }).map((item) => { return { value: item.Name, label: item.Name } }));
        setOptions(tmpOptions);

        if (context.currentLevel) {
            let foundItem = context.info.Levels.find((item) => { return (item.HPath === context.currentLevel) })
            if (foundItem) {
                setDefaultValue([{ value: foundItem.Path, label: foundItem.Path }]);
                newMessage.sendcard = foundItem.Path;
            } else {
                setDefaultValue([]);
            }
            contextUI.setShowBackButton(true);
        } else {
            setDefaultValue([])
        }

        setMessage(newMessage)
    }, [])

    const handleChange = (ev) => {
        let tmpMessage = Object.assign({}, message);
        tmpMessage[ev.target.id] = ev.target.value;
        setMessage(tmpMessage);
    }

    const onClick = () => {
        if (message.sendemail && message.sendemail.indexOf('@') > 0 && message.sendmessage) {
            FeedbackService.send(message)
                .then((result) => {
                    context.setMessage('Bedankt voor uw feedback!');
                    props.history.push('/index');
                }, (err) => {
                    context.setMessage('Uw feedback kon helaas niet worden verstuurd. Controleer uw internetverbinding of probeer het later opnieuw.')
                })
        } else {
            context.setMessage('De velden E-mailadres en uw feedback zijn verplicht')
        }
    }
    const changeSubject = (subjects) => {
        let tmpMessage = Object.assign({}, message);
        tmpMessage.sendcard = '';
        if (subjects) {
            for (let i = 0; i < subjects.length; i++) {
                if (subjects[i].value) {
                    tmpMessage.sendcard += subjects[i].value + ', ';
                }
            }
        }
        setMessage(tmpMessage);
    }

    return (
        <div>
            Vul hieronder uw gegevens in om over deze App of een bepaald hoofdstuk feedback te geven.<br /><br />

            <TextField
                id="sendname"
                label="Uw naam"
                value={message.sendname || ''}
                onChange={handleChange}
                onFocus={checkFocus}
                margin="normal"
                fullWidth
            />

            <TextField
                id="sendemail"
                label="E-mailadres"
                value={message.sendemail || ''}
                onChange={handleChange}
                onFocus={checkFocus}
                margin="normal"
                fullWidth
                required
            />

            <TextField
                id="sendphone"
                label="Telefoonnummer"
                value={message.sendphone || ''}
                onChange={handleChange}
                onFocus={checkFocus}
                margin="normal"
                fullWidth
            />

            <br /><br />
            <div className="label">Betreft hoofdstuk of document</div>

            {defaultValue && <Select options={options} isMulti onChange={changeSubject} label="Zoeken.." defaultValue={defaultValue.length > 0 ? defaultValue : null} />}

            <TextArea
                id="sendmessage"
                label="Feedback/vraag/opmerking"
                value={message.sendmessage || ''}
                onChange={handleChange}
                onFocus={checkFocus}
                margin="normal"
                fullWidth
                multiline
                required
            />
            <br /><br />
            <Button variant="outlined" color="primary" fullWidth onClick={onClick}>VERSTUUR</Button>
            <br /><br />
        </div>
    )
}
