import React,{ useRef,useState} from 'react'
import ContentEditable from "react-contenteditable";
import VeditorSimple from "../../../veditor-simple/veditor";
export default function Paragraph({block,onChange,pages}) {
    const [contentRef] = useState(React.createRef());
    const handleBlur = block => {
        let tmpBlock = Object.assign({}, block);
    
        tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock); 
    };
    const handleChange = evt => {
       
        let tmpBlock = Object.assign({}, block);
        tmpBlock.paragraph = contentRef.current.innerHTML;
        onChange(tmpBlock); 
    };
    /*
     <ContentEditable 
            html={text.current || 'Vul hier uw paragraaf tekst in'}
            disabled={false}
            //onBlur={handleBlur}
            onChange={handleChange}
            tagName='div'
        /> */
    return (
        <VeditorSimple id={block._id} value={block.paragraph!==undefined?block.paragraph: 'Plaats hier uw tekst'} contentRef={contentRef}  files={[]} pages={pages} onChange={handleChange} showMenu={block.selected===true}/>
       
    )

}
