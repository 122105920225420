import React, { useEffect, useState, useContext } from 'react'

import { List, ListItem, ListItemIcon, ListItemText, LinearProgress, Paper, Button } from '../../ui/core';
import { FileCopy, FormatAlignLeft, ContactPhone, AssignmentTurnedIn } from '../../ui/coreIcons';
import { Context } from "../../../AppContext";
import GeneralService from '../../../services-admin/general';
import FilesService from "../../../services-admin/files";
import FileMenu from './files-menu';
import FileUpload from './file-upload';
export default function Files(props) {
    const context = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState({});
    const [files, setFiles] = useState([]);
    const lock = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" /></svg>;
   
    useEffect(() => {
        setFiles(props.files);
        return () => { }
    }, [props.files])

    useEffect(() => {
        FilesService.getSettings(props.version, props.access, props.level)
        .then(result => {
            setSettings(result)
        }, (err) => {

        })
    },[props.files])

    const isSpecialFile = (filename) => {
        return (filename.indexOf('.html') >= 0 || filename.indexOf('.form') >= 0 || filename.indexOf('.note') >= 0 || filename.indexOf('.json') >= 0)
    }

    const getIcon = (filename) => {
        if (filename === 'adresboek.json') {
            return <ContactPhone />
        }
        switch (filename.split('.').pop()) {
            case 'form':
                return <AssignmentTurnedIn />
            case 'html':
                return <FormatAlignLeft />
            default:
                return <FileCopy />
        }
    }

    const uploadFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            let base64 = reader.result;
            FilesService.saveBase64(props.version, props.access, props.level, file.name, base64)
                .then(result => {
                    props.onRefresh();
                    props.onUpdateRevision(`Bestand ${file.name} is toegevoegd, wilt u de revisie bijwerken?`)
                    //  context.setMessage('Bestand '+file.name+' toegevoegd')
                }, (err) => {
                    context.setMessage('Bestand ' + file.name + ' toevoegen mislukt')
                })
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }


    const dropEvent = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
    }
    const fileDropHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {

            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                uploadFile(ev.dataTransfer.files[i]);
            }
        }
    }

    const uploadFinished = () => {
        props.onRefresh();
        props.onUpdateRevision(`Er is een bestand toegevoegd, wilt u de revisie bijwerken?`)
    }

    return (
        <div>
            {loading && <LinearProgress />}
            {props.revisionText && <Paper className="padding" style={{ backgroundColor: '#444', color: 'white', textAlign: 'center', width: '80%', margin: '0 auto', padding: '12px' }}>
                {props.revisionText}
                <br style={{ clear: 'both' }} /><br />
                <Button variant="outlined" onClick={() => { props.onUpdateRevision('') }}>annuleren</Button>  <Button variant="contained" onClick={props.onShowRevision}>bijwerken</Button>
            </Paper>}

            {props.version && props.access && props.level && <FileUpload version={props.version} access={props.access} level={props.level} onFinished={uploadFinished} />}

            {files.length > 0 && <React.Fragment>
                <List component="nav" aria-label="wijzigen">
                    {files.filter(file => { return file.filename.indexOf('.note') >= 0 }).map(file => {
                        return <ListItem key={file.DPath}>
                            <ListItemIcon>
                                {getIcon(file.filename)}
                            </ListItemIcon>
                            <ListItemText primary={file.filename} secondary={GeneralService.formatDateTime(file.dateModified)} />
                            <ListItemIcon>
                                {loading === false && <FileMenu onRefresh={props.onRefresh} file={file} files={files.map(file => { return { title: file.filename, value: file.DPath } })} version={props.version} access={props.access} level={props.level} />}
                            </ListItemIcon>
                        </ListItem>
                    })}
                </List>
                <List component="nav" aria-label="wijzigen">
                    {files.filter(file => { return file.filename.indexOf('.html') >= 0 }).map(file => {
                        return <ListItem key={file.DPath}>
                            <ListItemIcon>
                                {getIcon(file.filename)}
                            </ListItemIcon>
                            <ListItemText primary={file.filename} secondary={GeneralService.formatDateTime(file.dateModified)} />
                            <ListItemIcon>
                                {loading === false && <FileMenu onRefresh={props.onRefresh} file={file} files={files.map(file => { return { title: file.filename, value: file.DPath } })} pages={props.levels} version={props.version} access={props.access} level={props.level} />}
                            </ListItemIcon>
                        </ListItem>
                    })}
                </List>
                <List component="nav" aria-label="wijzigen">
                    {files.filter(file => { return file.filename.indexOf('.form') >= 0 }).map(file => {
                        return <ListItem key={file.DPath}>
                            <ListItemIcon>
                                {getIcon(file.filename)}
                            </ListItemIcon>
                            <ListItemText primary={file.filename} secondary={GeneralService.formatDateTime(file.dateModified)} />
                            <ListItemIcon>
                                {loading === false && <FileMenu onRefresh={props.onRefresh} file={file} files={files.map(file => { return { title: file.filename, value: file.DPath } })} version={props.version} access={props.access} level={props.level} />}
                            </ListItemIcon>
                        </ListItem>
                    })}
                </List>
                <List component="nav" aria-label="wijzigen">
                    {files.filter(file => { return file.filename.indexOf('adresboek.json') >= 0 }).map(file => {
                        return <ListItem key={file.DPath}>
                            <ListItemIcon>
                                {getIcon(file.filename)}
                            </ListItemIcon>
                            <ListItemText primary={file.filename} secondary={GeneralService.formatDateTime(file.dateModified)} />
                            <ListItemIcon>
                                {loading === false && <FileMenu onRefresh={props.onRefresh} file={file} files={files.map(file => { return { title: file.filename, value: file.DPath } })} version={props.version} access={props.access} level={props.level} />}
                            </ListItemIcon>
                        </ListItem>
                    })}
                </List>

                <List component="nav" aria-label="wijzigen">
                    {files.filter(file => { return !isSpecialFile(file.filename) }).map(file => {
                        return <ListItem key={file.DPath}>
                            <ListItemIcon>
                                <FileCopy />
                            </ListItemIcon>

                            <ListItemText primary={file.filename} secondary={GeneralService.formatDateTime(file.dateModified)} />
                            <ListItemIcon>
                            {settings.loginNeeded && settings.loginNeeded.indexOf(file.filename)>=0 && 
                                lock
                            }</ListItemIcon>
                            <ListItemIcon>
                                {loading === false && <FileMenu onRefresh={props.onRefresh} file={file} files={files.map(file => { return { title: file.filename, value: file.DPath } })} version={props.version} access={props.access} level={props.level} />}
                            </ListItemIcon>
                        </ListItem>
                    })}
                </List>
            </React.Fragment>}

            <br /><br /><br /><br />
        </div>
    )
}
