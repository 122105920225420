import React, { useEffect, useState } from 'react'
import Answer from './answer';
import GeneralService from '../../../services/general';
import DownloadExcelService from '../../../services/downloadexcel';
import { Button, ExpansionPanel,Typography } from '../../ui/core';
import { Download } from '../../ui/coreIcons';
import AppSettings from '../../../AppSettings';
export default function FormAnswers({ form, answers ,showConcepts, onEdit}) {
  const [downloadList, setDownloadList] = useState([]);
  const [downloadAttributes, setDownloadAttributes] = useState({});
  const [expandedItem,setExpandedItem] = useState(null);
  useEffect(() => {
    //attributes
    let attr = {};

    attr['Verzonden'] = 'String';
    attr['Naam'] = 'String';
    attr['Email'] = 'String';
    for (let l = 0; l < form.lines.length; l++) {
      if (form.lines[l].element !== 0) {
        attr[form.lines[l].question + ' (' + form.lines[l].id + ')'] = 'String';
      }
    }
    setDownloadAttributes(attr);
    //answers
    let list = [];
    for (let a = 0; a < answers.length; a++) {
      let attr = {};
      attr['Verzonden'] = answers[a].dateSend;
      attr['Naam'] = answers[a].sender ? answers[a].sender.Name : '';
      attr['Email'] = answers[a].sender ? answers[a].sender.Email : '';

      for (let l = 0; l < answers[a].lines.length; l++) {
        if (answers[a].lines[l].element !== 0) {
          if (answers[a].lines[l].answerDateTime) {
            attr[answers[a].lines[l].question + ' (' + answers[a].lines[l].id + ')'] = answers[a].lines[l].answerDateTime;
          } else if (answers[a].lines[l].answer) {
            attr[answers[a].lines[l].question + ' (' + answers[a].lines[l].id + ')'] = answers[a].lines[l].answer;
          } else if (answers[a].lines[l].answers) {
            attr[answers[a].lines[l].question + ' (' + answers[a].lines[l].id + ')'] = answers[a].lines[l].answers.join(',');
            if (answers[a].lines[l].element === 5) { //bijlage
              attr[answers[a].lines[l].question + ' (' + answers[a].lines[l].id + ')'] = answers[a].lines[l].answers.map(ans => { return AppSettings.getLocationDocumentAPI() + 'download?key=' + ans.split('/')[0] }).join(',')
            }
          } else  {
            attr[answers[a].lines[l].question + ' (' + answers[a].lines[l].id + ')'] = ''
          }
        }
      }
      list.push(attr)
    }
    setDownloadList(list);
  }, [answers])

  return (
    <div>
      <Button onClick={() => { DownloadExcelService.downloadExcel('antwoorden_' + form.title + '.xls', downloadList, downloadAttributes) }} ><Download color="white"/><span > Download</span></Button>
    {showConcepts &&  answers.filter(answer=>{return answer.concept==true}).map((answer, i) => {
      
        return  <ExpansionPanel key={"concept+"+answer.id + i} expanded={expandedItem=="con_"+i} onClick={()=>{setExpandedItem(expandedItem!==null?null:"con_"+i)}} primary={ <Typography  >{GeneralService.formatDate(answer.dateSend, true)} - {answer.sender ? answer.sender.Name + ' (' + answer.sender.Email + ') ' : 'Onbekend '} {answer.concept ? ' - concept':''}</Typography>}>
         
          <div style={{ display: 'block' }}>
            {answer.lines.map(line => {
              return <Answer key={line.id} line={line} />
            })}
           {onEdit && <Button onClick={()=>{onEdit(answer)}}>Bewerken</Button>}
          </div>
        </ExpansionPanel>
      })}
    
      {answers.filter(answer=>{return answer.concept !== true}).map((answer, i) => {
        return  <ExpansionPanel key={answer.id + i} expanded={expandedItem==i} onClick={()=>{setExpandedItem(expandedItem!==null?null:i)}} primary={ <Typography  >{GeneralService.formatDate(answer.dateSend, true)} - {answer.sender ? answer.sender.Name + ' (' + answer.sender.Email + ') ' : 'Onbekend '} {answer.concept ? ' - concept':''}</Typography>}>
         
          <div style={{ display: 'block' }}>
            {answer.lines.map(line => {
              return <Answer key={line.id} line={line} />
            })}
          </div>
        </ExpansionPanel>
      })}
    </div>
  )
}
