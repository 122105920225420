import React, { useState, useContext, useEffect } from 'react'

import { Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField, Typography } from '../../../ui/core';

import { Context } from "../../../../AppContext";
import FilesService from "../../../../services-admin/files";

export default function UploadFile(props) {
    const [contentRef] = useState(React.createRef());
    const context = useContext(Context);
    const [files, setFiles] = useState();
    const [pending, setPending] = useState([]);
    const uploadFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            let base64 = reader.result;
            FilesService.saveBase64(props.version, props.access, props.level, file.name, base64)
                .then(result => {
                    props.onRefresh();
                    let tmpFiles = Object.assign([], files);
                    tmpFiles.splice(0, 1);
                    setFiles(tmpFiles);
                    //      context.setMessage('Bestand '+file.name+' toegevoegd')
                }, (err) => {
                    context.setMessage('Bestand ' + file.name + ' toevoegen mislukt')
                })
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const processFile = (ev) => {
        setFiles(ev.target.files);
    }

    useEffect(() => {
        if (files && files.length > 0) {
            uploadFile(files[0]);
            let tmpPending = [];
            for (let i = 0; i < files.length; i++) {
                tmpPending.push(files[i].name)
            }
            setPending(tmpPending);
        } else if (files && files.length === 0) {
            props.onClose();
        }
        return () => {

        }
    }, [files])
    return (
        <Dialog onClose={props.onClose}>
            <DialogTitle ><Typography component="h2">Uploaden nieuw document</Typography></DialogTitle>
            <DialogContent >
                {
                    !files && <>
                        <div className="label">Selecteer document</div>
                        <input id="fileupload" ref={contentRef} type="file" multiple onChange={processFile} />
                    </>
                }{
                    pending.length > 0 && <div>Bezig met uploaden: <br /></div>
                }
                {pending && pending.map(file => {
                    return <div key={file}>{file}<br /></div>
                })}

            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
