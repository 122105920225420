import API from "./api";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('users').then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getSubscriptions: () => {
        return new Promise((resolve, reject) => {
            API.get('users/subscriptions').then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (user) => {
        return new Promise((resolve, reject) => {
            API.post('users/'+user._id , null, {item:user}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteUser: (user) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('users/'+user._id , null, {item:user}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteSessions:(user) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('users/'+user._id +'/sessions', null, {item:user}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
}
export default Service;