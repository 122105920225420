import React, { useState, useContext, useEffect } from 'react'

import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Button, Typography } from '../../../ui/core';

import ServiceSharedLinks from "../../../../services-admin/sharedlinks";
import { Delete } from '../../../ui/coreIcons';
import { IconButton } from '../../../ui/core';

export default function Rename(props) {
    const [links, setLinks] = useState([]);

    const getLinks = () => {
        ServiceSharedLinks.get(props.file)
            .then(result => {
                setLinks(result);
            }, (err) => {
                console.log("err", err)
            })
    }

    useEffect(() => {
        getLinks();
    }, [props.file])

    const handleAdd = () => {
        ServiceSharedLinks.create(props.file)
            .then(result => {
                getLinks();
            }, (err) => {
                console.log("err", err)
            })
    }

    const handleDelete = (id) => {
        window.confirm("Weet u zeker dat u deze link wilt verwijderen?") && ServiceSharedLinks.deleteSharedLink(id)
            .then(result => {
                getLinks();
            }, (err) => {
                console.log("err", err)
            })
    }

    return (
        <Dialog fullWidth maxWidth={'xl'} open={true} onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Deelbare links</Typography></DialogTitle>
            <DialogContent >
                <div className='label'>Link naar pad: {props.file}</div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr><th>Links</th></tr>
                        {links.map((link, index) => {
                            return <tr key={link._id}><td><TextField id={"link_" + link._id} value={"https://" + window.location.hostname + "/apiv2/sharedlink/" + link._id + "/" + link.key} /></td><td><IconButton onClick={() => handleDelete(link._id)}><Delete /></IconButton></td></tr>
                        })
                        }</tbody>
                </table>
                {links.length === 0 && <div><i>Er zijn nog geen deelbare links aangemaakt.</i></div>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleAdd}>Toevoegen</Button>
                <Button variant="contained" color="primary" onClick={props.onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
