import React, { useRef  } from 'react'
import ContentEditable from "react-contenteditable";
export default function Heading({ block, onChange }) {
    const text = useRef(block.heading);  

    const handleChange = evt => {
        text.current = evt.target.value; 
        let tmpBlock = Object.assign({}, block);
        const el = document.createElement('div');
        el.innerHTML =  evt.target.value;
        tmpBlock.heading = el.innerText; 
        onChange(tmpBlock); 
    };

    return (
        <ContentEditable 
            html={text.current ===undefined? 'Titel':text.current}
            disabled={false}
            //onBlur={handleBlur}
            onChange={handleChange}
            tagName={block.headingSize || 'h1'}
        />
    )
}
