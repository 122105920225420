import React, { useEffect, useState } from 'react'
import { CheckBox, Select, TextField,Button } from '../../../ui/core'
import { CompactPicker } from 'react-color'
export default function TableSettings({ block, onChange }) {
    const [currentCell, setCurrentCell] = useState()
    const handleChange = (attribute, value) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock[attribute] = value;
        onChange(tmpBlock);
    }

    const handleChangeCell = (column, row, cell) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.cells[row][column] = cell;
        onChange(tmpBlock);
    }

    const handleChangeStyle = (selectedStyle, val) => {
        let tmpCell = JSON.parse(JSON.stringify(currentCell));
        if (!tmpCell.style) { tmpCell.style = {} };
        tmpCell.style[selectedStyle] = undefined;
        switch (selectedStyle) {
            case 'borderTop':
                if (val) { tmpCell.style[selectedStyle] = '1px solid black'; } else { tmpCell.style[selectedStyle] = undefined }
                break;
            case 'borderBottom':
                if (val) { tmpCell.style[selectedStyle] = '1px solid black'; } else { tmpCell.style[selectedStyle] = undefined }
                break;
            case 'borderLeft':
                if (val) { tmpCell.style[selectedStyle] = '1px solid black'; } else { tmpCell.style[selectedStyle] = undefined }
                break;
            case 'borderRight':
                if (val) { tmpCell.style[selectedStyle] = '1px solid black'; } else { tmpCell.style[selectedStyle] = undefined }
                break;
            case 'backgroundColor':
                tmpCell.style[selectedStyle] = val.hex;
                break;
            case 'color':
                tmpCell.style[selectedStyle] = val.hex;
                break;

        }
        handleChangeCell(tmpCell.c, tmpCell.r, tmpCell);
  
    } 
    useEffect(() => {
        for (let r = 0; r < block.maxRows; r++) {
            for (let c = 0; c < block.maxColumns; c++) {
                try {
                    if (block.cells[r][c].selected === true) {
                        setCurrentCell(block.cells[r][c])
                    }
                } catch (ex) { }
            }
        }
    }, [block])

    const outlineAllCells = () =>{
        let tmpBlock = Object.assign({}, block);
        for (let r = 0; r < tmpBlock.maxRows; r++) {
            for (let c = 0; c < tmpBlock.maxColumns; c++) {
                if (!tmpBlock.cells[r][c].style) { tmpBlock.cells[r][c].style = {} };
                tmpBlock.cells[r][c].style['borderTop'] = undefined;
                tmpBlock.cells[r][c].style['borderLeft'] = undefined;
                tmpBlock.cells[r][c].style['borderBottom'] = undefined;
                tmpBlock.cells[r][c].style['borderRight'] = undefined;


                tmpBlock.cells[r][c].style['borderTop'] = '1px solid black';
                tmpBlock.cells[r][c].style['borderLeft'] = '1px solid black';
                if(r===tmpBlock.maxRows-1){
                    tmpBlock.cells[r][c].style['borderBottom'] = '1px solid black';
                }
                if(c===tmpBlock.maxColumns-1){
                    tmpBlock.cells[r][c].style['borderRight'] = '1px solid black';
                }
               
            }
        }
        onChange(tmpBlock);
    }

    if (!block) return null;

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div><TextField id="maxColumns" type="number" label="aantal kolommen" value={block.maxColumns || 3} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }}></TextField></div>
                <div><TextField id="maxRows" type="number" label="aantal rijen" value={block.maxRows || 3} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }}></TextField></div>

            </div>
 
            <Button style={{fontSize:'10px'}} variant="outlined" onClick={outlineAllCells}>Plaats lijnen in elke cel</Button>
            <br /><br />

            {currentCell && <div>
                <b>Geselecteerde cel</b><br /><br />
                <div className="label">Omlijning</div>
                <CheckBox id="borderTop" label="boven" checked={currentCell.style && currentCell.style.borderTop !== undefined} onChange={(ev) => handleChangeStyle(ev.target.id, ev.target.checked)}></CheckBox>
                <CheckBox id="borderBottom" label="onder" checked={currentCell.style && currentCell.style.borderBottom !== undefined} onChange={(ev) => handleChangeStyle(ev.target.id, ev.target.checked)}></CheckBox>
                <CheckBox id="borderLeft" label="links" checked={currentCell.style && currentCell.style.borderLeft !== undefined} onChange={(ev) => handleChangeStyle(ev.target.id, ev.target.checked)}></CheckBox>
                <CheckBox id="borderRight" label="rechts" checked={currentCell.style && currentCell.style.borderRight !== undefined} onChange={(ev) => handleChangeStyle(ev.target.id, ev.target.checked)}></CheckBox>
                <br />
                <div className="label">Achtergrondkleur</div>
                <CompactPicker color={(currentCell.style && currentCell.style.backgroundColor) || 'white'} onChangeComplete={(color) => { handleChangeStyle('backgroundColor', color) }} />
                <br /><br />
                <div className="label">Voorgrondkleur</div>
                <CompactPicker color={(currentCell.style && currentCell.style.color) || 'black'} onChangeComplete={(color) => { handleChangeStyle('color', color) }} />

            </div>}

        </div>
    )
}
