import APIV2 from "./apiv2";
import StorageService from './storage';
import { Secure, Device } from '../plugins/capacitor';
import AppSettings from '../AppSettings';
import EncryptionService from './encryption';
const attribute = "user";


const Service = {
    saveTokens: (tokens) => {
        if (AppSettings.getPlatform() !== 'web') {
            Secure.getSecureKey()
                .then((secret) => {
                    EncryptionService.aesEncryptParts(JSON.stringify(tokens), secret.value)
                        .then(encrypted => {
                            Secure.setToken({ value: encrypted });
                        });
                })
        }
    },
    retrieveTokens: () => {
        return new Promise((resolve, reject) => {
            Device.getInfo()
            .then(
                (device) => {
                    if (device.platform !== 'web') {
                        Secure.getToken()
                        .then(token => { 
                            Secure.getSecureKey()
                            .then((secret) => {
                                EncryptionService.aesDecryptParts(token.value, secret.value)
                                    .then(decrypted => {
                                        resolve(JSON.parse(decrypted));
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err);
                                    })
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err);
                            })
                        })
                        .catch(err => {
                            console.log(err);
                            reject();
                        }) 
                    } else {
                        reject();
                    }
                })
                .catch(err => {
                    reject();
                })
        })
    },
    /*
    getTokenDeprecated: () => {
        if (localStorage.getItem('accesskey') !== null) {
            return localStorage.getItem('accesskey');
        } else {
            return '0';
        }
    },
    setTokenDeprecated: (token) => {
        localStorage.setItem('accesskey', token);
    },
    */
    requestNewPasswordToken: (username) => {
        return new Promise((resolve, reject) => {
            APIV2.post(attribute + '/token', [], { u: username }).then(
                token => {
                    resolve(token)
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    changePassword: (username, password, token) => {
        return new Promise((resolve, reject) => {
            APIV2.post(attribute + '/changepassword', [], { u: username, p: password, token: token }).then(
                token => {
                    resolve(token);
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    login: (username, password) => {
        return new Promise((resolve, reject) => {
            APIV2.post(attribute + '/login', [], { p: password, u: username, cookie: 'true' }).then(
                token => {
                    resolve(token);
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    get: () => {
        return new Promise((resolve, reject) => {
            APIV2.get(attribute).then(
                currentuser => {
                    resolve(currentuser)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    autoLogin: (referrer, token) => {
        return new Promise((resolve, reject) => {
            APIV2.post(attribute + '/autologin', [], { referrer: referrer, token: token }).then(
                result => {
                    resolve(result)
                },
                error => {
                    console.log('err', error)
                    reject(error);
                }
            );
        });
    },
    checkAutologin: () => {
        return new Promise((resolve, reject) => {
            let token;
            if (window.autologintoken) {
                //check hardcoded autologin code
                if ((localStorage.getItem('accesskey') === '0' || !localStorage.getItem('accesskey'))) {
                    token = window.autologintoken;
                }
            } else if (window.location.search) {
                if (window.location.search.indexOf('autologintoken') >= 0) {
                    token = window.location.search.split('autologintoken=')[1];
                }
            } else {
                try {
                    token = window.cordova.plugins.EmmAppConfig.getValue("autologintoken")
                } catch (ex) { }
            }

            StorageService.readFile(StorageService.settingsDir(), 'autologin.dat').then(
                (storedToken) => {
                    token = storedToken;
                    //StorageService.removeFile(StorageService.settingsDir(), 'autologin.dat').then((result) => { }, (err) => { });
                    Service.checkAutologinStep2(token, reject, resolve);
                },
                (err) => {
                    Service.checkAutologinStep2(token, reject, resolve);
                }
            )
        });
    },
    checkAutologinStep2: (token, reject, resolve) => {
        if (token && !sessionStorage.getItem('firstrun')) {
            sessionStorage.setItem('firstrun', 'true');
            Service.autoLogin(document.referrer, token).then(function (tokens) {

                //toodo handle session
                /*
                                var token = h.replace('OK-H-', '');
                                if (token.length > 100) {
                                    Service.setToken(token);
                                    resolve();
                                } else {
                                    reject();
                                }
                                */
                resolve(tokens);
            }, function (err) {
                reject();
            });
        } else {
            reject();
        }
    },
    checkSSO:(key,secret,platform)=>{
        return new Promise((resolve, reject) => {
        APIV2.post(attribute + `/ssocheck?key=${key}&secret=${secret}&platform=${platform}`,null,{}).then(
            tokens => {
                resolve(tokens)
            },
            error => {
                reject(error);
            }
        );
    });
    },
    markDialogShowed: (dialog) => {
        return new Promise((resolve, reject) => {
            APIV2.post('user/currentuser/dialog', null, { item: dialog }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    subscribe:(user)=>{
        return new Promise((resolve, reject) => {
            APIV2.post('user/subscribe', null, { item: user }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    logout: () => {
        return new Promise((resolve, reject) => {
            APIV2.post(attribute + '/logout', [], {}).then(
                result => {
                    Service.saveTokens({});
                    AppSettings.setToken({});
                    resolve(result)
                },
                error => {
                    Service.saveTokens({});
                    AppSettings.setToken({});
                    reject(error);
                }
            );
        });
    }
}
export default Service;