import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../AppContext';
import { Button, Typography } from '../ui/core';
import Settings from "../../AppSettings"; 
import RedeemCodesService from '../../services/redeemcodes'; 
import APIV2 from "../../services/apiv2";
import {Browser} from '../../plugins/capacitor';
export default function Apps() {
    const context = useContext(Context);
    const [codes, setCodes] = useState([])
    const locationAPI = Settings.getLocationAPI();

    const downloadAPK = () => {
        const url = locationAPI + 'apk'
        APIV2.get('androiddownloadkey').then(
            result => { 
            
                Browser.open({ url: url + '/'+result.key }); 
            },
            error => {
               context.setMessage('Downloaden mislukt')
            }
        );
    }

    const openRedeemCode = (code) => {
        window.open('https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=' + code, '_blank');
    }

    const showHelpApple = () => {
        window.open('https://support.apple.com/nl-nl/HT201209', '_blank');
    }

    const getCodes = () => {
        RedeemCodesService.get()
            .then(result => { setCodes(result) })
            .catch(ex => { })
    }
    useEffect(() => {
        getCodes()
    }, [])

    const requestCode = () => {
        RedeemCodesService.request()
            .then(result => {
                getCodes();
            })
            .catch(ex => { })
    }

    return (
        <div>
       
            <table style={{width:'100%'}}>
                <tbody>{context.info && context.info.Settings && context.info.Settings.AndroidGooglePlay && 
                    <><tr><td colSpan="2">  <Typography component="h3">Google, Android</Typography></td></tr>
                    {  context.info.Settings.AndroidSingleFile && <tr><td>Installatiebestand</td><td><Button variant="text" onClick={downloadAPK}>Download</Button></td></tr>}
                    {  context.info.Settings.AndroidGooglePlayLink  && context.info.Settings.AndroidGooglePlayLink.length>0 && <tr><td>Google Play</td><td><Button variant="text" onClick={()=>{window.open(context.info.Settings.AndroidGooglePlayLink,'_blank')}}>Open play store</Button></td></tr>}
                   </>}
                    {context.info && context.info.Settings && context.info.Settings.IOSRedeemcodes && <tr><td colSpan="2">  <Typography component="h3">Apple, IOS</Typography></td></tr>}
                    {context.info && context.info.Settings && context.info.Settings.IOSRedeemcodes && <>
                        {codes.length < 3 && <tr><td>inwisselen (redeem) code</td><td><Button variant="text" onClick={() => { requestCode() }}>Code aanvragen</Button></td></tr>}
                        {codes.map(code => { return <tr key={code._id}><td>{code.code}</td><td><Button variant="text" onClick={() => { openRedeemCode(code.code) }}>Installeren</Button></td></tr> })}
                        <tr><td colSpan="2">U kunt maximaal 3 codes aanvragen.<br />Open bovenstaande link met de Safari browser. Hulp nodig bij het inwisselen van de code? Klik <span style={{ textDecoration: 'underline' }} onClick={showHelpApple}>hier</span> voor de instructie van Apple.</td></tr></>}
                    {context.info && context.info.Settings && context.info.Settings.IOSAppStore ===true && context.info.Settings.IOSAppStoreLink  && context.info.Settings.IOSAppStoreLink.trim().length>0 && <tr><td>App Store</td><td><Button variant="text" onClick={()=>{window.open(context.info.Settings.IOSAppStoreLink,'_blank')}}>Open app store</Button></td></tr>}
                   
                </tbody>
            </table>

        </div>
    )
}
