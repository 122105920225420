import APIV2 from "./apiv2";

const Service = { 
    getSessions: () => {
        return new Promise((resolve, reject) => {
            APIV2.get('auth/sessions',null).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteSession:(id)=> {
        return new Promise((resolve, reject) => {
            APIV2.deleteItem('auth/sessions/'+id,null).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;