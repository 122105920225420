import API from "./api";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('redeemcodes').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    insert: (codes) => {
        return new Promise((resolve, reject) => {
            API.post('redeemcodes' , null, {item:codes}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;