import React,{useEffect} from 'react'
import { Paper, TextField, Switch, Divider, IconButton, RadioButton } from '../../ui/core';
import { KeyBoardArrowUp, KeyBoardArrowDown, Delete } from '../../ui/coreIcons';
export default function Score({ item, onChange, onDelete, onChangeSort }) {
    const [scores,setScores] = React.useState([]);

    useEffect(() => {
        let scores = [];
        for(let i=(item.scoreStart || 0);i<=(item.scoreEnd||10);i++){
            scores.push(i);
        }
        setScores(scores);
    }, [item.scoreStart,item.scoreEnd]);

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    const checkBoxes = [];
    const start = item.scoreStart || 0;
    const end = item.scoreEnd || 10;
    for (let i = start; i <= end; i++) {
        checkBoxes.push(<RadioButton id={"btn" + i} color="primary" disabled style={{ padding: '4px' }} />)
    }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <TextField fullWidth placeholder="Vraag" value={item.question || ''} onChange={(ev) => { changeItem(ev.target.value, 'question') }} ></TextField>

            <table><tbody><tr>
                <td>
                    <select id="scoreStart" value={item.scoreStart || 0} onChange={(ev) => { changeItem(ev.target.value, 'scoreStart') }} >
                        {[0, 1].map((option) => { return <option key={option} value={option}>{option}</option> })}
                    </select>
                </td>
                <td>t.m.</td>
                <td>
                    <select id="scoreEnd" value={item.scoreEnd || 10} onChange={(ev) => { changeItem(ev.target.value, 'scoreEnd') }} >
                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => { return <option key={option} value={option}>{option}</option> })}
                    </select>
                </td>
            </tr></tbody></table>

            <table style={{ width: '100%' }}><tbody>
            <tr>
                    <td style={{ width: '20%' }}>

                    </td>
                    {scores.map((score) => {
                        return <td key={score} style={{ textAlign: 'center' }} >{score}</td>
                    })}
                    <td style={{ width: '20%' }}>

                    </td>
                </tr>
                <tr>
                <td style={{ width: '20%' }}>
                    <TextField fullWidth placeholder="Label (optioneel)" value={item.scoreStartLabel || ''} onChange={(ev) => { changeItem(ev.target.value, 'scoreStartLabel') }} ></TextField>
                </td>
                {scores.map((score) => {
                        return <td key={"rb" + score} style={{ textAlign: 'center' }} ><RadioButton id={"rb" + score} key={score} color="primary" disabled style={{ padding: '4px' }} /></td>
                    })}
                <td style={{ width: '20%' }}>
                    <TextField fullWidth placeholder="Label (optioneel)" value={item.scoreEndLabel || ''} onChange={(ev) => { changeItem(ev.target.value, 'scoreEndLabel') }} ></TextField>
                </td>
            </tr></tbody></table>

            <br /><br /><Divider />
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'up') }}><KeyBoardArrowUp /></IconButton>
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'down') }}><KeyBoardArrowDown /></IconButton>
            <div style={{ float: 'right' }}>
                <div style={{ display: 'flex' }}>
                    <div>{onDelete && <IconButton onClick={onDelete}><Delete /></IconButton>}</div>
                    <div style={{ padding: '6px' }}><Switch id={"switch"} checked={item.required || false} value="required" onChange={() => { changeItem(!item.required, 'required') }} label="Verplicht" /></div>
                </div> </div>
            <br style={{ clear: 'both' }} />
        </Paper>
    )
}
