import React, { useEffect, useState, useRef } from 'react';
import WebsiteService from "../../../services-admin/websites.js";

const FileUpload = ({ website, onFinished }) => {
    const [files, setFiles] = React.useState([]);
    const [currentFile, setCurrentFile] = React.useState(0);
    const [progress, setProgress] = useState(0);
    const ref = useRef(null);
    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleFileUpload = () => {
        let selectedFile = files[currentFile];
        if (!selectedFile) {
            onFinished && onFinished();
            ref.current.value = [];
            setFiles([]);
            setCurrentFile(0);
            return;
        }

        const chunkSize = 5 * 1024 * 1024; // 5MB 
        const totalChunks = Math.ceil(selectedFile.size / chunkSize);
        const chunkProgress = 100 / totalChunks;
        let chunkNumber = 0;
        let start = 0;
        let end = chunkSize;

        const uploadNextChunk = async () => {
            if (chunkNumber < totalChunks) {
                const chunk = selectedFile.slice(start, end);
                const formData = new FormData();
                formData.append("file", chunk);
                formData.append("chunkNumber", chunkNumber);
                formData.append("totalChunks", totalChunks);

                WebsiteService.saveFilePart(website, formData)
                    .then(data => {
                        setProgress(Number((chunkNumber + 1) * chunkProgress));
                        chunkNumber++;
                        start = end;
                        end = start + chunkSize;
                        uploadNextChunk();

                    }, (err) => {
                        // context.setMessage('Bestand ' + file.name + ' toevoegen mislukt')
                    })
            } else {
                setProgress(100);
                setCurrentFile(currentFile + 1);
            }
        };
        uploadNextChunk();
    };

    useEffect(() => {
        if (files.length > 0 && currentFile < files.length) {
            handleFileUpload();
        } else if (files.length !== 0) {
            setTimeout(() => {
                ref.current.value = [];
                setFiles([]);
                setCurrentFile(0);
                onFinished && onFinished();
            }, 2000);
        }
    }, [currentFile]);

    useEffect(() => {
        if (files.length > 0) {
            handleFileUpload();
        }
    }, [files]);

    const dropEvent = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
    }
    const fileDropHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setFiles(ev.dataTransfer.files);

    }


    return (
        <div style={{ border: '2px gray dashed', textAlign: 'center', margin: '0 auto', width: '80%', padding: '10px', backgroundColor: '#efefef' }} onDrop={fileDropHandler} onDragOver={dropEvent} onDragEnter={dropEvent} onDragLeave={dropEvent}>

            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#5f6368"><path d="M250-160q-86 0-148-62T40-370q0-78 49.5-137.5T217-579q20-97 94-158.5T482-799q113 0 189.5 81.5T748-522v24q72-2 122 46.5T920-329q0 69-50 119t-119 50H510q-24 0-42-18t-18-42v-258l-83 83-43-43 156-156 156 156-43 43-83-83v258h241q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-89-60.5-153T478-739q-89 0-150 64t-61 153h-19q-62 0-105 43.5T100-371q0 62 43.93 106.5T250-220h140v60H250Zm230-290Z" /></svg>

            <input style={{ display: 'none' }} accept=".zip" ref={ref} disabled={files.length > 0} type="file" onChange={handleFileChange} multiple={false} /><br />
            {files.length === 0 && <div> Sleep hier uw ingepakt bestand (zip) naartoe, of <span onClick={(ev) => { ev.preventDefault(); ref.current.click() }} style={{ textDecoration: 'underline', cursor: 'pointer' }}> bladeren</span><br /><br />Bestaande documenten worden overschreven.<br /> Na het uploaden kan het, afhankelijk van de groote, even duren voordat de ZIP is uitgepakt.<br /><br /></div>}
            {files.length > 0 && currentFile < files.length && <div>Bezig met uploaden zip bestand <b>{Math.round(progress)}%</b></div>}
            {files.length > 0 && currentFile >= files.length && <div>Klaar met uploaden</div>}

        </div>
    );
};

export default FileUpload;