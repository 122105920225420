import React from 'react'

import {Dialog,DialogContent, DialogActions, DialogTitle,Button,Typography} from '../../../ui/core';


import FilesService from "../../../../services-admin/files";

export default function DeleteFile(props) { 

    const deleteItem = () => {
        FilesService.deleteItem(props.version, props.access, props.level, props.file.filename)
            .then(result => {
                props.onRefresh();
                props.onClose();
            }, (err) => {
                props.onRefresh();
                props.onClose();
            })
    }
    return (
        <Dialog  onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Verwijderen van {props.file.filename}</Typography></DialogTitle>
            <DialogContent >
                 Weet u zeker dat u dit bestand wilt verwijderen? 

            </DialogContent>
            <DialogActions>
                <Button variant="outlined"  onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={deleteItem}  >Verwijder</Button>
            </DialogActions>
        </Dialog>
    )
}
