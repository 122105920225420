import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, Typography, TextField, CheckBox,ImageUpload } from '../../ui/core'

export default function ThemeEdit(props) {
    const [theme, setTheme] = useState(props.theme);

    const handleChange = (ev) => {
        let tmpObj = Object.assign({}, theme);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        setTheme(tmpObj);
    }

    return (
        <Dialog fullScreen>
            <DialogTitle><Typography component="h2">Wijzig thema {theme.name}</Typography></DialogTitle>
            <DialogContent>
              <TextField id="name" label="Naam thema" value={theme.name || ''} onChange={handleChange}/><br/><br/>
              <CheckBox id="isgeneral" label="Dit is het algemene thema" checked={theme.isgeneral || false} onChange={handleChange}/>

              <div style={{display:'flex'}}>
                  <div> <TextField id="primary" label="Primaire kleur" value={theme.primary || ''} onChange={handleChange}/></div>
                  <div><TextField id="primarycontrast" label="Primaire contrast kleur" value={theme.primarycontrast || ''} onChange={handleChange}/></div>
                    <div style={{color:theme.primarycontrast,backgroundColor:theme.primary,padding:'4px'}}>Dit is een voorbeeld</div>
              </div>
             
              <div style={{display:'flex'}}>
                  <div><TextField id="secondary" label="Secundaire kleur" value={theme.secondary || ''} onChange={handleChange}/></div>
                  <div> <TextField id="secondarycontrast" label="Secundaire contrast kleur" value={theme.secondarycontrast || ''} onChange={handleChange}/></div>
                  <div style={{color:theme.secondarycontrast,backgroundColor:theme.secondary,padding:'4px'}}>Dit is een voorbeeld</div>
            </div>

              <TextField id="notifymail" label="E-mailadres voor alert gekozen thema" value={theme.notifymail || ''} onChange={handleChange}/>
              <TextField id="recieverfeedback" label="E-mailadres voor ontvangen feedback" value={theme.recieverfeedback || ''} onChange={handleChange}/>
          
            <ImageUpload id="logo" label="Logo. Specificatie: 270px breed, type .png" value={theme.logo || ''} onChange={handleChange}/>

            <ImageUpload id="striping" label="Afbeelding onderaan de pagina. Specificatie: 40px hoog, type .png (optioneel)" value={theme.striping || ''} onChange={handleChange}/>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => props.onDelete(theme)}>Verwijder</Button>
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={() => { props.onSave(theme) }}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
