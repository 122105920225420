import React, { useEffect, useState } from 'react'
import ServiceWebsites from '../../../services-admin/websites';
import { List, ListItem, ListItemText, Fab } from '../../ui/core';
import Edit from './edit';
import { Add } from '../../ui/coreIcons';
export default function Index() {
    const [sites, setSites] = useState([]);
    const [site, setSite] = useState();

    const getWebsites = () => {
        ServiceWebsites.get().then(
            result => {
                setSites(result);
            },
            error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getWebsites();
    }, [])

    const handleDelete = (site) => {    
        window.confirm('Weet je zeker dat je deze website met alle onderliggende documenten wilt verwijderen?') && ServiceWebsites.remove(site).then(() => {
           setSite(null);
           
            getWebsites();
        });
    }

    return (
        <>
            {!site && <Fab onClick={() => { setSite("-") }} edge="end" color="primary" aria-label="add" style={{ position: 'fixed', right: '15px', bottom: '35px', zIndex: '99999' }}><Add color="white" /></Fab>}
            {site && <Edit site={site} onClose={() => { setSite(null) ;getWebsites()}} onDelete={(site) => {handleDelete(site) }} />}
            {sites.length === 0 && <div style={{ width: '100%', fontStyle: 'italic', color: 'gray', textAlign: 'center' }}><br /><br />Er is websites gevonden.</div>}
            <List >
                {sites.map((site) => {
                    return <ListItem key={site} button onClick={() => { setSite(site) }}>
                        <ListItemText id={site} primary={"/" + site} secondary={""} />
                    </ListItem>
                })}
            </List>

        </>
    )
}
