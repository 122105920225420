import React, { useState, useRef, useEffect } from 'react'
import './menu.css';
const Menu = function Menu(props) {
    const [showMenu, setShowMenu] = useState(false);
    const id = props.id || 'menuitems';
    const ref = useRef();
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    //checks
    if (!props.id) console.warn('No ID set for Menu component')
    //end checks

    const getMousePos = function (ev) {
        let posx = 0;
        let posy = 0;
        if (!ev) ev = window.event;
        if (ev.clientX || ev.clientY) {
            posx = ev.clientX;
            posy = ev.clientY;
            if (props.noscroll!==true) {
                posx +=  document.documentElement.scrollLeft;
                posy +=  document.documentElement.scrollTop;
            }
        }
        return {
            x: posx,
            y: posy
        };
    };

    const handleShowMenu = (ev) => {       
        ev.preventDefault();
        ev.stopPropagation();
        let pos = getMousePos(ev);
        setX(pos.x);
        setY(pos.y)
       setShowMenu(!showMenu);
  
    }

    const handleHideMenu = () =>{ 
        if(showMenu===true){
 
            setShowMenu(false)
        }
    }

    useEffect(() => {
        if (ref.current) {
            if ((x + ref.current.getBoundingClientRect().width) > window.innerWidth) {
                setX(window.innerWidth - ref.current.getBoundingClientRect().width - 6)
            }else{
               setX(x)
            }
          
            if ((y + ref.current.getBoundingClientRect().height ) > window.innerHeight) {  
                setY(y-  ref.current.getBoundingClientRect().height - 6)     
                //setY(y-  ref.current.getBoundingClientRect().height - document.documentElement.scrollTop - 6)
            }else{
                setY(y)
            }   
        }
    }, [showMenu])

    useEffect(()=>{
        if(ref.current){
            ref.current.style.left = x + 'px';
            ref.current.style.top = y + 'px';
        }
    },[x,y])

    //hide when click outside
    const checkClick = (e) => {
        try{
            if (!document.getElementById(id).contains(e.target)) {
        //        setShowMenu(false)
            }
        }catch(ex){}
        
    }
    useEffect(() => {
        window.addEventListener('click', checkClick);
        return () => { window.removeEventListener('click', checkClick); }
    }, [checkClick])

    return (
        <div id={id} className={'ui-menu'}>
            <span onClick={handleShowMenu}> {props.control}</span> 
            {showMenu && <div className={'ui-menuitems'} ref={ref} onMouseLeave={handleHideMenu}>
                {props.children}
            </div>}
        </div>
    )
}

export { Menu };
