import React from 'react'

import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from '../../ui/core';
import GeneralService from "../../../services/general";
export default function Preview({ item, onClose, onDelete }) {



    return (
        <Dialog fullScreen onClose={onClose}>
            <DialogTitle>
                <Typography component="h2">Feedback van {item.name}</Typography>
                    <div className="label">{GeneralService.formatDate(item.date, true)}</div>
                </DialogTitle>
            <DialogContent>
                <div>
                   <table><tbody><tr><td className="label">Verstuurd door</td><td>{item.name||''}</td></tr>
                       <tr><td className="label">Email</td><td>{item.email||''}</td></tr>
                       <tr><td className="label">Telefoon</td><td>{item.phone||''}</td></tr>
                       <tr><td className="label">Onderwerp(en)</td><td>{item.card||''}</td></tr>
                       <tr><td colSpan={2}  style={{whiteSpace:'pre-line'}}><br/>{item.message||''}</td></tr>
                       </tbody></table>
                 
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => { onDelete(item) }}>Verwijder</Button>
                <Button variant="contained" color="primary" onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
