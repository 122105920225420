import API from "./api";
import GeneralService from "./general";
const Service = {
    get: (version, access, level) => {
        return new Promise((resolve, reject) => {
            if(level){
                API.get('files/list/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level)).then(
                    info => {
                        resolve(info)
                    },
                    error => {
                        reject(error);
                    }
                );
            }else{
                API.get('files/list/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) ).then(
                    info => {
                        resolve(info)
                    },
                    error => {
                        reject(error);
                    }
                );
            }
           
        });
    },
    getAccess: () => {
        return new Promise((resolve, reject) => {
            API.get('files/access').then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteAccess: (version, access) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('files/access/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    newAccess: (version, newAccess) => {
        return new Promise((resolve, reject) => {
            API.post('files/access/' + GeneralService.base64Encode(version) , null, {  item: newAccess }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    renameAccess: (version, access,newName) => {
        return new Promise((resolve, reject) => {
            API.post('files/access/' + GeneralService.base64Encode(version),null, { item: access, newName: newName }).then(
                info => {                   
                    resolve(info);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getLevels: (version, access) => {
        return new Promise((resolve, reject) => {
            API.get('files/levels/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    newLevel: (version, access, level, newLevel) => {
        return new Promise((resolve, reject) => {
            API.post('files/levels/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access), null, { level: level, newLevel: newLevel }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteLevel: (version, access,level) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('files/levels/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access)+ '/' + GeneralService.base64Encode(level)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    
    saveBase64: (version, access, level, filename, base64File) => {
        return new Promise((resolve, reject) => {
            API.post('files/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level), null, { filename: filename, item: base64File }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    saveFilePart: (version, access, level,formData) => {
        return new Promise((resolve, reject) => {
            API.postFormData('filepart/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level) , null, formData).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getFileContent: (version, access, level, filename) => {
        return new Promise((resolve, reject) => {
            API.get('files/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level) + '/' + GeneralService.base64Encode(filename)).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    rename: (version, access, level, filename, renameFile) => {
        return new Promise((resolve, reject) => {
            API.post('files/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level), null, { filename: filename, renameFile: renameFile }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteItem: (version, access, level, filename) => {
        return new Promise((resolve, reject) => {
            API.deleteItem('files/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level), null, { filename: filename }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    moveItem: (version, access, level, filename,newVersion,newAccess,newLevel) => {
        console.log(newVersion,newAccess,newLevel)
        return new Promise((resolve, reject) => {
            API.post('files/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level), null, { filename: filename, moveFile: newVersion + '/'+ newAccess + '/'+ newLevel }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getKeywords: (version, access, level) => {
        return new Promise((resolve, reject) => {
            API.get('files/keywords/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    saveKeywords: (version, access, level, keywords) => {
        return new Promise((resolve, reject) => {
            API.post('files/keywords/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level), null, { keywords: keywords }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getSettings: (version, access, level) => {
        return new Promise((resolve, reject) => {
            API.get('files/settings/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    saveSettings: (version, access, level, settings) => {
        return new Promise((resolve, reject) => {
            API.post('files/settings/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) + '/' + GeneralService.base64Encode(level), null, { item: settings }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    renameLevel: (version, access, level, renameLevel) => {
        return new Promise((resolve, reject) => {
            API.post('files/levels/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access) , null, { level: level, renameLevel: renameLevel }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getTemplateFiles: () => {
        return new Promise((resolve, reject) => {
            API.get('templatefiles/list/' ).then(
                files => {
                    resolve(files)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getRevisions: (version, access) => {
        return new Promise((resolve, reject) => {
            API.get('files/revisions/' + GeneralService.base64Encode(version) + '/' + GeneralService.base64Encode(access)).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    encodeUnicode : (str) => {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    },
    decodeUnicode: (str) => {
        try{
            return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }catch(ex){
            return atob(str);
        }  
    },
    fileToBase64: (file, width) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                if (!width) {
                    resolve(reader.result);
                } else {
                    try{ 
                        var canvas = document.createElement('canvas');
                        var img = new Image();
                        img.src = reader.result;
                      
                        img.onload = () => {
                            try{
                                let ext = file.name.split('.').pop();
                                if(img.width < width){
                                  canvas.width = img.width;
                                }else{
                                  canvas.width = width;
                                }
                                canvas.height = canvas.width * img.height / img.width;
                                var context = canvas.getContext('2d');
                                context.drawImage(img, 0, 0, canvas.width, canvas.height);
                                resolve(canvas.toDataURL('image/'+ext, 0.7));
                            }catch(ex){
                                console.log('Error: ', ex);
                                reject(ex);
                            }
                           
                        }
                    }catch(ex){
                        console.log('Error: ', ex);
                        reject(ex);
                    }
                   
                };
                reader.onerror = function (error) {
                    reject(error);
                    console.log('Error: ', error);
                };
            }
        })
    },
    getBase64FromDPath:(DPath)=>{
        return new Promise((resolve,reject)=>{
            API.get('files/base64/' + DPath).then(
                info => {
                    resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        })
      
    }
}
export default Service;