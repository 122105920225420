import API from "./apiv2";

const Service = {
    get: (formID) => {
        return new Promise((resolve, reject) => {
            API.get('forms/'+formID).then(
                forms => {
                     resolve(forms)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    send:(form)=> {
        return new Promise((resolve, reject) => {
            API.post('forms',null,{item:form}).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;