import React from 'react'
import { Button, Paper, TextArea, TextField } from '../../ui/core'

export default function Setting({ setting, onChange,onDelete }) {

    const handleChange = (attribute, value) => {
        let tmpSetting = Object.assign({}, setting);
        tmpSetting[attribute] = value;
        onChange(tmpSetting)
    }

    const handleDelete = ()=>{
        if(window.confirm("Weet u zeker dat u deze vermelding wilt verwijderen?")){
            onDelete()
        }
    }
    return (
        <Paper className="padding">
            <div style={{ width: '100%', overflowX: 'auto' }}><b>{(setting.sharepointURL || "") + (setting.sharepointSite || "") + (setting.sharepointPath || "")}</b></div>

            <TextField id="sharepointURL" value={setting.sharepointURL || ""} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Sharepoint URL (b.v. https://www.locatiesharepoint.nl)" required></TextField>
            <TextField id="sharepointSite" value={setting.sharepointSite || ""} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Site (b.v. /sites/mijnsite)" required></TextField>
            <TextField id="sharepointPath" value={setting.sharepointPath || ""} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Pad (b.v. /documentmap1)" required></TextField>
            <br /><br />
            <TextField id="username" value={setting.username || ""} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Gebruikersnaam" required></TextField>
            <TextField type="password" id="newPassword" value={setting.newPassword || ''} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Instellen nieuw wachtwoord" ></TextField>
            <br /><br />
            <TextField id="locationFiles" value={setting.locationFiles || ""} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Doellocatie (b.v. /1/prive/documentmap1)" required></TextField>
            <TextArea id="excludes" value={setting.excludes || ""} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} label="Uitsluitingen, voer hieronder op elke regel een (deel van de) locatie of bestandsnaam op die niet moet worden meegenomen" ></TextArea>
            <Button style={{float:'right'}} onClick={handleDelete}>Verwijderen</Button>
            <br style={{clear:'both'}}/>
        </Paper>
    )
}
