import React, { useContext, useState } from 'react'
import { ContextUI } from '../../uiContext';

const RadioButton = function TextField(props) {
    const contextUI = useContext(ContextUI);
    const [style, setStyle] = useState(props.style)
    const checkStyle = props.checked ? { backgroundColor: contextUI.primary.color, border: '2px solid ' + contextUI.primary.color } : { backgroundColor: 'white', border: '2px solid rgb(118, 118, 118)' }

    if (!props.id) { console.warn('ID missing for radiobutton') }
    return (
        < >
              <label className={props.label?'radioButtonLabel':'radioButtonNoLabel'} htmlFor={props.id || ''}>{props.label}
                <input
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    style={style}
                    value={props.value || ''}
                    checked={props.checked || false}
                    id={props.id || ''}
                    onChange={props.onChange}
                    type={'radio'}
                    className='radio'
                    onFocus={() => setStyle({ ...props.style, borderBottom: '1px solid ' + contextUI.primary.color })}
                    onBlur={() => setStyle(props.style)}
                ></input>
                <span className="checkmarkRadio" style={checkStyle}></span>
            </label> 
        </>
    )
}

export { RadioButton };
