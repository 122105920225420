import React, { useContext, useEffect, useState } from 'react'
import { Browser } from '@capacitor/browser';
import StorageService from '../../services/storage';
import UpdateService from '../../services/updateservice';
import FileService from '../../services/file';
//import ElectronService from '../../services/electron';
import Settings from "../../AppSettings";

import { Context } from '../../AppContext';
import { List, ListItem, ListItemText, ListItemIcon, Menu, MenuItem, IconButton } from '../ui/core'
import { MoreVert, InsertDriveFile as FileIcon } from '../ui/coreIcons';

//const encryptWorker = new Worker('./workers/encryptdownload.js');
const locationAPI = Settings.getLocationAPI();

export default function File(props) {
    const context = useContext(Context);
   // const [openURL, setOpenURL] = useState('')

    /*
    const preventDirectWebOpener = () => {
        //exception for SPIN browser. Not allowing to acces coockies when opening pdf
        return (Settings.getDevice().version === "Mobile" || Settings.getDevice().version === "Tablet" || document.cookie.indexOf('jwtset')===-1)
    }

    useEffect(() => {
        if (openURL.length > 0) {
            if(preventDirectWebOpener() === false){
                Browser.open({ url: openURL });
            }else{
                if(Settings.getPlatform() === 'web'){
                    window.open(openURL);
                }else{
                    Browser.open({ url: openURL });
                } 
            }
        }
        setOpenURL('');
    }, [openURL])

  */

    const downloadFile = (ev) => {
        try { ev.preventDefault(); ev.stopPropagation(); } catch (ex) { }
        /*
        if (Settings.getPlatform() === 'web' && preventDirectWebOpener() === false) {
            Browser.open({ url: locationAPI + 'documentv3//?' + props.file.DPath });
        } else {
            UpdateService.getDownloadToken(props.file.DPath)
                .then(token => { 
                    setOpenURL(locationAPI + 'documentv3/' + token + '/?' + props.file.DPath);
                })
                .catch(ex => {
                    setOpenURL(locationAPI + 'documentv2/?' + props.file.DPath);
                })
        }
        */
    }
/*
    const previewFile = async () => {
        FileService.viewFile(props.file.DPath)
            .then(result => {
                console.log('File opend')
            })
            .catch(err => {
                console.log('File err')
                console.log(err);
            })
        //test
*/
        //end test

        /*
            
                */
        /*
                if (Settings.getPlatform() === 'web' && preventDirectWebOpener() === false) {
                    Browser.open({ url: locationAPI + 'documentpreviewv3//?' + props.file.DPath });
                } else {
                    UpdateService.getDownloadToken(props.file.DPath)
                        .then(token => {
                            setOpenURL(locationAPI + 'documentpreviewv3/' + token + '/?' + props.file.DPath);
                        })
                        .catch(ex => {
                            setOpenURL(locationAPI + 'documentpreview/?' + props.file.DPath);
                        })
                }
                 

    }
    */
/*
    const viewOnline = () => {

        UpdateService.getDownloadToken(props.file.DPath)
            .then(token => {
                encryptWorker.postMessage({ pool: 0, encrypt: false, url: encodeURI(locationAPI + 'documentv3/' + token + '/?' + props.file.DPath), fileName: props.file.Name });
            })
            .catch(ex => {
                encryptWorker.postMessage({ pool: 0, encrypt: false, url: encodeURI(locationAPI + 'documentv2/?' + props.file.DPath), fileName: props.file.Name });
            })
    }

    const saveBase64AndOpen = (base64, fileName) => {
        StorageService.writeFile(StorageService.documentsDir(), fileName, base64)
            .then(result => {
                StorageService.getUriDocs()
                    .then(fileUri => {
                        console.log('Open file', fileUri, fileName)
                        if (fileUri[fileUri.length - 1] !== '/') { fileUri = fileUri + '/' }
                        StorageService.openFile(fileUri + fileName, props.file, props.history);
                    })
                    .catch(err => {
                        console.log(err);
                        downloadFile();
                    })
            })
            .catch(err => {
                context.setMessage('Er is een fout opgetreden bij het openen van het bestand.')
            })
    }

    encryptWorker.onmessage = ($event) => {
        if ($event && $event.data) {
            if ($event.data.error) {
                context.setMessage('Er is een fout opgetreden bij het openen van het bestand.')
            } else {
                if ($event.data.pool === 0) {
                    saveBase64AndOpen($event.data.base64, $event.data.fileName)
                }
            }
        }
    }
*/
    const viewFile = (ev) => {

        try { ev.preventDefault(); ev.stopPropagation(); } catch (ex) { }
        context.setMessage('Bestand wordt geopend...')
        FileService.viewFile(props.file.DPath, StorageService.getStorageFileName(props.file), props.file.Name)
            .then(result => {
              
            })
            .catch(err => {
                context.setMessage('Om dit bestand te opnenen dient u eerst in te loggen.');
                props.history.push('/settings?redirect=' + props.location.pathname)
/*
                if (Settings.getPlatform() === 'web') {
                    context.setMessage('Het bestand kan niet worden geopend.')
                }else{
                    context.setMessage('Het bestand kan niet worden geopend.')
                    console.log(err);
                }
                    */
            })
        /*
        if (Settings.getPlatform() === 'web') {
            previewFile();
        } else if (props.file.encrypt) {
            StorageService.readFile(StorageService.documentsDir(), StorageService.getStorageFileName(props.file) )
                .then((result) => {
                    context.decryptFile(StorageService.getStorageFileName(props.file) , result);
                }, (err) => {
                    console.log('Error reading file', err);
                    viewOnline()
                })
        } else {
            StorageService.previewDocument(props.file, props.history)
                .then(() => { })
                .catch(err => {
                    viewOnline();
                })
        }
*/
    }

    /*
    useEffect(() => {
        if (context.decryptedFiles && context.decryptedFiles[StorageService.getStorageFileName(props.file) ]) {
            StorageService.writeFile(StorageService.documentsDir(), props.file.Name, context.decryptedFiles[StorageService.getStorageFileName(props.file) ])
                .then(result => {
                    context.removeDecryptedFile(StorageService.getStorageFileName(props.file) );
                    //open file
                    StorageService.getUriDocs()
                        .then(fileUri => {
                            if (fileUri[fileUri.length - 1] !== '/') { fileUri = fileUri + '/' }
                            StorageService.openFile(fileUri + props.file.Name, props.file, props.history);
                        })
                        .catch(err => {
                            console.log(err);
                            viewOnline();
                        })
                })
                .catch(err => {
                    console.log(err);
                    viewOnline();
                })
        }
    }, [context.decryptedFiles])

      <ListItemIcon>
                        <Menu id={"menu_" + props.file.DPath} control={<IconButton><MoreVert /></IconButton>}>
                            <MenuItem onClick={viewFile}>  Weergeven </MenuItem>
                            <MenuItem onClick={downloadFile}> Downloaden </MenuItem>
                        </Menu>
                    </ListItemIcon>
*/
    return (
        <>
            <List>
                <ListItem onClick={viewFile}>
                    <ListItemIcon>
                        <FileIcon style={{ color: 'gray' }} /><br />
                        <span style={{ fontSize: '10px', paddingLeft: '4px' }}>{props.file.Extention.toUpperCase()}</span>
                    </ListItemIcon>
                    <ListItemText primary={props.file.Name.replace('.' + props.file.Extention, '')}></ListItemText>
                </ListItem>
            </List>
        </>
    )
}
