import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { SearchBar } from '../../../ui/searchBar';
export default function Index({ items, value, onSelectedLevel, revisions }) {
    const [tree, setTree] = useState([]);
    const [search,setSearch] = useState('');
    const folderOpen = <svg height="24" width="24" viewBox='0 0 48 48'><path fill="gray" d="M7 40q-1.15 0-2.075-.925Q4 38.15 4 37V11q0-1.15.925-2.075Q5.85 8 7 8h14.05l3 3H41q1.15 0 2.075.925Q44 12.85 44 14H22.75l-3-3H7v26l5.1-20H47l-5.35 20.7q-.3 1.2-1.1 1.75T38.5 40Zm3.15-3h28.6l4.2-17h-28.6Zm0 0 4.2-17-4.2 17ZM7 14v-3 3Z" /></svg>;
    const folderClosed = <svg height="24" width="24" viewBox='0 0 48 48' ><path fill="gray" d="M7.05 40q-1.2 0-2.1-.925-.9-.925-.9-2.075V11q0-1.15.9-2.075Q5.85 8 7.05 8h14l3 3h17q1.15 0 2.075.925.925.925.925 2.075v23q0 1.15-.925 2.075Q42.2 40 41.05 40Zm0-29v26h34V14H22.8l-3-3H7.05Zm0 0v26Z" /></svg>
    const bellRed = <svg height="24" width="24" viewBox='0 0 48 48' ><path fill="#c01818" d="M6.2 19.65q0-4.05 1.7-7.675T12.75 5.8l2.05 2.25q-2.65 2.15-4.125 5.175T9.2 19.65Zm32.65 0q0-3.4-1.4-6.425T33.4 8.05l2.05-2.25q3.1 2.6 4.75 6.2t1.65 7.65ZM8 38v-3h4.2V19.7q0-4.2 2.475-7.475Q17.15 8.95 21.2 8.1V6.65q0-1.15.825-1.9T24 4q1.15 0 1.975.75.825.75.825 1.9V8.1q4.05.85 6.55 4.125t2.5 7.475V35H40v3Zm16-14.75ZM24 44q-1.6 0-2.8-1.175Q20 41.65 20 40h8q0 1.65-1.175 2.825Q25.65 44 24 44Zm-8.8-9h17.65V19.7q0-3.7-2.55-6.3-2.55-2.6-6.25-2.6t-6.275 2.6Q15.2 16 15.2 19.7Z" /></svg>
    const bellOrange = <svg height="24" width="24" viewBox='0 0 48 48' ><path fill="#f1b40d" d="M6.2 19.65q0-4.05 1.7-7.675T12.75 5.8l2.05 2.25q-2.65 2.15-4.125 5.175T9.2 19.65Zm32.65 0q0-3.4-1.4-6.425T33.4 8.05l2.05-2.25q3.1 2.6 4.75 6.2t1.65 7.65ZM8 38v-3h4.2V19.7q0-4.2 2.475-7.475Q17.15 8.95 21.2 8.1V6.65q0-1.15.825-1.9T24 4q1.15 0 1.975.75.825.75.825 1.9V8.1q4.05.85 6.55 4.125t2.5 7.475V35H40v3Zm16-14.75ZM24 44q-1.6 0-2.8-1.175Q20 41.65 20 40h8q0 1.65-1.175 2.825Q25.65 44 24 44Zm-8.8-9h17.65V19.7q0-3.7-2.55-6.3-2.55-2.6-6.25-2.6t-6.275 2.6Q15.2 16 15.2 19.7Z" /></svg>
    const [openFolders, setOpenFolders] = useState([]);

    useEffect(() => {
        setTree(getParents(items));
    }, [items,revisions, value,search,openFolders])

    const getParents = (levels) => {
        let parents = levels.filter(item => { return item.indexOf('/') === -1 });
        let items = [];
        parents.map(parent => { 
            if(parent.toLowerCase().indexOf(search.toLowerCase())>=0 || search.length==0){
                items.push(levelItem(parent, parent, 0));
            }          
            if(openFolders.indexOf(parent)>=0 || search.length>0){ 
            items.push(getChildren(parent, levels, 1))
            }
        })
        return items
    }
   
    const getRevision = (level) => {
        //  relativePath
        let found = revisions.find(rev => { return rev.relativePath === level });

        if (found && found.revision && found.revision.update) {
            let expireDate = new Date(found.revision.update); 
            if(expireDate< new Date()){
                return bellRed;
            }else{
                var timeDiff = moment(expireDate).diff(moment( ), 'months', true); 
                if(timeDiff<1){
                    return bellOrange;
                }            
            }
           
        }
        return null;
    }

    const toggleLevel = (level) => {
        if(openFolders.indexOf(level)>=0){
            setOpenFolders(openFolders.filter(item => { return item !== level }));
        }else{
            setOpenFolders([...openFolders, level]);
        }
    }

    const getChildren = (base, levels, deep) => {
        let children = levels.filter(level => { return level.indexOf(base) == 0 && level.split('/').length === deep + 1 });
        let items = [];
        children.map(child => {
            if(child.toLowerCase().indexOf(search.toLowerCase())>=0 || search.length==0){
                items.push(levelItem(child, child.replace(base, '').replace('/', ''), deep));
            }
            let nextChildren = levels.filter(level => { return level.indexOf(base) == 0 && level.split('/').length === deep + 2 });
            if (nextChildren.length > 0) {      
                if(openFolders.indexOf(child)>=0 || search.length>0){
                    items.push(getChildren(child, levels, deep + 1));
                }
            }
        })

        return items
    }

    const levelItem = (fullPath, level, deep) => {
        return <div key={level} style={{ display: 'flex', clear: 'both', paddingLeft: (deep * 20) + 'px', fontWeight: (value === fullPath ? 'bold' : 'normal') }} onClick={() => { toggleLevel(fullPath);onSelectedLevel(fullPath); }}>{openFolders.indexOf(fullPath)>=0? folderOpen:folderClosed}{getRevision(fullPath)}<div style={{ padding: '4px'  }}>{level}</div></div>
    }

    return (
        <div>
           {items.length>0 &&  <SearchBar value={search} onKeyUp={setSearch} onReset={()=>{setSearch('')}}/>}
            {tree}
        </div>
    )
}
