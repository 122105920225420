import React, { useState, useEffect } from 'react'


import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Tabs, TextField, CheckBox, Typography, TextArea } from '../../ui/core';

import UserService from "../../../services-admin/users"
//import GeneralService from '../../../services/general';
import FileService from "../../../services-admin/files"
//import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
//import Settings from "../../../AppSettings";

export default function Edit(props) {
    const [step, setStep] = useState(0);
    const [users, setUsers] = useState([]);
    const [accessList, setAccessList] = useState([]);
    const [selectedAccessList, setSelectedAccessList] = useState([])
    const [importList, setImportList] = useState('');


    useEffect(() => {
        FileService.getAccess()
            .then(result => setAccessList(result), err => { })
        UserService.get()
            .then(users => { setUsers(users); }, err => { })
    }, [])

    const check = () => {
        let importArr = importList.split('\n');
        //cleanup
        for (let i = 0; i < importArr.length; i++) {
            let importUser = importArr[i].split(';');
            importArr[i] = importUser[0] + ';' + (importUser[1] || '')
        }
        //do check
        for (let i = 0; i < importArr.length; i++) {
            let importUser = importArr[i].split(';');
            if (importUser[0].indexOf('@') >= 0 && importUser[0].indexOf('.') >= 0 && importUser[0].indexOf(',') === -1) {
               // let find = users.find(user => {if (!user.Email||!importUser[0].Email) return false; return user.Email.trim().toLowerCase() === importUser[0].trim().toLowerCase() })
                let find = users.find(user => { if (!user.Email||!importUser[0]) return false; return user.Email.trim().toLowerCase() === importUser[0].trim().toLowerCase() })
                if (find) {
                    importArr[i] = importArr[i] + '; GEBRUIKER WORDT BIJGEWERKT'
                } else {
                    importArr[i] = importArr[i] + '; NIEUWE GEBRUIKER'
                }

            } else {
                importArr[i] = importArr[i] + '; GEEN GELDIG EMAILADRES'
            }
        }
        setImportList(importArr.join('\n'))
        setStep(1);
    }

    useEffect(() => {
        if (step === 2) { 
            let importArr = importList.split('\n');
            if (importArr && importArr.length >= 0) {
                let importUserArr = importArr[0].split(';');
                if (importUserArr[0].indexOf('@') >= 0 && importUserArr[0].indexOf('.') >= 0 && importUserArr[0].indexOf(',') === -1) {
                    let find = users.find(user => { if (!user.Email||!importUserArr[0]) return false; return user.Email.trim().toLowerCase() === importUserArr[0].trim().toLowerCase() })
                    if (find) {
                        //UPDATE USER
                        let tmpUpdate = {}
                        tmpUpdate._id = find._id;
                        tmpUpdate.Accesslist = find.Accesslist
                        if (!tmpUpdate.Accesslist) {
                            tmpUpdate.Accesslist = selectedAccessList;
                        } else {
                            for (let a = 0; a < selectedAccessList.length; a++) {
                                if (tmpUpdate.Accesslist.indexOf(selectedAccessList[a]) === -1) {
                                    tmpUpdate.Accesslist.push(selectedAccessList[a])
                                }
                            }
                        }
                        UserService.update(tmpUpdate)
                            .then(result => {
                                importArr.splice(0, 1);
                                setImportList(importArr.join('\n'))
                            })
                            .catch(ex => {
                                console.log(ex);
                                importArr.splice(0, 1);
                                setImportList(importArr.join('\n'))
                            })
                    } else {
                        //NEW USER
                        let tmpUpdate = { Accesslist: selectedAccessList, Email: importUserArr[0].trim().toLowerCase(), Name: importUserArr[1]||'', FileAccesslist: [], Rechten: '', _id: '0' };
                        UserService.update(tmpUpdate)
                            .then(result => {
                                importArr.splice(0, 1);
                                setImportList(importArr.join('\n'))
                            })
                            .catch(ex => {
                                console.log(ex);
                                importArr.splice(0, 1);
                                setImportList(importArr.join('\n'))
                            })
                    }
                } else {
                    importArr.splice(0, 1);
                    setImportList(importArr.join('\n'))
                }

            }
        }
    }, [importList, step])

    const handleImport = () => {
        setStep(2);
    }

    const toggleAccess = (access) => {
        let tmpAccessList = Object.assign([], selectedAccessList);
        if (tmpAccessList.indexOf(access) >= 0) {
            tmpAccessList.splice(tmpAccessList.indexOf(access), 1);
        } else {
            tmpAccessList.push(access);
        }
        setSelectedAccessList(tmpAccessList);
    }


    return (
        <Dialog fullScreen onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Importeren gebruikers</Typography></DialogTitle>
            <DialogContent>
                Selecteer de toegang(en) waaraan de gebruikers moeten worden gekoppeld<br /><br />
                {accessList.map(access => { return <CheckBox key={access} id={access} label={access} checked={selectedAccessList.indexOf(access) >= 0} onClick={() => { toggleAccess(access) }} /> })}
                Vul in onderstaande veld de gebruikers in. Eén gebruiker per regel. Voer eerst het emailadres in, gevolgd door een ;  gevolgd door de naam.<br /><br /> Voorbeeld:<br /> janssen@mijnadres.nl;Janssen<br /><br />
                <TextArea id="importlist" label="Gebruikerslijst" value={importList} onChange={(ev) => { setImportList(ev.target.value) }}></TextArea>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.onClose}>Sluiten</Button>
                {step !== 2 && <Button variant="contained" color="primary" onClick={() => { check() }} disabled={users.length === 0 || accessList.length === 0 || selectedAccessList.length === 0 || importList.length === 0}>Controleren</Button>}
                {step !== 2 && <Button variant="contained" color="primary" onClick={() => { handleImport() }} disabled={step !== 1}>Importeren</Button>}
            </DialogActions>
        </Dialog>
    )
}
