import React, { useEffect } from 'react'
import { Paper, RadioButton } from '../../ui/core';
export default function Score({ item, onChange }) {
    const [scores, setScores] = React.useState([]);
    useEffect(() => {
        let scores = [];
        for (let i = (item.scoreStart || 0); i <= (item.scoreEnd || 10); i++) {
            scores.push(i);
        }
        setScores(scores);
    }, [item.scoreStart, item.scoreEnd]);

    const changeItem = (content, attribute) => { 
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }

    //const checkBoxes = [];
   // const start = item.scoreStart || 0;
  //  const end = item.scoreEnd || 10;
   // for (let i = start; i <= end; i++) {
   //     checkBoxes.push(<RadioButton  id={"rb-score"+ i} key={i} color="primary" checked={i === item.answer} onChange={(ev) => { changeItem(i, 'answer') }} style={{ padding: '4px' }} />)
   // }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br /><br />


            <table style={{ width: '100%' }}><tbody>
                <tr>
                    <td style={{ width: '20%' }}>

                    </td>
                    {scores.map((score) => {
                        return <td key={score} style={{ textAlign: 'center' }} >{score}</td>
                    })}
                    <td style={{ width: '20%' }}>

                    </td>
                </tr>
                <tr>
                    <td style={{ width: '20%' }}>
                        {item.scoreStartLabel}
                    </td>
                    {scores.map((score) => {
                        return <td key={"rb" + score} style={{ textAlign: 'center' }} ><RadioButton id={"rb" + score} key={score} color="primary" checked={score === item.answer} onChange={(ev) => { changeItem(score, 'answer') }} style={{ padding: '4px' }} /></td>
                    })}
                    <td style={{ width: '20%' }}>
                        {item.scoreEndLabel}
                    </td>
                </tr>
            </tbody></table>

            <br />



        </Paper>
    )
}
