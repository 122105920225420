import React, { useEffect } from 'react';
import FirebaseService from "./services/firebase";
import './App.css';
import './ui.css';
import UI from "./uiContext";
import Router from "./AppRouter";
import AppContext from "./AppContext"; 
import "typeface-roboto";
import ErrorBoundary from "./ErrorBoundary.js"
  

function App(props) {
  const startApp = () => {
    FirebaseService.initToken();
    FirebaseService.setBadge(0);
  }
 
  useEffect(() => {
    if (!window.cordova) {
      startApp()
    } else {
      document.addEventListener('deviceready', startApp, false)
    }
  }, [])
//<ErrorBoundary>
  return (
    <React.Fragment>
      
      <UI  >
        <AppContext>
          <Router {...props} />
        </AppContext>
      </UI>
     
    </React.Fragment>
  )
}

export default App;
