import API from "./apiv2";

const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('redeemcodes').then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    request:()=> {
        return new Promise((resolve, reject) => {
            API.get('redeemcodes/request').then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;