import React, { useContext } from 'react'
import { LinearProgress } from '../ui/core';
import { Paper, Button } from '../ui/core';
import { Context } from '../../AppContext';
import Settings from "../../AppSettings";
import APIV2 from "../../services/apiv2";
import { Browser } from '../../plugins/capacitor';

export default function Updatestatus() {
    const context = useContext(Context);
    const locationAPI = Settings.getLocationAPI();

    const downloadAPK = () => {
        const url = locationAPI + 'apk'
        APIV2.get('androiddownloadkey').then(
            result => { 
                Browser.open({ url: url + '/' + result.key });
            },
            error => {
                context.setMessage('Downloaden mislukt')
            }
        );
    }

    return (
        <React.Fragment>
            {(context.downloadList1Total + context.downloadList2Total + context.downloadList3Total) > 0 && <div className="padding">
                <Paper className="padding" >
                    {!context.downloading && <div>
                        <b>Update beschikbaar</b><br /><br />
                        <i>Deze update bevat {context.downloadList1Total + context.downloadList2Total + context.downloadList3Total} bestand(en) voor offline gebruik.</i>
                    </div>
                    }
                    {context.downloading && <b>Bezig met downloaden bestanden<br /><br /></b>}
                    {
                        context.downloading && <React.Fragment>
                            {context.downloadList1.length > 0 && <LinearProgress variant="determinate" value={(100 / context.downloadList1Total) * (context.downloadList1Total - context.downloadList1.length)} />}
                            {context.downloadList2.length > 0 && <LinearProgress variant="determinate" value={(100 / context.downloadList2Total) * (context.downloadList2Total - context.downloadList2.length)} style={{ marginTop: '4px' }} />}
                            {context.downloadList3.length > 0 && <LinearProgress variant="determinate" value={(100 / context.downloadList3Total) * (context.downloadList3Total - context.downloadList3.length)} style={{ marginTop: '4px' }} />}
                        </React.Fragment>
                    }
                    <br />
                    {!context.downloading && <Button variant="contained" color="primary" onClick={() => { context.setDownloading(true) }} fullWidth>Nu bijwerken</Button>}
                    <br /><br /><div className="label">Bekijk onder instellingen de diverse update mogelijkheden. U kunt o.a. per hoofdstuk aangeven of onderliggende documenten offline beschikbaar moeten zijn. </div>
                </Paper>
            </div>
            }
            {Settings.getPlatform() === 'android' && context.info && context.info.Settings && context.info.Settings.AndroidSingleFile && context.info.Settings.AndroidLatestVersion !== context.version && <div className="padding"><Paper className="padding" >Er is een nieuwe versie ({context.info.Settings.AndroidLatestVersion}) van de Android App beschikbaar, klik op onderstaande knop om deze te downloaden.<br /><br />
                <Button variant="contained" color="primary" onClick={downloadAPK} fullWidth>Download laatste versie van de Android App</Button>
                <br /></Paper></div>}
        </React.Fragment>
    )
}
