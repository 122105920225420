
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { CheckBox, Button, Dialog, DialogTitle, DialogContent, DialogActions ,Typography,TextField} from '../../ui/core';
export default function Sym({ db, onChange }) {
    const [editItem, setEditItem] = useState();

    const handleChange = (ID, attribute, value) => {
        let tmpDb = Object.assign({}, db);
        let found = tmpDb.Symptomen.find(sym => { return sym.ID === ID });
        if (found) {
            found[attribute] = value;
        }
        onChange(tmpDb);
    }

    const handleChangeEditItem = (attribute, value) => {
        let tmpEdit = Object.assign({}, editItem);
        tmpEdit[attribute] = value;
        setEditItem(tmpEdit);
    }

    const handleSaveEditItem = (ID, item) => {
        let tmpDb = Object.assign({}, db);
        let found = tmpDb.Symptomen.find(sym => { return sym.ID === ID });
        if (found) {
            found.Symptoom = item.Symptoom;
            found.Trefwoorden = item.Trefwoorden;
            found._checked = false;
        }else{
            tmpDb.Symptomen.push(item);         
        }     
        tmpDb.Symptomen = tmpDb.Symptomen.sort((a,b)=>{return (a.Symptoom > b.Symptoom)?1:-1})  
        onChange(tmpDb);
        setEditItem(null)
    }

    const handleDelete = () => {
        if (window.confirm('Weet u zeker dat u de geselecteerde items wilt verwijderen?')) {
            let tmpDb = Object.assign({}, db);
            tmpDb.Symptomen = tmpDb.Symptomen.filter(sym => { return sym._checked !== true })
            onChange(tmpDb);
        }
    }

    const handleEdit = () => {
        let found = db.Symptomen.find(sym => { return sym._checked === true });
        if (found) {
            setEditItem(found);
        }
    } 

    const handleNew = () =>{
        setEditItem({ID:uuidv4(),Symptoom:'Nieuw symptoom',Trefwoorden:''})
    }

    if (!db.Symptomen) return null;

    if (editItem) return <Dialog fullScreen={true}>
        <DialogTitle><Typography component="h1">Symptoom</Typography></DialogTitle>
        <DialogContent>
            <TextField id="Symptoom" label="Symptoom" value={editItem.Symptoom || ''} onChange={(ev)=>{handleChangeEditItem('Symptoom',ev.target.value)}}/>
            <br/>  <br/>
            <TextField id="Trefwoorden" label="Trefwoorden" value={editItem.Trefwoorden || ''} onChange={(ev)=>{handleChangeEditItem('Trefwoorden',ev.target.value)}}/>
        </DialogContent>
        <DialogActions> 
            <Button variant="containted" color="primary" onClick={()=>{handleSaveEditItem(editItem.ID,editItem)}}>Sluiten</Button>
        </DialogActions>
    </Dialog>

    return (
        <div className="padding">
            <Button  variant="contained" color="primary"  onClick={handleNew}>Nieuw symptoom</Button>
            <Button variant="contained" color="primary" disabled={db.Symptomen.filter(sym => { return sym._checked === true }).length !== 1} onClick={handleEdit}>Wijzigen</Button>
            <Button variant="contained" color="primary" disabled={db.Symptomen.filter(sym => { return sym._checked === true }).length === 0} onClick={handleDelete}>Verwijderen</Button>
            <table><tbody><tr><th></th><th></th><th>Symptoom</th><th>Trefwoorden</th></tr>
                {db.Symptomen && db.Symptomen.map((sym, i) => {
                    return <tr key={sym.ID}><td className="label">{i}</td><td><CheckBox id={sym.ID} checked={sym._checked} onChange={(ev) => { handleChange(sym.ID, '_checked', ev.target.checked) }} /></td><td>{sym.Symptoom}</td><td>{sym.Trefwoorden}</td></tr>
                })}
            </tbody></table>
            <br /><br /><br />
        </div>
    )
}
