import React ,{useState} from 'react'
import General from './general';
import Theme from './theme';
import Account from './account';
import App from './app';
import {Tabs} from '../../ui/core';

export default function Index() {
    const [selectedTab,setSelectedTab] = useState(0);
    return (
        <div>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="ui-tab" id={0}>ALGEMEEN</div>
                <div className="ui-tab"  id={1}>ACCOUNT</div>
                <div className="ui-tab"  id={2}>THEMA'S</div>
                <div className="ui-tab"  id={3}>APP</div>
            </Tabs>
            {selectedTab===0 && <General/>}
            {selectedTab===1 && <Account/>}
            {selectedTab===2 && <Theme/>}
            {selectedTab===3 && <App/>}
        </div>
    )
}
