import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, TextField, Tabs } from '../../ui/core';
import ServiceFile from '../../../services/file';
export default function Edit({ item, onClose, onSave, onDelete }) {
    const [contact, setContact] = useState(item);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        setContact(item);
    }, [item]);

    const handleChange = (ev) => {
        let tmpObj = Object.assign({}, contact);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        //compatability old version
        tmpObj.Piketfunctie = tmpObj.PiketFunctie;
        setContact(tmpObj);
    }
    const uploadFile = (evt) => {
        let tmpFiles = [];
        for (let i = 0; i < document.getElementById('uploadimage').files.length; i++) {
            let nameSplit = document.getElementById('uploadimage').files[i].name.split('.');
            let ext = nameSplit[nameSplit.length - 1].toLowerCase();

            ServiceFile.fileToBase64(document.getElementById('uploadimage').files[i], 150)
                .then(image => {
                    console.log('image', image);
                    evt.target.value = '';
                    let tmpObj = Object.assign({}, contact);
                    tmpObj.Image = image;
                    setContact(tmpObj);
                })
                .catch(err => {
                    console.log(err)
                })
        }
        // setUploadedFiles(tmpFiles);
    }
    const deleteFile = () =>{
        let tmpObj = Object.assign({}, contact);    
        tmpObj.Image  ='';
        setContact(tmpObj);
    }
    return (
        <Dialog fullScreen={true}>
            <DialogTitle><Typography component="h1">Bewerken contact</Typography></DialogTitle>
            <DialogContent>
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                    <div>ALGEMEEN</div>
                    <div>COMMUNICATIE</div>
                    <div>FUNCTIES</div>
                    <div>OVERIG</div>
                </Tabs>
                <br />
                {selectedTab === 0 && <div>

                    <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ textAlign: 'center' }}>
                            <img src={contact.Image} />
                            <input type="file" name={'uploadimage'} id={'uploadimage'} onChange={(e) => uploadFile(e)} style={{ display: 'none' }} multiple />
                            <br />
                            {!contact.Image && <Button onClick={() => { document.getElementById('uploadimage').click() }} variant="outlined"  >Upload afbeelding</Button>}
                            {contact.Image && <Button onClick={deleteFile} variant="outlined"  >Verwijder afbeelding</Button>}
                        </div>
                        <div><TextField id={'Voornaam'} label="Voornaam" value={contact.Voornaam || ''} onChange={handleChange}></TextField></div>
                        <div> <TextField id={'Tussenvoegsel'} label="Tussenvoegsel" value={contact.Tussenvoegsel || ''} onChange={handleChange}></TextField></div>
                        <div> <TextField id={'Achternaam'} label="Achternaam" value={contact.Achternaam || ''} onChange={handleChange}></TextField></div>
                        <div> <TextField id={'Voorletters'} label="Voorletters" value={contact.Voorletters || ''} onChange={handleChange}></TextField></div>
                       
                    </div>

                    <br /><br />
                    <TextField id={'Organisatie'} label="Organisatie" value={contact.Organisatie || ''} onChange={handleChange}></TextField>
                    <TextField id={'Gemeente'} label="Gemeente" value={contact.Gemeente || ''} onChange={handleChange}></TextField>
                    <br /><br />
                </div>}
                {selectedTab === 1 && <div>
                    <TextField id={'Adres'} label="Adres" value={contact.Adres || ''} onChange={handleChange}></TextField>
                    <TextField id={'Postcode'} label="Postcode" value={contact.Postcode || ''} onChange={handleChange}></TextField>
                    <TextField id={'Woonplaats'} label="Woonplaats" value={contact.Woonplaats || ''} onChange={handleChange}></TextField>
                    <br /><br />
                    <TextField id={'Email'} label="Email" value={contact.Email || ''} onChange={handleChange}></TextField>
                    <TextField id={'Telefoonnummer'} label="Telefoonnummer" value={contact.Telefoonnummer || ''} onChange={handleChange}></TextField>
                    <TextField id={'Mobiel'} label="Mobiel" value={contact.Mobiel || ''} onChange={handleChange}></TextField>
                    <TextField id={'Alternatiefbereikbaarop'} label="Alternatief bereikbaar op" value={contact.Alternatiefbereikbaarop || ''} onChange={handleChange}></TextField>
                    <br /><br />
                    <TextField id={'Piketnummer'} label="Piketnummer" value={contact.Piketnummer || ''} onChange={handleChange}></TextField>
                    <br /><br />
                </div>}
                {selectedTab === 2 && <div>
                    <TextField id={'Functie'} label="Functie" value={contact.Functie || ''} onChange={handleChange}></TextField>
                    <TextField id={'PiketFunctie'} label="Piketfunctie" value={contact.PiketFunctie || contact.Piketfunctie || ''} onChange={handleChange}></TextField>
                    <TextField id={'FunctieinCoPI'} label="Functie in CoPI" value={contact.FunctieinCoPI || ''} onChange={handleChange}></TextField>
                    <TextField id={'FunctieinROT'} label="Functie in ROT" value={contact.FunctieinROT || ''} onChange={handleChange}></TextField>
                    <TextField id={'FunctieinBT'} label="Functie in BT" value={contact.FunctieinBT || ''} onChange={handleChange}></TextField>
                    <TextField id={'Functieoverig'} label="Functie overig" value={contact.Functieoverig || ''} onChange={handleChange}></TextField>
                    <TextField id={'FunctieMeldkamer'} label="Functie Meldkamer" value={contact.FunctieMeldkamer || ''} onChange={handleChange}></TextField>
                    <TextField id={'FunctieActiecentrum'} label="Functie in het actiecentrum" value={contact.FunctieActiecentrum || ''} onChange={handleChange}></TextField>

                    <br /><br />
                </div>}
                {selectedTab === 3 && <div>
                    <TextField id={'Proces'} label="Proces" value={contact.Proces || ''} onChange={handleChange}></TextField>
                   
                    <TextField id={'Deskundigheid'} label="Deskundigheid" value={contact.Deskundigheid || ''} onChange={handleChange}></TextField>
                   
                    <TextField id={'Team'} label="Team" value={contact.Team || ''} onChange={handleChange}></TextField>
                    <TextField id={'Rolbinnenteam'} label="Rol binnen team" value={contact.Rolbinnenteam || ''} onChange={handleChange}></TextField>
                    <TextField id={'Sectie'} label="Sectie" value={contact.Sectie || ''} onChange={handleChange}></TextField>
                   
                    <TextField id={'Opmerkingen'} label="Opmerkingen" value={contact.Opmerkingen || ''} onChange={handleChange}></TextField>
                    <br /><br />
                </div>}


                <br /><br />



            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => { onDelete(contact) }}>Verwijder</Button>
                <Button variant="outlined" onClick={() => onClose()}>Annuleren</Button>
                <Button variant="contained" onClick={() => onSave(contact)}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
