import React, { useState } from 'react'
import { Menu, MenuItem,  Button } from '../ui/core';

import EditText from './edit/textblock';
import EditSingleLine from './edit/singleline';
import EditMultiLine from './edit/multiline';
import EditRadioButtons from './edit/radiobutton';
import EditCheckBox from './edit/checkbox';
import EditFileUpload from './edit/uploadfile';
import EditDateTime from './edit/datetime';
import EditScore from './edit/score';
import GeneralService from '../../services/general';
export default function Builder(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const insertElement = (element) => {
        let newItems = Object.assign([], props.items);
        if (!newItems) newItems = [];
        let newItem = { id: new Date().getTime(), sort: newItems.length + 1, element: element }
        if(element===7){
            newItem.scoreStart = 1;
            newItem.scoreEnd = 10;
        }
        newItems.push(newItem);
        props.onChange(newItems);
        setAnchorEl(null);
    }

    const changeItem = (item) => {
        let newItems = Object.assign([], props.items);
        for (let l = 0; l < newItems.length; l++) {
            if (newItems[l].id === item.id) {
                newItems[l] = item;
            }
        }
        props.onChange(newItems);
    }

    const deleteItem = (item) => {
        let newItems = Object.assign([], props.items);

        for (let l = newItems.length - 1; l >= 0; l--) {
            if (newItems[l].id === item.id) {
                newItems.splice(l, 1);
            }
        }
        props.onChange(newItems);
    }
    
    const changeSort = (item, direction) => {
        let newSortValue = 0;
        if (direction === 'up') {
            newSortValue = item.sort - 1;
        } else {
            newSortValue = item.sort + 1;
        }
        let newItems = Object.assign([], props.items);
        for (let l = newItems.length - 1; l >= 0; l--) {
            if (newItems[l].sort === newSortValue) {
                if (direction === 'up') {
                   newItems[l].sort = newItems[l].sort + 1;
                } else {
                    newItems[l].sort = newItems[l].sort - 1;
                }
            } else {
                if (newItems[l].id === item.id) {
                    newItems[l].sort = newSortValue;
                }
            }
        }
        newItems = GeneralService.sortJSON(newItems, 'sort', '123');
        for (let i = 0; i < newItems.length; i++) {
            newItems[i].sort = i + 1;
        }
        props.onChange(newItems);
    }

    return (
        <div>
            {
                props.items && props.items.map((item, i) => {
                    return <div key={item.id}>
                        {item.element === 0 && <EditText item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 1 && <EditSingleLine item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 2 && <EditMultiLine item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 3 && <EditRadioButtons item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 4 && <EditCheckBox item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 5 && <EditFileUpload item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 6 && <EditDateTime item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                        {item.element === 7 && <EditScore item={item} onChange={changeItem} onDelete={() => { deleteItem(item) }} onChangeSort={changeSort} />}
                    </div>
                })
            }
            <Menu id="menu" control={<Button fullWidth variant="contained" color="primary" onClick={(event) => { setAnchorEl(event.currentTarget); }}>Toevoegen element</Button>
            } >
                <MenuItem onClick={() => { insertElement(0) }} >Tekstblok</MenuItem>
                <MenuItem onClick={() => { insertElement(1) }} >Invoer tekst - enkele regel</MenuItem>
                <MenuItem onClick={() => { insertElement(2) }} >Invoer tekst - meerdere regels</MenuItem>
                <MenuItem onClick={() => { insertElement(3) }} >Selectie - één antwoord mogelijk</MenuItem>
                <MenuItem onClick={() => { insertElement(4) }} >Selectie - meerdere antwoorden mogelijk</MenuItem>
                <MenuItem onClick={() => { insertElement(5) }} >Bestanden aanleveren</MenuItem>
                <MenuItem onClick={() => { insertElement(6) }} >Datum/tijd selectie</MenuItem>
                <MenuItem onClick={() => { insertElement(7) }} >Score</MenuItem>
            </Menu>
        </div>
    )
}
