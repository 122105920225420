import { registerPlugin } from '@capacitor/core';

import type { SecurePlugin } from './definitions';

const Secure = registerPlugin<SecurePlugin>('Secure', {
  web: () => import('./web').then(m => new m.SecureWeb()),
});

export * from './definitions';
export { Secure };
