import React, { useState, useEffect,useContext } from 'react'

import {Context} from '../../../../AppContext';
import FilesService from "../../../../services-admin/files";

import {Dialog,DialogContent, DialogActions, DialogTitle,Select,Button,Typography} from '../../../ui/core';

export default function MoveFile(props) {
    const context = useContext(Context)
    const [levels, setLevels] = useState([])
    const [selectedLevel, setSelectedLevel] = useState(window.localStorage.getItem('moveto')|| null);

    const moveItem = () => {
        if(selectedLevel){
            window.localStorage.setItem('moveto',selectedLevel);
            FilesService.moveItem(props.version, props.access, props.level, props.file.filename,props.version, props.access,selectedLevel)
            .then(result => {
                props.onRefresh();
                props.onClose();
            }, (err) => {
                context.setMessage('Verplaatsen mislukt, controleer of het document al niet bestaat op de nieuwe locatie')
                props.onRefresh();
                props.onClose();
            })
        }
        
    }
    useEffect(() => {
        FilesService.getLevels(props.version, props.access)
            .then(result => { setLevels(result.sort()) }, err => { })
    }, [])
    return (
        <Dialog onClose={props.onClose}>
            <DialogTitle ><Typography component="h2">Verplaatsen van {props.file.filename}</Typography></DialogTitle>
            <DialogContent  >
                Waar wilt u dit bestand naartoe verplaatsen?<br/><br/>
                    <Select
                        label="Niveau"
                        id="Level"
                        value={selectedLevel || ''}
                        onChange={(ev) => { setSelectedLevel(ev.target.value) }}
                    >
                        {levels.map((level) => { return <option value={level} key={level}>{level}</option> })}

                    </Select>
              
            </DialogContent>
            <DialogActions  >
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={moveItem}  disabled={!selectedLevel}>Verplaats</Button>
            </DialogActions>
        </Dialog>
    )
}
