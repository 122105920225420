import API from "./api";
const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get('installations').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    toggleLock: (installation) => {
        return new Promise((resolve, reject) => {
            API.post('installations/'+installation._id+'/lock', null, {}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    delete: (installation) => {
        return new Promise((resolve, reject) => {
            API.delete('installations/'+installation._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;