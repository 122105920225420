import React, { useState, useContext, useEffect } from 'react'
import { Context } from "../../../AppContext";
import UserService from "../../../services-admin/users";
import ThemesService from "../../../services-admin/themes";
import GeneralService from '../../../services/general';
import Edit from './edit';
import Import from './import';
import { Fab, List, ListItem, ListItemText, SearchBar, ListItemIcon, Menu, MenuItem, Typography } from '../../ui/core';
import { Add, Person } from '../../ui/coreIcons';

export default function UsersIndex(props) {
    const context = useContext(Context);
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [userSubscriptions, setUserSubscriptions] = useState([])
    const [themes, setThemes] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState();
    const [showImport, setShowImport] = useState();

    useEffect(() => {
        getUsers()
        getThemes()
    }, [])

    const hasRightToUser = (userAccessList, adminAccessList) => {
        if(!userAccessList) return true;
        if(userAccessList.length===0) return true;
        for (let i = 0; i < userAccessList.length; i++) {
            if (adminAccessList.indexOf(userAccessList[i]) >= 0) {
                return true;
            }
        }
        return false;
    }

    const getUsers = () => {
        UserService.get()
            .then(users => {
                let newUsers = GeneralService.sortJSON(users, 'Name', '123');
                if (context.info.User.FileAccesslistUser && context.info.User.FileAccesslistUser.length > 0) {
                    newUsers = newUsers.filter(user => {if(context.info.User.Email === user.Email){return true}; return hasRightToUser(user.Accesslist, context.info.User.FileAccesslistUser) })
                }
                setUsers(newUsers);
                setFilteredUsers(newUsers)
            }, (err) => { })

        UserService.getSubscriptions()
            .then(users => {
                let newUsers = GeneralService.sortJSON(users, 'Name', '123');
                if (context.info.User.FileAccesslistUser && context.info.User.FileAccesslistUser.length > 0) {
                   newUsers = newUsers.filter(user => { return hasRightToUser(user.RequestAccess, context.info.User.FileAccesslistUser) })
                }
                setUserSubscriptions(newUsers);
            }, (err) => { })

    }

    const getThemes = () => {
        ThemesService.get()
            .then(themes => {
                setThemes(themes);
            }, (err) => { })
    }

    const updateUser = (user) => {
        return new Promise((resolve, reject) => {
            if (!user.Accesslist) { user.Accesslist = [] }
            if (!user.FileAccesslist) { user.FileAccesslist = [] }
            UserService.update(user)
                .then(users => {
                    getUsers();
                    setSelectedUser();
                    resolve();
                }, (err) => {
                    reject();
                })
        })

    }

    const selectUser = (user) => {
        let tmpUser = Object.assign({}, user);
        if (!tmpUser.Accesslist) { tmpUser.Accesslist = [] }
        if (!tmpUser.FileAccesslist) { tmpUser.FileAccesslist = [] }
        setSelectedUser(tmpUser)
    }

    const deleteUser = (user) => {
        if (window.confirm("Weet u zeker dat u deze gebruiker wilt verwijderen?")) {
            UserService.deleteUser(user)
                .then(users => {
                    getUsers();
                    setSelectedUser();
                }, (err) => {
                    console.log('Error verwijderen', err)
                })
        }
    }

    const filterList = (search) => {
        let newList = users.filter((item => { return JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) >= 0 }));
        setFilteredUsers(newList);
    }

    const downloadCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        users.forEach(function (user) {
            let row = '"' + (user.Email || '').replace(/,/g, '') + '","' + (user.Name || '').replace(/,/g, '') + '","' + (user.Accesslist || []).join(';').replace(/,/g, '') + '"';
            csvContent += row + "\r\n"; // add carriage return
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "gebruikers.csv");
        document.body.appendChild(link);
        link.click();
    }

    const sessionReset = (user) =>{
      console.log('Reset session user',user)
        UserService.deleteSessions(user)
        .then(result => {
           alert("Openstaande sessies zijn verwijderd")
        }, (err) => {
            console.log('Error reset sessions', err)
        })
    }

    return (
        <div>
            {selectedUser && <Edit user={selectedUser} themes={themes} onUpdate={updateUser} onClose={() => { setSelectedUser(null) }} onSave={updateUser} onDelete={deleteUser} onSessionReset={sessionReset} />}
            {showImport && <Import onClose={() => { getUsers(); setShowImport(false) }} />}
            {userSubscriptions.length > 0 && <><Typography variant='h3' ><div style={{ padding: '8px' }}>Nieuwe aanvragen</div></Typography> <List >
                {userSubscriptions.map((user, i) => {
                    return <ListItem key={user._id} button onClick={() => { selectUser(user) }}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText id={user._id} primary={user.Name} secondary={user.Email} />
                        <ListItemIcon><div className="label">{i + 1}</div></ListItemIcon>
                    </ListItem>
                })}
            </List></>}
            <SearchBar value={search} onKeyUp={filterList} onReset={() => filterList('')} />
            <Menu id="newuser" control={<Fab color="primary" style={{ position: 'fixed', right: '15px', bottom: '35px' }}><Add color="white" /></Fab>}>
                <MenuItem onClick={() => { setShowImport(true) }}>Importeren</MenuItem>
                <MenuItem onClick={() => { downloadCSV(true) }}>Exporteren</MenuItem>
                <MenuItem onClick={() => { setSelectedUser({ Accesslist: [], FileAccesslist: [], Rechten: '', _id: '0' }) }} >Aanmaken</MenuItem>
            </Menu>
            {/*!selectedUser && <Fab onClick={() => { setSelectedUser({Accesslist:[],FileAccesslist : [],Rechten:'',_id:'0'}) }} edge="end" color="primary" aria-label="add" style={{ position: 'fixed', right: '15px', bottom: '15px', zIndex: '99999' }}><AddIcon /></Fab>*/}
            <List >
                {filteredUsers.map((user, i) => {
                    return <ListItem key={user._id} button onClick={() => { selectUser(user) }}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText id={user._id} primary={user.Name} secondary={user.Email} />
                        <ListItemIcon><div className="label">{i + 1}</div></ListItemIcon>
                    </ListItem>
                })}
            </List>
        </div>
    )
}
