import APIV2 from "./apiv2";

const Service = {
    parseDeprecatedAnnouncments :(announcements)=>{
        return announcements.map(ann=>{
            let tmpAnn = ann;
            if(ann.ID){
                tmpAnn._id=ann.ID;
                tmpAnn.access = ann.access;
                tmpAnn.date = ann.Date;
                tmpAnn.description = ann.Description;
                tmpAnn.title = ann.Title;
            }   
            return tmpAnn;
        
        })
    },
    get: () => {
        return new Promise((resolve, reject) => {
            APIV2.post('announcement',null,{}).then(
                announcements => {
                     resolve(Service.parseDeprecatedAnnouncments(announcements))
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    markRead:(id)=> {
        return new Promise((resolve, reject) => {
            APIV2.post('announcement/'+id+'/read',null,{}).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;