import React, { useState, useContext, useEffect } from 'react'
import { TextField, Button, Paper, TextArea, CheckBox } from '../ui/core';
import { Context } from '../../AppContext';
import ServiceUser from '../../services/user';
import FirebaseService from "../../services/firebase";
import { v4 as uuidv4 } from 'uuid';
import AppSettings from '../../AppSettings';
import { Browser } from '../../plugins/capacitor';
import { ContextUI } from '../../uiContext';
export default function Login(props) {
    const styleCard = { margin: '0 auto', marginTop: '20px', maxWidth: '500px', width: '95%' }
    const context = useContext(Context);
    const contextUI = useContext(ContextUI)
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [funct, setFunct] = useState('');
    const [org, setOrg] = useState('');
    const [remark, setRemark] = useState('')
    const [password, setPassword] = useState('');
    const [subscribeForAccess, setSubscribeForAccess] = useState([]);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);

    const [codeSend, setCodeSend] = useState(false);
    const [recievedCode, setRecievedCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [strongPasswordOK, setStrongPasswordOK] = useState(false)
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    const validEmailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const [ssoCalled, setSsoCalled] = useState(false)

    //for sso
    const [key, setKey] = useState(uuidv4());
    const [secret, setSecret] = useState(uuidv4())


    useEffect(() => {
        if (strongPassword.test(newPassword)) {
            setStrongPasswordOK(true);
        } else {
            setStrongPasswordOK(false);
        }

    }, [newPassword])

    const completeLogin = (tokens) => {
        if (tokens) {
            AppSettings.setToken(tokens);
            ServiceUser.saveTokens(tokens);
        }
        context.checkInfo(true, true);
        FirebaseService.initToken();
        context.setLoading(false);
        props.history.push('/index');
    }

    const handleSubmit = () => {
        context.setLoading(true);
        let usePassword = password;
        if (newPassword.length > 0) {
            usePassword = newPassword;
        }
        ServiceUser.login(username, usePassword)
            .then((result) => {
                if (result.refresh_token && result.access_token) {

                    context.setLoading(false);
                    completeLogin(result);
                } else {
                    context.setLoading(false);
                    context.setMessage('Het inloggen is mislukt. Controleer uw gegevens');
                }
            }, (err) => {
                console.log(err)
                context.setLoading(false);
                context.setMessage('Het inloggen is mislukt. Controleer uw gegevens');
            })
    }

    const requestCode = () => {
        context.setLoading(true);
        if (username.indexOf('@') > 0) {
            ServiceUser.requestNewPasswordToken(username)
                .then((result) => {
                    context.setLoading(false);
                    setCodeSend(true);
                }, (err) => {
                    context.setMessage('Het opvragen van een token is mislukt');
                    context.setLoading(false);
                    setCodeSend(false);
                })
        } else {
            context.setLoading(false);
            context.setMessage('De door uw opgegeven E-mailadres is geen geldig adres')
        }
    }
    const createPassword = () => {
        context.setLoading(true);
        if (recievedCode.length > 0 && newPassword.length >= 6) {
            ServiceUser.changePassword(username, newPassword, recievedCode)
                .then((result) => {
                    handleSubmit();
                }, (err) => {
                    context.setLoading(false);
                    context.setMessage('Het wijzigen van uw wachtwoord is mislukt')
                })
        } else {
            context.setLoading(false);
            context.setMessage('Niet alle velden zijn volledig ingevuld')
        }
    }

    const doSSO = (url) => {
        let parsedURL = url.replace('[platform]', AppSettings.getPlatform()).replace('[key]', key).replace('[secret]', secret);
        //let opener = window.open(parsedURL);
        let opener;
        if (Browser) {

            Browser.open({ url: parsedURL });
            console.log('Opening with browser plugin')
        } else {
            opener = window.open(parsedURL);
            console.log('Opening with default')
        }
        checkProgressSSO(opener, 0);
    }

    const closeBrowser = (opener) => {
        if (Browser) {

            Browser.close();
        } else {
            try {
                opener.close();
            } catch (ex) {
                console.log(ex)
            }

        }
    }

    const checkProgressSSO = (opener, count) => {
        ServiceUser.checkSSO(key, secret, AppSettings.getPlatform())
            .then(tokens => {
                closeBrowser(opener);
                completeLogin(tokens);
            })
            .catch(err => {
                if (count < 40) {
                    setTimeout(() => checkProgressSSO(opener, count + 1), 2000);
                } else {
                    context.setMessage('De inlogsessie is verlopen, klik op de knop om opnieuw in te loggen')
                }
            })
        /*
            if (context.info && context.info.Settings && context.info.Settings.SSCOCheckTokenURL) {
                //new process
                axios.get(context.info.Settings.SSCOCheckTokenURL.replace('[platform]', AppSettings.getPlatform()).replace('[key]', key).replace('[secret]', secret)) //, { withCredentials: true }
                    .then(res => {
                        let token = res.data;
                        if(typeof res.data === 'string'){
                            token = JSON.parse(res.data);
                        }
                        if (!token.refresh_token) {
                            context.setMessage('Het inloggen is mislukt')
                        } else {
                            closeBrowser(opener);
                            completeLogin(token);
                        }
                    }, err => {
                        if (count < 40) {
                            setTimeout(() => checkProgressSSO(opener, count + 1), 2000);
                        } else {
                            context.setMessage('De inlogsessie is verlopen, klik op de knop om opnieuw in te loggen')
                        }
                    })
            } else {
                //old process
                if (localStorage.getItem('accesskey') !== null && localStorage.getItem('accesskey') !== '0') {
                    closeBrowser(opener);
                    completeLogin();
                } else {
                    if (count < 40) {
                        setTimeout(() => checkProgressSSO(opener, count + 1), 2000);
                    } else {
                        context.setMessage('De inlogsessie is verlopen, klik op de knop om opnieuw in te loggen')
                    }
                }
            }

        */
    }

    const isLoginDisabled = () => {
        if (context.info.Settings.disabledefaultlogin === true) {
            if (context.info.Settings['disabledefaultlogin' + AppSettings.getPlatform()] === true) {
                return true;
            }
        }
        return false;

    }

    const subscribe = () => {
        ServiceUser.subscribe({
            Name: name,
            Email: username.trim().toLowerCase(),
            Functie: funct,
            Organisatie: org,
            RequestAccess: subscribeForAccess,
            Opmerking: remark
        })
            .then(result => {
                contextUI.setMessage("Bedankt voor uw aanmelding, deze zal z.s.m. worden verwerkt.");
                setShowSubscription(false);
            })
            .catch(ex => {
                contextUI.setMessage("Er is iets mis gegaan bij het versturen, probeer het later opnieuw.")
            })
    }

    const handleChangeAccess = (access) => {
        let tmpObj = Object.assign([], subscribeForAccess);
        let found = tmpObj.find(o => { return o == access });
        if (!found) {
            tmpObj.push(access);
        } else {
            tmpObj = tmpObj.filter(o => { return o !== access })
        }

        setSubscribeForAccess(tmpObj);
    }

    useEffect(() => {
        if (props.location.pathname === '/login' && context.info && context.info.Settings && context.info.Settings.SSOURL && ssoCalled === false) {
            setSsoCalled(true);
            doSSO(context.info.Settings.SSOURL);
        }
    }, [context.info])

    return (
        <div className="padding">
            <div style={styleCard}  >
                <Paper>
                    <div style={{ padding: '20px' }}>
                        {context.info && context.info.Theme && context.info.Theme.logo && <div style={{ textAlign: 'center', paddingBottom: '10px' }}><img src={context.info.Theme.logo} style={{ width: '270px' }} /></div>}

                        {!showNewPassword && !showSubscription && <div>

                            <div style={{ textAlign: 'center' }}>{context.info.Settings.Logintext || 'Voor het ophalen van afgeschermde content kunt u hier inloggen'}</div>

                            <br />
                            {context.info.Settings.SSOURL && window.disableSSOURL !== true &&
                                <>
                                    <Button variant="outlined" color="primary" style={{ width: '100%' }} onClick={() => { doSSO(context.info.Settings.SSOURL) }}>{context.info.Settings.SSOLoginbuttonText}</Button>
                                    {context.info.Settings && !isLoginDisabled() && <div style={{ textAlign: 'center', paddingTop: '10px' }}> - OF - </div>}
                                </>
                            }

                            <br />

                            {(context.info.Settings && !isLoginDisabled()) && <>
                                <form onSubmit={() => { handleSubmit() }}>
                                    <TextField label='Gebruikersnaam / e-mailadres' style={{ width: '100%', backgroundColor: 'white' }}
                                        value={username}
                                        id='username'
                                        autoComplete="username"
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <br /><br />
                                    <TextField label='Wachtwoord' style={{ width: '100%', backgroundColor: 'white' }}
                                        type='password'
                                        value={password}
                                        id='password'
                                        autoComplete="current-password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { handleSubmit() } }}
                                    />
                                </form>

                                <br />
                                <Button style={{ width: '100%' }} variant="outlined" color="primary" onClick={() => { handleSubmit() }} disabled={context.loading}>Inloggen</Button>
                                <br style={{ clear: 'both' }} /><br style={{ clear: 'both' }} />


                                {(context.info && context.info.Settings && context.info.Settings.CanRequestPassword) && <Button style={{ width: '100%' }} variant="text" onClick={() => { setUsername(''); setShowNewPassword(true) }}>(Nieuw) wachtwoord aanmaken </Button>}

                                {(context.info && context.info.Settings && context.info.Settings.showSubscribe) && <Button style={{ width: '100%' }} variant="text" onClick={() => { setUsername(''); setShowSubscription(true) }}>Aanmelden</Button>}
                                {(context.info && context.info.Settings && context.info.Settings.PrivacyPolicy) && <Button style={{ width: '100%' }} variant="text" onClick={() => { props.history.push('privacy') }}>Privacy verklaring</Button>}

                            </>}

                            <br />

                        </div>

                        }

                        {showNewPassword && <div>

                            Hier kunt u een (nieuw) wachtwoord aanmaken. Vul hieronder uw E-mailadres, indien u bekend bent in het systeem ontvangt u een code waarmee u een nieuw wachtwoord kan opgeven.
                            <TextField
                                id="sendemail"
                                label="E-mailadres"
                                value={username || ''}
                                onChange={(ev) => { setCodeSend(false); setUsername(ev.target.value) }}
                                type="email"
                                fullWidth
                                required
                            /> <br /><br />
                            {!codeSend && <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={requestCode} disabled={context.loading}>Opvragen code</Button>}

                            {codeSend && <React.Fragment>
                                <br /> <br /> <i>De code is verstuurd naar E-mailadres <b>{username}</b> indien u bekend bent in het systeem. Mocht u geen mail ontvangen, controleer dan de junk/spam folder of neem contact op met de beheerder.</i><br />
                                <TextField
                                    id="recievedCode"
                                    label="Ontvangen code"
                                    value={recievedCode || ''}
                                    onChange={(ev) => { setRecievedCode(ev.target.value) }}
                                    margin="normal"
                                    fullWidth
                                    required
                                />
                                <TextField
                                    id="newPassword"
                                    label="Uw (nieuw) wachtwoord"
                                    value={newPassword || ''}
                                    onChange={(ev) => { setNewPassword(ev.target.value) }}

                                    margin="normal"
                                    fullWidth
                                    type="password"
                                    helper="Minimaal 8 karakters waarvan minimaal 1 hoofdletter, 1 kleine letter, 1 getal en een speciaal karakter"
                                    required
                                /><br /><br />
                                <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={createPassword} disabled={context.loading || recievedCode.length === 0 || strongPasswordOK === false}>Inloggen</Button>
                            </React.Fragment>}
                        </div>}

                        {showSubscription && <div>
                            {context.info && context.info.Settings && context.info.Settings.subscribeIntro && <div style={{ whiteSpace: 'pre' }}>{context.info.Settings.subscribeIntro}<br /><br /></div>}

                            <TextField
                                id="name"
                                label="Naam"
                                value={name || ''}
                                onChange={(ev) => { setName(ev.target.value) }}
                                type="text"
                                fullWidth
                                required
                            /> <br />
                            <TextField
                                id="sendemail"
                                label="E-mailadres"
                                value={username || ''}
                                onChange={(ev) => { setUsername(ev.target.value) }}
                                type="email"
                                fullWidth
                                required
                            />
                            <TextField
                                id="org"
                                label="Organisatie"
                                value={org || ''}
                                onChange={(ev) => { setOrg(ev.target.value) }}
                                type="text"
                                fullWidth
                            />
                            <TextField
                                id="funct"
                                label="Functie"
                                value={funct || ''}
                                onChange={(ev) => { setFunct(ev.target.value) }}
                                type="text"
                                fullWidth
                            />
                            {context.info && context.info.Settings && context.info.Settings.subscribeAccess && <>
                                <br /><br /> <div className='label'>Aanvraag toegang </div>
                                {context.info.Settings.subscribeAccess.map(access => {
                                    if (!access.access) return null;
                                    if (!access.name) return null;
                                    if (access.access.trim().length === 0) return null;
                                    if (access.name.trim().length === 0) return null;
                                    return <CheckBox key={access.access} id={access.access} value={access.access} label={access.name} checked={subscribeForAccess.indexOf(access.access) >= 0} onClick={() => handleChangeAccess(access.access)}> </CheckBox>
                                })}
                            </>}
                            <TextArea
                                id="remark"
                                label="Opmerking/toelichting"
                                value={remark || ''}
                                onChange={(ev) => { setRemark(ev.target.value) }}
                                type="text"
                                fullWidth>

                            </TextArea>
                            <br />   <br />
                            {<Button style={{ width: '100%' }} variant="contained" color="primary" onClick={subscribe} disabled={context.loading || !name || !username.match(validEmailRegex || subscribeForAccess.length === 0)}>Aanmelden</Button>}
                            {<Button style={{ width: '100%' }} variant="outlined" color="primary" onClick={() => { setShowSubscription(false) }}  >Terug</Button>}
                        </div>}
                    </div>
                </Paper>
            </div>

        </div>
    )
}
