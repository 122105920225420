import React, { useEffect, useState } from 'react'

import FeedbackService from "../../../services-admin/feedback";
import Preview from './preview';
import GeneralService from "../../../services/general";
import { CheckBox, Fab, List, ListItem, ListItemIcon, ListItemText } from '../../ui/core';

export default function Feedback() {
    const [items, setItems] = useState([]);
    const [item, setItem] = useState();

    const getItems = () => {
        FeedbackService.get()
            .then(result => {
                setItems(GeneralService.sortJSON(result, 'date', '321'));
            })
    }
 
    const deleteItem = (delItem) => {
        FeedbackService.deleteItem(delItem._id)
            .then(result => {
                getItems();
                setItem();
            })
    }

    useEffect(() => {
        getItems();
    }, [])

    return (
        <div>
            {item && <Preview item={item} onClose={() => { setItem(null) }} onDelete={deleteItem} />}
            {items.length===0 && <div style={{width:'100%',fontStyle:'italic',color:'gray',textAlign:'center'}}><br /><br />Er is nog geen feedback ontvangen.</div>}
             <List >
                {items.map((item) => {
                    return <ListItem key={item._id} button onClick={() => { setItem(item) }}>
                        <ListItemText id={item._id} primary={item.name} secondary={GeneralService.formatDate(item.date, true)} />
                    </ListItem>
                })}
            </List>
        </div>
    )
}
