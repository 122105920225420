import React from 'react'
import {  Paper } from '../../ui/core';
export default function Textblock({ item }) {
    return (
        <Paper className="padding" style={{ marginBottom: '6px',whiteSpace:'pre-line' }}>
            {item.textTitle && <h1>{item.textTitle}</h1>}<br/>
            {item.text && <div>{item.text}</div>}
        </Paper>
    )
}
