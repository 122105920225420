import React, { useEffect } from 'react'
import { TextField, Paper,RadioButton,Select,MenuItem} from '../../ui/core';
export default function RadioButtonItem({ item, onChange }) {

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br /><br />

            {item.showDropdown ?
                <Select
                id="selectbutton"
                value={item.answer || ''}
                onChange={(ev) => { changeItem(ev.target.value, 'answer') }}
                fullWidth
              >
                   {item.options && item.options.map((option) => {
                        if (option.text.length > 0) {
                            return <MenuItem  key={option.id} value={option.text }>{option.text}</MenuItem>
                        }else{
                            return null
                        }
                   })}
              </Select>:
                <table style={{ width: '100%' }}><tbody>
                    {item.options && item.options.map((option) => {
                        if (option.text.length > 0) {
                            return <tr key={option.id}><td style={{verticalAlign:'top' }}><RadioButton id={option.text} checked={option.text === item.answer} onChange={(ev) => { changeItem(option.text, 'answer') }} /><br/><br/></td><td style={{ width: '95%',verticalAlign:'top' }} onClick={(ev) => { changeItem(option.text, 'answer') }} >{option.text}<br/><br/></td></tr>

                        } else {
                            return null;
                        }
                    })}
                </tbody></table>
            }

        </Paper>
    )
}
