import React from 'react'
//todo create tooltip
const  Tooltip = (props)=> {
    //props.title
    //props.placement  : top ; bottom
    return (
        <>
            {props.children}
        </>
    )
}
export { Tooltip };