
import APIServiceV2 from "./apiv2";
import mime from 'mime';
import AppSettings from '../AppSettings';
import EncryptionService from './encryption';
import { Browser } from '@capacitor/browser';
import StorageService from '../services/storage';
import { Network, Secure, App } from '../plugins/capacitor';
const locationAPI = AppSettings.getLocationAPIV2();

const Service = {
    getDownloadURL: (DPath, download) => {//new function jan 2022
        return new Promise((resolve, reject) => {
            APIServiceV2.get('downloadtoken/?' + DPath)
                .then((token) => {
                    resolve(locationAPI + 'document/' + token + '/?file=' + DPath + (download ? '&download=true' : ''));
                }, (err) => { 
                    reject(err)
                })
        });
    },
    openInBrowser: (DPath, download) => {
        return new Promise((resolve, reject) => {
            Service.getDownloadURL(DPath, download)
                .then((downloadURL) => {
                    Browser.open({ url: downloadURL });
                    resolve();
                })
                .catch((err) => {
                    reject();
                })
        })
    },
    viewFile: ( DPath, storageFileName, fileName, download) => { //new function jan 2022
        //get token
        return new Promise((resolve, reject) => {

            if (AppSettings.getPlatform() === 'web') {
                Service.openInBrowser(DPath, download).then(result => { resolve() }).catch(err => { reject() });

            } else {
                //check local file
                StorageService.open(DPath, storageFileName, fileName)
                    .then(result => {
                        resolve();
                    })
                    .catch(err => {
                        //download file and open
                        Service.getDownloadURL(DPath, true)
                            .then((downloadURL) => {
                                Secure.getSecureKey()
                                    .then((retSecret) => {
                                        StorageService.downloadAndSaveEncrypted(downloadURL, storageFileName, retSecret.value)
                                            .then(result => {
                                                StorageService.open(DPath, storageFileName, fileName)
                                                    .then(result => {
                                                        resolve();
                                                    })
                                                    .catch(err => {
                                                        Service.openInBrowser(DPath, download).then(result => { resolve() }).catch(err => { reject() });
                                                    })

                                            })
                                            .catch((err) => {
                                                Service.openInBrowser(DPath, download).then(result => { resolve() }).catch(err => { reject() });
                                            })
                                    })
                            })
                            .catch((err) => {
                              
                               reject();
                            })
                    })
            }

        })

    },
    getContent: (DPath, fileName, decrypt) => {
        return new Promise((resolve, reject) => {
            StorageService.readFile(StorageService.documentsDir(), fileName, decrypt)
                .then(fileContent => {
                    resolve(fileContent);
                })
                .catch(ex => {
                    Service.getContentOnline(DPath)
                        .then(content => {
                            resolve(content);
                        })
                        .catch(ex => {
                            reject();
                        })
                })
        })
    },
    getContentOnline: (DPath) => {  //adjust jan 2022
        return new Promise((resolve, reject) => {
            Service.getDownloadURL(DPath, true)
                .then((downloadURL) => {
                    APIServiceV2.get(downloadURL).then(
                        result => {
                            resolve(result);
                        },
                        error => {
                            reject(error);
                        }
                    );
                })
        });
    },
    getMimeType: (filename) => {
        var ext = '';
        var filesplit = filename.split('.');
        if (filesplit.length > 0) {
            ext = filesplit[filesplit.length - 1];
        }
        const foundMime = mime.getType(ext.toLowerCase());
        if (foundMime === false) {
            return 'application/octet-stream';
        } else {
            return mime.getType(ext.toLowerCase());
        }

    },
    uploadFile: (file, dataURL, progress) => {
        const url = AppSettings.getLocationDocumentAPI() + 'upload';
        return new Promise((resolve, reject) => {
            let fileType = file.name.split('.').pop().toLowerCase();
            var fileName = '' + (new Date().getTime()) + '-' + file.name.toLowerCase().replace('.' + fileType, '');

            fileName = fileName + '.' + fileType;
            const formData = new FormData();

            if (dataURL) {
                formData.append('base64file', dataURL);
            } else {
                formData.append('file', file, fileName);
            }
            formData.append('filename', fileName);

            let prepHeaders = {};
            prepHeaders['uuid'] = AppSettings.getUUID();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            Object.keys(prepHeaders).forEach(function (key) {
                xhr.setRequestHeader(key, prepHeaders[key]);
            })

            xhr.upload.onprogress = function (e) {
                if (e.lengthComputable) {
                    var percentComplete = Math.round((e.loaded / e.total) * 100);
                    progress(percentComplete);
                }
            };

            xhr.onload = function () {
                progress(100);
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr);
                }
            }

            xhr.onerror = function () {
                reject();
            }

            xhr.send(formData);
        })
    },
    encodeUnicode: (str) => {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    },
    decodeUnicode: (str) => {
        try {
            return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        } catch (ex) {
            return atob(str);
        }
    }, fileToBase64: (file, width) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                if (!width) {
                    resolve(reader.result);
                } else {
                    try{
                        console.log('creating canvas')
                        var canvas = document.createElement('canvas');
                        var img = new Image();
                        img.src = reader.result;
                        img.onload = () => {
                            try{
                                canvas.width = width;
                                canvas.height = canvas.width * img.height / img.width;
                                var context = canvas.getContext('2d');
                                context.drawImage(img, 0, 0, canvas.width, canvas.height);
                                resolve(canvas.toDataURL('image/jpeg', 0.5));
                            }catch(ex){
                                console.log('Error: ', ex);
                                reject(ex);
                            }
                           
                        }
                    }catch(ex){
                        console.log('Error: ', ex);
                        reject(ex);
                    }
                   
                };
                reader.onerror = function (error) {
                    reject(error);
                    console.log('Error: ', error);
                };
            }
        })
    },
}
export default Service;