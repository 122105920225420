/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import FileService from '../../services/file';
import StorageService from '../../services/storage';
import FormPopup from '../forms/fillinformpopup';
import {List,ListItem,ListItemText} from '../ui/core';

export default function Form(props) {
    const [form, setForm] = useState({});
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (props.id) {
            //readStorage();
            FileService.getContent(props.file.DPath, StorageService.getStorageFileName(props.file), true)
            .then(content => {
                setForm((typeof content === 'string') ? JSON.parse(content) : content);
            })
            .catch(err => {
                console.log(err)
            })
        }
    }, [])
/*
    const readOnline = () => {
        FileService.getContentOnline(props.id).then((result) => {
            setForm(result);
        }, (err) => { console.error(err) })
    }

    const readStorage = () => {
        StorageService.readFile(StorageService.documentsDir(), StorageService.getStorageFileName(props.file) )
            .then((result) => {
                try {
                    setForm(JSON.parse(result));
                } catch (ex) {
                    readOnline();
                }
            }, (err) => {
                readOnline();
            })
    } 
    */
    return (
        <React.Fragment>
            {showForm && <FormPopup form={form} onClose={()=>{setShowForm(false)}}/>}
            <List>
                <ListItem onClick={()=>{setShowForm(true)}}>
                <ListItemText primary= {props.file.Name.replace('.' + props.file.Extention, '')}></ListItemText>
                </ListItem>
                </List>
        </React.Fragment>
    )
}
