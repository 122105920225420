import React,{useState,useEffect,useContext} from 'react'
import { Button } from '../../ui/core';
import { Tabs } from '../../ui/tabs'
import ServiceSharepoint from '../../../services-admin/sharepoint';
import {ContextUI} from '../../../uiContext';
export default function Backup() {
    const contextUI = useContext(ContextUI);
    const [selectedTab,setSelectedTab] = useState(0);
    const [syncCopied,setSycCopied] = useState('')
    const [syncFailed,setSycFailed] = useState('') 
    const [syncDeleted,setSycDeleted] = useState('')
    useEffect(()=>{
        ServiceSharepoint.getlogs('backup','copied')
        .then(result=>{setSycCopied(result) },err=>{setSycCopied("Geen gegevens aanwezig") })
        ServiceSharepoint.getlogs('backup','failed')
        .then(result=>{setSycFailed(result) },err=>{setSycFailed("Geen gegevens aanwezig") }) 
        ServiceSharepoint.getlogs('backup','delete')
        .then(result=>{setSycDeleted(result.replace()) },err=>{ setSycDeleted("Geen gegevens aanwezig")})
    },[])
    const handleDelete = ()=>{
        ServiceSharepoint.backupDelete()
        .then(()=>{contextUI.setMessage("Bestanden zijn verwijderd.")})
    }
    return (
        <> 
            <Tabs value={selectedTab} onChange={setSelectedTab}>  
                <div className="ui-tab">GEKOPIEERD ({syncCopied.split("\n").length -1})</div>
                <div className="ui-tab">MISLUKT ({syncFailed.split("\n").length -1})</div> 
                <div className="ui-tab">TE VERWIJDEREN ({syncDeleted.split("\n").length -1})</div>
            </Tabs>
            <div className="padding" style={{whiteSpace:'pre-wrap'}}>
            {selectedTab === 2 && syncDeleted.split("\n").length >1 && <>
                    <Button onClick={handleDelete}>Verwijder documenten</Button><br/><br/>
                  
                </>}
            {selectedTab===0 && syncCopied}
            {selectedTab===1 && syncFailed} 
            {selectedTab===2 && syncDeleted}
            </div>
        </>
    )
}
