import React, { useEffect, useState } from 'react'
import FileService from '../../../services-admin/files';

import { TextField, CheckBox, Select, Button } from '../../ui/core';

export default function Settings(props) {
    const [settings, setSettings] = useState();
    const [files, setFiles] = useState([]);
    const [levels, setLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState('')

    useEffect(() => {
        setFiles(props.files);
    }, [props.files])

    useEffect(() => {
        const tmpLevels = props.levels.filter(level => { return level.indexOf(props.level) === 0 && level !== props.level && (props.level.split('/').length + 1) === level.split('/').length })
        setLevels(tmpLevels);
    }, [props.levels, currentLevel])

    useEffect(() => {
        setCurrentLevel(props.level);
    }, [props.level])
    useEffect(() => {
        FileService.getSettings(props.version, props.access, props.level)
            .then(result => {
                setSettings(result)
            }, (err) => {

            })
    }, [props.version, props.access, props.level]);

    const saveSort = (sort) => {
        let newSettings = Object.assign({}, settings);
        newSettings.Sort = sort;
        setSettings(newSettings);
        saveSettings(newSettings);
    }
    const saveItem = (attribute, value) => {
        let newSettings = Object.assign({}, settings);
        newSettings[attribute] = value;
        setSettings(newSettings);
        saveSettings(newSettings);
    }
    const saveURL = (url, saveOnServer) => {
        let newSettings = Object.assign({}, settings);
        newSettings.URL = url;
        setSettings(newSettings);
        if (saveOnServer) { saveSettings(newSettings); }
    }

    const saveForce = (levelForceDownload) => {
        let newSettings = Object.assign({}, settings);
        newSettings.levelForceDownload = levelForceDownload;
        setSettings(newSettings);
        saveSettings(newSettings);
    }

    const saveDownloadAddressbook = (val) => {
        let newSettings = Object.assign({}, settings);
        newSettings.downloadAddressbook = val;
        setSettings(newSettings);
        saveSettings(newSettings);
    }

    const saveSettings = (newSettings) => {
        FileService.saveSettings(props.version, props.access, props.level, newSettings)
            .then(result => { }, (err) => { })
    }

    const saveChangeAttribute = (attribute, item, checked) => {
        let newSettings = Object.assign({}, settings);
        if (!newSettings[attribute]) newSettings[attribute] = [];
        if (checked) {
            if (newSettings[attribute].indexOf(item) === -1) {
                newSettings[attribute].push(item);
            }
        } else {
            if (newSettings[attribute].indexOf(item) >= 0) {
                newSettings[attribute].splice(newSettings[attribute].indexOf(item), 1);
            }
        }
        setSettings(newSettings);
        saveSettings(newSettings);
    }


    const hasAttributeFile = (attribute, item) => {
        return (settings[attribute] && settings[attribute].indexOf(item) >= 0)
    }


    const deleteLevel = () => {
        var r = window.confirm("Weet u zeker dat u dit niveau en alle onderliggende nivea's en documenten wilt verwijderen?");
        if (r == true) {
            FileService.deleteLevel(props.version, props.access, props.level)
                .then(result => {
                    props.onRefresh()
                }, err => {
                    props.onRefresh()
                })
        } else {
        }

    }
    if (!settings) return null;
    return (
        <div className="padding">

            <TextField id="level" label="Naam niveau" value={currentLevel || ''} fullWidth onChange={(ev) => { setCurrentLevel(ev.target.value) }} onBlur={(ev) => { props.onChange(ev.target.value) }}></TextField>


            <br />  <br />
            <Select
                label="Sortering documenten"
                id="sort"
                value={settings.Sort || ''}
                onChange={(ev) => { saveSort(ev.target.value) }}
            >
                <option value='123'>alfabetisch oplopend</option>
                <option value='321'>alfabetisch aflopen</option>
            </Select>

            <br />

            <TextField id="URL" label="Dit niveau opent website" value={settings.URL || ''} placeholder="https://" fullWidth onChange={(ev) => { saveURL(ev.target.value) }} onBlur={(ev) => { saveURL(ev.target.value, true) }}></TextField>

            <br />  <br />
            <CheckBox id="levelForceDownload" label="Documenten in dit niveau verplicht downloaden op toestel" checked={settings.levelForceDownload || false} onChange={(ev) => { saveForce(ev.target.checked) }} value="checkedA" />

            {files.find(f => { return f.filename === 'adresboek.json' }) && <CheckBox id="downloadAddressbook" label="Mogelijkheid tot downloaden adresboek" checked={settings.downloadAddressbook || false} onChange={(ev) => { saveDownloadAddressbook(ev.target.checked) }} />}

            {files.length > 0 && files.filter(file => { return file.filename.split('.').pop() === 'html' }).length > 0 && <>
                <CheckBox id="showIndex" label="Toon inhoudsopgave" checked={settings.showIndex || false} onChange={(ev) => { saveItem('showIndex', ev.target.checked) }} value="checkedA" />
                <br /></>}
            {files.length > 0 && files.filter(file => { return file.filename.split('.').pop() === 'html' }).length > 0 && <React.Fragment>
                <div className="label">Tekstblokken die in/uitklapbaar zijn</div>

                {files.filter(file => { return file.filename.split('.').pop() === 'html' })
                    .map(file => {
                        return <CheckBox id={file.filename} key={file.filename} label={file.filename} checked={hasAttributeFile('toggleTextBlock', file.filename) || false} onChange={(ev) => saveChangeAttribute('toggleTextBlock', file.filename, ev.target.checked)} value="file" />
                    })}
                <br />
            </React.Fragment>}

            {levels.length > 0 && <React.Fragment>
                <div className="label">Verberg niveaus in overzicht</div>

                {levels.map(level => {
                    return <CheckBox id={level} key={level} label={level} checked={hasAttributeFile('hideLevels', level) || false} onChange={(ev) => saveChangeAttribute('hideLevels', level, ev.target.checked)} value="level" />
                })}
            </React.Fragment>}
            <br />
            {files.filter(file => { return ['html', 'note', 'json', 'form'].indexOf(file.filename.split('.').pop()) < 0 && file.filename !== 'logo.png' }).length > 0 && <React.Fragment>
                <div className="label">Verberg bestanden in overzicht</div>
                {files.filter(file => { return ['html', 'note', 'json', 'form'].indexOf(file.filename.split('.').pop()) < 0 && file.filename !== 'logo.png' })
                    .map(file => {
                        return <CheckBox id={file.filename} key={file.filename} label={file.filename} checked={hasAttributeFile('hideFiles', file.filename) || false} onChange={(ev) => saveChangeAttribute('hideFiles', file.filename, ev.target.checked)} value="file" />
                    })}
                <br />
            </React.Fragment>}

            {props.access == "publiek" &&
                files.filter(file => { return ['html', 'note', 'json', 'form'].indexOf(file.filename.split('.').pop()) < 0 && file.filename !== 'logo.png' }).length > 0 && <React.Fragment>
                    <div className="label">Verplicht inloggen bij downloaden</div>
                    {files.filter(file => { return ['html', 'note', 'json', 'form'].indexOf(file.filename.split('.').pop()) < 0 && file.filename !== 'logo.png' })
                        .map(file => {
                            return <CheckBox id={file.filename} key={file.filename} label={file.filename} checked={hasAttributeFile('loginNeeded', file.filename) || false} onChange={(ev) => saveChangeAttribute('loginNeeded', file.filename, ev.target.checked)} value="file" />
                        })}
                    <br />
                </React.Fragment>
            }

            {/*files.filter(file => { return ['html', 'note', 'json', 'form'].indexOf(file.filename.split('.').pop()) < 0 && file.filename !== 'logo.png' }).length > 0 && <React.Fragment>
                <div className="label">Bestand in de app niet versleutelen</div>
                {files.filter(file => { return ['html', 'note', 'json', 'form'].indexOf(file.filename.split('.').pop()) < 0 && file.filename !== 'logo.png' })
                    .map(file => {
                        return <CheckBox id={file.filename} key={file.filename} label={file.filename} checked={hasAttributeFile('noEncryptionFiles',file.filename) || false} onChange={(ev) => saveChangeAttribute('noEncryptionFiles',file.filename, ev.target.checked)} value="file" />
                    })}
                <br />
                </React.Fragment>*/}


            <Button variant={'text'} style={{ color: '#e40000' }} onClick={deleteLevel}>Verwijder niveau {currentLevel}</Button>
        </div>
    )
}
