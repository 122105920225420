/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'

import { Context } from '../../AppContext';
import { CheckBox, Typography } from '../../components/ui/core';
import UpdateStatus from '../index/updatestatus';
import UpdateService from '../../services/updateservice';
import FileService from '../../services/file';
import StorageService from '../../services/storage';
import Settings from "../../AppSettings";
const locationAPI = Settings.getLocationAPI();
export default function Updates(props) {
    const context = useContext(Context);
    const [listUpdateHistory, setListUpdateHistory] = useState([]);
    const [autoupdate, setAutoUpdate] = useState(localStorage.getItem("autoupdate") === 'true');
    const [downloadCell, setDownloadCell] = useState(localStorage.getItem("downloadcell") === 'true');

    const toggleAutoupdate = () => {
        let newVal = !(localStorage.getItem("autoupdate") === 'true');
        localStorage.setItem("autoupdate", newVal)
        setAutoUpdate(newVal);
        if (newVal) { context.checkInfo() }
    }

    const toggleDownloadCell = () => {
        let newVal = !(localStorage.getItem("downloadcell") === 'true');
        localStorage.setItem("downloadcell", newVal)
        setDownloadCell(newVal)
    }

    useEffect(() => {
        context.checkInfo();
    }, []);

    const viewFile = (file) => {
        if (file.Extention !== 'note' && file.Extention !== 'json' && file.Extention !== 'html' && file.Extention !== 'form') {
           
            context.setMessage('Bestand wordt geopend...');
            console.log(file.DPath, StorageService.getStorageFileName(file), file.Name)
            FileService.viewFile(file.DPath, StorageService.getStorageFileName(file), file.Name)
                .then(result => {
                 
                })
                .catch(err => {
                    context.setMessage('Om dit bestand te opnenen dient u eerst in te loggen.');
                    props.history.push('/settings?redirect=' + props.location.pathname);
    /*
                    if (Settings.getPlatform() === 'web') {
                        context.setMessage('Het bestand kan niet worden geopend.')
                    }else{
                        context.setMessage('Het bestand kan niet worden geopend.')
                        console.log(err);
                    }
                        */
                })
            /*
            UpdateService.getDownloadToken(file.DPath)
                .then(token => {
                    window.open(locationAPI + 'documentpreviewv3/' + token + '/?' + file.DPath, '_blank');
                })
                .catch(ex => {
                    window.open(locationAPI + 'documentpreview/?' + props.file.DPath, '_blank');
                    //context.setMessage('Er is een fout opgetreden bij het openen van het bestand.')
                })
                    */
        }
    }

 

    useEffect(() => {
        if (context.info.Index) {

            let tmpDates = [];
            for (let i = 0; i < context.info.Index.length; i++) {
                let dt = context.info.Index[i].Mdate.substr(0, 10);
                if (!tmpDates[dt]) {
                    tmpDates[dt] = [];
                }
            }
            let tmpList = [];
            let dates = Object.keys(tmpDates);
            let compare = (a, b) => {
                if (a < b) return 1;
                if (b < a) return -1;
                return 0;
            }
            dates = dates.sort(compare);
            dates.forEach(day => {
                let filterIndex = context.info.Index.filter((index) => { return index.Mdate.substr(0, 10) === day });
                let listIndex = [];
                for (let i = 0; i < filterIndex.length; i++) {
                    listIndex.push(<div key={filterIndex[i].DPath} style={{ paddingLeft: '20px' }} onClick={() => { viewFile(filterIndex[i]) }}>{filterIndex[i].Name}<br /><div style={{ fontSize: '10px', color: 'gray' }}>{filterIndex[i].Path}<br /><br /></div></div>);
                }
                tmpList.push(<div key={day}><br /> {day} <br />{listIndex} </div>)
            })
            setListUpdateHistory(tmpList);
        }

    }, [context.info])

    return (
        <div>
            <UpdateStatus />
            <div className="padding">
                {Settings.getPlatform() !== 'web' && <div>
                    <Typography component="h2">Instellingen</Typography>
                    <CheckBox id="instellingen" checked={autoupdate} onClick={() => { toggleAutoupdate() }} label='Automatisch bijwerken' />
                    <br />
                    <CheckBox id="bijwerken" checked={downloadCell} onClick={() => { toggleDownloadCell() }} label='Bijwerken met een mobiele dataverbinding (3G/4G/5G) automatisch starten' />
                
                    <br /><br />
                </div>
                }
                {(context.info.Index && context.info.Index.length > 0) ? <div>
                    <Typography component="h2">Update historie</Typography>
                    {
                        listUpdateHistory
                    }
                </div>
                    : <div style={{ width: '100%', fontStyle: 'italic', color: 'gray', textAlign: 'center' }} className="padding">
                        <br /><br /> Er zijn geen documenten aanwezig. Log eerst in om de updates in te zien.
                    </div>
                }
            </div>

        </div>
    )
}

