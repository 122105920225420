import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, TextArea, Select } from '../../ui/core';
import ServiceWebsites from '../../../services-admin/websites';
import FileUpload from './file-upload';
export default function EditWebsite({ site, onClose, onDelete }) {
    const [contentRef] = useState(React.createRef());
    const [website, setWebsite] = useState();

    useEffect(() => {
        ServiceWebsites.get(site).then((result) => { 
            if(!result.settings) result.settings = { security:1};
            result.originalName = result.name; 
            setWebsite(result);
        });
    }, [])

    const processFile = (ev) => {
        // setFiles(ev.target.files);
    }

    const handleSave = (websiteUpdate) => {  
        ServiceWebsites.update(websiteUpdate).then((result) => {
            let tmpSite = Object.assign({}, website);
            tmpSite.originalName = website.name;
            setWebsite(tmpSite);
        });
    }

    const validatePageName = (name) => {
        const regex = /[^a-zA-Z0-9_-]/g;
        return name.replace(regex, '').toLowerCase();
    }

    const handleChangePageName = (ev) => {
        let tmpSite = Object.assign({}, website);
        tmpSite.name = ev.target.value;
        tmpSite.name = validatePageName(tmpSite.name);
        setWebsite(tmpSite);
    }

    const handleChangeSecurity = (ev) => {
        let tmpSite = Object.assign({}, website);
        if (!tmpSite.settings) tmpSite.settings = {};
        tmpSite.settings.security = parseInt(ev.target.value);
        setWebsite(tmpSite);
        handleSave(tmpSite);
    }
    if (!website) return null;

    return (
        <Dialog fullScreen onClose={onClose}>
            <DialogTitle>
                <Typography component="h2">{website.name}</Typography>
            </DialogTitle>
            <DialogContent>
                <TextArea id="pagename" value={website.name} label="Pagina" onChange={handleChangePageName} onBlur={()=>handleSave(website)} />
                <br /><br />
                <Select id="security" label="Beveiliging" value={(website.settings && website.settings.security) || false} onChange={handleChangeSecurity}>
                <option value={0}>Openbaar</option>
                <option value={1}>Achter inlog</option>
            </Select>
            <br />
                <div className='label'>Volledige url</div>
                <a href={"https://" + window.location.hostname + '/websites/' + website.name + "/"} target="_blank" rel="noreferrer">{"https://" + window.location.hostname + '/websites/'}<b>{website.name}</b>/</a> <br /><br />

          
            <br/>
                <div className="label">Upload website documenten</div>
              
                <FileUpload website={website.name} onFinished={() => { }} />
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => { onDelete(site) }}>Verwijder</Button>
                <Button variant="contained" color="primary" onClick={()=>{setTimeout(onClose,1000)}} >Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
