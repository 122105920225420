
import React, { useEffect, useState,useContext } from 'react'
import { DialogTitle, Typography, Dialog, DialogContent, DialogActions, Button, List, ListItem, ListItemText, ListItemIcon,SearchBar, Tabs,Select } from '../../ui/core'

import { Context } from '../../../AppContext';
import ServiceFiles from '../../../services-admin/files';
import ServiceGeneral from '../../../services-admin/general';
import FilesBrowserFile from './files-browser-file';
export default function FileBrowser({ onClose, onSelectBase64 }) {
    const context = useContext(Context);
    const [contentRef] = useState(React.createRef()); 
    const [selectedTab, setSelectedTab] = useState(0);

    const [selectedVersion, setSelectedVersion] = useState('1');
    const [selectedAccess, setSelectedAccess] = useState();
    const [selectedLevel, setSelectedLevel] = useState();

    const [accessList,setAccessList]= useState([]);
    const [levels,setLevels] = useState([]);
    const [files,setFiles] = useState([]);

    useEffect(() => {
        ServiceFiles.getAccess()
        .then(result => {
            let tmpAccessList = [];
            result = result.sort();
            for (let i = 0; i < result.length; i++) {
                if (context.info.User.FileAccesslist && context.info.User.FileAccesslist.indexOf(result[i]) >= 0) {
                    tmpAccessList.push(result[i]);
                }
            } 
            setAccessList(tmpAccessList);
        }, err => { })
    }, [])


  

    useEffect(() => {
            if (selectedAccess) {
                ServiceFiles.getLevels(selectedVersion, selectedAccess)
                    .then(result => {
                        setLevels(result.sort());
    
                        if (context && context.info && context.info.Levels && !selectedLevel) {
                            let searchHPath = window.location.hash.replace('#/index/', '');
                            let found = context.info.Levels.find(level => { return level.HPath === searchHPath });
                            if (found) setSelectedLevel(found.Path.slice(0, found.Path.length - 1))
                        }
    
                    }, err => { })
            }
    }, [selectedAccess])


    const handleUpload = (file) => {
        ServiceFiles.fileToBase64(file, 1000)
            .then(result => {
                onSelectBase64(result);
                onClose()
            })
            .catch(ex => {

            })
    }
     
    useEffect(() => {
        if (selectedVersion && selectedAccess && selectedLevel) {
            ServiceFiles.get(selectedVersion, selectedAccess, selectedLevel)
                   .then(files => {
                       setFiles(files); 
                   }, err => {
                       setFiles([]); 
                   })
           }
    }, [selectedAccess, selectedLevel]);

    const processFile = (ev) => {
        handleUpload(ev.target.files[0]);
    }

    const handleSelectFile = (file)=>{
        ServiceFiles.getBase64FromDPath(file.DPath).then(result=>{
            onSelectBase64(result);
            onClose();
        },(err)=>{
            console.log(err)
        })
    }

    return (
        <Dialog fullScreen={true}>
            <DialogTitle><Typography component="h1">Bestanden</Typography></DialogTitle>
            <DialogContent style={{ padding: '0px' }}>
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                    <div className="ui-tab">UPLOADEN</div>
                    <div className="ui-tab">SELECTEREN UIT HANDBOEK</div>
                </Tabs>
                <input ref={contentRef} type="file" onChange={processFile} style={{ display: 'none' }} />
                {selectedTab === 0 && <>
                    <Button onClick={() => { contentRef.current.click() }}>Selecteer bestand van computer</Button>
                </>}
                {selectedTab===1 && <div className="padding">
                    <Select
                        label="Toegang"
                        id="Access"
                        value={selectedAccess || ''}
                        onChange={(ev) => { setSelectedAccess(ev.target.value);setSelectedLevel();setFiles([]) }}
                    >
                        <option value=""></option>
                        {accessList.map((access) => { return <option key={access} value={access}>{access}</option> })}
                    </Select>

                    <Select
                        label="Niveau"
                        id="Level"
                        value={selectedLevel || ''}
                        onChange={(ev) => { setSelectedLevel(ev.target.value) }}
                    >
                        <option value=""></option>
                        {levels.map((level) => { return <option value={level} key={level}>{level}</option> })}

                    </Select>
                 <div style={{ display: 'flex' }}>
                    {files.filter(file => {  return (file.filename.indexOf('.note') ==-1 && file.filename.indexOf('.htmlbuilder') ===-1)  }).map(file => {
                             return   <FilesBrowserFile onClick={()=>handleSelectFile(file)} key={file.DPath} file={file}/>
                            
                         })}
                  </div>
                </div>}
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={onClose}>Annuleren</Button>
            </DialogActions>
        </Dialog>
    )
}
