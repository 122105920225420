import React from 'react'
import {List,ListItem,ListItemText,ListItemIcon} from '../../ui/core';
export default function list({items,onClick}) {
   
    return (
        <>
        <List>
           {items.map(item=>{return <ListItem  key={item.ID} onClick={()=>{onClick(item)}} ><ListItemIcon>{item.Image && <img src={item.Image} className="avatar"/>}</ListItemIcon><ListItemText primary={item.Achternaam ? <span>{item.Achternaam}, {item.Voornaam} {item.Tussenvoegsel}<br />{item.Organisatie}</span> : <span>{item.Organisatie}</span>}></ListItemText></ListItem>})} 
        </List>
        </>
    )
}
 