import APIV2 from "./apiv2";

const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            APIV2.post('appinfo',null,{}).then(
                info => {
                     resolve(info)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
} 
export default Service;