import React, { useState, useEffect, useContext } from 'react'
import UserService from '../../services/user';
import AppSettings from '../../AppSettings';
import { LockScreen } from '../ui/core';
import { Context } from '../../AppContext';
import { Secure } from '../../plugins/capacitor';

export default function LockScreenContainer(props) {
    const context = useContext(Context);
    const [locked, setLocked] = useState(false)
    const [isPinSet, setIsPinSet] = useState(false);


    //check if a pin excists
    const checkPinSet = () => {
        if (Secure) {
          
            if (Secure && AppSettings.getPlatform() !== 'web') {
                Secure.isPinSet({ value: '' })
                    .then((result) => {
                        setIsPinSet((result.value === 'true'));
                    }, err => {
                        console.log(err)
                    })
            }
        }

    }
    useEffect(() => {
        checkPinSet();
    }, [])

    //check on changing state
    useEffect(() => {
        if (Secure) {
           
            if (Secure && AppSettings.getPlatform() !== 'web') {
                checkPinSet()
                if (canLock() === true) {
                    if (context.state && context.state.isActive === false) {
                        Secure.lock()
                            .then((result) => { }, err => { })
                    } else {
                        checkIsLocked()
                    }
                }
            }
        }
    }, [context.state])

    const checkIsLocked = () => {
        if (Secure) {
          
            if (Secure && AppSettings.getPlatform() !== 'web' && canLock()) {
                Secure.isLocked()
                    .then((result) => {
                        if (locked === false && result.value === 'true') {
                            setLocked(true);
                        } else {
                            Secure.isPinSet().then((result) => {
                                if (result.value === 'false') {
                                    setLocked(true);
                                }
                            }, err => { console.log(err) })
                        }
                    }, err => { console.log(err) })
            }
        }
    }

    //verify if is locked
    useEffect(() => {
        checkIsLocked();
    }, [locked])


    const canLock = () => {
        if (  AppSettings.getToken().refresh_token == undefined ) { return false }
        if (AppSettings.getPlatform() === 'web') { return false }
        if (context.info && context.info.Settings && context.info.Settings.UsePin !== true) { return false }
        return true;
    }

    const onSubmitLockScreen = (input) => {
        if (Secure) {
            if (isPinSet) {
                if (Secure && AppSettings.getPlatform() !== 'web') {
                    Secure.checkPin({ value: input })
                        .then((result) => {
                            if (result.value === 'success') {
                                setLocked(false);
                            }
                        }, err => { console.log(err) })
                }

            } else {
                if (Secure && AppSettings.getPlatform() !== 'web') {
                    Secure.setPin({ value: input })
                        .then((result) => {
                            setIsPinSet(true);
                        }, err => { console.log(err) })
                }
            }
        }
    }

    const onResetLockScreen = () => {
        console.log('Reset lockscreen')
        context.logout(props.history);
        if (Secure) {
         
            if (Secure && AppSettings.getPlatform() !== 'web') {
                Secure.setPin({ value: "" })
                    .then((result) => {
                        setIsPinSet(false);
                    }, err => { console.log(err) })
                setLocked(false);
            }
        }
    }


    if (locked) return <LockScreen labelSetPin={"Vul hieronder uw gewenste pincode ter beveiliging."} onSubmit={onSubmitLockScreen} onReset={onResetLockScreen} isSet={isPinSet} />

    return (props.children)
}
