import React,{useState,useEffect} from 'react'
import FormBuilderPopup from '../../../forms/builderpopup';
import FilesService from "../../../../services-admin/files";
import FormService from '../../../../services-admin/form';
export default function Form(props) {
    const [form, setForm] = useState();
    const [answers, setAnswers] = useState([])

    const handleSaveForm = (form) => {
        FilesService.saveBase64(props.version, props.access, props.level, props.file.filename, FilesService.encodeUnicode(JSON.stringify(form)))
            .then((result) => {
                props.onRefresh();
                props.onClose();
            }, (err) => {
                props.onRefresh();
                props.onClose();
            })
    }

    useEffect(() => {
        try {
            const obj = JSON.parse(props.file.content)
            if (!obj.lines) {
                obj.lines = [];
            } 
            setForm(obj);
            FormService.get(obj.id)
                .then((result) => {
                    setAnswers(result);
                }, (err) => {
                    console.log(err)
                })
        } catch{ }
    }, [props.file.content])


    return (
        <React.Fragment>
            {form && <FormBuilderPopup form={form}  answers={answers} onSaveForm={handleSaveForm} onClose={props.onClose} />}
        </React.Fragment>
    )
}
 