/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'

import { Context } from '../../AppContext';
import {CheckBox} from '../ui/core';
import { Button } from '../../components/ui/button';

import UpdateService from '../../services/updateservice';

export default function Chapters() {
    const context = useContext(Context);
    const [HPaths, setHPaths] = useState(localStorage.getItem('offline_hpaths') || '');
    const [dirty, setDirty] = useState(false);
    const [size,setSize] = useState('0 Kb');
    useEffect(() => {
        return () => {
            if (dirty) {
                context.checkUpdates(true);
            }
        };
    }, [dirty])

    const updateHPath = (HPath, Path) => {
        let tmpPaths = HPaths
        if (HPaths.indexOf(HPath) >= 0) {
            tmpPaths = HPaths.replace(HPath + ',', '');
            //remove underlying paths
            for (let l = 0; l < context.info.Levels.length; l++) {
                if (context.info.Levels[l].Path.indexOf(Path) === 0) {
                    tmpPaths = tmpPaths.replace(context.info.Levels[l].HPath + ',', '');
                }
            }

        } else {
            tmpPaths = HPaths + HPath + ',';
            //add underlying paths
            for (let l = 0; l < context.info.Levels.length; l++) {
                if (context.info.Levels[l].Path.indexOf(Path) === 0 && tmpPaths.indexOf(context.info.Levels[l].HPath) === -1) {
                    tmpPaths = tmpPaths + context.info.Levels[l].HPath + ',';
                }
            }
        }
        localStorage.setItem('offline_hpaths', tmpPaths)
        setHPaths(tmpPaths);
        setDirty(true);
    }

    useEffect(() => {
     
        let size = 0;
        for (let l = 0; l < context.info.Index.length; l++) {
            if (HPaths.indexOf(context.info.Index[l].HPath) >= 0 || context.info.Index[l].Extention==='json' ||  context.info.Index[l].Extention==='note' )  {             
                size  = size +  context.info.Index[l].Size;
            }
        }
        if(size> 1000000){
            setSize('' + Math.round((size/1000000)*1.7) + ' Mb')
        }else{
            setSize('' + Math.round((size/1000)*1.7) + ' Kb')
        }
        //check force downloads
        let newHPaths = HPaths; 
        for(let i=0;i<context.info.Levels.length;i++){
            if(context.info.Levels[i].DirInfo && context.info.Levels[i].DirInfo.levelForceDownload){               
                if(HPaths.indexOf(context.info.Levels[i].HPath)===-1){
                    newHPaths +=  context.info.Levels[i].HPath + ',';
                }
            }
        }
        if(newHPaths!==HPaths){
            localStorage.setItem('offline_hpaths', newHPaths);
            setHPaths(newHPaths);
            setDirty(true);
        }
    }, [HPaths])

    const clear = () => {
        console.log('Cleaning'); 
        if(window.confirm("Weet u zeker dat u alle lokale documenten wilt verwijderen?")){
            UpdateService.cleanDirectory([], []).then((result) => { context.setMessage("Lokale bestanden verwijderd") }, (err) => { }); 
        }    
    }


    return (
        <div className="padding">
                Selecteer de hoofdstukken waarvan u de bestanden wilt downloaden voor offline gebruik. De beheerder kan aangeven dat een hoofdstuk verplicht moet worden gedownload. De App zal inclusief onderstaande selectie ongeveer <b>{size}</b> aan opslagruimte in beslag nemen.<br /> 
                    <Button variant="text" onClick={clear} style={{float:'right'}}>Verwijder alle lokale bestanden</Button>
                    <div style={{ fontSize: '12px',clear:'both' }} >
                        {context.info.Levels.map((level) => {
                            let path = level.Path;
                            if (path !== '/') {
                                let currentPath = path.split('/');
                                if(currentPath.length>2){
                                    currentPath = currentPath[currentPath.length-2];
                                }else{
                                    currentPath = path;
                                }
                                return <CheckBox style={{ fontSize: '12px', paddingLeft: (20 * (path.split('/').length - 2)) + 'px' }} id={path } key={path}  label={currentPath } disabled={level.DirInfo && level.DirInfo.levelForceDownload} checked={HPaths.indexOf(level.HPath) >= 0} onClick={() => { updateHPath(level.HPath, level.Path) }} value="path"/>
                                
                            
                            } else {
                                return null;
                            }
                        })}
                    </div>
            
        </div>
    )
}
