import React,{useRef,useState} from 'react'
import ContentEditable from "react-contenteditable";
import VeditorSimple from "../../../veditor-simple/veditor";
export default function Tablecell({tableCell, row, column, onChange,style}) {
   // const contentRef = useRef(tableCell.content);  
    const [contentRef] = useState(React.createRef());

    const handleChange = evt => {
      //  contentRef.current = evt.target.value; 
        let tmpCell = Object.assign({}, tableCell);
        tmpCell.content =  contentRef.current.innerHTML; 
        onChange( row, column,tmpCell); 
    };

    return (
        <div style={style}  >
            <VeditorSimple id={'cell-editor-'+ row+'-' + column} value={tableCell.content  || ''} contentRef={contentRef}  files={[]} pages={[]} onChange={handleChange} showMenu={tableCell.selected===true}/>
        </div>
    )
}
