const download = function (content, filename, contentType) {
    if (!contentType) contentType = 'application/octet-stream';
    var blob = new Blob([content], {
        'type': contentType
    });
    var link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const emitXmlHeader = function (attributes) {
    var headerRow = '<ss:Row>\n';
    for (var colName in attributes) {
        headerRow += '  <ss:Cell>\n';
        headerRow += '    <ss:Data ss:Type="String">';
        headerRow += colName + '</ss:Data>\n';
        headerRow += '  </ss:Cell>\n';
    }
    headerRow += '</ss:Row>\n';
    return '<?xml version="1.0" encoding="utf-8" ?>\n' +
        '<?mso-application progid="Excel.Sheet"?>' +
        '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n' +
        '<ss:Worksheet ss:Name="Tab1">\n' +
        '<ss:Table>\n\n' + headerRow;
};

const emitXmlFooter = function () {
    return '\n</ss:Table>\n' +
        '</ss:Worksheet>\n' +
        '</ss:Workbook>\n';
};

const jsonToSsXml = function (jsonObject, attributes) {
    var row;
    var col;
    var xml;
    var data = typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;

    xml = emitXmlHeader(attributes);

    for (row = 0; row < data.length; row++) {
        xml += '<ss:Row>\n';

        for (col in data[row]) {
            xml += '  <ss:Cell>\n';
            xml += '    <ss:Data ss:Type="' + attributes[col] + '">';
            xml += data[row][col] + '</ss:Data>\n';
            xml += '  </ss:Cell>\n';
        }

        xml += '</ss:Row>\n';
    }

    xml += emitXmlFooter();
    return xml;
};

//example  attributes = { "name": "String",   "amount": "Number" };
//example  objectFile = [ { "name": "John",  "amount": 42 }]

const Service = {
    downloadExcel: (filename, objectFile, attributes) => {
        download(jsonToSsXml(objectFile, attributes), filename, 'application/vnd.ms-excel');
    }

};
export default Service