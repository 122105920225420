import React, { useState, useEffect } from 'react'
import ThemesService from '../../../services-admin/themes';
import { Fab, List, ListItem, ListItemText } from '../../ui/core';
import { Add } from '../../ui/coreIcons'
import ThemeEdit from './theme-edit';
import { v1 as uuidv1 } from 'uuid';
export default function Theme() {
    const [themes, setThemes] = useState([]);
    const [theme, setTheme] = useState();

    const getThemes = ()=>{
        ThemesService.get()
        .then(result => { setThemes(result) }, err => { })
    }

    useEffect(() => {
         getThemes();
    }, []);

    const handleSave = (theme) => {
        let tmpObj = themes.filter(item=>{return item.id !==theme.id});
        tmpObj.push(theme);
        
        setThemes(tmpObj);
        ThemesService.update(tmpObj)
            .then(result => {
                setTheme();
                getThemes(); 
            }, err => { })
    }

    const handleDelete = (theme) => {
        if(window.confirm('Weet u zeker dat u dit thema wilt verwijderen?')){
            let tmpObj = themes.filter(item=>{return item.id !==theme.id}); 
        
            setThemes(tmpObj);
            ThemesService.update(tmpObj)
                .then(result => {
                    setTheme();
                    getThemes(); 
                }, err => { })
        }
       
    }

    const handleNewTheme = () => {
        let tmpObj = Object.assign([], themes);
        let newTheme = { id: uuidv1(), name: 'Nieuw thema' };
        newTheme.primary ='rgb(0,0,0)';
        newTheme.primarycontrast ='rgb(255,255,255)';
        newTheme.secondary ='rgb(0,0,0)';
        newTheme.secondarycontrast ='rgb(255,255,255)';
        tmpObj.push(newTheme);
        setThemes(tmpObj);
        setTheme(newTheme);
    }

    return (
        <>
            {theme && <ThemeEdit theme={theme} onSave={handleSave} onDelete={handleDelete} onClose={() => { setTheme() }} />}
            <List>
                {themes.map(theme => { return <ListItem key={theme.id} onClick={() => { setTheme(theme) }}><ListItemText primary={theme.name} secondary={theme.isgeneral?'Algemeen thema':''}></ListItemText></ListItem> })}
            </List>
            {!theme && <Fab onClick={handleNewTheme} color="primary" style={{ position: 'absolute', right: '15px', bottom: '35px', zIndex: '99999' }}><Add color="white" /></Fab>}
        </>
    )
}
