import React from 'react'
import { ListItem, IconButton, ListItemText } from '../../ui/core';

import AppSettings from '../../../AppSettings';

import { Delete, Attachment } from '../../ui/coreIcons';
export default function attachment({ item, onDelete }) {
    return (
        <ListItem dense key={item} onClick={() => window.open(AppSettings.getLocationDocumentAPI() + 'download?key=' + item.split('/')[0])}>
            <div style={{ display: 'flex' }}>

                <IconButton aria-label="Downloaden" >
                    <Attachment />
                </IconButton>

                <ListItemText
                    primary={item.split('/')[1]}

                />
                {onDelete && <IconButton style={{ float: 'left' }} aria-label="Delete" onClick={() => onDelete(item)}>
                    <Delete />
                </IconButton>}
            </div>
        </ListItem>
    )
}
