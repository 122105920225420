import React, { useEffect, useState } from 'react'

import InstallationService from "../../../services-admin/installations"
import UserService from "../../../services-admin/users";
import GeneralService from "../../../services/general";
import { CheckBox } from '../../ui/core';
import moment from 'moment';
export default function Index() {
    const [installations, setInstallations] = useState([]);

    const getInstallations = () => {
        UserService.get()
            .then(users => {
                InstallationService.get()
                    .then(installs => {                    
                        installs = installs.map(item => { item.userObj = users.find(user => { return user._id === item.user }); return item })
                        setInstallations(GeneralService.sortJSON(installs, 'lastActive', '321'))
                    })
            })
    }

    useEffect(() => {
        getInstallations();
    }, [])

    const toggleLock = (install) => {
        InstallationService.toggleLock(install)
                    .then(result => {
                        getInstallations();
                    })
                    .catch(ex=>{})
      
    }

    return (
        <table className="padding" cellSpacing={'8px'}>
            <tbody>
                <tr><th>UUID</th><th>Laatst online</th><th>Gebruiker</th><th>Platform</th><th>Bedrijf</th><th>Besturing</th><th>Versie</th><th>Vergrendeld</th></tr>
                {installations.map(install => {
                    return <tr key={install._id}><td>{install.uuid && (install.uuid.substr(0, 4))}...</td><td>{moment(install.lastActive).format('YYYY-MM-DD HH:mm')}</td><td>{install.userObj && (install.userObj.Name||install.userObj.Email)}</td><td>{install.platform}</td><td>{install.manufacturer}</td><td>{install.operatingSystem}</td><td>{install.osVersion}</td><td><CheckBox id={'cb_'+install._id} checked={install.status === 0} label={install.status === 0?'Ja':'Nee'} onChange={() => { toggleLock(install) }}></CheckBox></td></tr>
                })}
            </tbody>
        </table>
    )
}
