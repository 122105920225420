/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../AppContext';
import useWindowSize from '../../hooks/windowsize';
import { Browser } from "../../plugins/capacitor";
import {List,ListItem,ListItemText,ListItemIcon,Typography} from '../ui/core';
import {NavigateNext} from '../ui/coreIcons';

export default function Level(props) {
    const context = useContext(Context);
    const windowSize = useWindowSize();
    const [image, setImage] = useState();
    const levelNameSplit = props.level.Path.split('/');
    let levelName = props.level.Path;
    let currentPath = props.level.Path;
  
    try {
        levelName = levelNameSplit[levelNameSplit.length - 2];  
        levelNameSplit.pop()
        levelNameSplit.pop()
        currentPath = levelNameSplit.join('/')
        if(context.info.Settings && context.info.Settings.hideLevelNumbers){
            try{
                let prefix = levelName.split(' ')[0];
                if(!isNaN(prefix)){
                    levelName = levelName.replace(prefix + ' ','');
                }
            }catch(ex){}
        }
    } catch (ex) { }

    useEffect(() => {
        const image = context.info.Images.find((item) => { return item.HPath === props.level.HPath });
        if (image) {
            if (windowSize.innerWidth < 700) {
                setImage(<img onClick={() => { openLevel(props.level)  }} style={{ padding: '8px', cursor: 'pointer' }} src={image.Type + image.Base64} alt={levelName} height={context.info.Settings.Heightsmallscreen || 90} width={context.info.Settings.Widthsmallscreen || 90} />)
            } else {
                setImage(<img onClick={() => { openLevel(props.level)  }} style={{ padding: '8px', cursor: 'pointer' }} src={image.Type + image.Base64} alt={levelName} height={context.info.Settings.Height || 150} width={context.info.Settings.Width || 150} />)
            }

        }
    }, [context.info, windowSize])

    const openLevel = (level)=>{
        if(level.DirInfo && level.DirInfo.URL && level.DirInfo.URL.length>0){
            if(Browser){ 
                Browser.open({ url:level.DirInfo.URL});
            }else{
               window.open(level.DirInfo.URL,'_blank');
            }
        }else{
            props.history.push('/index/' + level.HPath)
        }
    }

    return (
        <React.Fragment>
            {
                props.inSearchResult ?
                    <React.Fragment>
                        <Typography component="h2" className="padding">{levelName}<br/>
                        <div   style={{fontSize:'10px',color:'gray'}}>/{currentPath}</div>
                        </Typography>
                        
                      <List>
                                <ListItem onClick={() => { openLevel(props.level) }}  >
                                    <ListItemText primary= {'Bekijk hoofdstuk'}></ListItemText>
                                    <ListItemIcon> <NavigateNext  /></ListItemIcon>
                                </ListItem>
                            </List>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {image ?
                            image
                            :
                            <List>
                                <ListItem onClick={() => {  openLevel(props.level)  }} >
                                    <ListItemText primary= {levelName}></ListItemText>
                                    <ListItemIcon> <NavigateNext  /></ListItemIcon>
                                </ListItem>
                            </List>
                          
                        }
                    </React.Fragment>

            }
        </React.Fragment>
    )
}
