
import React from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Typography } from '../ui/core'

export default function PushitemDialog(props) {
    const onRead = () => {
        if (props.incommingMessage.view) {
            props.history.push('/' + props.incommingMessage.view);
            props.onClose();
        }
    }
    if(!props.incommingMessage) return null;
    return (
        <div>
            <Dialog
                open={true}
                onClose={props.onClose} 
            >
                <DialogTitle ><Typography component="h1">Nieuw bericht</Typography></DialogTitle>
                <DialogContent>
                    {props.incommingMessage.title}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="outlined">
                        Sluiten
            </Button>
                    <Button onClick={onRead}  variant="contained">
                        Lees verder
            </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
