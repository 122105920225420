import React, { useState } from 'react'
import { Dialog, DialogContent, Button, IconButton } from '../../../ui/core';
import ServiceFiles from '../../../../services-admin/files';
import FileBrowser  from '../../index/files-browser';
export default function Imageselection({files,  uploadFile, onSelect }) {
    const [contentRef] = useState(React.createRef());
    const [showDialog, setShowDialog] = useState(false);
    

    const handleUpload = (file) => {
        ServiceFiles.fileToBase64(file,1000)
        .then(result=>{
            onSelect(result);
        })
        .catch(ex=>{

        })

        /*
        console.log(file)
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            let base64 = reader.result;

            file.base64 = base64;
            onSelect(file.base64);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
        */
    }


    const processFile = (ev) => {
        handleUpload(ev.target.files[0]);
    }
    
    return (
        <>
        {showDialog && <FileBrowser onClose={()=>setShowDialog(false)} onSelectBase64={onSelect}/>}
            <Button onClick={()=>{setShowDialog(true);/*contentRef.current.click()*/}}>Selecteer afbeelding</Button>
            <input  ref={contentRef} type="file" onChange={processFile} style={{display:'none'}}/>  
        </>
    )
}
