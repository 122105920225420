import API from "./api";

const Service = {
    get: (formID) => {
        return new Promise((resolve, reject) => {
            API.get('forms/'+formID+'/answers').then(
                forms => {
                     resolve(forms)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;