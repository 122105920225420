/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import AppSettings from './AppSettings';
import { Network, Secure, App } from './plugins/capacitor';
import InfoService from './services/info';
import AnnouncementsService from './services/announcements';
import UpdateService from './services/updateservice';
import FileService from './services/file';
import StorageService from './services/storage';
import UserService from './services/user';
import ElectronService from './services/electron';

import PushDialog from './components/general/pushdialog';

import useIncommingMessage from "./hooks/incommingmessage";
import GeneralService from "./services/general";
import settings from './AppSettings';

//const locationAPI = AppSettings.getLocationAPI();
//const encryptWorker = new Worker('./workers/encryptdownload.js');
//const decryptWorker = new Worker('./workers/decryptfile.js');

export const Context = React.createContext();

export default function AppContext(props) {
  const [state, setState] = useState();
  const [message, setMessage] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [newInfo, setNewInfo] = useState(null);
  const [currentLevel, setCurrentLevel] = useState('');
  const [refresh, setRefresh] = useState('');
  const [popup, setPopup] = useState();
  const [helpText, setHelpText] = useState();
  const [showBackButton, setShowBackButton] = useState(false);
  const [theme, setTheme] = useState();
  const [title, setTitle] = useState(null);
  const [downloadList1, setDownloadList1] = useState([]);
  const [downloadList2, setDownloadList2] = useState([]);
  const [downloadList3, setDownloadList3] = useState([]);
  const [downloadList1Total, setDownloadList1Total] = useState(0);
  const [downloadList2Total, setDownloadList2Total] = useState(0);
  const [downloadList3Total, setDownloadList3Total] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [history, setHistory] = useState();
  const [lastModified, setLastModified] = useState('');
  const [activateThemeSelect, setActivateThemeSelect] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState();
  //const [decryptedFiles, setDecryptedFiles] = useState([]);
  //const [cryptKey, setCryptKey] = useState('');
  const [incommingMessage, resetIncommingMessage] = useIncommingMessage();


  useEffect(() => {
    UserService.retrieveTokens()
      .then(tokens => {
        console.log('got token', tokens);
        AppSettings.setToken(tokens);
      })
      .catch(err => {
        console.log('Error getting tokens');
      })
  }, [])

  //INFO
  const checkInfo = (force, redirectToIndex) => {

    UpdateService.getLastModified()
      .then((lastModifiedServer) => {
        if (lastModifiedServer !== lastModified) {
          checkInfo2(force, lastModifiedServer, redirectToIndex);
        } else {
          if (!newInfo) {
            setNewInfo(info);
          }
        }
      }, (err) => {
        console.log(err)
        checkInfo2(force, '', redirectToIndex);
      });
  }

  const checkInfo2 = (force, lastModifiedServer, redirectToIndex) => {
    InfoService.get()
      .then(result => {

        //check Storage
        StorageService.readFile(StorageService.settingsDir(), 'appinfo.dat').then(
          (appinfo) => { },
          (err) => {

            applyInfo(result, redirectToIndex);
          }
        )

        if (AppSettings.getPlatform() === 'web' || force) {
          applyInfo(result, redirectToIndex);
        }

        if (AppSettings.getPlatform() !== 'web') {
          result.lastModifiedServer = lastModifiedServer;
          setNewInfo(result);
        }
      }, err => { console.log('Error checking info ', err) })
  }

  const applyInfo = (updateInfo, redirectToIndex) => {

    if (updateInfo.Settings) {
      document.title = updateInfo.Settings.Title || '';
    }

    //sort levels
    if (updateInfo.Levels) {
      updateInfo.Levels = GeneralService.sortJSON(updateInfo.Levels, 'Path', '123')
    }

    //check encryption
    /*
    for (let f = 0; f < updateInfo.Index.length; f++) {
      if (updateInfo.Index[f].encrypt === undefined) {
        updateInfo.Index[f].encrypt = UpdateService.checkUseEncryption(updateInfo.Levels, updateInfo.Index[f]);
      }
    }
    */

    //saving file 
    StorageService.writeFileBase64(StorageService.settingsDir(), 'appinfo.dat', JSON.stringify(updateInfo));
    setInfo(updateInfo);

    ///setting theme
    if (updateInfo && updateInfo.Settings) {
      if (updateInfo.User && updateInfo.User.Theme) {
        setTheme(updateInfo.User.Theme);
      } else if (updateInfo.Theme) {
        setTheme(updateInfo.Theme);
      } else {
        setTheme({
          name: 'Algemeen',
          primary: updateInfo.Settings.Color,
          primarycontrast: updateInfo.Settings.Contrast || '#fff',
          secondary: updateInfo.Settings.SecondaryColor,
          secondarycontrast: updateInfo.Settings.Contrast || '#fff',
          icon: updateInfo.Settings.Icon,
          logo: updateInfo.Settings.Logo
        })
      }
    }
    //update last modified
    setLastModified(updateInfo.lastModifiedServer);

    if (window.location.href.split('?')[1] && window.location.href.split('?')[1].indexOf('redirect') == 0) {
      setRedirectLocation(window.location.href.split('?')[1])
    }
    if (redirectToIndex) {
      try {
        // if (window.location.href.split('?')[1] && window.location.href.split('?')[1].indexOf('redirect') === 0) {
        if (redirectLocation) {
          if (redirectLocation.replace('redirect=', '').indexOf('http') === 0) {
            window.open(redirectLocation.replace('redirect=', ''), "_self");
          } else {
            history.push(redirectLocation.replace('redirect=', ''));
          }
          setRedirectLocation(null);
        } else {
          if (hasOnlyAdmin()) {
            history.push('/admin');
          } else {
            history.push('/index');
          }
        }
      } catch (ex) {

      };
    }
  }
  //END INFO

  const hasOnlyAdmin = () => {
    return window.location.pathname.indexOf('/beheer') === 0;
  }

  const checkAnnouncements = () => {
    StorageService.readFile(StorageService.settingsDir(), 'announcements.dat').then(
      (announcements) => {
        try {
          if (Array.isArray(announcements)) {
            let ann = JSON.parse(announcements);
            setAnnouncements(ann);
          }
        } catch (ex) {
          console.log('Getting annoucement from storage failed')
        }
        checkAnnouncementsOnline();
      },
      (err) => {
        console.log('Getting announcement from storage failed')
        checkAnnouncementsOnline();
      }
    )
  }

  const checkAnnouncementsOnline = () => {
    AnnouncementsService.get()
      .then(result => {
        StorageService.writeFileBase64(StorageService.settingsDir(), 'announcements.dat', JSON.stringify(result));
        setAnnouncements(result);
      }, err => { console.log('Error checking announcements ', err) })
  }

  useEffect(() => {
    checkAnnouncements();
  }, [incommingMessage])

  //UPDATE SYSTEM
  const resetUpdate = () => {
    setDownloadList1([]);
    setDownloadList1Total(0)
    setDownloadList2([]);
    setDownloadList2Total(0)
    setDownloadList3([]);
    setDownloadList3Total(0)
    setNewInfo(null);
    setDownloading(false);
  }

  const checkUpdates = (force) => {

    if (force) {
      resetUpdate();
    }

    if (newInfo && downloadList1.length === 0 && downloadList2.length === 0 && downloadList3.length === 0) {

      UpdateService.getDownloadList(newInfo.Index, newInfo.Levels)
        .then(resultDownloadList => {

          if (resultDownloadList.length === 0 && newInfo) {
            //UPDATE DIRECT DOORVOEREN
            finishUpdate(newInfo);
          } else {
            let tmpList1 = [];
            let tmpList2 = [];
            let tmpList3 = [];
            let counter = 1;
            for (let i = 0; i < resultDownloadList.length; i++) {
              switch (counter) {
                case 1:
                  tmpList1.push(resultDownloadList[i])
                  break;
                case 2:
                  tmpList2.push(resultDownloadList[i])
                  break;
                case 3:
                  tmpList3.push(resultDownloadList[i])
                  break;
                default:
                  break;
              }
              counter++
              if (counter > 3) { counter = 1 }
            }
            setDownloadList1(tmpList1);
            setDownloadList1Total(tmpList1.length);
            setDownloadList2(tmpList2);
            setDownloadList2Total(tmpList2.length);
            setDownloadList3(tmpList3);
            setDownloadList3Total(tmpList3.length);

            let downloadCell = (localStorage.getItem("downloadcell") === 'true');
            let autoupdate = (localStorage.getItem("autoupdate") === 'true');

            Network.getStatus().then((status) => {
              if (status.connected && autoupdate && (status.connectionType === 'wifi' || downloadCell)) {
                setDownloading(true);
              }
            }, (err) => {
              console.log(err)
            })
          }
        }, err => { console.log('Error checking updates ', err) })
    }
  }

  //handle change in network connection
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      checkUpdates();
    });
    return () => {
      Network.removeAllListeners();
    }
  }, [])

  useEffect(() => {
    checkUpdates();
  }, [newInfo, downloadList1, downloadList2, downloadList3])

  const finishUpdate = (appliedInfo) => {
    applyInfo(appliedInfo);
    setNewInfo(null);
    setDownloading(false);
    setDownloadList1Total(0);
    setDownloadList2Total(0);
    setDownloadList3Total(0);

    UpdateService.cleanDirectory(appliedInfo.Index, appliedInfo.Levels)
      .then((result) => { }, (err) => { });
  }

  const updateMessage= (msg)=>{
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    },4000);
  }

  useEffect(() => {
    if (downloading === true && downloadList1.length === 0 && downloadList2.length === 0 && downloadList3.length === 0) {
      if (newInfo) {
        finishUpdate(newInfo);
      }
      updateMessage('De documenten zijn bijgewerkt')
    }
  }, [downloading, downloadList1, downloadList2, downloadList3])


  /*
    decryptWorker.onmessage = ($event) => {
      if ($event && $event.data) {
        if ($event.data.fileName) {
          let tmp = Object.assign([], decryptedFiles);
          tmp[$event.data.fileName] = $event.data.base64;
          setDecryptedFiles(tmp);
        }
      }
    }
   
   
    const removeDecryptedFile = (fileName) => {
      let tmp = Object.assign([], decryptedFiles);
      delete tmp[fileName];
      setDecryptedFiles(tmp);
    }
   
    const decryptFile = (fileName, base64Enc) => {
   
      if (Secure && AppSettings.getPlatform() !== 'web') {
        Secure.getSecureKey()
          .then((result) => {
            decryptWorker.postMessage({ fileName: fileName, base64Enc: base64Enc, secret: result.value });
          }).catch(err => { })
      }
    }
  */
  const downloadFromPoolFinished = (pool) => {
    let tmpDownloadList
    switch (pool) {
      case 1:
        tmpDownloadList = Object.assign([], downloadList1);
        tmpDownloadList.shift();
        setDownloadList1(tmpDownloadList);
        break;
      case 2:
        tmpDownloadList = Object.assign([], downloadList2);
        tmpDownloadList.shift();
        setDownloadList2(tmpDownloadList);
        break;
      case 3:
        tmpDownloadList = Object.assign([], downloadList3);
        tmpDownloadList.shift();
        setDownloadList3(tmpDownloadList);
        break;
    }
  }

  /*
  // IPC FOR DOWNLOADSTATUS WINDOWS
  const updateICPMessage = (evt, arg) => {
   let data = JSON.parse(arg);
   downloadFromPoolFinished(data.pool)
  }
  
   
  useEffect(() => {
   if (window.ipc) {
     window.ipc.on('downloadcomplete', updateICPMessage)
   }
   return () => {
     if (window.ipc) {
       window.ipc.removeListener('downloadcomplete', updateICPMessage)
     }
   }
  }, [downloadList1, downloadList2, downloadList3])
  // END IPC FOR DOWNLOADSTATUS WINDOWS
  */

  /*
  // WORDKER FOR DOWNLOADSTATUS IOS/ANDROID
  encryptWorker.onmessage = ($event) => {
    if ($event && $event.data) {
      console.log('Message from worker')
      let data = $event.data;
      if (data.error) {
        console.log('got error')
        console.log(data.fileName)
  
      } else if (data.pool) {
        StorageService.writeFileBase64(StorageService.documentsDir(), data.fileName, data.base64)
          .then(result => {
  
          })
          .catch(err => {
            console.log('Error writing file')
            console.log(err)
          })
        downloadFromPoolFinished(data.pool);
      }
    }
  }
  // END WORDKER FOR DOWNLOADSTATUS IOS/ANDROID
  */
  const checkNewDownload = (pool) => {

    let downloadList;
    switch (pool) {
      case 1:
        downloadList = downloadList1;
        break;
      case 2:
        downloadList = downloadList2;;
        break;
      case 3:
        downloadList = downloadList3;
        break;
    }
    if (downloadList.length > 0 && downloading) {

      FileService.getDownloadURL(downloadList[0].DPath, true)
        .then((downloadURL) => {
          if (settings.getPlatform() === 'web') {
            StorageService.downloadAndSaveEncrypted(downloadURL, downloadList[0].target, '')
              .then(result => {
                console.log('Download OK');
                downloadFromPoolFinished(pool);
              })
              .catch((err) => {
                console.log('Download Failed', err)
              })
          } else {
            Secure.getSecureKey()
              .then((retSecret) => {
                StorageService.downloadAndSaveEncrypted(downloadURL, downloadList[0].target, retSecret.value)
                  .then(result => {
                    console.log('Download OK')
                    downloadFromPoolFinished(pool);
                  })
                  .catch((err) => {
                    console.log('Download Failed', err)
                  })
              })
          }

        })
        .catch(err => {
          console.log(err);
          downloadFromPoolFinished(pool);
        })
    }
    /*
          UpdateService.getDownloadToken(downloadList[0].DPath)
            .then(token => {
    
              if (AppSettings.getPlatform() !== 'web' && AppSettings.getPlatform() !== 'electron') {
    
    
                if (Secure) {
                  Secure.getSecureKey()
                    .then((result) => {
                      let encrypt = downloadList[0].encrypt === undefined ? true : downloadList[0].encrypt;
                      encryptWorker.postMessage({ pool: pool, encrypt: encrypt, url: encodeURI(locationAPI + 'documentv3/' + token + '/?' + downloadList[0].DPath), fileName: downloadList[0].target, secret: result.value });
                    }).catch(err => { })
                }
    
              } else if (AppSettings.getPlatform() === 'electron') {
                StorageService.statDocument()
                  .then(stats => {
                    if (stats) {
                      ElectronService.downloadFile(encodeURI(locationAPI + 'documentv3/' + token + '/?' + downloadList[0].DPath), stats.uri + '/' + downloadList[0].target, pool)
                        .then((result) => { }, (err) => { console.log('Error downloading electron', err) })
                    } else {
    
                    }
                  })
                  .catch(err => { console.log('err stat'); console.log(err) })
    
              }
            })
            .catch(ex => {
              console.log("Ophalen token mislukt")
              console.log(ex);
              //setMessage('Er is een fout opgetreden bij het downloaden van het bestand.');
              //Fallback to old download
    
              console.log('Check Secure')
    
              if (Secure) {
                console.log('Got secure')
                Secure.getSecureKey()
                  .then((result) => {
                    let encrypt = downloadList[0].encrypt === undefined ? true : downloadList[0].encrypt;
                    encryptWorker.postMessage({ pool: pool, encrypt: encrypt, url: encodeURI(locationAPI + 'documentv2/?' + downloadList[0].DPath), fileName: downloadList[0].target, secret: result.value });
                  }).catch(err => {
                    setMessage('Er is een fout opgetreden bij het downloaden van het bestand.');
                  })
              }
            })
        }
        */
  }

  useEffect(() => {
    checkNewDownload(1);
  }, [downloadList1, downloading])

  useEffect(() => {

    checkNewDownload(2);
  }, [downloadList2, downloading])

  useEffect(() => {
    checkNewDownload(3);
  }, [downloadList3, downloading])
  //END UPDATE SYSTEM

  //CHECK CACHE ITEMS ON STARTUP
  useEffect(() => {
    StorageService.readFile(StorageService.settingsDir(), 'appinfo.dat').then(
      (appinfo) => {
        try {
          appinfo && applyInfo(JSON.parse(appinfo));
        } catch (ex) {
          console.log('Parsing Appinfo failed', appinfo)
        }
      },
      (err) => {
        console.log('Getting from storage failed')

      }
    )
  }, [])


  //STARTUP APP
  useEffect(() => {
    prepareStep1();
  }, [])

  const prepareStep1 = () => {
    //  console.log('Startup App 1/4 - preparing storage')
    StorageService.prepareStorage()
      .then((result) => { prepareStep2() }, (err) => { prepareStep2() });
  }
  const prepareStep2 = () => {
    //  console.log('Startup App 2/4 - registering listeners')


    App.addListener('appStateChange', (state) => {
      setState(state);
      if (state.isActive) {
        setRefresh(new Date());
        checkInfo();
        checkAnnouncements();
      } else {
        resetUpdate();
        //  if (info && info.Index && info.Levels) { UpdateService.cleanDirectory(info.Index, info.Levels).then((result) => { }, (err) => { }); }
      }
      if (info && info.Index && info.Levels) { UpdateService.cleanDirectory(info.Index, info.Levels).then((result) => { }, (err) => { }); }
    });

    App.addListener('appUrlOpen', (data) => {
      console.log('App opened with URL: ' + data.url);
      if (data.url.indexOf('token=') >= 0) {
        let token = data.url.split('token=').pop();
        UserService.setToken(JSON.parse(token));
      }
    });

    App.addListener('backButton', checkPopup);

    prepareStep3();
  }

  const prepareStep3 = () => {
    //   console.log('Startup App 3/4 - checking EMM & autologin');
    //make some EMM settings
    try {
      if (window.cordova.plugins.EmmAppConfig.getValue("allow4g")) {
        if (window.cordova.plugins.EmmAppConfig.getValue("allow4g").toLowerCase() === 'true') {
          localStorage.setItem("downloadcell", true)
        } else {
          localStorage.removeItem("downloadcell")
        }
      }
    } catch (ex) { }

    try {
      if (window.cordova.plugins.EmmAppConfig.getValue("autoupdate")) {
        if (window.cordova.plugins.EmmAppConfig.getValue("autoupdate").toLowerCase() === 'true') {
          localStorage.setItem("autoupdate", true)
        } else {
          localStorage.removeItem("autoupdate")
        }
      }
    } catch (ex) { }

    try {
      if (window.cordova.plugins.EmmAppConfig.getValue("forcedownload")) {
        if (window.cordova.plugins.EmmAppConfig.getValue("forcedownload").toLowerCase() === 'true') {
          localStorage.setItem("forcedownload", true)
        } else {
          localStorage.removeItem("forcedownload")
        }
      }
    } catch (ex) { }

    UserService.checkAutologin().then((tokens) => {
      //todo set access and refesh tokens
      AppSettings.setToken(tokens);
      prepareStep4(true, true);
    }, (err) => {
      prepareStep4()
    });
  }

  const prepareStep4 = (force, redirectToIndex) => {
    //   console.log('Startup App 4/4 - retrieving app info');
    checkInfo(force, redirectToIndex);
    checkAnnouncements();
  }
  //END STARTUP APP

  //MONITOR BACKBUTTON IN WINDOW
  const checkPopup = (event) => {
    if (popup) { setPopup(); }
  }


  useEffect(() => {
    if (popup) {
      window.history.pushState(null, document.title, window.location.href);
    }
    window.addEventListener('popstate', checkPopup);
    return () => {
      window.removeEventListener('popstate', checkPopup);
    }
  }, [popup])
  //END MONITOR BACKBUTTON IN WINDOW

  const logout = (history) => {
    // UserService.setToken('0');

    if (info.Settings.SSOSignoutURL) {
      let win = window.open(context.info.Settings.SSOSignoutURL, '_blank');
    }
    UserService.logout()
      .then(() => {
        settings.setToken({});
        if (history) {
          history.push('settings');
        }
        checkInfo(true);
      })
      .catch(err => {
        settings.setToken({});
        if (history) {
          history.push('settings');
        }
        checkInfo(true);
      })
  }

  const isBeta = () => {
    try {
      return info.Settings.useBeta === true
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }

  const context = {
    version: '3.21.0',
    state: state,
    logout: logout,
    isBeta: isBeta,
    message: message,
    setMessage: updateMessage,
    loading: loading,
    setLoading: setLoading,
    info: info,
    setInfo: applyInfo,
    checkInfo: checkInfo,
    theme: theme,
    setTheme: setTheme,
    announcements: announcements,
    checkAnnouncements: checkAnnouncements,
    currentLevel: currentLevel,
    setCurrentLevel: setCurrentLevel,
    refresh: refresh,
    setRefresh: setRefresh,
    setPopup: setPopup,
    helpText: helpText,
    setHelpText: setHelpText,
    showBackButton: showBackButton,
    setShowBackButton: setShowBackButton,
    title: title,
    setTitle: setTitle,
    downloadList1: downloadList1,
    downloadList2: downloadList2,
    downloadList3: downloadList3,
    downloadList1Total: downloadList1Total,
    downloadList2Total: downloadList2Total,
    downloadList3Total: downloadList3Total,
    downloading: downloading,
    setDownloading: setDownloading,
    history: history,
    setHistory: setHistory,
    setActivateThemeSelect: setActivateThemeSelect,
    activateThemeSelect, activateThemeSelect,
    checkUpdates: checkUpdates,
    //  decryptFile: decryptFile,
    //  decryptedFiles: decryptedFiles,
    //  removeDecryptedFile: removeDecryptedFile,
    // cryptKey: cryptKey,
    //  setCryptKey, setCryptKey,
    hasOnlyAdmin: hasOnlyAdmin
  }

  return (
    <Context.Provider value={context}>
      <div id="snackbar" className={message.length > 0 ? 'show' : ''}>
        <span >{message}</span>
      </div>
   
      {popup && popup}
      {incommingMessage && <PushDialog incommingMessage={incommingMessage} onClose={() => { resetIncommingMessage() }} history={history} />}

      {(info && info.Settings) && props.children}

    </Context.Provider>
  )
};