import React, { useState } from 'react'
import GeneralService from '../../../services/general';
import FilesService from '../../../services-admin/files';
import DeleteAccess from './edit/delete-access';
import RenameAccess from './edit/rename-access';
import { IconButton, Menu, MenuItem } from '../../ui/core';
import { Edit as EditIcon, LowPriority as CopyIcon, Delete as DeleteIcon, FontDownload as EditNameIcon } from '../../ui/coreIcons';

import { MoreVert } from '../../ui/coreIcons';

export default function FilesMenu(props) {
    const [showDelete, setShowDelete] = useState(false)
    const [showRename, setShowRename] = useState(false)
 


    return (
        <div>
            {showDelete && <DeleteAccess version={props.version} access={props.access} onClose={()=>{setShowDelete(false)}} onRefresh={props.onRefresh}/>}
            {showRename && <RenameAccess version={props.version} access={props.access} onClose={()=>{setShowRename(false)}} onRefresh={props.onRefresh}/>}
            <Menu   id="menu-edit-access"      control={<IconButton><MoreVert /></IconButton>}    >
                <MenuItem onClick={() => { setShowRename(true) }}><EditNameIcon />Wijzig naam</MenuItem>
                <MenuItem onClick={() => { setShowDelete(true) }}><DeleteIcon />Verwijderen</MenuItem>
               
            </Menu>
        </div>
    )
}
