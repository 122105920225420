import React from 'react'

import { Paper, CheckBox, TextField, Switch, Divider, IconButton } from '../../ui/core';
import { KeyBoardArrowUp, KeyBoardArrowDown, Delete } from '../../ui/coreIcons';


export default function SingleLine({ item, onChange, onDelete, onChangeSort }) {
    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <TextField fullWidth placeholder="Vraag" value={item.question || ''} onChange={(ev) => { changeItem(ev.target.value, 'question') }} ></TextField>
            <TextField fullWidth placeholder="Kort antwoord" disabled ></TextField>
            {((item.question && (item.question.toLowerCase().indexOf('email') >= 0 || item.question.toLowerCase().indexOf('e-mail') >= 0)) || item.emailowner === true) && <div >
                <br /> <CheckBox checked={item.emailowner || false} onChange={() => { changeItem(!item.emailowner, 'emailowner') }} label="Gebruik dit veld als emailadres van de verzender." />
            </div>}
            {((item.question && (item.question.toLowerCase().indexOf('naam') >= 0 || item.question.toLowerCase().indexOf('name') >= 0)) || item.nameowner === true) && <div >
                <br /> <CheckBox checked={item.nameowner || false} onChange={() => { changeItem(!item.nameowner, 'nameowner') }} label="Gebruik dit veld als naam van de verzender." />
            </div>}
            {((item.question && (item.question.toLowerCase().indexOf('functie') >= 0 || item.question.toLowerCase().indexOf('function') >= 0)) || item.functionowner === true) && <div >
                <br /> <CheckBox checked={item.functionowner || false} onChange={() => { changeItem(!item.functionowner, 'functionowner') }} label="Gebruik dit veld als functie van de verzender." />
            </div>}
            <br /><br /><Divider />
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'up') }}><KeyBoardArrowUp /></IconButton>
            <IconButton style={{ float: 'left' }} onClick={() => { onChangeSort(item, 'down') }}><KeyBoardArrowDown /></IconButton>
            <div style={{ float: 'right' }}>
                <div style={{ display: 'flex' }}>
                    <div>{onDelete && <IconButton onClick={() => { if (window.confirm("Weet u zeker dat u dit element wilt verwijderen?")) { onDelete() } }}><Delete /></IconButton>}</div>
                    <div style={{ padding: '6px' }}> <Switch checked={item.required || false} value="required" onChange={() => { changeItem(!item.required, 'required') }} label="verplicht" /></div>
                </div>
            </div>          
            <br style={{ clear: 'both' }} />
        </Paper>
    )
}
