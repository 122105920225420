import React, { useState, useContext, useRef, useEffect } from 'react'
import { Dialog, DialogContent, Typography } from './core'
import { ContextUI } from '../../uiContext';

const LockScreen = ({ onSubmit, isSet, onSubmitNew, onReset, label }) => {
    const contextUI = useContext(ContextUI);
    const [pin, setPin] = useState('');
    const ref = useRef();
    const inputStyle = { width: '60px', height: '40px', borderRadius: '50%', border: '1px solid ' + contextUI.primary.color, margin: '10px', paddingTop: '20px', color: contextUI.primary.color };
    const pinStyle = { width: '14px', height: '14px', borderRadius: '50%', margin: '6px', border: '1px solid #8a8a8a', backgroundColor: '#8a8a8a' }
    const pinStyleFill = { width: '14px', height: '14px', borderRadius: '50%', margin: '6px', border: '1px solid ' + contextUI.primary.color, backgroundColor: contextUI.primary.color }

    useEffect(() => {
        if (onSubmit) {
            if (pin.length === 5) {
                onSubmit(pin)
            }
        }
        if (pin.length > 5) {
            setPin('')
        }
    }, [pin])

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [ref])



    return (
        <Dialog fullScreen>
            <DialogContent>
                <input type='tel' value={pin} onChange={(ev) => { setPin(ev.target.value) }} ref={ref} style={{ position: 'absolute', top: '-1000px' }} />

                {isSet ? <>
                    <div style={{ width: '100%', textAlign: 'center' }}><Typography component="h3"  >{label || 'Vul hieronder uw pincode in.'}</Typography></div>
                    <div style={{ width: '100%', textAlign: "center", margin: '0 auto', justifyContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                        <div className="label" onClick={() => { if (onReset) { onReset() } }}>Pincode vergeten?<br /><div style={{ color: contextUI.primary.color, textDecoration: 'underline' }}>Klik hier om te resetten.</div> </div>
                    </div>
                </>
                    :
                    <div style={{ width: '100%', textAlign: 'center' }}><Typography component="h3"  >{label || 'Vul hieronder uw gewenste pincode in.'}</Typography></div>
                }

                <div style={{ width: '100%', display: 'flex', textAlign: "center", margin: '0 auto', justifyContent: 'center', paddingBottom: '20px' }} onClick={() => { if (ref.current) { ref.current.focus() } }}>
                    <div style={pin[0] ? pinStyleFill : pinStyle}>&nbsp;</div>
                    <div style={pin[1] ? pinStyleFill : pinStyle}>&nbsp;</div>
                    <div style={pin[2] ? pinStyleFill : pinStyle}>&nbsp;</div>
                    <div style={pin[3] ? pinStyleFill : pinStyle}>&nbsp;</div>
                    <div style={pin[4] ? pinStyleFill : pinStyle}>&nbsp;</div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export { LockScreen }