
const Service = {
    base64Encode: function (string) {
        var encoded = btoa(unescape(encodeURIComponent(string))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        return encoded;
    },
    formatDateTime: function (datetime) {
        var date = new Date(datetime);
        return Service.padding_left(date.getDate() + '', '0', 2) + "-" + Service.padding_left((date.getMonth() + 1) + '', '0', 2) + "-" + date.getFullYear() + " " + Service.padding_left(date.getHours() + '', '0', 2) + ":" + Service.padding_left(date.getMinutes() + '', '0', 2);
    },
    padding_left(s, c, n) {
        if (!s || !c || s.length >= n) {
            return s;
        }
        var max = (n - s.length) / c.length;
        for (var i = 0; i < max; i++) {
            s = c + s;
        }
        return s;
    }

} 
export default Service;