import React, { useState, useEffect } from 'react'
import AppSettingsService from '../../../services-admin/appsettings';
import RedeemCodesService from '../../../services-admin/redeemcodes';
import { TextField, TextArea, CheckBox, Select,Button } from '../../ui/core';

export default function General() {
    const [settings, setSettings] = useState();
    const [newRedeemCodes,setNewRedeemCodes] = useState('');
    const [redeemCodes,setRedeemCodes] = useState();

    const getRedeemCodes = () =>{
        RedeemCodesService.get()
        .then(items=>{setRedeemCodes(items)})
    }

    useEffect(() => {
        AppSettingsService.get()
            .then(result => { setSettings(result) }, err => { })
        getRedeemCodes();
    }, []);

    const handleChange = (ev) => {
        let tmpObj = Object.assign({}, settings);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        setSettings(tmpObj);
    }


    const handleSave = (ev) => {
        let tmpObj = Object.assign({}, settings);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        setSettings(tmpObj);
        AppSettingsService.update(tmpObj)
            .then(result => { }, err => { })

    }

    const importRedeemCodes = () =>{
        const newCodes = newRedeemCodes.split('\n').filter(code=>{return code.length>8});
        setNewRedeemCodes('')
        RedeemCodesService.insert(newCodes)
        .then(result=>{
            getRedeemCodes();
        })
    }

    if (!settings) { return <></> }
    return (
        <div className="padding">
            <br />
            <CheckBox id="AndroidSingleFile" checked={settings.AndroidSingleFile === true} onChange={handleSave} label="Android beschikbaar als losse installatie" />

            {settings.AndroidSingleFile && <><TextField id="AndroidLatestVersion" value={settings.AndroidLatestVersion || ''} label="Actuele android versie" onChange={handleChange} onBlur={handleSave} /><br/><br/></>}

            <CheckBox id="AndroidGooglePlay" checked={settings.AndroidGooglePlay === true} onChange={handleSave} label="Android beschikbaar via Google Play " />

            {settings.AndroidGooglePlay &&  <><TextField id="AndroidGooglePlayLink" value={settings.AndroidGooglePlayLink || ''} label="Link naar Google Play" onChange={handleChange} onBlur={handleSave} /><br/><br/></>}

            <CheckBox id="IOSRedeemcodes" checked={settings.IOSRedeemcodes === true} onChange={handleSave} label="IOS beschikbaar met inwissel codes" />
            {settings.IOSRedeemcodes && redeemCodes && redeemCodes.length>0 && <span>Momenteel zijn er  <b>{redeemCodes.length}</b> codes in de database, waarvan er <b>{redeemCodes.filter(code=>{return code.user}).length}</b> zijn opgevraagd.<br/><br/></span>}
            {settings.IOSRedeemcodes &&  <Button onClick={importRedeemCodes} disabled={newRedeemCodes.length===0}>Importeren</Button>}
            {settings.IOSRedeemcodes &&  <><TextArea id="redeemcodes" value={newRedeemCodes} onChange={(ev)=>{setNewRedeemCodes(ev.target.value)}} label="Vul hier uw losse inwissel codes in, per regel 1 code"/><br/><br/></>}
            
            <CheckBox id="IOSAppStore" checked={settings.IOSAppStore === true} onChange={handleSave} label="IOS beschikbaar via de App Store" />

            {settings.IOSAppStore &&  <><TextField id="IOSAppStoreLink" value={settings.IOSAppStoreLink || ''} label="Link naar App Store" onChange={handleChange} onBlur={handleSave} /><br/><br/></>}
            <br /><br />
            <CheckBox id="UsePin" checked={settings.UsePin === true} onChange={handleSave} label="Vergrendel App met pincode" /><br />
            <CheckBox id="disableAdminInApp" checked={settings.disableAdminInApp === true} onChange={handleSave} label="Schakel de beheerfuncties uit in de Android/IOS/Windows app" /><br />
            <br /><br /><br /><br />
        </div>
    )
}
