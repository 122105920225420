const XLSX = require('xlsx');
const Service = {
    downloadHTMLTable: (table, fileName) => {
        let workbook = XLSX.utils.book_new();
       // var ws1 = XLSX.utils.table_to_sheet(table, { cellNF: false });//, {cellNF:true}
        let ws1 = XLSX.utils.table_to_sheet(table,{ raw: true });
        let keys = Object.keys(ws1);
        keys.forEach(key => { 
            if (ws1[key].v && typeof ws1[key].v === 'string' && ws1[key].v.indexOf('€') >= 0) {
                try {
                    ws1[key].v = parseFloat(ws1[key].v.replace('€ ', '').replace('.', '').replace(',', '.'));
                    ws1[key].t = 'n'
                    //   ws1[key].z = '€ #.##0,00;[Red]€ #.##0,00';
                    //   ws1[key].s = { numFmt: "€ #.##0,00;[Red]€ #.##0,00"} 
                    XLSX.utils.format_cell(ws1[key]);
                } catch (ex) {
                  console.log('Error parsing excel',ws1[key])
                }

            }else if(typeof ws1[key].v === 'string'){
                    ws1[key].v =  ws1[key].v.replace(' ⚠️','');
                    if(ws1[key].v && !isNaN(ws1[key].v.replace('.', '').replace(',', '.') )){
                        ws1[key].v = parseFloat(ws1[key].v.replace('.', '').replace(',', '.'));
                        ws1[key].t = 'n'
                    }
            }
        })
        XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
        XLSX.writeFile(workbook, fileName || 'export.xlsx');
    },
    downloadJSON: (json, fileName) => {
        let workbook = XLSX.utils.book_new();
       // var ws1 = XLSX.utils.table_to_sheet(table, { cellNF: false });//, {cellNF:true}
        let ws1 = XLSX.utils.json_to_sheet(json,{ raw: true });
        let keys = Object.keys(ws1);
        keys.forEach(key => { 
            if (ws1[key].v && typeof ws1[key].v === 'string' && ws1[key].v.indexOf('€') >= 0) {
                try {
                    ws1[key].v = parseFloat(ws1[key].v.replace('€ ', '').replace('.', '').replace(',', '.'));
                    ws1[key].t = 'n'
                    //   ws1[key].z = '€ #.##0,00;[Red]€ #.##0,00';
                    //   ws1[key].s = { numFmt: "€ #.##0,00;[Red]€ #.##0,00"} 
                    XLSX.utils.format_cell(ws1[key]);
                } catch (ex) {
                  console.log('Error parsing excel',ws1[key])
                }

            }else if(typeof ws1[key].v === 'string'){
                    ws1[key].v =  ws1[key].v.replace(' ⚠️','');
                    if(ws1[key].v && !isNaN(ws1[key].v.replace('.', '').replace(',', '.') )){
                        ws1[key].v = parseFloat(ws1[key].v.replace('.', '').replace(',', '.'));
                        ws1[key].t = 'n'
                    }
            }
        })
        XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
        XLSX.writeFile(workbook, fileName || 'export.xlsx');
   
    }
}
export default Service;