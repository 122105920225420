import React from 'react' 


import { TextField, Paper } from '../../ui/core';

export default function SingleLine({item,onChange}) {
    const changeItem = (content,attribute)=>{ 
        let newItem = Object.assign({},item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{marginBottom:'6px'}}>
          <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br/><br/>
           <TextField   id={"textfield"+ item.id} fullWidth value={item.answer||''} placeholder="Antwoord" onChange={(ev)=>{changeItem(ev.target.value,'answer')}}></TextField>
         </Paper>
    )
}
