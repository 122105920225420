import React, { useState, useContext, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle,DialogActions, Typography, Paper, Button } from '../ui/core';
import { Context } from '../../AppContext';
import Settings from '../../AppSettings';
import UserService from '../../services/user';
import Apps from './apps';
export default function SelectApps(props) {
    const context = useContext(Context);
    const [show, setShow] = useState(false);

    useEffect(() => {
        let viewedDialog = false;
        if(context.info && context.info.User && context.info.User.ShowedDialog){
            if(context.info.User.ShowedDialog.indexOf('selectapps')>=0){
                viewedDialog = true;
            }
        }
        if (context.info && context.info.User && !viewedDialog && Settings.getPlatform() === 'web') {
           if((context.info && context.info.Settings && context.info.Settings.AndroidSingleFile)||( context.info && context.info.Settings && context.info.Settings.IOSRedeemcodes))
           {
                setShow(true);
           }

           
        }
    }, [context.info])

    const close = () =>{
        UserService.markDialogShowed('selectapps').then(result=>{
            context.checkInfo(true,true);
            setShow(false);
        },err=>{
            setShow(false);
        })
    }
// 
    return (<>{
        show && <Dialog fullScreen onClose={props.onClose} className="padding-ios">
             <DialogTitle style={{ textAlign: 'center' ,backgroundColor: '#f7f7f7'}}>
                    <Typography component="h1" >Download hier de app voor uw smartphone of tablet</Typography>
                    
                </DialogTitle>
            <DialogContent style={{ backgroundColor: '#f7f7f7' }}  className="padding">
               
                <div  style={{ width: '100%', textAlign: 'center' }}>Deze applicatie is ook beschikbaar als App en kan via onderstaande worden geinstalleerd.<br />
                    U kunt deze gegevens ook terugvinden onder de knop 'instellingen'</div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Apps />
                    <br /> <br />
                </div>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#f7f7f7' }}>
                <Button onClick={() => { close() }}>Ga verder</Button>
            </DialogActions>
        </Dialog>
    }</>

    )
}
