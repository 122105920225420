import React from 'react'

const LoadingPlaceholder = function LoadingPlaceholder(props) {
    return (
        <div  style={{ clear: 'both',boxSizing:'border-box' ,padding:'14px'}}>
             <div style={{width:'80%',height:'16px',backgroundColor:'#eaeaea'}}>&nbsp;</div>
             <div style={{width:'90%',height:'16px',backgroundColor:'#eaeaea',marginTop:'16px'}}>&nbsp;</div>
             <div style={{width:'50%',height:'16px',backgroundColor:'#eaeaea',marginTop:'16px'}}>&nbsp;</div>
        </div>
    )
}
export { LoadingPlaceholder };
