import React from 'react'
import { Select } from '../../../ui/core'

export default function HeadingSettings({ block, onChange}) {
    const handleChange = (value) =>{
        let tmpBlock = Object.assign({}, block);
        tmpBlock.headingSize = value;
        onChange(tmpBlock); 
    } 
    return (
        <div>
           {block &&  <Select label='Kop' id='headingSize' value={block.headingSize || 'h1'} onChange={(ev)=>{handleChange(ev.target.value)}}>
                <option value='h1'>Hoofdstuk</option>
                <option value='h2'>Paragraaf</option>
                <option value='h3'>Subparagraaf</option>
                <option value='h4'>Sub-subparagraaf</option>
            </Select>}
        </div>
    )
}
