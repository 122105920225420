/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Context } from "../AppContext";
import { ContextUI } from '../uiContext';
import AppSettings from '../AppSettings';
import GeneralService from '../services/general';
import UpdateService from '../services/updateservice';
import { Network , Secure} from '../plugins/capacitor';
 
const Info = (props) => {
  const contextUI = useContext(ContextUI);
  const createDOMPurify = require('dompurify');
  const DOMPurify = createDOMPurify(window);
  const context = useContext(Context);
  const [connectionType, setConnectionType] = useState('');

  useEffect(() => {
    contextUI.setTitle('Info');
    contextUI.setSubTitle('');
    contextUI.setShowBackButton(false);


    Network.getStatus().then((status) => {
      setConnectionType(status.connectionType);
    }, (err) => {
      console.log(err)
    })

    Network.addListener('networkStatusChange', status => {
      setConnectionType(status.connectionType)
    });

    return () => {
      Network.removeAllListeners();
    }
  }, [])


  return (
    <div className="padding">
      {context.info.Settings && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(GeneralService.processTags(context.info.Settings.Infotext)) }}></div>}
      <div className="label" style={{ textAlign: "right", paddingTop: '20px' }}>
        installatie ID : {AppSettings.getUUID() || navigator.userAgent.toLowerCase()}<br />
        platform : {AppSettings.getPlatform() || 'web'} {AppSettings.getDevice().version}<br />
        verbinding : {connectionType}<br />
        versie : {context.version}<br />
     
      </div>

    </div>
  )
}
export default Info
