import React, { useState, useEffect } from 'react'
import AppSettingsService from '../../../services-admin/appsettings';
import ServiceFile from '../../../services-admin/files';
import { TextField, TextArea, CheckBox, Select } from '../../ui/core';

export default function Account() {
    const [settings, setSettings] = useState();
    const [accessList, setAccessList] = useState([]);

 
    useEffect(() => {
        AppSettingsService.get()
            .then(result => {
                setSettings(result);

            }, err => { })

        ServiceFile.getAccess()
            .then(result => { 
                if(result.length>0){
                    setAccessList(result.sort());
                }else{
                    setAccessList(["prive"]);
                }
            }, err => { })
    }, []);

    const handleChange = (ev) => {
        let tmpObj = Object.assign({}, settings);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        setSettings(tmpObj);
    }


    const handleSave = (ev) => {
        let tmpObj = Object.assign({}, settings);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        setSettings(tmpObj);
        AppSettingsService.update(tmpObj)
            .then(result => { }, err => { })
    }
    //   <CheckBox id="EncryptFiles" checked={settings.EncryptFiles === true} onChange={handleSave} label="Encrypt bestanden in de App" />

    const toggleAccess = (access) =>{
        let tmpObj = Object.assign({}, settings);
       if(!tmpObj.defaultAccessNewUser) tmpObj.defaultAccessNewUser = [];
       if( tmpObj.defaultAccessNewUser.indexOf(access)>=0){
        tmpObj.defaultAccessNewUser = tmpObj.defaultAccessNewUser.filter(a=>{return a!=access})
       }else{
        tmpObj.defaultAccessNewUser.push(access);
       }
        setSettings(tmpObj);
        AppSettingsService.update(tmpObj)
            .then(result => { }, err => { })
    }

    if (!settings) { return <></> }
    return (
        <div className="padding">
            <br/>
            <CheckBox id="CanRequestPassword" checked={settings.CanRequestPassword === true} onChange={handleSave} label="Wachtwoord aanmaken in de App mogelijk" />
            <br/>
            <CheckBox id="disabledefaultlogin" checked={settings.disabledefaultlogin === true} onChange={handleSave} label="Inloggen met accountgegevens van de App uitschakelen. (vanaf v.3.4.6)" />
            {settings.disabledefaultlogin && <div style={{paddingLeft:'60px'}}>
                <div className="label">Geef aan voor welke platformen de inlog uitgeschakeld moet worden.</div><br/>
                <CheckBox id="disabledefaultloginweb" checked={settings.disabledefaultloginweb === true} onChange={handleSave} label="Website" /><br/>
                <CheckBox id="disabledefaultloginandroid" checked={settings.disabledefaultloginandroid === true} onChange={handleSave} label="Android" /><br/>
                <CheckBox id="disabledefaultloginios" checked={settings.disabledefaultloginios === true} onChange={handleSave} label="IOS" /><br/>
                <CheckBox id="disabledefaultloginelectron" checked={settings.disabledefaultloginelectron === true} onChange={handleSave} label="Windows" /><br/>
                </div>}
            <br />
            <Select label="Beperking gebruiker" id="NumberOfDevices" value={settings.NumberOfDevices || '-99'} onChange={handleSave}>
                <option value='-99'>onbeperkt aantal installaties</option>
                <option value='0'>installatie eerst handmatig goedkeuren</option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => { return <option key={'opt' + i} value={i}>{i} installatie(s)</option> })}
            </Select>
            <br />
            <div className='label'>Standaard toegangen die aan een nieuwe gebruiker gekoppeld worden</div>
            {accessList.map(access=>{
                return      <CheckBox key={access} id={access} checked={settings.defaultAccessNewUser? settings.defaultAccessNewUser.indexOf(access)>=0:false} onChange={()=>{toggleAccess(access)}} label={access} />
            })}
            <br /><br />
        </div>
    )
}
