import React, { useState, useContext, useEffect } from 'react'
import TextBlock from './fillin/textblock';
import SingleLine from './fillin/singleline';
import MultiLine from './fillin/multiline';
import RadioButtons from './fillin/radiobutton';
import CheckBox from './fillin/checkbox';
import UploadFile from './fillin/uploadfile';
import DateTime from './fillin/datetime'
import Score from './fillin/score';
import { Button } from '../ui/core';
import { Context } from '../../AppContext';
import FormService from '../../services/form';

export default function Fillinform({ form, answer, onClose, readonly }) {
    const [filledForm, setFilledform] = useState();//useState(JSON.parse(JSON.stringify(form)));
    const context = useContext(Context);
    const [canSaveAsConcept,setCanSaveAsConcept] = useState(true);

    useEffect(() => {
        let tmpForm = JSON.parse(JSON.stringify(form));

        if (context && context.info && context.info.User) {
            if(context.info.User.generalAccount && tmpForm.generalAccountCanSaveAsConcept !== true){
                setCanSaveAsConcept(false);
            }
            
            if (tmpForm.lines) {
                tmpForm.lines.forEach(line => {
                    if (line && line.emailowner === true && context.info.User.Email && context.info.User.Email.indexOf('@') >= 0) {
                        line.answer = context.info.User.Email;
                    }
                    if (line && line.nameowner === true) {
                        line.answer = context.info.User.Name;
                    }
                    if (line && line.functionowner === true) {
                        line.answer = context.info.User.Functie;
                    }
                });
            }

        }
        setFilledform(tmpForm);
    }, [form])

    useEffect(() => {
        if (answer) {
            setFilledform(answer);
        }
    }, [answer])

    const updateFilledForm = (line) => {
        let newFilledForm = Object.assign({}, filledForm);
        if (!newFilledForm.lines) { newFilledForm.lines = [] };
        let found = false;
        for (let l = 0; l < newFilledForm.lines.length; l++) {
            if (newFilledForm.lines[l].id === line.id) {
                newFilledForm.lines[l] = line;
                found = true;
            }
        }
        if (!found) { newFilledForm.lines.push(line) }
        setFilledform(newFilledForm);
    }

    const checkComplete = (filledForm) => {
        for (let l = 0; l < filledForm.lines.length; l++) {
            if (filledForm.lines[l].required && filledForm.lines[l].element !== 0) {

                if ([4, 5].indexOf(filledForm.lines[l].element) >= 0) {

                    if (!filledForm.lines[l].answers || (filledForm.lines[l].answers && filledForm.lines[l].answers.length === 0)) {

                        return false;
                    }
                } else {
                    if (!filledForm.lines[l].answer || (filledForm.lines[l].answer && filledForm.lines[l].answer.length === 0)) {

                        return false;
                    }
                }
            }
        }
        return true;
    }

    const sendForm = () => {
        if (checkComplete(filledForm)) {
            let newFilledForm = Object.assign({}, filledForm);
            if (context.info && context.info.User) {
                newFilledForm.sender = context.info.User
            }
            newFilledForm.concept = false;
            newFilledForm.dateSend = new Date();
            FormService.send(newFilledForm).then(result => {
                context.setMessage('Het formulier is verzonden.');
                setFilledform(Object.assign({}, form));
                onClose && onClose();
            }, (err) => {
                context.setMessage('Het versturen is mislukt, probeert het later nog eens.');
            })


        } else {
            context.setMessage('Nog niet alle verplichte velden zijn ingevuld')
        }
    }
    const sendFormAsConcept = () => {
       // if (checkComplete(filledForm)) {
            let newFilledForm = Object.assign({}, filledForm);
            if (context.info && context.info.User) {
                newFilledForm.sender = context.info.User
            }
            newFilledForm.dateSend = new Date();
            newFilledForm.concept = true;
            FormService.send(newFilledForm).then(result => {
                context.setMessage('Het formulier is als concept opgeslagen.');
                setFilledform(Object.assign({}, form));
                onClose && onClose();
            }, (err) => {
                context.setMessage('Het opslaan is mislukt, probeert het later nog eens.');
            })
      //  } else {
      //      context.setMessage('Nog niet alle verplichte velden zijn ingevuld')
      //  }
    }
    if (!filledForm) return null;

    return (
        <div>
            {filledForm && filledForm.lines && filledForm.lines.map(line => {
                return <React.Fragment key={line.id}>
                    {line.element === 0 && <TextBlock item={line} />}
                    {line.element === 1 && <SingleLine item={line} onChange={updateFilledForm} />}
                    {line.element === 2 && <MultiLine item={line} onChange={updateFilledForm} />}
                    {line.element === 3 && <RadioButtons item={line} onChange={updateFilledForm} />}
                    {line.element === 4 && <CheckBox item={line} onChange={updateFilledForm} />}
                    {line.element === 5 && <UploadFile id={line.id} item={line} onChange={updateFilledForm} />}
                    {line.element === 6 && <DateTime id={line.id} item={line} onChange={updateFilledForm} />}
                    {line.element === 7 && <Score id={line.id} item={line} onChange={updateFilledForm} />}

                </React.Fragment>
            })}
            <div style={{ display: 'flex' }}>
                {!readonly && <Button fullWidth color="primary" variant="contained" onClick={sendForm} disabled={!filledForm.lines}>Verstuur</Button>}
                {!readonly && canSaveAsConcept && <Button fullWidth color="primary" variant="outlined" onClick={sendFormAsConcept} disabled={!filledForm.lines}>Opslaan als concept</Button>}
            </div>
        </div>
    )
}
