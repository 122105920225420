import React, { useState,useContext } from 'react'

import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Button, Typography } from '../../../ui/core';

import FilesService from "../../../../services-admin/files";

import {Context} from '../../../../AppContext';
export default function RenameAcccess(props) {
    const context = useContext(Context);
    const [newName, setNewName] = useState(props.access);

    const save = () => {
        if (newName !== 'prive' && newName !== 'publiek') {
            FilesService.renameAccess(props.version, props.access, newName.replace(/\//g, '_'))
                .then(result => {
                    context.checkInfo(true);
                    props.onRefresh();
                    props.onClose();
                }, (err) => {
                    props.onRefresh();
                    props.onClose();
                })
        }
    }

    return (
        <Dialog fullWidth maxWidth={'xl'} open={true} onClose={props.onClose}>
            <DialogTitle><Typography component="h2">Aanpassen naamgeving</Typography></DialogTitle>
            <DialogContent >
                <TextField id='newname' label="Nieuwe naam" fullWidth value={newName} onChange={(ev) => setNewName(ev.target.value)}></TextField>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={save} disabled={newName==='' || newName === props.access || newName === 'prive' || newName === 'publiek'}>Wijzigen</Button>
            </DialogActions>
        </Dialog>
    )
}
