import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: true,error:error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  //  logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
   //   return <>.{JSON.stringify(this.state)}</>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;