import React from 'react'
import { List, ListItem, ListItemText, ListItemIcon } from '../ui/core';
import { NavigateNext } from '../ui/coreIcons';
import GeneralService from '../../services/general';
export default function Announcements(props) {
    return (
        <>
            {props.announcements.length === 0
                ? <div style={{width:'100%',fontStyle:'italic',color:'gray',textAlign:'center'}}><br /><br />Geen mededelingen gevonden</div>
                : <React.Fragment>
                    <List>
                        {props.announcements.map((announcement) => {
                            return <ListItem key={'announcement' + announcement._id} onClick={() => { props.history.push('/announcements/' + announcement._id) }} >
                                <ListItemText primary={<span style={{ fontWeight: (announcement.read ? 'normal' : 'bold') }}>{announcement.title}</span>} secondary={GeneralService.formatDate(announcement.date,true)} ></ListItemText>
                                <ListItemIcon>  <NavigateNext /></ListItemIcon>
                            </ListItem>
                        })}
                    </List>
                </React.Fragment>
            }
        </>
    )
}
