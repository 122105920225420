import React from 'react'
import './badge.css'

const Badge = function Badge(props) {
    return (
        <div onClick={()=>{props.onClick && props.onClick()}}>
            {props.count > 0 && <div className={'ui-badge'}>{props.count}</div>}
            {props.children}
        </div>
    )
}
export { Badge };
