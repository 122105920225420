import axios from 'axios';
import Settings from "../AppSettings";
import UserService from "./user";
const locationAPI = Settings.getLocationAPIV2();

const refreshAccessToken = () => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};
        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        prepHeaders['Accept'] = 'application/json; charset=utf-8';

        axios.post(locationAPI + 'auth/refresh', { uuid:Settings.getUUID(),refresh_token: Settings.getToken().refresh_token, access_token: Settings.getToken().access_token }, { headers: prepHeaders })
            .then(res => {
                Settings.setToken(res.data); 
                UserService.saveTokens(res.data);
                resolve(res.data.access_token);
            }, err => {
                console.log('err', err);
                reject(err);
            })
    })
}
const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const access_token = await refreshAccessToken();
            originalRequest.headers['authorization'] = 'Bearer ' + access_token;

        } catch (ex) { }
        return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
});

const get = (attribute, headers) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};
        prepHeaders['platform'] = Settings.getPlatform();

        if (Settings.getToken().access_token && Settings.getToken().access_token.length > 0) {
            prepHeaders['authorization'] = 'Bearer ' + Settings.getToken().access_token;
        }

        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }

        axiosApiInstance.get(((attribute.indexOf('apiv2') > 0) ? '' : locationAPI) + attribute, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                if (err && err.response && err.response.status === 401) {
                    console.log('Logging out, got 401')
                    Settings.setToken({});
                    UserService.logout();
                }
                reject(err);
            })
    })
}

const post = (attribute, headers, data) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};

        data.uuid = Settings.getUUID();
        data.device = JSON.stringify(Settings.getDevice());
        if (localStorage.getItem('fcm')) {
            data.fcmtoken = localStorage.getItem('fcm');
        }
        prepHeaders['platform'] = Settings.getPlatform();

        if (Settings.getToken().access_token && Settings.getToken().access_token.length > 0) {
            prepHeaders['authorization'] = 'Bearer ' + Settings.getToken().access_token;
        }
        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }
        //axios.defaults.withCredentials = false;

        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        prepHeaders['Accept'] = 'application/json; charset=utf-8';

        axiosApiInstance.post(locationAPI + attribute, data, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                if (err && err.response && err.response.status === 401) {
                    Settings.setToken({});
                    UserService.logout();
                }
                reject(err);
            })

    })
}

const put = (attribute, headers, data) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};

        prepHeaders['platform'] = Settings.getPlatform();
        if (Settings.getToken().access_token && Settings.getToken().access_token.length > 0) {
            prepHeaders['authorization'] = 'Bearer ' + Settings.getToken().access_token;
        }

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }

        axiosApiInstance.put(locationAPI + attribute, { item: data }, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                if (err && err.response && err.response.status === 401) {
                    Settings.setToken({});
                    UserService.logout();
                }
                reject(err);
            })
    })
}

const deleteItem = (attribute, headers) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};

        prepHeaders['platform'] = Settings.getPlatform();
        prepHeaders['authorization'] = 'Bearer ' + Settings.getToken().access_token;

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }

        axiosApiInstance.delete(locationAPI + attribute, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                if (err && err.response && err.response.status === 401) {
                    Settings.setToken({});
                    UserService.logout();
                }
                reject(err);
            })
    })
}
export default { get, post, put, deleteItem };
