import React, { useState, useEffect, useContext } from 'react'
import FilesService from "../../../../services-admin/files";
import { Context } from '../../../../AppContext';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Typography } from '../../../ui/core';
import HTMLBuilder from '../../htmlbuilder/index';
import ServiceHTMLBuilder from '../../htmlbuilder/services';
export default function EditHtmlBuilder(props) {
    const context = useContext(Context);
    const [builderFile, setBuilderFile] = useState();
    const [files, setFiles] = useState([])


    useEffect(() => {
        FilesService.getFileContent(props.version, props.access, props.level, props.file.filename + '.htmlbuilder')
            .then((result) => {

                if (result) {
                    if (result.publishPage === undefined) result.publishPage = true;
                    setBuilderFile(result);
                }
            }, (err) => {

            })

        FilesService.get(props.version, props.access, props.level)
            .then(files => {
                setFiles(files);

            }, err => {
                setFiles([]);
            })

    }, [props.file])

    const handleChange = (item) => {
        setBuilderFile(item);
    }

    const save = () => {
        FilesService.saveBase64(props.version, props.access, props.level, props.file.filename + '.htmlbuilder', FilesService.encodeUnicode(JSON.stringify(builderFile)))
            .then((result) => {

                if (builderFile.publishPage === true) {
                    ServiceHTMLBuilder.createHTML(builderFile)
                        .then(html => {
                            FilesService.saveBase64(props.version, props.access, props.level, props.file.filename, FilesService.encodeUnicode(html))
                                .then((result) => {
                                    props.onRefresh && props.onRefresh();
                                    props.onClose && props.onClose();
                                })
                                .catch(ex => {
                                    props.onRefresh && props.onRefresh();
                                    props.onClose && props.onClose();
                                })
                        })
                        .catch(ex => {
                            props.onRefresh && props.onRefresh();
                            props.onClose && props.onClose();
                        })
                } else {
                    FilesService.saveBase64(props.version, props.access, props.level, props.file.filename,FilesService.encodeUnicode(""))
                    .then((result) => {
                        props.onRefresh && props.onRefresh();
                        props.onClose && props.onClose();
                    })
                    .catch(ex => {
                        props.onRefresh && props.onRefresh();
                        props.onClose && props.onClose();
                    })
                    /*
                    FilesService.deleteItem(props.version, props.access, props.level, props.file.filename)
                    .then((result) => {
                        props.onRefresh && props.onRefresh();
                        props.onClose && props.onClose();
                    })
                    .catch(ex => {
                        props.onRefresh && props.onRefresh();
                        props.onClose && props.onClose();
                    })
                    */
                }
            }, (err) => {
                props.onRefresh && props.onRefresh();
                props.onClose && props.onClose();
            })
    }

    const handleUpload = (file) => {
        FilesService.saveBase64(props.version, props.access, props.level, file.name, file.base64)
            .then(result => {
                props.onRefresh();
            }, (err) => {
                context.setMessage('Bestand ' + file.name + ' toevoegen mislukt')
            })
    }
    return (
        <Dialog fullScreen onClose={props.onClose} className="padding-ios">
            <DialogTitle><Typography component="h2">{props.file.filename}</Typography></DialogTitle>
            <DialogContent style={{ padding: '0px' }}>
                <HTMLBuilder builderFile={builderFile} onChange={handleChange} files={files} uploadFile={handleUpload} pages={props.pages} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={save}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
