
import { Filesystem, FilesystemDirectory, FilesystemEncoding } from '../plugins/capacitor';
import FileService from '../services/file';
//import UpdateService from '../services/updateservice';
//import ElectronService from '../services/electron';
import EncryptionService from './encryption';
import { Network, Secure, App } from '../plugins/capacitor';
import Settings from '../AppSettings';
import mime from 'mime';
import {FileOpener} from '@capacitor-community/file-opener';
//const worker = new Worker('./workers/encryptdownload.js');
const locationAPI = Settings.getLocationAPI();

const arrayBufferToBase64 = function (buffer) {
    return new Promise(function (resolve, reject) {
        var blob = new Blob([buffer], { type: 'application/octet-binary' });
        var reader = new FileReader();
        reader.onload = function (evt) {
            var dataurl = evt.target.result;
            resolve(dataurl.substr(dataurl.indexOf(',') + 1));
        };
        reader.onerror = function (err) {
            reject(err);
        }
        reader.readAsDataURL(blob);
    })
}

const downLoadFileAsBase64 = function (url) {
    return new Promise(function (resolve, reject) {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status == 200) {
                arrayBufferToBase64(xhttp.response)
                    .then(function (base64) {
                        resolve(base64);
                    }, function (err) {
                        reject(err)
                    })
            } else if (this.readyState === 4) {
                reject(this.status)
            }
        };
        xhttp.open("GET", url, true);
        xhttp.responseType = "arraybuffer";
        xhttp.setRequestHeader('responseType', 'arraybuffer')
        xhttp.send();
    })
}

const Service = {
    getStorageFileName: (item) => {
        return "_" + item.HFile + "_" + item.Checksum;
    },
    documentsDir: () => {
        if (Settings.getPlatform() === 'electron') {
            return 'Handboek-documenten';
        } else {
            return 'docs';
        }
    },
    settingsDir: () => {
        if (Settings.getPlatform() === 'electron') {
            return 'Handboek-instellingen';
        } else {
            return 'settings';
        }
    },
    getDataDirectory: () => {
        if (Settings.getPlatform() === 'electron') {
            return FilesystemDirectory.Documents;
        } else {
            return FilesystemDirectory.Data;
        }

    },
    prepareStorage: () => {
        return new Promise((resolve, reject) => {
            // console.log('preparing storage'+ Service.documentsDir()+'/'+Service.getDataDirectory())
            Filesystem.mkdir({ path: Service.documentsDir(), directory: Service.getDataDirectory(), createIntermediateDirectories: true })
                .then(
                    (result) => {
                        Filesystem.mkdir({ path: Service.settingsDir(), directory: Service.getDataDirectory(), createIntermediateDirectories: true })
                            .then((result) => { resolve() }, (err) => { console.log(err); reject(err) })
                    },
                    (err) => {
                        Filesystem.mkdir({ path: Service.settingsDir(), directory: Service.getDataDirectory(), createIntermediateDirectories: true })
                            .then((result) => { resolve() }, (err) => { console.log(err); reject(err) })
                    })
        });
    },
    listDirIndex: () => {
        return new Promise((resolve, reject) => {
            Filesystem.readdir({
                path: Service.documentsDir(),
                directory: Service.getDataDirectory()
            }).then((result) => {
                resolve(result)
            }, (err) => {
                reject(err)
            })
        });
    },
    getUriDocs: () => {
        return new Promise((resolve, reject) => {
            Filesystem.getUri({
                directory: Service.getDataDirectory(),
                path: Service.documentsDir()
            }).then((result) => { resolve(result.uri) }, (err) => { reject(err) });
        });
    },
    open: (DPath, storageFileName, fileName) => {  //new function jan 2022
        return new Promise((resolve, reject) => {
            Service.readFile(Service.documentsDir(), storageFileName, false)
                .then(data => {
                    Secure.getSecureKey()
                        .then(retKey => { 
                            EncryptionService.aesDecryptParts(data, retKey.value)
                                .then(encrypted => { 
                                    Service.writeFile(Service.documentsDir(), fileName, encrypted)
                                        .then(saveResult => { 
                                            FileOpener.open({filePath:saveResult.uri,contentType:FileService.getMimeType(fileName)})
                                            .then(result => {   resolve();  })
                                            .catch(err => { console.log(err) ; reject();})
                                            /*
                                            if (window.cordova && window.cordova.plugins && window.cordova.plugins.fileOpener2) {
                                                window.cordova.plugins.fileOpener2.open(saveResult.uri, FileService.getMimeType(fileName), {
                                                    error: function (e) {
                                                        reject();
                                                    },
                                                    success: function () {
                                                        resolve();
                                                    }
                                                });
                                            } else {
                                                reject();
                                            }
                                            */
                                        })
                                        .catch(err => {
                                            console.log(err)
                                            reject();
                                        })
                                })
                                .catch(err => {
                                    console.log(err)
                                    reject();
                                })
                        })
                        .catch(err => {
                            console.log(err)
                            reject();
                        })
                })
                .catch(err => {
                    console.log(err)
                    reject(err);
                })
        })
    },
    downloadAndSaveEncrypted: (url, fileName, secret) => { //new function jan 2022
        return new Promise((resolve, reject) => {
            downLoadFileAsBase64(url)
                .then(base64 => {
                    EncryptionService.aesEncryptParts(base64, secret)
                        .then(encrypted => {                            
                            Service.writeFileBase64(Service.documentsDir(), fileName, encrypted)
                                .then(result => {
                                    resolve();
                                })
                                .catch(err => {
                                    console.log('Error writing base64 file', err)
                                    reject();
                                })
                        })
                        .catch(ex => {
                            console.log('Error enc', ex)
                            reject();
                        })

                })
                .catch(err => {
                    reject();
                })
        })
    },
    /*
    previewDocument: (item, history) => {
        return new Promise((resolve, reject) => {
            let fileName = Service.getStorageFileName(item);

            Service.getUriDocs()
                .then((fileUri) => {
                    if (fileUri[fileUri.length - 1] !== '/') { fileUri = fileUri + '/' }

                    if (Settings.getPlatform() === 'electron') {
                        if (history && item.Extention === 'pdf') {
                            history.push('/pdf/' + item.DPath);
                            resolve();
                        } else {
                            UpdateService.getDownloadToken(item.DPath)
                                .then(token => {
                                    ElectronService.openDocument(fileUri + item.Name, locationAPI + 'documentv3/' + token + '/?' + item.DPath, fileUri + fileName)
                                        .then((result) => {
                                            resolve();
                                        }, (err) => {
                                            reject(err);
                                        })
                                })
                                .catch(ex => {
                                    reject(ex);
                                })
                        }
                    } else {
                        Service.statDocument(fileName)
                            .then((result) => {
                                Service.copyDocument(fileUri + fileName, fileUri + item.Name)
                                    .then((result) => {
                                        Service.openFile(fileUri + item.Name, item, history)
                                            .then((result) => {
                                                resolve(result)
                                            }, (err) => {
                                                reject(err)
                                            });
                                    }, (err) => {
                                        reject(err)
                                    })
                            }, (err) => {
                                UpdateService.getDownloadToken(item.DPath)
                                    .then(token => {
                                        Service.downloadFile(locationAPI + 'documentv3/' + token + '/?' + item.DPath, item.Name)
                                            .then((resultFile) => {
                                                Service.openFile(resultFile, item, history)
                                                    .then((result) => {
                                                        resolve(result)
                                                    }, (err) => {
                                                        reject(err)
                                                    });
                                            },
                                                (err) => { reject(err) });
                                    })
                                    .catch(ex => {
                                        console.log(ex);
                                        reject(ex);
                                    })
                            })
                    }
                }, (err) => {
                    if (history && item.Extention === 'pdf' && Settings.getPlatform() === 'electron') {
                        history.push('/pdf/' + item.DPath);
                        resolve();
                    } else {
                        reject()
                    }
                })
        })
    },
    copyDocument: (source, target) => {
        return new Promise((resolve, reject) => {
            console.log("Cannot copy")
            //todo
            //https://capacitorjs.com/docs/apis/filesystem#copyoptions
            //     Filesystem.copy(from,to,directory,toDirectory)

            /*
            var fileTransfer = new window.FileTransfer();
            fileTransfer.download(
                source,
                target,
                function (entry) {
                    console.log('Download OK');
                    resolve();
                },
                function (error) {
                    console.log(error)
                    console.log("download error source " + error.source);
                    console.log("download error target " + error.target);
                    console.log("download error code" + error.code);
                    reject(error);
                },
                false
            );
            
        });
    },
    */
    removeDocument: (file) => {
        return new Promise((resolve, reject) => {

            Filesystem.deleteFile({
                path: Service.documentsDir() + '/' + file,
                directory: Service.getDataDirectory()
            }).then((result) => { resolve(result) }, (err) => { reject(err) });
        });
    },
    statDocument(file) {
        return new Promise((resolve, reject) => {

            Filesystem.stat({
                path: Service.documentsDir() + '/' + (file || ''),
                directory: Service.getDataDirectory()
            }).then((result) => { resolve(result) }, (err) => { reject(err) });
        });
    },
    openFile: (filePath, item, history) => {
        return new Promise((resolve, reject) => {
            if (history && item && item.Extention === 'pdf' && Settings.getPlatform() === 'electron') {
                history.push('/pdf/' + item.DPath);
                resolve();
            } else {
                console.log('open', filePath)
                if (window.cordova && window.cordova.plugins && window.cordova.plugins.fileOpener2) {
                    window.cordova.plugins.fileOpener2.open(filePath, FileService.getMimeType(filePath), {
                        error: function (e) {
                            console.log('Openen mislukt 1', e);
                            reject();
                        },
                        success: function () {
                            resolve();
                        }
                    });
                } else {
                    reject();
                }
            }
        });
    },
    writeFile: (path, fileName, content) => {
        return new Promise((resolve, reject) => {
            Filesystem.writeFile({
                path: path + '/' + fileName,
                data: content,
                directory: Service.getDataDirectory()
            }).then((result) => {
                resolve(result);
            }, (err) => {
                console.log('error writing')
                console.log(err)
                reject(err);
            })
        })
    },
     decodeBase64:(base64)=> {
        const text = atob(base64);
        const length = text.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = text.charCodeAt(i);
        }
        const decoder = new TextDecoder(); // default is utf-8
        return decoder.decode(bytes);
    },
    readFile: (path, fileName, decrypt) => {
        return new Promise((resolve, reject) => {
            Filesystem.readFile({
                path: path + '/' + fileName,
                directory: Service.getDataDirectory(),
                encoding: FilesystemEncoding.UTF8
            }).then((result) => {
                if (decrypt) {
                    Secure.getSecureKey()
                        .then((retSecret) => {
                            EncryptionService.aesDecryptParts(result.data, retSecret.value)
                                .then(decrypted => {
                                    //resolve(atob(decrypted));
                                    console.log(fileName);
                                    resolve(Service.decodeBase64(decrypted));
                                })
                                .catch(err => {
                                    reject(err);
                                })
                        })
                        .catch(err => {
                            reject(err);
                        })
                } else {
                    resolve(result.data);
                }

            }, (err) => {
                reject(err);
            })
        })
    },
    removeFile: (path, fileName) => {
        return new Promise((resolve, reject) => {

            Filesystem.deleteFile({
                path: path + '/' + fileName,
                directory: Service.getDataDirectory(),
            }).then((result) => {
                resolve(result.data);
            }, (err) => {
                reject(err);
            })
        })
    },
    readFileBase64: (path, fileName, Extention) => {
        return new Promise((resolve, reject) => {
            Filesystem.readFile({
                directory: Service.getDataDirectory(),
                path: path + '/' + fileName,
                //   encoding: FilesystemEncoding.UTF8
            }).then((result) => {
                let useMimeType = mime.getType(Extention) || 'image/png';
                let retValue = 'data:' + useMimeType + ';base64,' + result.data;
                resolve(retValue);
            }, (err) => { reject(err) })
        })
    },
    writeFileBase64: (path, fileName, content) => {
        return new Promise((resolve, reject) => {

            Filesystem.writeFile({
                path: path + '/' + fileName,
                data: content,
                directory: Service.getDataDirectory(),
                encoding: FilesystemEncoding.UTF8
            }).then((result) => {
                resolve();
            }, (err) => {
                console.log('error writing')
                console.log(err)
                reject(err);
            })
        })
    },
}
export default Service;