import React, { useState, useEffect } from 'react'
import AppSettingsService from '../../../services-admin/appsettings';
import ServiceFile from '../../../services-admin/files';
import { TextField, TextArea, CheckBox, Select } from '../../ui/core';
export default function General() {
    const [settings, setSettings] = useState();
    const [accessList, setAccessList] = useState([]);

    useEffect(() => {
        AppSettingsService.get()
            .then(result => {
                setSettings(result);

            }, err => { })

        ServiceFile.getAccess()
            .then(result => { 
                setAccessList(result.sort());
            }, err => { })
    }, []);

    const handleChange = (ev) => {
        let tmpObj = Object.assign({}, settings);
        tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
        setSettings(tmpObj);
    }


    const handleSave = (ev,ignoreEvent) => {
        let tmpObj = Object.assign({}, settings); 
          tmpObj[ev.target.id] = ev.target.value || ev.target.checked || '';
   
        setSettings(tmpObj);
        AppSettingsService.update(tmpObj)
            .then(result => { }, err => { })

    }

    const handleSaveWithoutUpdate = (ev) => {
        let tmpObj = Object.assign({}, settings);  
 
        setSettings(tmpObj);
        AppSettingsService.update(tmpObj)
            .then(result => { }, err => { })

    }
    //   <CheckBox id="EncryptFiles" checked={settings.EncryptFiles === true} onChange={handleSave} label="Encrypt bestanden in de App" />

    const getValueSubscription = (access,attribute) =>{
        if(settings.subscribeAccess ) {
            let found = settings.subscribeAccess.find(acc=>{return acc.access===access});
            if(found)  return found[attribute] || "";
        }
        return "";       
    }
  
    const handleChangeAccess = (access,attribute,value) => {
        let tmpObj = Object.assign({}, settings);
       
        if(!tmpObj.subscribeAccess) tmpObj.subscribeAccess = [];
        let found = tmpObj.subscribeAccess.find(acc=>{return acc.access===access});
        if(found){
            found[attribute] = value;
        }else{
            let newObj = {};
            newObj.access = access;
            newObj[attribute] = value;
            tmpObj.subscribeAccess.push(newObj)
        }  
        setSettings(tmpObj);
    }

    if (!settings) { return <></> }
    return (
        <div className="padding">
            <TextField id="Title" value={settings.Title || ''} label="Titel voor de App" onChange={handleChange} onBlur={handleSave} />
            <TextArea id="Infotext" value={settings.Infotext || ''} label="Informatietekst in de App" onChange={handleChange} onBlur={handleSave} />
            <TextArea id="PrivacyPolicy" value={settings.PrivacyPolicy || ''} label="Privacy verklaring" onChange={handleChange} onBlur={handleSave} />
           
            <br /><br />
            <TextField id="RecieverFeedback" value={settings.RecieverFeedback || ''} label="Ontvanger feedback (E-mailadres)" onChange={handleChange} onBlur={handleSave} />
            <TextField id="DefaultMainSender" value={settings.DefaultMainSender || ''} label="Standaard E-mailadres voor verzenden mail" onChange={handleChange} onBlur={handleSave} />
            <TextArea id="TemplateMail" value={settings.TemplateMail || ''} label="Template voor het mailbericht" helper="Gebruik de tag [content] op de plek waar het bericht moet komen te staan." onChange={handleChange} onBlur={handleSave} />

            <br /><br />

            <Select label="Weergave menu" id="MenuStyle" value={settings.MenuStyle || '0'} onChange={handleSave}>
                <option value='0'>menuitems onderin de balk</option>
                <option value='1'>uitklap menu bovenin</option>
            </Select>
            <Select label="Uitlijning tegels" id="TileStyle" value={settings.TileStyle || 'center'} onChange={handleSave}>
                <option value='center'>centreren</option>
                <option value='left'>links</option>
            </Select>
            <br /><br />
            <CheckBox id="hideLevelNumbers" checked={settings.hideLevelNumbers === true} onChange={handleSave} label="Verberg nummering van de niveaus. Plaats een spatie tussen het nummer en de naam van het niveau (De nummering 1. ___ , 2. ___ wordt niet getoond in de app)" />

            <br />
            <CheckBox id="disablepublicfeedback" checked={settings.disablepublicfeedback === true} onChange={handleSave} label="Verberg feedback in pubieke toegang" />

            <br />
            <CheckBox id="disableprivatefeedback" checked={settings.disableprivatefeedback === true} onChange={handleSave} label="Verberg feedback voor ingelogde gebruikers" />
            <br />
            <CheckBox id="showSubscribe" checked={settings.showSubscribe === true} onChange={handleSave} label="Toon aanmeldformulier bij inlogvenster" />
            {settings.showSubscribe && <>
                <TextArea id="subscribeMailto" value={settings.subscribeMailto || ''} label="E-mailadres ontvanger aanmelding" onChange={handleChange} onBlur={handleSave} />
                <TextArea id="subscribeIntro" value={settings.subscribeIntro || ''} label="Introductie tekst aanmeldformulier" onChange={handleChange} onBlur={handleSave} />
                <TextArea id="subscribeDefaultReaction" value={settings.subscribeDefaultReaction || ''} label="Opzet mail reactie naar aanmelder" onChange={handleChange} onBlur={handleSave} />
              
                <br/><br/>
            <table style={{width:'100%'}}><tbody>
                    <tr><th>Toegang</th><th>Naamgeving toegang</th><th>Verantwoordelijke (E-mailadressen)</th></tr>
                    {accessList.map(access => {
                        return <tr key={access}><td> {access} </td><td> <TextField id={"access_" + access} value={getValueSubscription(access,'name')} label="" onChange={(ev)=>{handleChangeAccess(access,'name',ev.target.value)}}  onBlur={handleSaveWithoutUpdate}/> </td><td> <TextField id={"access_mail_" + access} value={getValueSubscription(access,'email')} label="" onChange={(ev)=>{handleChangeAccess(access,'email',ev.target.value)}}  onBlur={handleSaveWithoutUpdate}/> </td></tr>
                    })}
                </tbody></table>
            </>}

            <br />
            {/*<CheckBox id="useBeta" checked={settings.useBeta === true} onChange={handleSave} label="Gebruik functionaliteiten die nog in testfase zijn." />*/}

            <br /><br /><br />
        </div>
    )
}
