import React, { useState, useEffect } from 'react'
import Block from './block';
import BlockAdd from './block-add';
import { v4 as uuidv4 } from 'uuid';
import PageSettings from './settings/page';
import BlockSettings from './settings/block';
import LoadTemplate from './loadtemplate';
import ServiceFileAdmin from '../../../services-admin/files'
import ServiceFile from '../../../services/file'
import { Button } from '../../ui/button';
export default function Index({ builderFile, onChange, files, uploadFile, pages }) {
    const [showTemplate, setShowTemplate] = useState(false)
    const handleChangeBlock = (block) => {
        let tmpBuilderFile = Object.assign({}, builderFile);
        //check if excists 
        let found = tmpBuilderFile.blocks.find(b => { return b._id === block._id })

        if (!found) {
            tmpBuilderFile.blocks.push(block);
        }

        //check if deleted
        if (block.active === false) {
            tmpBuilderFile.blocks = tmpBuilderFile.blocks.filter(b => { return b._id !== block._id })
        }
        //setting new block items
        for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
            if (block._id === tmpBuilderFile.blocks[i]._id) {
                tmpBuilderFile.blocks[i] = Object.assign({}, block);
            }
        }
        //reorder sorting
        tmpBuilderFile.blocks = tmpBuilderFile.blocks.sort((a, b) => { return a.sort - b.sort })
        for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
            tmpBuilderFile.blocks[i].sort = i;
        }

        onChange(tmpBuilderFile);

    }

    const handleAddBlock = (type) => {
        if (type === "template") {
            setShowTemplate(true);
        } else {
            let tmpBuilderFile = Object.assign({}, builderFile);
            if (!tmpBuilderFile.blocks) tmpBuilderFile.blocks = [];
            let maxSort = 0;
            for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
                tmpBuilderFile.blocks[i].selected = false;
                if (tmpBuilderFile.blocks[i].sort > maxSort) maxSort = tmpBuilderFile.blocks[i].sort;
            }
            let newBlock = { _id: uuidv4(), type: type, sort: maxSort + 1, selected: true, publish: true }
            if (type === 'heading') {
                newBlock.headingSize = 'h1'
            }
            tmpBuilderFile.blocks.push(newBlock)
            onChange(tmpBuilderFile);
        }
    }

    const setSelectedBlock = (block) => {
        let tmpBuilderFile = Object.assign({}, builderFile);
        for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
            if (tmpBuilderFile.blocks[i]._id === block._id) {
                tmpBuilderFile.blocks[i].selected = true;
            } else {
                tmpBuilderFile.blocks[i].selected = false;
            }
        }
        onChange(tmpBuilderFile);
    }

    const getStyle = (block) => {
        // let lineStle = block.publish !== true ? 'dashed' : 'solid'
        if (block.selected) {
            return { opacity: '1' }
        } else {
            return { opacity: '0.4' }
        }
        return null
    }

    const handleSelectTemplate = (dpath) => {
        ServiceFile.getContentOnline(dpath)
        .then(template=>{
       

            let tmpBuilderFile = Object.assign({}, builderFile);
            let maxSort = 0;
            for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
                tmpBuilderFile.blocks[i].selected = false;
                if (tmpBuilderFile.blocks[i].sort > maxSort) maxSort = tmpBuilderFile.blocks[i].sort;
            }
            for(let i=0;i<template.blocks.length;i++){
                maxSort++;
                let tmpNewBlock = Object.assign({},template.blocks[i]);
                tmpNewBlock.sort = maxSort;
                tmpNewBlock._id =  uuidv4();
                tmpBuilderFile.blocks.push(tmpNewBlock)
            }
            onChange(tmpBuilderFile);
        })
        .catch(ex=>{console.log(ex)})
        setShowTemplate(false);
    }

    if (!builderFile) return null;

    if (showTemplate) { return <LoadTemplate onClose={() => { setShowTemplate(false) }} onSelect={handleSelectTemplate} /> }

    return (
        <div className="block-container">
            <div className="block-settings">
                <PageSettings builderFile={builderFile} onChange={onChange} />
                <BlockSettings builderFile={builderFile} block={builderFile.blocks.find(b => { return b.selected === true })} onChange={handleChangeBlock} onChangeBuilderFile={onChange} />
            
                <br style={{ clear: 'both' }} />
            </div>
            <div className="block-content">

                {builderFile && builderFile.blocks.sort((a, b) => { return a.sort - b.sort }).map((block, i) => {
                    return <div key={block._id} className='block' style={getStyle(block)} onClick={() => setSelectedBlock(block)}>

                        <div >
                            <Block block={block} onChange={handleChangeBlock} files={files} uploadFile={uploadFile} pages={pages} />
                        </div>
                    </div>
                })}
                <BlockAdd onSelect={handleAddBlock} />
                <div style={{ height: '1000px' }}>&nbsp;</div>
            </div>
        </div>
    )
}
