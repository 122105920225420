
import { PushNotifications } from "../plugins/capacitor";
import API from "./apiv2";
import Settings from "../AppSettings";
const Service = {
    initToken: () => { 
        if(PushNotifications && Settings.getPlatform()!=='web'){

            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.requestPermissions().then( result => {
                if (result.receive === 'granted') {
                //if (result.granted) {
                  // Register with Apple / Google to receive push via APNS/FCM
                  PushNotifications.register();
                } else {
                  // Show some error
                }
              })
              .catch(ex=>{
                  console.log('Request permission failed',ex)
              });
           
    
            // On succcess, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token) => {
                    console.log('Push registration success, token: ' , token.value);
                    localStorage.setItem('fcm',token.value);
                    Service.sendToken(token.value);
                }
            );
    
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error) => {
                    console.log('Error on registration: ' + JSON.stringify(error));
                }
            );
        }else{
           // console.log("No pushregistration plugin")
        }
    },
    sendToken:(token)=>{
        return new Promise((resolve,reject)=>{
            API.post('fcm',null,{item:token}).then(
                result => {
                     resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        })
       
    },
    setBadge: (number) => {
        //TODO
       
    }
}

export default Service