import React, { useState, useEffect,useContext } from 'react'
import { Button } from '../../ui/core';
import { Tabs } from '../../ui/tabs'
import ServiceSharepoint from '../../../services-admin/sharepoint';
import Settings from './setting';
import {ContextUI} from '../../../uiContext';
export default function Sync() {
    const contextUI = useContext(ContextUI);
    const [selectedTab, setSelectedTab] = useState(0);
    const [syncCopied, setSyncCopied] = useState('')
    const [syncFailed, setSyncFailed] = useState('')
    const [syncExcluded, setSyncExcluded] = useState('')
    const [syncDeleted, setSyncDeleted] = useState('');
    const [syncSettings, setSyncSettings] = useState([]);
    const [doneSync, setDoneSync] = useState(false)
    const getItems = () => {
        ServiceSharepoint.getlogs('sync', 'copied')
            .then(result => { setSyncCopied(result) }, err => { setSyncCopied("Geen gegevens aanwezig") })
        ServiceSharepoint.getlogs('sync', 'failed')
            .then(result => { setSyncFailed(result) }, err => { setSyncFailed("Geen gegevens aanwezig") })
        ServiceSharepoint.getlogs('sync', 'excluded')
            .then(result => { setSyncExcluded(result) }, err => { setSyncExcluded("Geen gegevens aanwezig") })
        ServiceSharepoint.getlogs('sync', 'delete')
            .then(result => { setSyncDeleted(result) }, err => { setSyncDeleted("Geen gegevens aanwezig") })
        ServiceSharepoint.getSettings()
            .then(result => { setSyncSettings(result) }, err => { setSyncSettings([]) })
    }

    useEffect(() => {
        getItems();
    }, [])

    const handleChangeSetting = (setting, index) => {
        let tmpSettings = Object.assign([], syncSettings);
        tmpSettings[index] = setting;
        setSyncSettings(tmpSettings); 
    }
    const handleDeleteSetting = (setting, index) => {
        let tmpSettings = Object.assign([], syncSettings);
        tmpSettings.splice(index,1);
        setSyncSettings(tmpSettings); 
    }
    const handleNew = () =>{
        let tmpSettings = Object.assign([], syncSettings);
        tmpSettings.push({})
        setSyncSettings(tmpSettings); 
    }
    const handleSaveSettings = () => {
        ServiceSharepoint.update(syncSettings)
        .then(()=>{contextUI.setMessage("Instellingen opgeslagen")})
    }
    const handleDelete = ()=>{
        ServiceSharepoint.syncDelete(syncSettings)
        .then(()=>{contextUI.setMessage("Bestanden zijn verwijderd.")})
    }
    return (
        <>
            <Button disabled={doneSync} onClick={() => { setDoneSync(true); ServiceSharepoint.sync(); }}>Uitvoeren synchronisatie</Button>    <Button onClick={()=>{getItems();contextUI.setMessage("Instellingen en logfiles worden opnieuw opgehaald.")}}>Refresh</Button>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="ui-tab">SITES</div>
                <div className="ui-tab">GEKOPIEERD ({syncCopied.split("\n").length - 1})</div>
                <div className="ui-tab">MISLUKT ({syncFailed.split("\n").length - 1})</div>
                <div className="ui-tab">UITGESLOTEN ({syncExcluded.split("\n").length - 1})</div>
                <div className="ui-tab">TE VERWIJDEREN ({syncDeleted.split("\n").length - 1})</div>
            </Tabs>
            <div className="padding" style={{ whiteSpace: 'pre-wrap' }}>
                {selectedTab === 0 && <>
                    <Button onClick={handleSaveSettings}>Opslaan</Button>
                    {syncSettings.map((setting, i) => {
                        return <div key={i}><Settings setting={setting} onChange={(setting) => handleChangeSetting(setting, i)} onDelete={(setting) => handleDeleteSetting(setting, i)} /><br /></div>
                    })}
                     <Button onClick={handleNew}>Nieuwe synchronisatie toevoegen</Button>
                </>}
                {selectedTab === 4 && syncDeleted.split("\n").length >1 && <>
                    <Button onClick={handleDelete}>Verwijder documenten</Button><br/><br/>
                  
                </>}
                {selectedTab === 1 && syncCopied}
                {selectedTab === 2 && syncFailed}
                {selectedTab === 3 && syncExcluded}
                {selectedTab === 4 && syncDeleted}
            </div>
            <br/><br/><br/>
        </>
    )
}
