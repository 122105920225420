import React from 'react'
import { Paper, TextField } from '../../ui/core';

export default function Settings(props) {

    const changeItem = (content,attribute)=>{ 
        let newItem = Object.assign({},props.form);
        newItem[attribute] = content;
        props.onChange(newItem);
    }
    // <TextField id="ID" fullWidth  label="ID" value={props.form.id || ''} onChange={(ev)=>{changeItem(ev.target.value,'id')}} style={{color:'gray !important'}} readOnly></TextField>    <br/><br/>       
           
    return (
        <Paper className="padding" style={{marginBottom:'6px'}}>
            <TextField id="title" fullWidth  label="Titel van dit formulier" value={props.form.title || ''} onChange={(ev)=>{changeItem(ev.target.value,'title')}} ></TextField><br/><br/>
            <TextField id="titlemail" fullWidth  label="Titel voor de E-mail" value={props.form.titlemail || ''} onChange={(ev)=>{changeItem(ev.target.value,'titlemail')}} ></TextField><br/><br/>
            <TextField id="recipients" fullWidth helper="meerdere adressen scheiden met een komma" label="Ontvanger van het formulier (E-mailadres)" value={props.form.recipients || ''} onChange={(ev)=>{changeItem(ev.target.value,'recipients')}} ></TextField><br/>
            <br/><br/>
          
         </Paper>
    )
}
