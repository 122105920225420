/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { Tabs, Paper } from '../components/ui/core';
import Chapters from '../components/settings/chapters';
import Updates from '../components/settings/updates';
import Account from '../components/settings/account';
import Sessions from '../components/settings/sessions';
import { ContextUI } from '../uiContext';
import { Context } from '../AppContext';
import Settings from "../AppSettings";

export default function SettingsPage(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
  
 
    const [selectedTab, setSelectedTab] = useState('0');

    useEffect(() => {
        contextUI.setShowBackButton(false);
        contextUI.setTitle('Instellingen');
        contextUI.setSubTitle('');

    }, [])

    return (
        <div>
            {context.hasOnlyAdmin() === false &&
                <Tabs
                    value={selectedTab}
                    onChange={(newVal) => { setSelectedTab(newVal) }}  >
                    <div className="ui-tab" id={'0'}>ALGEMEEN</div>
                    <div className="ui-tab" id={'1'}>UPDATES</div>
                    {Settings.getPlatform() !== 'web' && <div className="ui-tab" id={'2'}>HOOFDSTUKKEN</div>}
                  {context.info && context.info.User && context.info.User.generalAccount!==true && context.info.LoggedIn &&  <div className="ui-tab" id={'3'}>SESSIES</div>}
                </Tabs>
            }
            {selectedTab === '0' && <Account {...props} />}
            {selectedTab === '1' && <Updates  {...props} />}
            {selectedTab === '2' && <Chapters  {...props} />}
            {selectedTab === '3' && context.info && context.info.User && context.info.User.generalAccount!==true && context.info.LoggedIn && <Sessions  {...props} />}
            <br /><br /><br />
        </div>
    )
}
