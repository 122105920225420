import React, { useEffect, useState } from 'react'

import AnnouncementService from "../../../services-admin/announcements";
import Edit from './edit';
import GeneralService from "../../../services/general";
import { CheckBox,Fab ,List,ListItem,ListItemIcon,ListItemText} from '../../ui/core';
import {Add} from '../../ui/coreIcons';

export default function Announcements() {
    const [items, setItems] = useState([]);
    const [item , setItem] = useState();

    const getItems = () => {
        AnnouncementService.get()
            .then(result => {                    
                    setItems(GeneralService.sortJSON(result, 'date', '321'));
            })
    }

    const updateItem = (updateItem) => {
        updateItem.date = new Date();
        AnnouncementService.update(updateItem)
            .then(result => {                    
                getItems();
                setItem();
            })
    }

    const deleteItem = (delItem) => {
        AnnouncementService.deleteItem(delItem._id)
            .then(result => {                    
                getItems();
                setItem();
            })
    }

    useEffect(() => {
        getItems();
    }, [])
 
    return (
        <div>
        {item && <Edit item={item}  onClose={() => { setItem(null) }} onSave={updateItem} onDelete={deleteItem}/>}
         {!item && <Fab onClick={() => { setItem({_id:'0'}) }} edge="end" color="primary" aria-label="add" style={{ position: 'fixed', right: '15px', bottom: '35px', zIndex: '99999' }}><Add color="white" /></Fab>}
         {items.length===0 && <div style={{width:'100%',fontStyle:'italic',color:'gray',textAlign:'center'}}><br /><br />Geen mededelingen gevonden, klik hieronder om een nieuwe aan te maken.</div>}
           
        <List >
            {items.map((item) => {
                return <ListItem key={item._id} button onClick={() => { setItem(item) }}>
                    <ListItemText id={item._id} primary={item.title} secondary={GeneralService.formatDate(item.date,true)} />
                </ListItem>
            })}
        </List>
    </div>
    )
}
