import API from "./api";
const Service = {
    get: (sitename) => {
        return new Promise((resolve, reject) => {
            let url = 'websites';
            if(sitename) url += '/' + sitename;
            API.get(url).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (sitename) => {
        return new Promise((resolve, reject) => {
            let url = 'websites';
            if(sitename) url += '/' + sitename;
            API.deleteItem(url).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (website) => {
        return new Promise((resolve, reject) => {
            API.post('websites' , null, {item:website}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },    
    saveFilePart: (website,formData) => {
        return new Promise((resolve, reject) => {
            API.postFormData('websites/'+website, null, formData).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
}
export default Service;