/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { Context } from '../../AppContext';
import Level from './level';
import File from './file';
import Form from './form';
import FileService from '../../services/file';
import StorageService from '../../services/storage';
import AddressbookContact from './addressbookcontact';
import { CheckBox, Tabs } from '../ui/core';

export default function Searchresults(props) {
    const context = useContext(Context);
  //  const [index, setIndex] = useState([]);
    const [levels, setLevels] = useState([]);
    const [files, setFiles] = useState([]);
    const [pendingLoadAddresBook, setPendingLoadAddresBook] = useState([])
    const [contacts, setContacts] = useState([])
    const [documents, setDocuments] = useState([])
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchWholeWord,setSearchWholeWord] = useState(false);

    useEffect(() => {
        if (props.searchWord.length > 1) {
            let tmpLevels = [];
            let tmpFiles = [];
            let searchWords = [];
            if(searchWholeWord){
                searchWords.push(props.searchWord);
            }else{ 
                searchWords = props.searchWord.split(' ');
            }
            const tmpIndex = context.info.Index.filter((item) => {
                let result;
                for (let s = 0; s < searchWords.length; s++) {
                    if (searchWords[s].trim().length > 0) {
                        if (result !== false) {
                            result = ((item.Name.toLowerCase().indexOf(searchWords[s].toLowerCase()) >= 0 || item.Keywords.toLowerCase().indexOf(searchWords[s].toLowerCase()) >= 0));
                        }
                    }
                }

                if (result === true && tmpLevels.find((level) => { return level.HPath === item.HPath }) === undefined) {
                    tmpLevels.push({ HPath: item.HPath, Path: item.Path });
                }
                if (result === true && item.Extention !== 'note' && item.Extention !== 'html' && item.Extention !== 'json') {
                    tmpFiles.push(item);
                }
                return result;
            })
            setFiles(tmpFiles);
          //  setIndex(tmpIndex);
            setLevels(tmpLevels);
            let foundContacts = [];
            for (let c = 0; c < pendingLoadAddresBook.length; c++) {
                if (pendingLoadAddresBook[c].content) {
                    let tmpArr = []
                    tmpArr = pendingLoadAddresBook[c].content;
                    for (let s = 0; s < searchWords.length; s++) {
                        if (searchWords[s].trim().length > 0) {
                            tmpArr = tmpArr.filter((contact) => { return Object.values(contact).join(',').toLowerCase().indexOf(searchWords[s].toLowerCase()) >= 0 })
                        }
                    }
                    tmpArr = tmpArr.map(a => { a.Path = pendingLoadAddresBook[c].Path; return a; })
                    foundContacts = foundContacts.concat(tmpArr);
                }
            }
            setContacts(foundContacts);

        } else {
            setFiles([]);
        //    setIndex([]);
            setLevels([]);
            setContacts([]);
        }
    }, [props.searchWord, pendingLoadAddresBook,searchWholeWord]);

    //loading all addressbooks
    useEffect(() => {
        let adressbooks = context.info.Index.filter((item) => { return item.Name === 'adresboek.json' }).map((item) => { return { DPath: item.DPath, HFile: item.HFile, Checksum: item.Checksum, Path: item.Path } });
        setPendingLoadAddresBook(adressbooks);
    }, [context.info])

    //getting addresbook data

    const setPendingAddresBook = (DPath, content) => {
        let tmpPendingLoadAddresBook = Object.assign([], pendingLoadAddresBook);
        let found = tmpPendingLoadAddresBook.find((item) => { return item.DPath === DPath })
        if (found) {
            found.content = content;
        }
        setPendingLoadAddresBook(tmpPendingLoadAddresBook);
    }

    useEffect(() => {
        if (pendingLoadAddresBook.length > 0) {
            let nextLoad;
            for (let i = 0; i < pendingLoadAddresBook.length; i++) {
                if (!pendingLoadAddresBook[i].content) {
                    nextLoad = pendingLoadAddresBook[i];
                }
            }
            if (nextLoad) {
                FileService.getContent(nextLoad.DPath, StorageService.getStorageFileName(nextLoad), true)
                    .then(content => {
                        setPendingAddresBook(nextLoad.DPath, (typeof content === 'string') ? JSON.parse(content) : content);

                    })
                    .catch(err => {
                        console.log(err)
                    })


            }
        }
    }, [pendingLoadAddresBook])

    useEffect(() => {
        let tmpDocs = levels.map((level) => {
            let retValue = []
            retValue.push(<Level key={level.HPath} level={level} {...props} inSearchResult={true} />);
            for (let f = 0; f < files.length; f++) {
                if (files[f].HPath === level.HPath) {
                    if (files[f].Extention === 'form') {
                        retValue.push(<Form key={'form' + files[f].DPath} id={files[f].DPath} file={files[f]} />)
                    } else {
                        retValue.push(<File key={'file' + files[f].DPath} id={files[f].DPath} file={files[f]}  {...props} />)
                    }
                }

            }
            return retValue;
        })
        setDocuments(tmpDocs);
    }, [levels, files])

    useEffect(() => {
        if (contacts.length > 0 && documents.length === 0) {
            setSelectedTab(1)
        } else if (documents.length > 0 && contacts.length === 0) {
            setSelectedTab(0)
        }
    }, [documents, contacts])

    let prevContactPath = '';
    return (
        <div>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div style={{ fontWeight: documents.length > 0 ? 'bold' : 'normal' }}>DOCUMENTEN ({documents.length})</div>
                <div style={{ fontWeight: contacts.length > 0 ? 'bold' : 'normal' }}>CONTACTEN ({contacts.length})</div>
            </Tabs>
           
            {props.searchWord.indexOf(' ')>0 && <div className='padding'><CheckBox id="searchwholeword" checked={searchWholeWord} onChange={()=>setSearchWholeWord(!searchWholeWord)} label="Zoek op het volledige woord"></CheckBox></div>}
            {props.searchWord.length >= 2 && <div className="padding">Zoekresultaten voor <i>"{props.searchWord}"</i></div>}
            {selectedTab === 0 && <div>
                {documents}
            </div>}
            {selectedTab === 1 && <div>
                {contacts.map((contact) => {
                    let header = '';
                    if (contact.Path !== prevContactPath) {
                        prevContactPath = contact.Path;
                        header = prevContactPath;
                    }
                    return <span key={contact.ID} >{header && <div style={{ paddingLeft: '16px', paddingTop: '6px', fontSize: '10px', color: 'gray' }}>{header}</div>}<AddressbookContact key={contact.ID} contact={contact}></AddressbookContact></span>
                })}
            </div>}
        </div>
    )
}
