import React, { useState, useContext, useEffect } from 'react'

import { Tabs, Select, Tree, Paper } from '../../ui/core';

import { Context } from "../../../AppContext";
import Files from "./files";
import Keywords from "./keywords";
import Settings from "./settings";
import Revision from './revision';
import FileMenuNew from './files-menu-new';
import AccessMenu from './access-menu';
import FileService from "../../../services-admin/files"
import UseWindowSize from '../../../hooks/windowsize';
import FolderTree from './tree/index';
import ServiceGeneral from '../../../services/general';
import moment from 'moment';

export default function AdminIndex(props) {
    const context = useContext(Context);
    const windowsSize = UseWindowSize();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedVersion, setSelectedVersion] = useState('1')
    const [selectedLevel, setSelectedLevel] = useState();
    const [accessList, setAccesList] = useState([]);
    const [selectedAccess, setSelectedAccess] = useState(accessList[0]);
    const [levels, setLevels] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [revisions, setRevisions] = useState([]);
    const [revisionsExpired, setRevisionsExpired] = useState([]);
    const [revisionText, setRevisionText] = useState('');
    const folderOpen = <svg height="24" width="24" viewBox='0 0 48 48'><path fill="gray" d="M7 40q-1.15 0-2.075-.925Q4 38.15 4 37V11q0-1.15.925-2.075Q5.85 8 7 8h14.05l3 3H41q1.15 0 2.075.925Q44 12.85 44 14H22.75l-3-3H7v26l5.1-20H47l-5.35 20.7q-.3 1.2-1.1 1.75T38.5 40Zm3.15-3h28.6l4.2-17h-28.6Zm0 0 4.2-17-4.2 17ZM7 14v-3 3Z" /></svg>;
    const bellRed = <svg height="24" width="24" viewBox='0 0 48 48' ><path fill="#c01818" d="M6.2 19.65q0-4.05 1.7-7.675T12.75 5.8l2.05 2.25q-2.65 2.15-4.125 5.175T9.2 19.65Zm32.65 0q0-3.4-1.4-6.425T33.4 8.05l2.05-2.25q3.1 2.6 4.75 6.2t1.65 7.65ZM8 38v-3h4.2V19.7q0-4.2 2.475-7.475Q17.15 8.95 21.2 8.1V6.65q0-1.15.825-1.9T24 4q1.15 0 1.975.75.825.75.825 1.9V8.1q4.05.85 6.55 4.125t2.5 7.475V35H40v3Zm16-14.75ZM24 44q-1.6 0-2.8-1.175Q20 41.65 20 40h8q0 1.65-1.175 2.825Q25.65 44 24 44Zm-8.8-9h17.65V19.7q0-3.7-2.55-6.3-2.55-2.6-6.25-2.6t-6.275 2.6Q15.2 16 15.2 19.7Z" /></svg>
    const bellOrange = <svg height="24" width="24" viewBox='0 0 48 48' ><path fill="#f1b40d" d="M6.2 19.65q0-4.05 1.7-7.675T12.75 5.8l2.05 2.25q-2.65 2.15-4.125 5.175T9.2 19.65Zm32.65 0q0-3.4-1.4-6.425T33.4 8.05l2.05-2.25q3.1 2.6 4.75 6.2t1.65 7.65ZM8 38v-3h4.2V19.7q0-4.2 2.475-7.475Q17.15 8.95 21.2 8.1V6.65q0-1.15.825-1.9T24 4q1.15 0 1.975.75.825.75.825 1.9V8.1q4.05.85 6.55 4.125t2.5 7.475V35H40v3Zm16-14.75ZM24 44q-1.6 0-2.8-1.175Q20 41.65 20 40h8q0 1.65-1.175 2.825Q25.65 44 24 44Zm-8.8-9h17.65V19.7q0-3.7-2.55-6.3-2.55-2.6-6.25-2.6t-6.275 2.6Q15.2 16 15.2 19.7Z" /></svg>


    useEffect(() => {
        loadAccess();
    }, [])

    useEffect(() => {
        setRevisionText('')
    }, [selectedTab])

    const loadRevisions = () => {
        FileService.getRevisions(selectedVersion, selectedAccess)
            .then(revisions => {
                let tmpRevisions = revisions.map(revision => { revision.relativePath = revision.path.replace(selectedVersion + '/' + selectedAccess + '/', ''); return revision; })
                setRevisions(tmpRevisions);
                let expired = [];
                for(let i=0;i<tmpRevisions.length;i++){
                    if(tmpRevisions[i].revision.update){
                        let expireDate = new Date(tmpRevisions[i].revision.update);
                        if (expireDate < new Date()) {
                            expired.push(tmpRevisions[i]);
                        } 
                    }
                }
              setRevisionsExpired(expired);

            })
        setRevisionText('');
    }

    const loadAccess = () => {
        FileService.getAccess()
            .then(result => {
                let tmpAccessList = [];
                result = result.sort();
                for (let i = 0; i < result.length; i++) {
                    if (context.info.User.FileAccesslist && context.info.User.FileAccesslist.indexOf(result[i]) >= 0) {
                        tmpAccessList.push(result[i]);
                    }
                }
                setAccesList(tmpAccessList);
                
              //  setSelectedAccess(tmpAccessList[0]); 
             //   setSelectedLevel(); 
             //   setFiles([]) 
            }, err => { })
    }

    const loadAccessLevels = () => {
        if (selectedAccess) {
            loadRevisions();
            FileService.getLevels(selectedVersion, selectedAccess)
                .then(result => {
                    setLevels(result.sort());

                    if (context && context.info && context.info.Levels && !selectedLevel) {
                        let searchHPath = window.location.hash.replace('#/index/', '');
                        let found = context.info.Levels.find(level => { return level.HPath === searchHPath });
                        if (found) setSelectedLevel(found.Path.slice(0, found.Path.length - 1))
                    }

                }, err => { })
        }
    }

    useEffect(() => {
        loadAccessLevels();
    }, [selectedAccess])


    const loadFiles = () => {
        setLoading(true);
        if (selectedVersion && selectedAccess && selectedLevel) {
            FileService.get(selectedVersion, selectedAccess, selectedLevel)
                .then(files => {
                    setFiles(files);
                    setLoading(false);
                }, err => {
                    setFiles([]);
                    setLoading(false);
                })
        }

    }

    useEffect(() => {
        loadFiles();
    }, [selectedAccess, selectedLevel]);

    const handleChangeLevelName = (renameLevel) => {
        if (selectedLevel !== renameLevel) {
            FileService.renameLevel(selectedVersion, selectedAccess, selectedLevel, renameLevel)
                .then(result => {
                    loadAccessLevels();
                    setSelectedLevel(renameLevel);
                }, err => {
                    context.setMessage('Verplaatsen van het niveau is mislukt')
                })
        }
    }

    const getRevision = (level) => {
        //  relativePath
        let found = revisions.find(rev => { return rev.relativePath === level });

        if (found && found.revision && found.revision.update) {
            let expireDate = new Date(found.revision.update);
            if (expireDate < new Date()) {
                return bellRed;
            } else {
                var timeDiff = moment(expireDate).diff(moment(), 'months', true);
                if (timeDiff < 1) {
                    return bellOrange;
                }
            }

        }
        return null;
    }

    useEffect(() => {
        setRevisionText('')
    }, [selectedLevel])
 
    return (
        <div>
            {selectedTab === 0 && <FileMenuNew onRefresh={() => { loadFiles(); loadAccess(); loadAccessLevels(); }} files={files} version={selectedVersion} access={selectedAccess} level={selectedLevel} pages={levels} />}

            <table style={{ width: '100%' }} ><tbody>
                <tr><td style={{ width: '100%' }} className="padding">
                    <Select
                        label="Toegang"
                        id="Access"
                        value={selectedAccess || ''}
                        onChange={(ev) => { setSelectedAccess(ev.target.value); setSelectedLevel(); setFiles([]) }}
                    >
                        <option value=""></option>
                        {accessList.map((access) => { return <option key={access} value={access}>{access}</option> })}
                    </Select>
                </td><td>{selectedAccess !== 'publiek' && selectedAccess !== 'prive' && selectedAccess !== '' && selectedAccess && <AccessMenu version={selectedVersion} access={selectedAccess} onRefresh={loadAccess} />}
                    </td>
                </tr>
                {windowsSize.innerWidth <= 800 && <tr><td className="padding">
                    <Select
                        label="Niveau"
                        id="Level"
                        value={selectedLevel || ''}
                        onChange={(ev) => { setSelectedLevel(ev.target.value) }}
                    >
                        <option value=""></option>
                        {levels.map((level) => { return <option value={level} key={level}>{level}</option> })}

                    </Select>
                </td><td></td>
                </tr>}
            </tbody></table>

            {revisionsExpired.length > 0 && <>
                <div className="padding" style={{ margin: '8px' }}><div className='label'>Verlopen revisies</div>
                    <table cellSpacing={4}><tbody>
                        <tr><th></th><th>Niveau</th><th>Datum</th><th>Verantwoordelijke</th></tr>
                        {
                           ServiceGeneral.sortJSON(revisionsExpired,'relativePath','123').map(rev => {
                                return <tr key={rev.dpath} onClick={()=>{setSelectedLevel(rev.relativePath)}} style={{cursor:'pointer'}}><td>{folderOpen}</td><td>{rev.relativePath}</td><td>{rev.revision.update && ServiceGeneral.formatDate(rev.revision.update, false)}</td><td>{rev.revision.name}</td></tr>
                            })
                        }
                    </tbody></table>
                </div>
            </>}


            <table style={{ width: '100%' }}><tbody>
                <tr>
                    {windowsSize.innerWidth > 800 && <td style={{ verticalAlign: 'top', width: '30%' }} className="padding">
                        {levels.length > 0 && <div className="label">Niveau's</div>}
                        <div style={{ height: (window.innerHeight - 240), overflow: 'auto' }}><FolderTree items={levels} revisions={revisions} value={selectedLevel} onSelectedLevel={setSelectedLevel} /></div>
                        {/*  <div style={{height:(window.innerHeight-240),overflow:'auto'}}><Tree items={levels} value={selectedLevel} onClick={setSelectedLevel}/></div>*/}
                    </td>}
                    {selectedAccess && selectedLevel && <td style={{ verticalAlign: 'top' }}>
                        <Tabs value={selectedTab} onChange={setSelectedTab}>
                            <div className="ui-tab">DOCUMENTEN</div>
                            <div className="ui-tab">ZOEKWOORDEN</div>
                            <div className="ui-tab">INSTELLINGEN</div>
                            {<div className="ui-tab">REVISIE <div style={{ position: 'absolute', marginLeft: '54px', marginTop: '-20px' }}>{getRevision(selectedLevel)}</div></div>}
                        </Tabs>
                        <div style={{ color: 'gray', fontSize: '10px', padding: '4px' }}>/{selectedLevel}</div>

                        <div style={{ height: (window.innerHeight - 280), overflow: 'auto' }}>
                            {selectedTab === 0 && <Files onRefresh={() => { setRevisionText("Heeft u iets gewijzigd?, vergeet niet het revisie document bij te werken!"); loadFiles(); }} version={selectedVersion} access={selectedAccess} level={selectedLevel} levels={levels} files={files} revisionText={revisionText} onShowRevision={() => { setRevisionText(''); setSelectedTab(3) }} onUpdateRevision={(text) => { setRevisionText(text) }} />}
                            {selectedTab === 1 && <Keywords version={selectedVersion} access={selectedAccess} level={selectedLevel} />}
                            {selectedTab === 2 && <Settings version={selectedVersion} access={selectedAccess} level={selectedLevel} levels={levels} files={files} onChange={handleChangeLevelName} onRefresh={() => { setSelectedLevel(); loadAccessLevels() }} />}
                            {selectedTab === 3 && <Revision version={selectedVersion} access={selectedAccess} level={selectedLevel} onRefresh={loadRevisions} revisionText={revisionText} />}
                        </div>
                    </td>}
                </tr>
            </tbody></table>


            <br /><br /><br />



        </div>
    )
}
