import React, { useState, useContext } from 'react'

import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Button, Typography } from '../../../ui/core';

import FilesService from "../../../../services-admin/files";

export default function Rename(props) {
  const [newName, setNewName] = useState(props.file.filename);

  const save = () => {
    FilesService.rename(props.version, props.access, props.level, props.file.filename, newName.replace(/\//g, '_'))
      .then(result => {
        props.onRefresh();
        props.onClose();
      }, (err) => {
        props.onRefresh();
        props.onClose();
      })

  }
  return (
    <Dialog fullWidth maxWidth={'xl'} open={true} onClose={props.onClose}>
      <DialogTitle><Typography component="h2">Aanpassen naamgeving</Typography></DialogTitle>
      <DialogContent >
        <TextField id='newname' label="Nieuwe naam" fullWidth value={newName} onChange={(ev) => setNewName(ev.target.value)}></TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>Annuleren</Button>
        <Button variant="contained" color="primary" onClick={save} disabled={newName === props.file.filename}>Wijzigen</Button>
      </DialogActions>
    </Dialog>
  )
}
