import React, { useEffect,useState } from 'react';
import PushNotification from '../../../services-admin/pushnotifications';
import {  IconButton} from '../../ui/core';
import {Delete,Send} from '../../ui/coreIcons';
import GeneralFunctions from '../../../services/general';
const PushMessages = ({user}) => {
    const [pushRegistrations,setPushRegistrations] = useState([]);

    const getPushRegistrations = () => {
        PushNotification.getPushMessages(user._id).then((messages) => {
            setPushRegistrations(messages);
        });
    }
    useEffect(() => {
        if(user){
            getPushRegistrations();
        }
    }, [user]);

    const handleDelete = (id) => {
        if(window.confirm('Weet u zeker dat u deze push registratie wilt verwijderen?')){
            PushNotification.deletePushMessage(id).then((result) => {
                getPushRegistrations();
            });
        }
    }
    const handleSend = (id) => { 
      PushNotification.sendTestMessage(id).then((result) => {
        console.log('send test message', result);
        alert('Test bericht is verstuurd');
      } )
    }

    return (
        <div>
         
          <table className='w3-table'>
            <thead>
              <tr>               
                <th>UUID</th>
                <th>Token</th>
                <th>Aangemaakt</th>
                <th>Laatst gewijzigd</th>
                <th>Verstuur test</th>
                <th>Verwijder</th>
              </tr>
            </thead>
            <tbody>
              {pushRegistrations.map((item,index) => (
                <tr key={index}>               
                  <td>{item.uuid}</td>
                  <td>{item.token && item.token.substr(0,10) + '...'}</td>
                  <td>{GeneralFunctions.formatDate(item.created,true)}</td>
                  <td>{GeneralFunctions.formatDate(item.lastModified,true)}</td>
                  <td><IconButton variant="outlined" onClick={(ev) => { handleSend(item._id) }}><Send/></IconButton></td>
                  <td> <IconButton variant="outlined" onClick={(ev) => { handleDelete(item._id) }}><Delete/></IconButton></td>
                </tr>
              ))}
            </tbody>
            </table>
        </div>
    );
};

export default PushMessages;